// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { Transforms } from 'slate';
import { Box, Typography, darken, lighten } from '@mui/material';

// Local
import { insertMention } from './InsertMention';

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const MentionDropdown = ({
  refProp,
  chars,
  index,
  setTarget,
  editor,
  target,
}) => {
  return (
    <Portal>
      <Box
        ref={refProp}
        sx={{
          top: '-9999px',
          left: '-9999px',
          position: 'absolute',
          zIndex: 2,
          padding: '1px',
          background: 'inherit',
          boxShadow: '0 1px 5px rgba(0,0,0,.2)',
        }}
        data-cy="mentions-portal"
      >
        {chars.map((char, i) => (
          <Typography
            key={i}
            onClick={() => {
              Transforms.select(editor, target);
              insertMention(editor, char);
              setTarget(null);
            }}
            sx={{
              color: (theme) => theme.palette.text.primary,
              padding: '10px',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' && i === index
                  ? darken(theme.palette.background.paper, 0.05)
                  : lighten(theme.palette.background.paper, 0.05),
            }}
          >
            {char.citation}
          </Typography>
        ))}
      </Box>
    </Portal>
  );
};

export default MentionDropdown;
