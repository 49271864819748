// Libraries
import React from "react";
import { Typography } from "@mui/material";

const Title = ({ paper }) => (
  <Typography
    variant="body1"
    sx={{
      textAlign: "flex-start",
      fontWeight: "bold",
    }}
  >
    {paper.Title}
  </Typography>
);

export default Title;
