import React from 'react';
import { Box } from '@mui/material';
import Menu from './Menu';

export default function Layout({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        background: 'background.paper',
      }}
    >
      <Menu />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          // height: 'auto',
          alignItems: 'center',
          marginTop: { xs: '54px', sm: '64px' },
          width: '100%',
          boxSizing: 'border-box',
          overflowY: 'auto',
          overflowX: 'hidden',
          color: 'text.primary',
          backgroundColor: 'background.paper',
          paddingLeft: { xs: 0, xl: '10%' },
          paddingRight: { xs: 0, xl: '10%' },
          height: [
            '100%',
            '-moz-available', // For older versions of Firefox
            '-webkit-fill-available', // For WebKit-based browsers
            'fill-available', // Standard (or fallback)
          ],
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
