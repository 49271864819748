// Libraries
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  // Stack, Link
} from '@mui/material';

// Local
import Form from './Form';
import Footer from '../Common/Footer';

const Login = ({ title, session, refetch }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (session && session.status === 'success') {
      navigate('/notes');
    }

    document.title = title;
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '400px',
          padding: '20px',
          boxSizing: 'border-box',
          height: '100vh',
        }}
      >
        <Form refetch={refetch} />
        {/* <Stack
            direction="column"
            spacing={2}
            sx={{
              marginTop: '10px',
              border: '1px',
              textAlign: 'center',
              fontFamily: 'Arial',
            }}
          >
            <Link
              onClick={() => {
                navigate('/recover');
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              Forgotten password
            </Link>
            <Link
              onClick={() => {
                navigate('/register');
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              Register
            </Link>
            <Link
              onClick={() => {
                navigate('/resend');
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              Email confirmation
            </Link>
          </Stack> */}
      </Box>
      <Footer />
    </>
  );
};

export default Login;
