// Libraries
import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

// Local
import Error from './Error';

export const PaperForm = ({ onSubmit }) => {
  const [publicationYear, setPublicationYear] = useState(null);
  const [publicationMonth, setPublicationMonth] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    e.stopPropagation();
    register({ name: e.target.name }, e.target.value);
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
        }}
        onSubmit={handleSubmit((data) => 
          onSubmit({
            ...data, 
            Year: `${publicationYear?.year() ?? ""}`, 
            Month: `${publicationMonth ? publicationMonth.month() + 1 : ""}`
          }))}
      >
        <TextField
          required
          type="text"
          name="Title"
          placeholder="Add paper title"
          sx={{ marginBottom: '10px' }}
          onChange={handleChange}
          {...register('Title', {
            required: 'A paper title must be at least min 5 characters long',
            min: 5,
          })}
        />
        <TextField
          type="text"
          name="Author"
          placeholder="Add paper authors, separated by comma"
          sx={{ marginBottom: '10px' }}
          onChange={handleChange}
          {...register('Author')}
        />
        <TextField
          InputProps={{
            maxRows: 5,
            multiline: true,
          }}
          type="text"
          name="Abstract"
          placeholder="Add paper abstract"
          sx={{ marginBottom: '10px' }}
          onChange={handleChange}
          {...register('Abstract')}
        />
        <TextField
          type="text"
          name="Venue"
          placeholder="Add paper venue"
          sx={{ marginBottom: '10px' }}
          onChange={handleChange}
          {...register('Venue')}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year']}
            placeholder="Publication Year"
            minDate={dayjs('1800-01-01')}
            maxDate={dayjs('2030-01-01')}
            value={publicationYear}
            onChange={(newValue) => setPublicationYear(newValue)}
            sx={{
              marginBottom: "10px",
            }}
          />
          <DatePicker
            views={['month']}
            placeholder="Publication Month"
            value={publicationMonth}
            onChange={(newValue) => setPublicationMonth(newValue)}
            sx={{
              marginBottom: "10px",
            }}
          />
        </LocalizationProvider>
        {errors.title && <Error response={{ error: errors.title.message }} />}
        {errors.year && <Error response={{ error: errors.year.message }} />}
        {errors.month && <Error response={{ error: errors.month.message }} />}
        <Button variant="outlined" type="submit" sx={{ marginTop: '10px' }}>
          Add paper
        </Button>
      </Box>
    </>
  );
};

