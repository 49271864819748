// Libraries
import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { Box, Typography, Alert, Fade, Badge } from '@mui/material';

// Local
import { DocumentContext } from '../../../DocumentContext';
import Selection from '../Selection';

const SortedView = ({ showSelection }) => {
  const [sentences, setSentences] = useState([]);
  const context = useContext(DocumentContext);

  useEffect(() => {
    let orderedSentences = [];
    const paper = context.paper;

    if (
      paper.Content &&
      paper.Content.Abstract_Parsed &&
      paper.Content.Abstract_Parsed.length > 0
    ) {
      paper.Content.Abstract_Parsed.forEach((section) =>
        section.section_text.forEach((paragraph) =>
          paragraph.paragraph_text.forEach((sentence) => {
            if (sentence.sentence_similarity > 0) {
              orderedSentences.push({
                ...sentence,
                scroll_id: `${'Abstract_Parsed'}#${section.section_id}-${
                  paragraph.paragraph_id
                }-${sentence.sentence_id}`,
              });
            }
          })
        )
      );
    }

    if (
      paper.Content &&
      paper.Content.Fullbody_Parsed &&
      paper.Content.Fullbody_Parsed.length > 0
    ) {
      paper.Content.Fullbody_Parsed.forEach((section) => {
        section.section_text.forEach((paragraph) => {
          paragraph.paragraph_text.forEach((sentence) => {
            if (sentence.sentence_similarity > 0) {
              orderedSentences.push({
                ...sentence,
                scroll_id: `${'Fullbody_Parsed'}#${section.section_id}-${
                  paragraph.paragraph_id
                }-${sentence.sentence_id}`,
              });
            }
          });
        });
      });
    }

    orderedSentences = _.orderBy(
      orderedSentences,
      ['sentence_similarity'],
      ['desc']
    );

    setSentences(orderedSentences);
  }, [showSelection, context.paper]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: 'calc(100vh - 51px - 25px)',
          sm: 'calc(100vh - 51px - 25px - 35px)',
        },
        boxSizing: 'border-box',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <Selection />
      <Box sx={{ marginBottom: '10px' }} />
      {sentences.map((sentence, index) => (
        <Fade in={true} timeout={2000} key={index}>
          <Badge
            badgeContent={`${Math.round(sentence.sentence_similarity * 100)}%`}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              zIndex: 0,
              opacity: 0.5,
              // Add custom styling to move the badge slightly downwards
              '.MuiBadge-badge': {
                marginTop: '8px', // Adjust this value to move the badge down
                marginRight: '22px', // Adjust this value to move the badge down
              },
            }}
            color="primary"
          >
            <Typography
              onClick={async () => {
                await context.updateContext('sortedView', false);
                context.updateContext('scrollToSentenceId', sentence.scroll_id);
              }}
              variant="body2"
              sx={{
                width: '100%',
                marginTop: '5px',
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '5px',
                padding: '5px',
                cursor: 'pointer',
                border: '1px solid lightgrey',
                color: 'black',
                background:
                  sentence.sentence_similarity >= 0.66
                    ? '#fbc02d'
                    : sentence.sentence_similarity >= 0.33 &&
                      sentence.sentence_similarity >= 0.65
                    ? '#fdd835'
                    : sentence.sentence_similarity >= 0.1 &&
                      sentence.sentence_similarity >= 0.32
                    ? '#ffeb3b'
                    : 'none',
              }}
            >
              {sentence.sentence_text}
            </Typography>
          </Badge>
        </Fade>
      ))}
      <Alert
        severity="info"
        sx={{
          display: sentences.length > 0 && 'none',
          margin: '10px',
        }}
      >
        No matching sentences
      </Alert>
    </Box>
  );
};

export default SortedView;
