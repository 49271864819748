// Libraries
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// Local
import { LOGOUT_FROM_SESSION } from '../Queries';
import Constants from '../constants';

export const useSignOut = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const signOut = () => {
    client
      .query({
        query: LOGOUT_FROM_SESSION,
        fetchPolicy: 'network-only',
      })
      .then(() => {
        Constants.TOKEN = '';
        client.resetStore().then(() => {
          // If you need to perform any actions right after the store has been reset, do it here.
          window.location.reload(false); // Consider if you really need this, as it will reload the whole page.
        });
        navigate('/');
      })
      .catch((err) => {
        // Handle error
        console.error('Error during sign out:', err);
      });
  };

  return signOut;
};
