import { Author } from '../Types';

/**
 * Format authors as provided in IEEE style
 *
 * @param authors - list of authors
 * @returns formatted authors
 */

export const formatAuthors = (authors: Author[]): string[] =>
  authors.map((author) => {
    const initials = author.GivenName.split(' ')
      .map((name) => name.charAt(0).toUpperCase() + '.')
      .join(' ');

    const familyName =
      author.FamilyName.charAt(0).toUpperCase() + author.FamilyName.slice(1);

    return `${initials} ${familyName}`;
  });
