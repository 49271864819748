// Libraries
import React, { useRef, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useLazyQuery } from '@apollo/client';
import { useSlate, useFocused } from 'slate-react';
import { Editor, Node } from 'slate';
import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import SearchRounded from '@mui/icons-material/SearchRounded';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// Local
import {
  HIGHLIGHT_PAPER,
  DOCUMENT_SEARCH,
  KEYWORDS_BASED_ON_SEARCH,
  PAGINATED_SEARCH,
} from '../Queries/nlp.js';
import { DocumentContext } from '../DocumentContext';
import { ModularContext } from '../../Modular/ModularContext.js';
import { notify } from '../../Common/notify.js';
import { addScoresToSearchResults } from '../Utilities/addScoresToSearchResults.js';
import updateAnnotatorX from '../Explorer/Annotator/updateAnnotatorX.js';

const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const EditorToolbar = ({ modular, editorBoxRef }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);

  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();

  const [keywordsBasedOnSearch] = useLazyQuery(KEYWORDS_BASED_ON_SEARCH);

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH);

  const [documentSearch] = useLazyQuery(DOCUMENT_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ documentSearch }) => {
      context.updateContext('paperIds', documentSearch.response.paper_list);
      context.updateContext(
        'rerankingScores',
        documentSearch.response.reranking_scores
      );
      context.updateContext(
        'prefetchingScores',
        documentSearch.response.prefetching_scores
      );
      context.updateContext(
        'pageCount',
        documentSearch.response.paper_list.length / 10
      );

      paginatedSearch({
        fetchPolicy: 'network-only',
        variables: {
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list.slice(0, 10),
        },
        onCompleted: ({ paginatedSearch }) => {
          const updatedSearchResults = addScoresToSearchResults(
            paginatedSearch.response, // in batches of 10 papers
            documentSearch.response.reranking_scores, // scores for all papers
            documentSearch.response.prefetching_scores, // prefetching scores for all papers
            1, // current page
            10 // items per page
          );

          context.updateContext('searchResults', updatedSearchResults);
          context.updateContext('currentPage', 1);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', 'Retrieved top papers');
          context.updateContext('tab', 'Discover');
        },
      });

      keywordsBasedOnSearch({
        fetchPolicy: 'network-only',
        variables: {
          ranking_variable: context.searchTerm,
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list,
        },
        onCompleted: ({ keywordsBasedOnSearch }) => {
          context.updateContext(
            'keywordsBasedOnSearch',
            keywordsBasedOnSearch.response
          );
        },
      });
    },
  });

  const [highlightPaper] = useLazyQuery(HIGHLIGHT_PAPER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ highlightPaper }) => {
      if (highlightPaper.status === 'success') {
        context.updateContext('tab', 'View');
        context.updateContext('showSelection', true);
        context.updateContext('paper', highlightPaper.response);
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Retrieved similar sentences');
        context.updateContext('tab', 'View');
      } else {
        context.updateContext('loadingRequest', false);
        notify('Sentence highlighting did not work with the provided text');
      }
    },
  });

  const returnSelection = () => {
    const { selection } = editor;

    if (selection) {
      const selectedText = Editor.string(editor, editor.selection);
      return selectedText;
    }

    return '';
  };

  function extractTextFromNode(node) {
    if ('text' in node) {
      return node.text;
    } else if ('citation' in node) {
      return node.citation;
    } else if ('children' in node) {
      return node.children.map(extractTextFromNode).join('');
    }
    return '';
  }

  const returnSelectionSpan = () => {
    const { selection } = editor;

    if (!selection) {
      return null;
    }

    const fragment = editor.getFragment();
    const text = fragment.map(extractTextFromNode).join('');
    const anchor = selection.anchor;
    const focus = selection.focus;
    const type = fragment.map((item) => item.type);
    if (!anchor || !focus) {
      notify('Something went wrong with your text selection.');
      return {};
    }

    return {
      text: text,
      anchor: anchor,
      focus: focus,
      type: type,
    };
  };

  const returnParagraphCitedIds = (anchor) => {
    const paragraphCitedIds = [];
    Node.parent(editor, anchor.path).children.forEach((child) => {
      if (child.type === 'citation') {
        paragraphCitedIds.push(
          `${child.metadata.collection}_${child.metadata.id_value}`
        );
      }
    });
    return paragraphCitedIds;
  };

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el || !selection || !inFocus) {
      return;
    }
    const editorBox = editorBoxRef.current;

    const updatePosition = () => {
      const domSelection = window.getSelection();
      if (!domSelection || domSelection.rangeCount === 0) {
        el.removeAttribute('style');
        return;
      }

      try {
        const domRange = domSelection.getRangeAt(0);
        if (domRange.collapsed) {
          el.removeAttribute('style');
          return;
        }
        const rect = domRange.getBoundingClientRect();
        el.style.opacity = '1';
        const relativeToolbarPosY = rect.top - el.offsetHeight;
        const editorHeight = editorBox.clientHeight;
        const editorWidth = editorBox.clientWidth;

        // handle horizontal position
        const centerSelectionPosition = rect.left + rect.width / 2;
        let toolbarPosLeft;
        if (centerSelectionPosition - el.offsetWidth / 2 < 0) {
          toolbarPosLeft = 0;
          el.style.borderLeft = 0;
          el.style.borderRight = '';
        } else if (centerSelectionPosition + el.offsetWidth / 2 > editorWidth) {
          toolbarPosLeft = editorWidth - el.offsetWidth;
          el.style.borderLeft = '';
          el.style.borderRight = 0;
        } else {
          toolbarPosLeft = centerSelectionPosition - el.offsetWidth / 2;
          el.style.borderLeft = '';
          el.style.borderRight = '';
        }

        // handle verical position
        if (relativeToolbarPosY < 35) {
          el.style.borderBottom = '';
          el.style.borderTop = 0;
          el.style.top = `${35}px`;
          el.style.left = `${toolbarPosLeft}px`;
          if (editorBox) {
            editorBox.addEventListener('scroll', updatePosition);
          }
        } else if (relativeToolbarPosY > editorHeight - 35) {
          el.style.borderBottom = 0;
          el.style.borderTop = '';
          el.style.top = `${editorHeight - 35}px`;
          el.style.left = `${toolbarPosLeft}px`;
          if (editorBox) {
            editorBox.addEventListener('scroll', updatePosition);
          }
        } else {
          el.style.borderBottom = '';
          el.style.borderTop = '';
          el.style.top = `${rect.top - el.offsetHeight}px`;
          el.style.left = `${toolbarPosLeft}px`;
          if (editorBox) {
            // Setup scroll event listener on the editor box
            editorBox.addEventListener('scroll', updatePosition);
          }
        }
      } catch (error) {
        console.error('Error positioning toolbar:', error);
        el.removeAttribute('style');
      }
    };

    updatePosition();

    return () => {
      if (editorBox) {
        editorBox.removeEventListener('scroll', updatePosition);
      }
    };
  }, [editor.selection]); // Depend on selection and inFocus to re-run useEffect

  // Do not display toolbar or provide functionality in modular mode
  return modular ? null : (
    <Portal>
      <Stack
        direction={'row'}
        spacing={1}
        ref={ref}
        sx={{
          padding: '8px 7px 6px',
          position: 'absolute',
          zIndex: 0,
          marginTop: '-10px',
          opacity: 0,
          bgcolor: 'background.paper',
          borderRadius: '5px',
          transition: 'opacity 0.75s',
          border: (theme) =>
            theme.palette.mode === 'dark'
              ? '1px solid #333333'
              : '1px solid #d4d4d4',
        }}
      >
        <Tooltip title="Search for papers using this text" placement="top">
          <IconButton
            aria-label="search"
            size="small"
            onMouseDown={(e) => {
              e.preventDefault();

              const text = returnSelection();

              context.updateContext('searchTerm', text);
              context.updateContext('loadingRequest', true);
              context.updateContext('loadingMessage', 'Retrieving papers');

              documentSearch({
                variables: {
                  ranking_variable: text,
                  keywrods: context.keywords,
                },
              });
            }}
          >
            <SearchRounded fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Find similar sentences in the paper'} placement="top">
          <Box component="span">
            <IconButton
              disabled={!context.paper}
              aria-label="highlight"
              size="small"
              onMouseDown={() => {
                const text = returnSelection();

                context.updateContext('highlightSelection', text);
                context.updateContext(
                  'loadingMessage',
                  'Retrieving similar sentences'
                );
                context.updateContext('loadingRequest', true);

                const {
                  rankingCollection,
                  rankingIdField,
                  rankingIdValue,
                  rankingIdType,
                } = context.rankingDetails;

                highlightPaper({
                  variables: {
                    highlight_source: text,
                    paper_id: {
                      collection: rankingCollection,
                      id_value: rankingIdValue,
                      id_field: rankingIdField,
                      id_type: rankingIdType,
                    },
                  },
                });
              }}
            >
              <CompareArrowsOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Tooltip>
        {/* <Tooltip title="Add to citation generation sentences" placement="top">
          <Box component="span">
            <IconButton
              disabled={!context.paper}
              aria-label="citation sentence"
              size="small"
              onMouseDown={() => {
                if (context.paper) {
                  const text = returnSelection();
                  context.updateContext('highlightSelection', text);

                  const newSentences = [...context.generatorSentences, text];
                  context.updateContext('generatorContextText', newSentences);
                  context.updateContext('tab', 'View');
                  context.updateContext('generatorView', true);
                } else {
                  notify('You must be viewing a paper first');
                }
              }}
            >
              <AutoFixHighIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Tooltip> */}
        <Tooltip
          title="Add to influenced content"
          placement="top"
          sx={{
            display: context.user?.isDataLabeler ? 'inherit' : 'none',
          }}
        >
          <Box component="span">
            <IconButton
              disabled={
                context.tab !== 'View' ||
                !context.paper ||
                !context.annotatorView
              }
              aria-label="add to x"
              size="small"
              style={{ display: 'flex', alignItems: 'center' }}
              onMouseDown={() => {
                // get selected span
                const newSpan = returnSelectionSpan();
                context.updateContext('highlightSelection', newSpan.text);

                // update selected span with id of paper being viewed
                newSpan.paperId = context.annotatorPaperId;

                // (try to) update annotator spans with new span
                const paragraphCitedIds = returnParagraphCitedIds(
                  newSpan.anchor
                );
                const spans = updateAnnotatorX(
                  newSpan,
                  context.annotatorX,
                  paragraphCitedIds,
                  context.annotatorPaperId
                );
                if (spans.length > 0) {
                  context.updateContext('annotatorX', spans);
                } else {
                  notify('Please check the annotation manual.');
                }
              }}
            >
              <div style={{ fontSize: 'smaller' }}>+ Influenced content</div>
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Portal>
  );
};

export default EditorToolbar;
