/**
 * Initial value for creating new Slate documents
 */

export const InitialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
