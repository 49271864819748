import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Collapse,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const TextBox = ({ title, listItems, readMore = true, isLeft }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => setIsOpen(!isOpen);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isLeft ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        flex: '1 1 auto',
        height: '100%',
        marginTop: { xs: '20px', md: '0px' },
        width: { sm: '100%', md: 'auto' },
        mr: { xs: 2, md: 10 },
        ml: { xs: 2, md: 10 },
      }}
    >
      <Stack
        direction="column"
        sx={{ mb: '20px', textAlign: 'left', maxWidth: '800px' }}
      >
        <Typography variant="h3" sx={{ mb: '10px', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <List dense>
          {listItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="h6">{item}</Typography>}
              />
            </ListItem>
          ))}
        </List>
        {readMore && (
          <Stack direction="column" alignItems="center">
            <Button variant="text" onClick={toggleCollapse}>
              {isOpen ? 'Read less' : 'Read more'}
            </Button>
            <Collapse in={isOpen}>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography variant="body1" paragraph>
                  {readMore.body}
                </Typography>
                {readMore.refLink && (
                  <Typography
                    variant="body2"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(readMore.refLink, '_blank', 'noreferrer')
                    }
                  >
                    {readMore.ref}
                  </Typography>
                )}
              </Box>
            </Collapse>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default TextBox;
