// Library
import React, { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Local
import { GENERATE_BIBTEX } from '../../Queries/nlp';
import { DocumentContext } from '../../DocumentContext';
import { exportDocument, exportDocx } from '../../Requests/Document';
import { getReferences } from '../../Editor/Utilities/getReferences';

const ExportManuscript = ({ client, setBackdrop, setBackdropText }) => {
  const context = useContext(DocumentContext);

  const [generateBibtex] = useLazyQuery(GENERATE_BIBTEX);

  const retrieveMetadata = () => {
    const references = getReferences(context.editorRef);
    const metadata = references.map((item) => item[0].metadata);
    return metadata;
  };

  const getBib = async () => {
    const paperList = retrieveMetadata();

    let bib = '';

    await generateBibtex({
      fetchPolicy: 'network-only',
      variables: {
        paper_list: paperList,
      },
      onCompleted: ({ generateBibtex }) => {
        if (generateBibtex.status === 'success') {
          bib = generateBibtex.response.map((item) => item).join('');
        }
      },
    });

    return bib;
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return (
    <>
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={async () => {
          setBackdrop(true);
          setBackdropText('Exporting your note, please wait...');

          const bib = await getBib();
          const results = getReferences(context.editorRef);

          const metadata = results
            .filter(
              (result) =>
                result[0].metadata && !isEmptyObject(result[0].metadata)
            ) // Filter out results without metadata or with empty metadata
            .map((result) => ({
              ...result[0].metadata,
              number: result[0].number,
            }));

          exportDocument(
            client,
            {
              _id: context.documentId,
              title: context.title,
              value: context.value,
              bib,
              numbering: context.showNumbering,
              papers: metadata,
            },
            setBackdrop
          );
        }}
      >
        <FileDownloadOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography variant="body1">Export note (.tex, .bib)</Typography>
      </Button>
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={async () => {
          setBackdrop(true);
          setBackdropText('Exporting your note, please wait...');

          const bib = await getBib();
          const results = getReferences(context.editorRef);

          const metadata = results
            .filter(
              (result) =>
                result[0].metadata && !isEmptyObject(result[0].metadata)
            ) // Filter out results without metadata or with empty metadata
            .map((result) => ({
              ...result[0].metadata,
              number: result[0].number,
            }));

          exportDocx(
            client,
            {
              _id: context.documentId,
              title: context.title,
              value: context.value,
              bib,
              numbering: context.showNumbering,
              papers: metadata,
            },
            setBackdrop
          );
        }}
      >
        <FileDownloadOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography variant="body1">Export docx</Typography>
      </Button>
    </>
  );
};

export default ExportManuscript;
