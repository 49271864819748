// Libraries
import ReactGA from 'react-ga4';

// Local
import { saveAs } from 'file-saver';
import {
  GET_DOCUMENT,
  SAVE_DOCUMENT,
  EXPORT_DOCUMENT,
  EXPORT_DOCX,
  ADD_TO_ZOTERO_LIBRARY
} from '../../Queries';
import { notify } from '../../Common/notify';

/**
 * It retrieves a document export zip file with .tex and .bib files
 * @param {object} client - Apollo Client
 * @param {object} document - Document
 */

export const exportDocument = (client, document, setBackdrop) => {
  ReactGA.event({
    category: 'Export',
    action: 'Exported note as a .zip',
    label: 'Export note',
  });

  return client
    .mutate({
      mutation: EXPORT_DOCUMENT,
      variables: {
        id: document._id,
        title: document.title,
        content: JSON.stringify(document.value),
        collaborators: '',
        bib: document.bib,
        numbering: document.numbering,
        papers: document.papers,
      },
    })
    .then((response) => {
      // base64 string
      const base64str = response.data.exportDocument.response;

      // decode base64 string, remove space for IE compatibility
      const binary = window.atob(base64str.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: 'application/octet-stream' });

      saveAs(blob, `download-${document._id}.zip`);
      setBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setBackdrop(false);
      notify('Could not export to .zip, something went wrong');
    });
};

/**
 * It retrieves a document export .docx file
 * @param {object} client - Apollo Client
 * @param {object} document - Document
 */

export const exportDocx = (client, document, setBackdrop) => {
  ReactGA.event({
    category: 'Export',
    action: 'Exported note as a .docx',
    label: 'Export note',
  });

  return client
    .mutate({
      mutation: EXPORT_DOCX,
      variables: {
        id: document._id,
        title: document.title,
        content: JSON.stringify(document.value),
        collaborators: '',
        bib: document.bib,
        numbering: document.numbering,
        papers: document.papers,
      },
    })
    .then((response) => {
      const base64str = response.data.exportDocx.response;

      // decode base64 string, remove space for IE compatibility
      const binary = window.atob(base64str.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, `download-${document._id}.docx`);
      setBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setBackdrop(false);
      notify('Could not export to docx, something went wrong');
    });
};

export const addPaperToZotero = (client, document, setLoadingRequest, setLoadingMessage) => {
  ReactGA.event({
    category: 'Export',
    action: 'Add paper to Zotero',
    label: 'Export note',
  });

  return client
    .mutate({
      mutation: ADD_TO_ZOTERO_LIBRARY,
      variables: {
        id: document._id,
        title: document.title,
        papers: document.papers,
      },
    })
    .then(() => {
      setLoadingRequest(false)
      setLoadingMessage('Paper added to Zotero library');
    })
    .catch((error) => {
      console.log(error);
      setLoadingRequest(false);
      notify('Could not add to Zotero library, something went wrong');
    });
};

/**
 * It retrieves a document's text editing details
 * @param {client} object - Apollo Client
 * @param {doc_id} number - Document ID
 */

export const getDocument = (client, docId) => {
  return client
    .query({
      query: GET_DOCUMENT,
      variables: { doc_id: docId },
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      return data.docServiceGetDoc;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * It saves a document
 * @param {client} object - Apollo Client
 * @param {documentId} string - Document ID
 * @param {title} string - Document title
 * @param {value} array - Slate editor value
 * @param {user} object - Authorized user logged in
 */

export const saveDocument = (
  client,
  documentId,
  title,
  value,
  user,
  numbering,
  orderByAppearance
) => {
  client
    .mutate({
      mutation: SAVE_DOCUMENT,
      variables: {
        id: documentId,
        title,
        content: JSON.stringify(value),
        name: user.name,
        numbering,
        orderByAppearance,
      },
    })
    .then(() => {})
    .catch(() => {
      notify('Could not save note, something went wrong');
    });
};
