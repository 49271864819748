// Libraries
import React, { useEffect, useContext, useState } from 'react';
import Abstract from '../Abstract';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Highlighter from 'react-highlight-words';

// Local
import { DocumentContext } from '../../../../DocumentContext';
import { ModularContext } from '../../../../../Modular/ModularContext';
import { insertCitationLinks } from '../../../../Utilities/paper';

const AbstractParsed = ({ useTextAlign, paper, modular, highlighter }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [showParsedAbstract, setShowParsedAbstract] = useState(false);

  useEffect(() => {
    if (
      Array.isArray(paper.Content?.Abstract_Parsed) &&
      paper.Content.Abstract_Parsed.length > 0
    ) {
      setShowParsedAbstract(true);
    } else {
      setShowParsedAbstract(false);
    }
  }, [paper.Content?.Abstract_Parsed]); // Dependency array includes the specific field

  useEffect(() => {
    if (!modular) {
      const element = document.getElementById(context.scrollToSentenceId);

      if (element && context.scrollToSentenceId !== '') {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
        context.updateContext('scrollToSentenceId', '');
      }
    }
  }, [modular ? null : context.scrollToSentenceId]);

  return showParsedAbstract ? (
    <Box
      sx={{
        width: '100%',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        padding: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
          fontWeight: 'bold',
          marginBottom: '10px',
          width: '100%',
        }}
      >
        Abstract
      </Typography>
      {!modular
        ? paper.Content.Abstract_Parsed.map((abstract) => {
            return abstract.section_text.map((paragraph, sectIndex) => {
              return (
                <React.Fragment key={sectIndex}>
                  <Box>
                    {paragraph.paragraph_text.map((sentence, index) => {
                      return (
                        <Typography
                          key={index}
                          variant="body2"
                          id={`${'Abstract_Parsed'}#${abstract.section_id}-${
                            paragraph.paragraph_id
                          }-${sentence.sentence_id}`}
                          sx={{
                            display: 'inline',
                            background:
                              sentence.sentence_similarity >= 0.66
                                ? '#fbc02d'
                                : sentence.sentence_similarity >= 0.33 &&
                                    sentence.sentence_similarity >= 0.65
                                  ? '#fdd835'
                                  : sentence.sentence_similarity >= 0.1 &&
                                      sentence.sentence_similarity >= 0.32
                                    ? '#ffeb3b'
                                    : 'none',
                            color:
                              sentence.sentence_similarity >= 0.1 && 'black',
                          }}
                        >
                          {insertCitationLinks(
                            sentence.sentence_text,
                            sentence.cite_spans,
                            context
                          )}
                          {paragraph.paragraph_text.length === index
                            ? null
                            : ' '}
                        </Typography>
                      );
                    })}
                  </Box>
                  <br />
                </React.Fragment>
              );
            });
          })
        : // MODULAR HERE

          useTextAlign === 'none'
          ? paper.Content.Abstract_Parsed.map((abstract, abstractIdx) =>
              abstract.section_text.map((paragraph, paragraphIdx) => (
                <Highlighter
                  style={{
                    display: 'inline-block',
                    marginBottom: '10px',
                  }}
                  key={abstractIdx + paragraphIdx}
                  searchWords={[highlighter]}
                  highlightStyle={{
                    backgroundColor: '#fbc02d', // gold color
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                    fontWeight: 400,
                  }}
                  unhighlightStyle={{
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                    fontWeight: 400,
                  }}
                  textToHighlight={paragraph.paragraph_text
                    .map((sentence) => sentence.sentence_text)
                    .join(' ')}
                  autoEscape={true}
                  caseSensitive={false}
                />
              ))
            )
          : paper.Content.Abstract_Parsed.map((abstract) => {
              return abstract.section_text.map((paragraph) => {
                return paragraph.paragraph_text.map((sentence, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="body2"
                      id={`${abstract.section_id}${paragraph.paragraph_id}${sentence.sentence_id}`}
                      sx={{
                        display: 'inline',
                        marginRight: '5px',
                        background:
                          sentence.sentence_similarity >= 0.66
                            ? '#fbc02d'
                            : sentence.sentence_similarity >= 0.33 &&
                                sentence.sentence_similarity >= 0.65
                              ? '#fdd835'
                              : sentence.sentence_similarity >= 0.1 &&
                                  sentence.sentence_similarity >= 0.32
                                ? '#ffeb3b'
                                : 'none',
                      }}
                    >
                      {insertCitationLinks(
                        sentence.sentence_text,
                        sentence.cite_spans,
                        context
                      )}
                    </Typography>
                  );
                });
              });
            })}
    </Box>
  ) : (
    <Abstract paper={paper} highlighter={highlighter} />
  );
};

export default AbstractParsed;
