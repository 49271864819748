// Libraries
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';

// Local
import Footer from '../Common/Footer';

const PrivacyPolicy = ({ title }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          alignItems: 'flex-start',
          padding: '40px',
          height: 'auto',
          color: 'text.primary',
          textAlign: 'left',
        }}
      >
        <Typography variant="h5">Our Privacy Policy</Typography>
        <Typography variant="caption">Last updated: 2023-10-13</Typography>
        <Typography variant="body1">
          This privacy policy will explain how our platform uses the personal
          data we collect from you when you use our website services. The policy
          is also aligned to the Swiss New Federal Act on Data Protection
          (nFADP) dated 2023-09-01.
        </Typography>
        <Typography variant="h6">What data do we collect?</Typography>
        <Typography variant="body1">
          Endoc collects the following data:
          <ul>
            <li>Name.</li>
            <li>Email Address.</li>
            <li>AI Analytics.</li>
          </ul>
          The amount of data we collect is always intended to be the least in
          order to continue providing a reputable service. We have applied and
          continue to practice the following recommended approaches towards
          development:
          <ul>
            <li>
              Privacy by Design - requires developers to integrate the
              protection and respect of users&apos; privacy into the very
              structure of the products or services that collects personal data.
            </li>
            <li>
              Privacy by Default - ensures the highest level of security as soon
              as the products or services are released, by activating by
              default, i.e. without any intervention from users, all the
              measures necessary to protect data and limit their use. In other
              words, all software, hardware and services must be configured to
              protect data and respect the privacy of users.
            </li>
          </ul>
        </Typography>
        <Typography variant="h6"> How do we collect your data?</Typography>
        <Typography variant="body1">
          You directly provide Endoc with most of the data we collect. We
          collect data and process data when you:
          <ul>
            <li>Register online for our services.</li>
            <li>
              Voluntarily provide feedback on any of our contact forms or via
              email.
            </li>
            <li>AI Analytics.</li>
            <li>Use or view our website via your browser&apos;s cookies.</li>
            <li>Import external files for use with our AI services.</li>
            <li>Interact with our AI services.</li>
          </ul>
          Endoc may also receive your data indirectly from the following
          sources:
          <ul>
            <li>
              Access the platform through an affiliated association, such as
              Endocite.
            </li>
            <li>
              Access the platform using a third-party sign-in service such as
              Switch edu-ID.
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">How will we use your data?</Typography>
        <Typography variant="body1">
          Endoc collects your data so that we can:
          <ul>
            <li>Process your requests and manage your account</li>
            <li>Email you with notifications from our platform services</li>
            <li> Improve our NLP offerings based on interactivity</li>
          </ul>
        </Typography>
        <Typography variant="h6"> How do we store your data?</Typography>
        <Typography variant="body1">
          We securely store personal data at renowned global Third Party Cloud
          database solutions and self hosted solutions.
          <ul>
            <li>
              If you access through an affiliation, we use third party cloud
              services.
            </li>
            <li>
              If you access through a supported institution, we use self-hosted
              institutional network services.
            </li>
          </ul>
          Upon deleting your account, all relevant account management and
          generated work data is deleted. Your personal data is regularly backed
          up, isolated and passwords are always encrypted or managed by third
          party services, such as Switch edu-ID.
        </Typography>
        <Typography variant="h6">
          What are your data protection rights?
        </Typography>
        <Typography variant="body1">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
          <ul>
            <li>
              The right to access - You have the right to request our platform
              for copies of your personal data. We may charge you a small fee
              for this service.
            </li>
            <li>
              The right to rectification - You have the right to request that
              Our platform correct any information you believe is inaccurate.
            </li>
            <li>
              You also have the right to request our platform to complete the
              information you believe is incomplete.
            </li>
            <li>
              The right to erasure - You have the right to request that Our
              platform erase your personal data.
            </li>
            <li>
              The right to restrict processing - You have the right to request
              that our platform restrict the processing of your personal data,
              under certain conditions.
            </li>
            <li>
              The right to object to processing - You have the right to object
              to our platform&apos;s processing of your personal data, under
              certain conditions.
            </li>
            The right to data portability - You have the right to request that
            our platform transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </ul>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please use the contact
          form to get in touch. Cookies are text files placed on your computer
          to collect standard Internet log information and visitor behavior
          information. When you visit our websites, we may collect information
          from you automatically through cookies or similar technology For
          further information, visit allaboutcookies.org.
        </Typography>
        <Typography variant="h6">How do we use cookies?</Typography>
        <Typography variant="body1">
          Our platform uses cookies in a range of ways to improve your
          experience on our website, including:
          <ul>
            <li>Keeping you signed in.</li>
            <li>Understanding how you use our website.</li>
            <li>Tracking errors to improve platform experience.</li>
          </ul>
        </Typography>
        <Typography variant="h6">What types of cookies do we use? </Typography>
        <Typography variant="body1">
          There are a number of different types of cookies, however, our website
          uses:
          <ul>
            <li>
              Functionality - Our platform uses these cookies so that we
              recognize you on our website and remember your previously selected
              preferences.
            </li>
            <li>A mix of first-party and third-party cookies are used.</li>
          </ul>
        </Typography>
        <Typography variant="h6">How to manage cookies?</Typography>
        <Typography variant="body1">
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, in a few
          cases, some of our website features may not function as a result.
        </Typography>
        <Typography variant="h6">Privacy policies of other websites</Typography>
        <Typography variant="body1">
          The platform website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy. Changes to our
          privacy policy Our platform keeps its privacy policy under regular
          review and places any updates on this web page.
        </Typography>
        <Typography variant="h6">How to contact us?</Typography>
        <Typography variant="body1">
          If you have any questions about Our platform&apos;s privacy policy,
          the data we hold on you, or you would like to exercise one of your
          data protection rights, please do not hesitate to contact us using our
          contact form or relevant association contact forms.
        </Typography>
        <Typography variant="h6">
          How to contact the appropriate authority?
        </Typography>
        <Typography variant="body1">
          Should you wish to report a complaint or if you feel that our platform
          has not addressed your concern in a satisfactory manner, you may
          contact your local Information Commissioner&apos;s Office.
        </Typography>
        {process.env.REACT_APP_ETH && (
          <Button
            variant="outlined"
            type="submit"
            onClick={() => navigate('/contact')}
          >
            Contact us
          </Button>
        )}
      </Stack>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
