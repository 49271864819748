import {
  Element as SlateElement,
  // Transforms,
  BaseElement,
  Editor,
} from 'slate';

interface ChildNode extends BaseElement {
  type: string;
}

const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor;
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      ensureReferencesAtBottom(editor);
    }

    normalizeNode([node, path]);
  };

  return editor;
};
const ensureReferencesAtBottom = (editor: Editor) => {
  const children = editor.children;
  const references = [];
  const nonReferences = [];

  // Separate reference and non-reference nodes
  for (const child of children) {
    if (
      SlateElement.isElement(child) &&
      (child as ChildNode).type === 'reference'
    ) {
      references.push(child);
    } else {
      nonReferences.push(child);
    }
  }

  // Combine nodes ensuring references are at the bottom
  const newChildren = [...nonReferences, ...references];

  // Only update if the order is different
  if (JSON.stringify(children) !== JSON.stringify(newChildren)) {
    // Update the editor's children directly
    editor.children = newChildren;
    // Indicate that the document has changed
    editor.onChange();
  }
};

export default withLayout;
