// Library
import React, { useContext, useState } from 'react';
import {
  Drawer,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  darken,
  lighten,
} from '@mui/material';

// Local
import { DocumentContext } from '../DocumentContext';
import Documentation from './Components/Documentation';
// import Collaborators from './Components/Collaborators';
import GeneralSettings from './Components/GeneralSettings';
// import ManuscriptSettings from './Components/ManuscriptSettings';
import Header from './Components/Header';
import Return from './Components/Return';
import Feedback from './Components/Feedback';
// import Shortcuts from './Components/Shortcuts';
import AddPapersLibrary from './Components/AddPapersLibrary';
// import ImportLibrary from './Components/ImportLibrary';
import ExportLibrary from './Components/ExportLibrary';
import ExportManuscript from './Components/ExportManuscript';
// import InfoSquare from './Components/InfoSquare';
import ImportPaperManually from './Components/ImportPaperManually';

const Panel = ({ client, user }) => {
  const context = useContext(DocumentContext);
  const [backdrop, setBackdrop] = useState(false);
  const [backdropText, setBackdropText] = useState('');

  return (
    <Drawer
      anchor="left"
      open={context.togglePanel}
      onClose={() => context.updateContext('togglePanel', false)}
      PaperProps={{
        sx: {
          overflowY: 'auto',
          overflowX: 'hidden',
          width: 305,
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? darken(theme.palette.background.paper, 0.02)
              : lighten(theme.palette.background.paper, 0.02),
        },
      }}
    >
      <Backdrop
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'cornflowerblue',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={backdrop}
        onClick={() => setBackdrop(false)}
      >
        <CircularProgress
          color="inherit"
          sx={{
            marginBottom: '10px',
          }}
        />
        <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {backdropText}
        </Typography>
      </Backdrop>
      <Header user={user} />
      {/* <InfoSquare /> */}
      <Stack
        direction={'column'}
        sx={{
          height: '100%',
          justifyContent: 'flex-start',
          padding: '10px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginBottom: '10px' }}
        >
          Note
        </Typography>
        {/* <Collaborators client={client} /> */}
        <ExportManuscript
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        {/* <ManuscriptSettings client={client} user={user} /> */}
        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '20px' }}
        >
          General
        </Typography>
        <ExportLibrary
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        {/* <ImportLibrary
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        /> */}
        <ImportPaperManually
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        <AddPapersLibrary
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        {/* <Shortcuts /> */}
        <Documentation />
        <Feedback />
        <GeneralSettings client={client} user={user} />
        <Return client={client} />
      </Stack>
    </Drawer>
  );
};

export default Panel;
