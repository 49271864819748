// Library
import React, { useContext, useState } from 'react';
import { debounce } from 'lodash';
import { TextField, useTheme } from '@mui/material';

// Local
import { DocumentContext, DocumentContextType } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';

interface TitleProps {
  modular: boolean;
}

const Title: React.FC<TitleProps> = ({ modular }) => {
  const contextSource: object = modular ? ModularContext : DocumentContext;

  const context = useContext(
    contextSource as React.Context<DocumentContextType>
  );

  const [title, setTitle] = useState<string>(
    modular ? context?.title : context?.title
  );

  const theme = useTheme();

  const dynamicBackground: string =
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.10)'
      : 'rgba(255, 255, 255, 0.10)';

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSync(event.target.value);
    setTitle(event.target.value);
  };

  const debouncedSync = debounce((value: string) => {
    if (modular) {
      context.updateContext('title', value);
    } else {
      context.updateContext('title', value);
    }

    window.document.title = value;
  }, 2000);

  return (
    <TextField
      className="title"
      placeholder="Untitled"
      value={title}
      onChange={onChangeHandler}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        maxRows: 5,
        multiline: true,
      }}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        height: 'auto',
        minHeight: '60px',
        '& .MuiInputBase-root': {
          fontSize: '34px',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '0',
        },
        '& .Mui-focused': {
          border: '0',
          outline: 'none',
        },
        '&:hover, &.Mui-focused': {
          backgroundColor: dynamicBackground,
        },
      }}
      disabled={!modular ? context.readOnly : false}
    />
  );
};

export default Title;
