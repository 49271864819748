// Libraries
import React, { useRef, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useLazyQuery } from '@apollo/client';
import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import SearchRounded from '@mui/icons-material/SearchRounded';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// Local
import {
  HIGHLIGHT_PAPER,
  DOCUMENT_SEARCH,
  KEYWORDS_BASED_ON_SEARCH,
  PAGINATED_SEARCH,
} from '../Queries/nlp.js';
import { DocumentContext } from '../DocumentContext';
import { ModularContext } from '../../Modular/ModularContext.js';
import { notify } from '../../Common/notify.js';
import { addScoresToSearchResults } from '../Utilities/addScoresToSearchResults.js';
import updateAnnotatorY from '../Explorer/Annotator/updateAnnotatorY.js';

const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const PaperToolbar = ({ targetEl, modular, id }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const ref = useRef();

  const [keywordsBasedOnSearch] = useLazyQuery(KEYWORDS_BASED_ON_SEARCH);

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH);

  const [documentSearch] = useLazyQuery(DOCUMENT_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ documentSearch }) => {
      context.updateContext('paperIds', documentSearch.response.paper_list);
      context.updateContext(
        'rerankingScores',
        documentSearch.response.reranking_scores
      );
      context.updateContext(
        'prefetchingScores',
        documentSearch.response.prefetching_scores
      );
      context.updateContext(
        'pageCount',
        documentSearch.response.paper_list.length / 10
      );

      paginatedSearch({
        fetchPolicy: 'network-only',
        variables: {
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list.slice(0, 10),
        },
        onCompleted: ({ paginatedSearch }) => {
          const updatedSearchResults = addScoresToSearchResults(
            paginatedSearch.response, // in batches of 10 papers
            documentSearch.response.reranking_scores, // scores for all papers
            documentSearch.response.prefetching_scores, // prefetching scores for all papers
            1, // current page
            10 // items per page
          );

          context.updateContext('searchResults', updatedSearchResults);
          context.updateContext('currentPage', 1);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', 'Retrieved top papers');
          context.updateContext('tab', 'Discover');
        },
      });

      keywordsBasedOnSearch({
        fetchPolicy: 'network-only',
        variables: {
          ranking_variable: context.searchTerm,
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list,
        },
        onCompleted: ({ keywordsBasedOnSearch }) => {
          context.updateContext(
            'keywordsBasedOnSearch',
            keywordsBasedOnSearch.response
          );
        },
      });
    },
  });

  const [highlightPaper] = useLazyQuery(HIGHLIGHT_PAPER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ highlightPaper }) => {
      if (highlightPaper.status === 'success') {
        context.updateContext('showSelection', true);
        context.updateContext('paper', highlightPaper.response);
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Retrieved similar sentences');
        context.updateContext('tab', 'View');
      } else {
        context.updateContext('loadingRequest', false);
        notify('Sentence highlighting did not work with the provided text');
      }
    },
  });

  const returnSelection = () => {
    const text = window.getSelection().toString();

    if (!modular) {
      context.updateContext('highlightSelection', text);
    } else {
      context.updateContext('textAlignSourceText', {
        ...context.textAlignSourceText,
        [id]: text,
      });
    }

    return text;
  };

  const returnSelectionSpan = () => {
    const selection = window.getSelection();

    if (!selection) {
      return {};
    }

    const text = selection.toString();
    const anchorParentId = selection.anchorNode.parentNode.id;
    const focusParentId = selection.focusNode.parentNode.id;

    if (!anchorParentId || !focusParentId) {
      notify('Something went wrong with your text selection.');
      return {};
    }

    return {
      text: text,
      anchorParentId: anchorParentId,
      anchorOffset: selection.anchorOffset,
      focusParentId: focusParentId,
      focusOffset: selection.focusOffset,
    };
  };

  useEffect(() => {
    const updateToolbarPosition = () => {
      const selection = window.getSelection();
      // Check if there's a selection and it's not just whitespace
      const hasSelectedText = selection.toString().trim().length > 0;

      // Check if the selection is within targetEl
      const isSelectionWithinTarget = targetEl.current.contains(
        selection.anchorNode
      );

      if (!hasSelectedText || !isSelectionWithinTarget) {
        if (ref.current) {
          ref.current.removeAttribute('style');
        }
        return;
      }

      // Proceed to position the toolbar
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      if (ref.current) {
        ref.current.style.opacity = '1';
        ref.current.style.top = `${
          rect.top + window.scrollY - ref.current.offsetHeight
        }px`;
        ref.current.style.left = `${
          rect.left +
          window.scrollX -
          ref.current.offsetWidth / 2 +
          rect.width / 2
        }px`;
      }
    };

    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !targetEl.current.contains(event.target)
      ) {
        ref.current.removeAttribute('style');
      }
    };

    // Add event listeners
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mouseup', updateToolbarPosition);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mouseup', updateToolbarPosition);
    };
  }, [targetEl]);

  // Do not display toolbar or provide functionality in modular mode
  return modular ? null : (
    <Portal>
      <Stack
        direction={'row'}
        spacing={1}
        ref={ref}
        sx={{
          padding: '8px 7px 6px',
          position: 'absolute',
          zIndex: 1,
          top: '-10000px',
          left: '-10000px',
          marginTop: '-10px',
          opacity: 0,
          bgcolor: 'background.paper',
          borderRadius: '5px',
          transition: 'opacity 0.75s',
          border: (theme) =>
            theme.palette.mode === 'dark'
              ? '1px solid #333333'
              : '1px solid #d4d4d4',
        }}
      >
        <Tooltip title="Search for papers using this text" placement="top">
          <IconButton
            aria-label="search"
            size="small"
            onMouseDown={(e) => {
              e.preventDefault();

              const text = returnSelection();

              context.updateContext('searchTerm', text);
              context.updateContext('loadingRequest', true);
              context.updateContext('loadingMessage', 'Retrieving papers');

              documentSearch({
                variables: {
                  ranking_variable: text,
                  keywrods: context.keywords,
                },
              });
            }}
          >
            <SearchRounded fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Find similar sentences in the paper'} placement="top">
          <IconButton
            aria-label="highlight"
            size="small"
            onMouseDown={() => {
              const text = returnSelection();

              context.updateContext('highlightSelection', text);
              context.updateContext(
                'loadingMessage',
                'Retrieving similar sentences'
              );
              context.updateContext('loadingRequest', true);

              const {
                rankingCollection,
                rankingIdField,
                rankingIdValue,
                rankingIdType,
              } = context.rankingDetails;

              highlightPaper({
                variables: {
                  highlight_source: text,
                  paper_id: {
                    collection: rankingCollection,
                    id_value: rankingIdValue,
                    id_field: rankingIdField,
                    id_type: rankingIdType,
                  },
                },
              });
            }}
          >
            <CompareArrowsOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title="Add to citation generation paper context"
          placement="top"
        >
          <IconButton
            aria-label="citation sentence"
            size="small"
            onMouseDown={() => {
              if (context.paper) {
                const text = returnSelection();
                const newSentences = [...context.generatorSentences, text];
                context.updateContext('generatorSentences', newSentences);
                context.updateContext('tab', 'View');
                context.updateContext('generatorView', true);
              } else {
                notify('You must be viewing a paper first');
              }
            }}
          >
            <AutoFixHighIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
        <Tooltip
          title="Add influencing text"
          placement="top"
          sx={{
            display: context.user?.isDataLabeler ? 'inherit' : 'none',
          }}
        >
          <Box component="span">
            <IconButton
              disabled={
                context.tab !== 'View' ||
                !context.paper ||
                context.annotatorView !== true
              }
              aria-label="influencing text"
              size="small"
              style={{ display: 'flex', alignItems: 'center' }}
              onMouseDown={() => {
                // get selected span
                const newSpan = returnSelectionSpan();
                context.updateContext('highlightSelection', newSpan.text);

                // (try to) update annotator spans with new span
                const spans = updateAnnotatorY(
                  newSpan,
                  context.annotatorY,
                  context.annotatorX,
                  context.paper._id
                );
                if (spans.length > 0) {
                  context.updateContext('annotatorY', spans);
                } else {
                  notify('Please check the annotation manual.');
                }
              }}
            >
              <div style={{ fontSize: 'smaller' }}>+ Influencing content</div>
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Portal>
  );
};

export default PaperToolbar;
