// Library
import React, { useContext, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

// Local
import { PAGINATED_SEARCH } from '../../Queries/nlp';
import { IMPORT_PDF } from '../../Queries/document';
import { DocumentContext } from '../../DocumentContext';
import { bytesToMegaBytes } from '../../Utilities/bytesToMegabytes';
import { notify } from '../../../Common/notify';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const AddPapersLibrary = ({ setBackdrop, setBackdropText }) => {
  const context = useContext(DocumentContext);
  const importLibraryPDF = useRef(null);

  const [importPDF] = useMutation(IMPORT_PDF);
  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH);

  const importFiles = async (base64list) => {
    setBackdrop(true);

    importPDF({
      fetchPolicy: 'network-only',
      variables: {
        user_id: context.user._id,
        base64list,
      },
      onCompleted: ({ importPDF }) => {
        const paperList = importPDF.response.map((bookmark) => {
          return {
            collection: bookmark.id_collection,
            id_field: bookmark.id_field,
            id_type: bookmark.id_type,
            id_value: bookmark.id_value,
          };
        });

        paginatedSearch({
          fetchPolicy: 'network-only',
          variables: {
            paper_list: paperList,
          },
          onCompleted: ({ paginatedSearch }) => {
            if (paginatedSearch.status === 'success') {
              const updatedBookmarks = [];

              paginatedSearch.response.forEach((paper) => {
                paperList.forEach((meta) => {
                  const paperIdStr = paper.id_int?.toString() ?? '';

                  if (paperIdStr === meta.id_value) {
                    updatedBookmarks.push({
                      _id: paper._id,
                      abstract: paper.Content.Abstract,
                      abstract_parsed: paper.Content.Abstract_Parsed,
                      doi: paper.DOI,
                      id_int: paper.id_int,
                      title: paper.Title,
                      id_collection: meta.collection,
                      id_field: meta.id_field,
                      id_type: meta.id_type,
                      id_value: meta.id_value,
                    });
                  }
                });
              });

              setBackdrop(false);
              context.updateContext('loadingMessage', 'Imported library');
              context.updateContext('bookmarks', updatedBookmarks);
            } else {
              notify(
                'Could not add papers to library at this moment, try again later'
              );
              setBackdrop(false);
              setBackdropText('');
              return;
            }
          },
        });
      },
    });
  };

  return (
    <>
      <input
        type="file"
        name="upload"
        id="file"
        multiple
        accept="application/pdf"
        ref={importLibraryPDF}
        style={{ display: 'none' }}
        required
        onChange={async (e) => {
          setBackdrop(true);
          setBackdropText('Uploading papers, please wait...');

          try {
            const files = e.target.files;
            const uploads = {};

            // If cancelled
            if (files.length === 0) return;

            for (let i = 0; i < files.length; i++) {
              // Ignore non pdf files
              if (!files[i].name.match(/.(pdf)$/i)) {
                notify('Importing a file that is not a PDF');
                setBackdrop(false);
                setBackdropText('');
                return;
              }

              // Ignore files over 50 mb in size
              if (bytesToMegaBytes(files[i].size) > 50) {
                notify('Maximum pdf size of 50 MB exceeded');
                setBackdrop(false);
                setBackdropText('');
                return;
              }

              uploads[i + 1] = {
                checked: false,
                name: files[i]['name'],
                size: bytesToMegaBytes(files[i]['size']).toFixed(2),
                file: files[i],
              };
            }

            const pdfKeys = Object.keys(uploads).map((key) => parseInt(key)); // get pdf keys
            pdfKeys.sort((a, b) => a - b);
            const base64list = [];

            for (let i = 0; i < pdfKeys.length; i++) {
              const key = pdfKeys[i];

              let base64File = await getBase64(uploads[key]['file']);
              base64File = base64File.replace(
                'data:application/pdf;base64,',
                ''
              );
              base64list.push(base64File);
            }

            importFiles(base64list);
          } catch (err) {
            notify(err.message);
          }
        }}
      />
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={() => {
          importLibraryPDF.current.click();
        }}
      >
        <FileUploadOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography variant="body1">Add papers to library (.pdf)</Typography>
      </Button>
    </>
  );
};

export default AddPapersLibrary;
