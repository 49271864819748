// Libraries
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';

// Local
import { SWITCH_LOGIN } from '../Queries';
import { notify } from '../Common/notify';
import Constants from '../constants';

const SwitchAuthenticate = () => {
  const [step, setStep] = useState('Accessing Switch edu-ID');

  const [switchLogin, { loading, error }] = useMutation(SWITCH_LOGIN, {
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const handleLogin = useCallback(
    async (code) => {
      try {
        const { data } = await switchLogin({ variables: { code } });
        if (data.switchLogin.status === 'success') {
          Constants.TOKEN = data.switchLogin.response.token;
          Constants.EXPIRY = data.switchLogin.response.jwtExpiry;

          navigate('/notes');
        } else {
          notify(data.switchLogin.message);
          navigate('/login');
        }
      } catch (err) {
        notify('Internal Server Error');
        navigate('/login');
      }
    },
    [switchLogin]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      handleLogin(code);
    } else {
      notify('Invalid URL');
      navigate('/login');
    }
  }, [handleLogin]);

  // Use useEffect to handle state updates based on loading and error
  useEffect(() => {
    if (loading) {
      setStep('Authenticating...');
    } else if (error) {
      setStep(error.message);
    }
  }, [loading, error]); // Dependency array includes loading and error

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: 'text.primary',
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Accessing via Switch edu-ID
      </Typography>
      <CircularProgress
        sx={{
          color: 'cornflowerblue',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      />
      <Typography variant="h6">
        {!loading && !error ? 'Authenticated' : step}
      </Typography>
    </Box>
  );
};

export default SwitchAuthenticate;
