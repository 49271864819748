// Libraries
import React, { useContext } from "react";
import { Chip, Stack } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

// Local
import { DocumentContext } from "../../../DocumentContext";

const SearchKeywords = () => {
  const context = useContext(DocumentContext);

  const adjustKeywords = (keyword) => {
    context.updateContext(
      "keywordsBasedOnSearch",
      context.keywordsBasedOnSearch.filter((item) => item !== keyword)
    );

    context.updateContext("keywords", [...context.keywords, keyword]);
  };

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        padding: "5px",
        paddingLeft: "0px !important",
        marginBottom: "10px",
        flexWrap: "wrap",
        display: context.keywordsBasedOnSearch.length === 0 && "none",
      }}
    >
      {context.keywordsBasedOnSearch.map((keyword, index) => (
        <Chip
          key={index}
          size="small"
          label={keyword}
          sx={{
            background: "none",
            border: "solid 1px lightgrey",
            borderRadius: "4px",
          }}
          onClick={() => adjustKeywords(keyword, index)}
          onDelete={undefined}
          icon={<AddCircleOutlineOutlinedIcon sx={{ fontSize: 16 }} />}
        />
      ))}
    </Stack>
  );
};

export default SearchKeywords;
