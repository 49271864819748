import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode, ReferenceNode } from '../Types';

/**
 * find and delete a citation or reference node in the editor
 *
 * @param {object} editor - The current slate editor instance
 * @param {string} type - The type of node to find and delete, either 'citation' or 'reference'
 * @param {string} idValue - The id value of the node to find and update
 * @returns {void}
 */

export const findAndDelete = (
  editor: ReactEditor,
  type: string,
  idValue: string
) => {
  // If its not a citation or reference, return
  if (type !== 'citation' && type !== 'reference') {
    return;
  }

  // If idValue is empty, return
  if (!idValue) {
    return;
  }

  Transforms.removeNodes(editor, {
    match: (node) => {
      if (
        (node as CitationNode | ReferenceNode).type === type &&
        (node as CitationNode | ReferenceNode).metadata.id_value === idValue
      ) {
        return true;
      }

      return false;
    },
    at: [],
  });
};
