// Libraries
import React, { useState, useContext } from 'react';
import { Box, Stack, Chip, Tooltip, IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

// Local
import { DocumentContext } from '../../../DocumentContext';

function EditableChip({
  option,
  index,
  getTagProps,
  setAutoCompleteInputValue,
}) {
  const context = useContext(DocumentContext);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: 'inline-block',
        width: option.length > 60 ? 'inherit' : 'auto',
      }}
    >
      <Chip
        variant="outlined"
        label={option}
        size="small"
        {...getTagProps({ index })}
        sx={{
          paddingLeft: 0,
          borderRadius: '3px',
          marginTop: '5px',
          marginBottom: '5px',
          marginRight: '5px',
        }}
        onDelete={undefined}
        icon={
          isHovered ? (
            <Stack
              direction={'row'}
              sx={{
                display: isHovered ? 'inline-flex' : 'none', // Only show the button when hovered
                width: 'inherit',
              }}
            >
              <IconButton
                size="small"
                sx={{
                  borderRadius: 0,
                  boxSizing: 'border-box',
                }}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents the chip from being selected

                  const newKeywords = [...context.keywords];
                  newKeywords.splice(index, 1);
                  context.updateContext('keywords', newKeywords);
                }}
              >
                <Tooltip title="Remove" placement="top">
                  <HighlightOffOutlinedIcon sx={{ fontSize: 16 }} />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                sx={{
                  borderRadius: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents the chip from being deleted

                  const newKeywords = [...context.keywords];
                  const editedKeyword = newKeywords.splice(index, 1);
                  context.updateContext('keywords', newKeywords);
                  setAutoCompleteInputValue(editedKeyword[0]);
                }}
              >
                <Tooltip title="Edit" placement="top">
                  <EditOutlinedIcon sx={{ fontSize: 16 }} />
                </Tooltip>
              </IconButton>
            </Stack>
          ) : null
        }
      />
    </Box>
  );
}

export default EditableChip;
