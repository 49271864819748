// Library
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Local
import GlobalModal from '../../../Common/GlobalModal';
import DarkModeToggle from '../../../Common/DarkModeToggle';
import Zotero from '../../../Account/Zotero';

const Settings = ({ client, user }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <GlobalModal
        isOpen={isOpen}
        setOpen={setOpen}
        heading={'General settings'}
        subheading={'Adjust general settings'}
      >
        <Typography variant="body1" gutterBottom>
          Theme
        </Typography>
        <DarkModeToggle
          small={false}
          sx={{
            padding: '10px',
          }}
        />
        {user.email !== process.env.REACT_APP_DEMO_ACCOUNT && (
          <>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginTop: '10px',
              }}
            >
              Zotero account
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontSize: '12px',
              }}
            >
              Endoc automatically creates a dedicated folder with your citations
              and references in the Zotero library when exporting
            </Typography>
            <Zotero client={client} user={user} small={true} />
          </>
        )}
      </GlobalModal>
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <SettingsOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography variant="body1">General settings</Typography>
      </Button>
    </>
  );
};

export default Settings;
