// Libraries
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// Local
import { ZOTERO_ACCESS } from '../Queries';
import { notify } from '../Common/notify';

const Zotero = (props) => {
  const navigate = useNavigate();

  const [zoteroAccess, { loading, error }] = useMutation(ZOTERO_ACCESS);

  const handleConnect = useCallback(
    async (oauthToken, oauthVerifier, sessionKey) => {
      try {
        const { data } = await zoteroAccess({
          variables: {
            oauthToken,
            oauthVerifier,
            sessionKey,
          },
        });

        notify(data.zoteroAccess.message);
        navigate('/account');
      } catch (err) {
        notify('Internal Server Error');
      }
    },
    [zoteroAccess, navigate, props]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const oauthToken = params.get('oauth_token');
    const oauthVerifier = params.get('oauth_verifier');
    const sessionKey = localStorage.getItem('zoteroSessionKey');
    localStorage.removeItem('zoteroSessionKey');

    if (oauthToken && oauthVerifier && sessionKey) {
      handleConnect(oauthToken, oauthVerifier, sessionKey);
    } else {
      notify('Invalid URL, could not connect');
    }
  }, []);

  if (loading) return <div>Authenticating...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return <div>Authenticated successfully!</div>;
};

export default Zotero;
