// Libraries
import React from 'react';
import { Stack } from '@mui/material';

// Local
import TextBox from './TextBox';
import Feature from './Feature';

const ValueProposition = ({
  gif,
  isGifLeft,
  title,
  subtitle,
  listItems,
  readMore,
}) => {
  const isTextLeft = !isGifLeft;

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'column',
        md: isTextLeft ? 'row' : 'row-reverse',
      }}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        p: { xs: '0vw', md: '5vw' },
        height: 'auto',
        bgcolor: 'background.paper',
      }}
    >
      <Feature gif={gif} isLeft={isGifLeft} />
      <TextBox
        title={title}
        subtitle={subtitle}
        listItems={listItems}
        readMore={readMore}
        isLeft={isTextLeft}
      />
    </Stack>
  );
};

export default ValueProposition;
