// Libraries
import React from 'react';
import { Paper, Typography } from '@mui/material';

// Local
import { RenderElementProps } from 'slate-react';
import { HyperlinkNode } from '../Types';


interface HyperlinkProps extends RenderElementProps {
  element: HyperlinkNode
}

const Hyperlink: React.FC<HyperlinkProps> = ({ attributes, element, children }) => {
  const openInNewTab = (url: string) => {
    // Check if the URL starts with 'http://' or 'https://'
    // If not, prepend 'https://'
    const protocolPattern = /^https?:\/\//i;
    if (!protocolPattern.test(url)) {
      url = 'https://' + url;
    }

    // Open the URL in a new tab
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Paper
        aria-label='hyperlink-paper'
        component={'span'}
        elevation={0}
        sx={{
          display: 'inline',
          cursor: 'pointer',
          fontSize: 'inherit',
          color: 'grey',
          borderBottom: '1px solid grey',
          borderRadius: '0px',
          paddingBottom: '2px',
          marginLeft: '1px', // Needed to allow cursor placement
          marginRight: '1px', // Needed to allow cursor placement
        }}
        {...attributes}
        contentEditable={false}
        onClick={(e) => {
          e.preventDefault();
          openInNewTab(element.url);
        }}
      >
        <Typography
          aria-label='hyperlink-typography'
          component={'span'}
          sx={{
            fontSize: 'inherit',
          }}
        >
          {element.textToShow === '' ? element.url : element.textToShow}
        </Typography>
        {children}
      </Paper>
    </>
  );
};

export default Hyperlink;
