import { gql } from '@apollo/client';

export const KEYWORD_SUGGESTIONS = gql`
  query keywordSuggestions($keyword: String!) {
    keywordSuggestions(keyword: $keyword) {
      status
      message
      response
    }
  }
`;

export const KEYWORDS_BASED_ON_SEARCH = gql`
  query keywordsBasedOnSearch(
    $ranking_variable: String
    $keywords: [String]
    $paper_list: [MetadataInput]!
  ) {
    keywordsBasedOnSearch(
      ranking_variable: $ranking_variable
      keywords: $keywords
      paper_list: $paper_list
    ) {
      status
      message
      response
    }
  }
`;

export const DOCUMENT_SEARCH = gql`
  query documentSearch(
    $ranking_variable: String
    $keywords: [String]
    $paper_list: [MetadataInput]
    $ranking_collection: String
    $ranking_id_field: String
    $ranking_id_value: String
    $ranking_id_type: String
  ) {
    documentSearch(
      ranking_variable: $ranking_variable
      keywords: $keywords
      paper_list: $paper_list
      ranking_collection: $ranking_collection
      ranking_id_field: $ranking_id_field
      ranking_id_value: $ranking_id_value
      ranking_id_type: $ranking_id_type
    ) {
      status
      message
      response {
        search_stats {
          DurationTotalSearch
          nMatchingDocuments
        }
        paper_list {
          collection
          id_field
          id_type
          id_value
        }
        reranking_scores
        prefetching_scores
      }
    }
  }
`;

export const PAGINATED_SEARCH = gql`
  query paginatedSearch($paper_list: [MetadataInput]!, $keywords: [String]) {
    paginatedSearch(paper_list: $paper_list, keywords: $keywords) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        PublicationDate {
          Year
          Month
          Day
          Name
        }
        id_int
        relevant_sentences
      }
    }
  }
`;

export const SINGLE_PAPER = gql`
  query singlePaper($paper_id: MetadataInput!) {
    singlePaper(paper_id: $paper_id) {
      status
      message
      response {
        _id
        id_int
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        PublicationDate {
          Year
          Month
          Day
          Name
        }
        Reference {
          Title
          Author {
            FamilyName
            GivenName
          }
          Venue
          PublicationDate {
            Year
            Month
            Day
            Name
          }
          ReferenceText
          PaperID {
            collection
            id_field
            id_type
            id_value
          }
        }
      }
    }
  }
`;

export const SUMMARIZE = gql`
  query summarizePaper($paper_id: MetadataInput!) {
    summarizePaper(paper_id: $paper_id) {
      status
      message
      response {
        paragraph_id
        section_id
        sentence_id
        sentence_text
        tag
      }
    }
  }
`;

export const CITE_PAPER = gql`
  query citePaper($paper_id: MetadataInput!) {
    citePaper(paper_id: $paper_id) {
      status
      message
      response {
        bibtex
        mla
      }
    }
  }
`;

export const HIGHLIGHT_PAPER = gql`
  query highlightPaper($highlight_source: String!, $paper_id: MetadataInput!) {
    highlightPaper(highlight_source: $highlight_source, paper_id: $paper_id) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_similarity
            section_text {
              paragraph_id
              paragraph_similarity
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody_Parsed {
            section_id
            section_title
            section_similarity
            section_text {
              paragraph_id
              paragraph_similarity
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        Reference {
          Title
          Author {
            FamilyName
            GivenName
          }
          Venue
          PublicationDate {
            Year
            Month
            Day
            Name
          }
          ReferenceText
          PaperID {
            collection
            id_field
            id_type
            id_value
          }
        }
        PublicationDate {
          Year
          Month
          Day
          Name
        }
      }
    }
  }
`;

export const TITLE_SEARCH = gql`
  query titleSearch($titles: [String]!) {
    titleSearch(titles: $titles) {
      status
      message
      response {
        Title
        collection
        found
        id_field
        id_type
        id_value
        Author {
          FamilyName
          GivenName
        }
      }
    }
  }
`;

export const GENERATE_BIBTEX = gql`
  query generateBibtex($paper_list: [MetadataInput]!) {
    generateBibtex(paper_list: $paper_list) {
      status
      message
      response
    }
  }
`;

export const MODEL_PROMPTING = gql`
  query modelPrompting($text: String!) {
    modelPrompting(text: $text) {
      status
      message
      response
    }
  }
`;
