import { saveAs } from 'file-saver';

export const downloadBibtex = (text) => {
  const blob = new Blob([text], {
    type: 'text/plain;charset=utf-8',
  });

  saveAs(blob, `exported-citation.bib`);
};

export const downloadMla = (text) => {
  const blob = new Blob([text], {
    type: 'text/plain;charset=utf-8',
  });

  saveAs(blob, `exported-citation.txt`);
};

export const downloadAnnotation = (text, filename) => {
  const blob = new Blob([text], {
    type: 'text/plain;charset=utf-8',
  });

  saveAs(blob, `${filename}.json`);
};
