import React from 'react';

export const ModularContext = React.createContext({
  client: null,
  title: '',
  items: [],
  value: [],
  documentId: 0,
  user: {},
  keywords: [], // keywords used for discovery
  metadataResults: [], // the list of IDs
  paperResults: [], // the papers retrieved using the IDs
  paperResultsTemp: [], // the papers retrieved using the IDs, temporary buffer
  textAlign: {}, // the master object for cross read window highlighting
  textAlignSourceText: {},
  textAlignWindowOptions: {},
  citationGeneration: '', // the title of the paper to be cited
  citationGenerationIntent: '', // the intent of the citation generation
  citationGenerationContextText: '', // the context text used for citation generation
  citationGenerationKeywords: [], // the keywords used for citation generation
  citationGenerationRelevantText: '', // the relevant text used for citation generation
  citationGenerationResponse: '', // the response from the citation generation service
  argumentGenerationPromptSource: '', // the user edited premise for the API
  argumentGenerationPremiseWrite: '', // the premise of the argument generation in Write
  argumentGenerationPremiseRead: '', // the premise of the argument generation in Read
  argumentGenerationKeywords: [], // the keywords used for argument generation
  argumentGenerationResponse: '', // the response from the argument generation service
  writeAnchorOffset: 0, // the anchor offset of the write window
  writeFocusOffset: 0, // the focus offset of the write window
  writeScope: '', // the scope of the write window
  searchStats: '', // the search statistics to display
  searchTerm: '', // search term for document discovery
  papers: {}, // the papers currently viewed
  papersMetadata: {}, // the metadata of the currently reading paper
  paperTitles: [], // titles of papers in Read window
  references: {}, // the references of the currently viewing paper
  highlightPapers: {}, // target paper for highlighting selected text
  bookmarks: [], // papers added to My Library
  nlpAPI: [], // currently used nlp API in TextGen
  memsumSummaries: [], // memsumSummaries
  memsumHighlights: [], // used by the Read window when set ListenTo to Highlights
  displayDiscovery: true,
  displayLibrary: false,
  displayRead: true,
  displayKeywords: true,
  displaySources: true,
  allPaperShowed: false,
  compactType: null,
  managerToggle: false, // For opening and closing the manager
  managerView: {
    // For controlling the manager view
    formatCitations: false,
    importLibrary: false,
    importPDF: false,
    exportLibrary: false,
    exportReferences: false,
    manualEntry: false,
  },
  showNumbering: true, // For editor citations display numbers
  autoAppearanceOrder: true, // For editor citations and reference syncing
  updateContext: () => {},
  onAddWindow: () => {},
  onRemoveWindow: () => {},
  updateWindow: () => {},
  onAddArrow: () => {},
  onRemoveArrow: () => {},
  onClearAll: () => {},
  onAddSource: () => {},
  onRemoveSource: () => {},
  onUpdateSource: () => {},
});

export const ModularProvider = ModularContext.Provider;
export const ModularConsumer = ModularContext.Consumer;
