import React from 'react';
import { Button, Typography } from '@mui/material';
import ReactGA from 'react-ga4';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';

const Documentation = () => (
  <Button
    size="small"
    sx={{
      alignItems: 'center',
      justifyContent: 'flex-start',
      border: 'none',
      width: '100%',
      textTransform: 'none',
    }}
    onClick={() => {
      ReactGA.event({
        category: 'Documentation',
        action: 'Go to documentation',
        label: 'User selected to go documentation',
      });

      window.open(
        process.env.REACT_APP_PLATFORM_DOCUMENTATION,
        '_blank',
        'noreferrer'
      );
    }}
  >
    <HelpCenterOutlinedIcon
      fontSize="small"
      sx={{
        marginRight: '8px',
      }}
    />
    <Typography variant="body1">Documentation</Typography>
  </Button>
);

export default Documentation;
