// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Card,
  ButtonGroup,
  CardContent,
  IconButton,
  Collapse,
  Typography,
  Tooltip,
  Chip,
  Stack,
  darken,
  lighten,
} from '@mui/material';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

// Local
import { SUMMARIZE, SINGLE_PAPER, CITE_PAPER } from '../../../Queries/nlp';
import {
  POST_MANUSCRIPT_LIBRARY,
  DELETE_MANUSCRIPT_LIBRARY,
} from '../../../../Queries';
import { DocumentContext } from '../../../DocumentContext';
import { ModularContext } from '../../../../Modular/ModularContext';
import { getReferences } from '../../../Editor/Utilities/getReferences';
import { cite } from '../../../Editor/Utilities/cite';
import { notify } from '../../../../Common/notify';
import AbstractParsed from '../../View/Document/AbstractParsed';
import ModalWindow from './RemovePrompt';
import CitationExport from '../../Common/CitationExport';

const Item = ({ client, modular, paper }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);

  const [modal, setModal] = useState(false);
  const [referenced, setReferenced] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [bibtex, setBibtex] = useState('');
  const [mla, setMla] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [inManuscriptLibrary, setInManuscriptLibrary] = useState(false);

  const [postManuscriptLibrary] = useMutation(POST_MANUSCRIPT_LIBRARY, {
    fetchPolicy: 'network-only',
  });

  const [deleteManuscriptLibrary] = useMutation(DELETE_MANUSCRIPT_LIBRARY, {
    fetchPolicy: 'network-only',
  });

  const [summarizePaper] = useLazyQuery(SUMMARIZE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ summarizePaper }) => {
      context.updateContext('summaries', summarizePaper.response);
    },
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER, {
    fetchPolicy: 'network-only',
  });

  const [citePaper] = useLazyQuery(CITE_PAPER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ citePaper }) => {
      if (citePaper.status === 'success') {
        setBibtex(citePaper.response.bibtex);
        setMla(citePaper.response.mla);
        setIsOpen(true);
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Retrieved citations');
      } else {
        notify('Could not generate citations, try again later');
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', '');
      }
    },
  });

  useEffect(() => {
    const references = getReferences(context.editorRef);
    let isReferenced = false;

    // Post Slate migration, we check if metadata available first
    if (references.length > 0) {
      references.forEach((reference) => {
        if (
          reference[0].metadata &&
          reference[0].metadata.id_value === paper.id_value
        ) {
          isReferenced = true;
          return;
        }
      });
    }

    setReferenced(isReferenced);

    // eslint-disable-next-line
  }, [context.value]);

  useEffect(() => {
    if (context.manuscriptLibrary) {
      const hasId = context.manuscriptLibrary.some(
        (item) => item._id === paper._id
      );

      if (hasId) {
        setInManuscriptLibrary(true);
      } else {
        setInManuscriptLibrary(false);
      }
    }
  }, [
    context.manuscriptLibrary,
    context.onlyManuscriptLibrary,
    context.searchLibrary,
  ]); // Run on any of these toggles or changes

  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '0px',
        padding: '0px',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? 'background.paper' : '#171717',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? darken(theme.palette.background.paper, 0.05)
              : lighten('#171717', 0.05),
        },
      }}
      elevation={0}
    >
      <ModalWindow
        paper={paper}
        modal={modal}
        setModal={setModal}
        client={client}
      />
      <CitationExport
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        bibtex={bibtex}
        setBibtex={setBibtex}
        mla={mla}
        setMla={setMla}
      />
      <CardContent
        sx={{
          padding: '10px !important',
        }}
      >
        <Stack
          direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              boxSizing: 'border-box',
              maxWidth: '100%',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              variant="body2"
              component="div"
              gutterBottom
              sx={{
                color: 'cornflowerblue',
                cursor: 'pointer',
                width: '100%',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={async (e) => {
                e.preventDefault();

                context.updateContext('loadingRequest', true);
                context.updateContext('loadingMessage', 'Retrieving paper');
                context.updateContext('sortedView', false);

                context.updateContext('rankingDetails', {
                  rankingIdValue: paper.id_value,
                  rankingIdField: paper.id_field,
                  rankingIdType: paper.id_type,
                  rankingCollection: paper.id_collection,
                });

                summarizePaper({
                  variables: {
                    paper_id: {
                      collection: paper.id_collection,
                      id_value: paper.id_value,
                      id_field: paper.id_field,
                      id_type: paper.id_type,
                    },
                  },
                });

                singlePaper({
                  variables: {
                    paper_id: {
                      collection: paper.id_collection,
                      id_value: paper.id_value,
                      id_field: paper.id_field,
                      id_type: paper.id_type,
                    },
                  },
                  onCompleted: ({ singlePaper }) => {
                    if (singlePaper.status === 'success') {
                      context.updateContext('paper', singlePaper.response);
                      context.updateContext('tab', 'View');
                      context.updateContext('showSelection', false);
                      context.updateContext('highlightSelection', '');
                      context.updateContext('loadingRequest', false);
                      context.updateContext(
                        'loadingMessage',
                        'Retrieved paper'
                      );
                      context.recordActionHistory({
                        collection: paper.id_collection,
                        id_value: paper.id_value,
                        id_field: paper.id_field,
                        id_type: paper.id_type,
                      });
                    } else {
                      notify('Could not retrieve paper, try again later');
                      context.updateContext('loadingRequest', false);
                      context.updateContext('loadingMessage', '');
                    }
                  },
                });
              }}
            >
              {paper.title}
            </Typography>
            {paper.author !== undefined &&
              paper.author.length > 0 &&
              paper.author.map((author, index) => (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'green',
                    marginLeft: '0px !important',
                    marginRight: '5px !important',
                  }}
                  key={index}
                >
                  {`${author.FamilyName}, ${author.GivenName}`}
                </Typography>
              ))}
            {paper.venue && (
              <Typography
                variant="caption"
                sx={{
                  color: 'green',
                  marginLeft: '0px !important',
                  marginRight: '5px !important',
                }}
              >
                {paper.venue}.
              </Typography>
            )}
          </Stack>

          <ButtonGroup
            fullWidth={false}
            aria-label="small button group"
            sx={{
              marginLeft: { xs: '0px', md: '20px' },
              maxHeight: '30px',
              marginBottom: '10px',
            }}
          >
            {!inManuscriptLibrary ? (
              <Tooltip title="Add to note library">
                <IconButton
                  disabled={context.readOnly}
                  onClick={async () => {
                    context.updateContext('loadingRequest', true);
                    context.updateContext(
                      'loadingMessage',
                      'Adding paper to note library'
                    );

                    postManuscriptLibrary({
                      variables: {
                        doc_id: context.documentId,
                        bookmark_id: paper._id,
                      },
                      onCompleted: ({ postManuscriptLibrary }) => {
                        if (postManuscriptLibrary.status === 'success') {
                          context.updateContext(
                            'manuscriptLibrary',
                            postManuscriptLibrary.response
                          );
                          setInManuscriptLibrary(true);
                          context.updateContext('loadingRequest', false);
                          context.updateContext(
                            'loadingMessage',
                            'Added paper to note library'
                          );
                        } else {
                          notify(postManuscriptLibrary.message);
                          context.updateContext('loadingRequest', false);
                          context.updateContext(
                            'loadingMessage',
                            'Could not add paper to note library, try again later'
                          );
                        }
                      },
                    });
                  }}
                >
                  <StarBorderOutlinedIcon
                    sx={{
                      color: context.readOnly ? 'inherit' : 'goldenrod',
                      width: 16,
                      height: 16,
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Remove from note library">
                <IconButton
                  onClick={async () => {
                    context.updateContext('loadingRequest', true);
                    context.updateContext(
                      'loadingMessage',
                      'Removing paper from note library'
                    );

                    deleteManuscriptLibrary({
                      variables: {
                        doc_id: context.documentId,
                        bookmark_id: paper._id,
                      },
                      onCompleted: ({ deleteManuscriptLibrary }) => {
                        if (deleteManuscriptLibrary.status === 'success') {
                          context.updateContext(
                            'manuscriptLibrary',
                            deleteManuscriptLibrary.response
                          );
                          setInManuscriptLibrary(false);
                          context.updateContext('loadingRequest', false);
                          context.updateContext(
                            'loadingMessage',
                            'Removed paper from note library'
                          );
                        } else {
                          notify(deleteManuscriptLibrary.message);
                          context.updateContext('loadingRequest', true);
                          context.updateContext(
                            'loadingMessage',
                            'Could not remove paper from note library, try again later'
                          );
                        }
                      },
                    });
                  }}
                >
                  <StarOutlinedIcon
                    sx={{
                      color: context.readOnly ? 'inherit' : 'goldenrod',
                      width: 16,
                      height: 16,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Cite paper in note">
              <IconButton
                color="primary"
                aria-label="cite"
                disabled={context.readOnly}
                component="label"
                onClick={async () => {
                  if (context.readOnly) return;

                  if (!modular) {
                    context.updateContext('loadingRequest', true);
                    context.updateContext('loadingMessage', 'Citing paper');
                  }

                  singlePaper({
                    variables: {
                      paper_id: {
                        collection: paper.id_collection,
                        id_value: paper.id_value,
                        id_field: paper.id_field,
                        id_type: paper.id_type,
                      },
                    },
                    onCompleted: ({ singlePaper }) => {
                      if (singlePaper.status === 'success') {
                        cite(
                          context.editorRef,
                          singlePaper.response,
                          {
                            collection: paper.id_collection,
                            id_value: paper.id_value,
                            id_field: paper.id_field,
                            id_type: paper.id_type,
                          },
                          context.autoAppearanceOrder,
                          context.showNumbering
                        );

                        if (!modular) {
                          context.updateContext('loadingRequest', false);
                          context.updateContext(
                            'loadingMessage',
                            'Cited paper'
                          );
                        }
                      } else {
                        notify('Could not retrieve paper, try again later');
                        if (!modular) {
                          context.updateContext('loadingRequest', false);
                          context.updateContext('loadingMessage', '');
                        }
                      }
                    },
                  });
                }}
              >
                <FormatQuoteOutlinedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate paper citations">
              <IconButton
                color="primary"
                aria-label="generate citations"
                component="label"
                onClick={async () => {
                  if (!modular) {
                    context.updateContext('loadingRequest', true);
                    context.updateContext(
                      'loadingMessage',
                      'Retrieving citations'
                    );
                  }

                  citePaper({
                    variables: {
                      paper_id: {
                        collection: paper.id_collection,
                        id_value: paper.id_value,
                        id_field: paper.id_field,
                        id_type: paper.id_value,
                      },
                    },
                  });
                }}
              >
                <IosShareIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove paper">
              <IconButton
                color="primary"
                aria-label="Remove paper"
                component="label"
                onClick={(e) => {
                  e.preventDefault();

                  setModal(true);
                }}
              >
                <DisabledByDefaultOutlinedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
            <IconButton
              color="primary"
              aria-label="show or hide abstract"
              component="label"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <ExpandLess sx={{ width: 16, height: 16 }} />
              ) : (
                <ExpandMore sx={{ width: 16, height: 16 }} />
              )}
            </IconButton>
          </ButtonGroup>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flext-start"
          spacing={1}
          sx={{
            boxSizing: 'border-box',
            maxWidth: '100%',
            flexWrap: 'wrap',
            marginTop: '10px',
          }}
        >
          {paper.doi && (
            <Chip
              icon={<LocalLibraryOutlinedIcon />}
              size="small"
              label={paper.doi}
            />
          )}
          {paper.id_collection === 'UserUploaded' && (
            <Chip
              icon={
                <CloudDoneOutlinedIcon
                  sx={{
                    color: 'cornflowerblue !important',
                  }}
                />
              }
              size="small"
              label={'Uploaded paper'}
            />
          )}
          {referenced && (
            <Chip
              icon={
                <AddTaskOutlinedIcon
                  sx={{
                    color: 'cornflowerblue !important',
                  }}
                />
              }
              size="small"
              label={'Cited paper'}
            />
          )}
        </Stack>
        <Collapse
          in={collapsed}
          timeout="auto"
          unmountOnExit
          sx={{
            marginTop: '10px',
          }}
        >
          <AbstractParsed
            paper={{
              Content: {
                Abstract: paper.abstract,
                Abstract_Parsed: paper.abstract_parsed,
              },
            }}
            modular={modular}
            highlighter={''}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default Item;
