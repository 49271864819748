// Page
export const manuscripts = [
  {
    title: 'Notes toolbar',
    target: '.toolbar',
    content:
      'This is your notes toolbar, you can can create new notes, import a PDF to make a new note, sort or search through your notes as well as toggle other options',
  },
  {
    title: 'A note',
    placement: 'right',
    target: '.manuscript',
    content: 'Through these individual note cards you can view a note',
  },
  {
    title: 'Other pages and options',
    target: '.navigator',
    content:
      'To view other pages or log out, simply toggle this navigator and access one of the options',
  },
];

// Page
export const manuscript = [
  {
    title: 'Workspace panel',
    target: '.panel',
    content:
      "You can access the workspace panel, it's useful for exporting, importing and changing settings",
  },
  {
    title: 'Note title',
    target: '.title',
    content: 'Change your note title here',
  },
  {
    title: 'The text editor',
    target: '.editor',
    content: 'Write your note text here, you can cite discovered papers',
  },
  {
    title: 'Workspace footer',
    target: '.footer',
    content:
      'The footer shows you some important statistics, you can also toggle the manager and check the status of our services',
  },
  {
    title: 'Discover tab',
    target: '.discover',
    content: 'In this space, you will be able to search and select papers',
  },
  {
    title: 'Library',
    target: '.library',
    content:
      'Your library is also under discover, toggle it on to view saved papers, these are also searchable',
  },
  {
    title: 'Read tab',
    target: '.view',
    content:
      'Once you click on a paper, you will be able to read its content and get access to language tools here',
  },
];
