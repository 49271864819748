// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Stack } from '@mui/material';

const Authors = ({ paper }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (paper.Author && paper.Author.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [paper.Author]);

  const formatName = (familyName, givenName) => {
    const trimmedFamilyName = familyName.trim();
    const trimmedGivenName = givenName.trim();

    if (trimmedFamilyName && trimmedGivenName) {
      return `${trimmedFamilyName}, ${trimmedGivenName}.`;
    } else if (trimmedFamilyName) {
      return `${trimmedFamilyName}`;
    } else if (trimmedGivenName) {
      return `${trimmedGivenName}.`;
    } else {
      return null; // Both names are either empty or contain only spaces
    }
  };

  return show ? (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      sx={{
        boxSizing: 'border-box',
        maxWidth: '100%',
        flexWrap: 'wrap',
      }}
    >
      {paper.Author.map((author, index) => {
        const formattedName = formatName(author.FamilyName, author.GivenName);

        return formattedName ? (
          <Typography key={index} variant="body1">
            {formattedName}
          </Typography>
        ) : null;
      })}
    </Stack>
  ) : null;
};

export default Authors;
