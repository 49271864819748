import { GET_SHARED_DOCUMENTS, DELETE_COLLABORATOR } from "../../Queries";

/**
 * It retrieves all shared document details based on authToken
 * @param {client} object - Apollo Client
 */

export const getSharedDocuments = (client) => {
  return client
    .watchQuery({
      query: GET_SHARED_DOCUMENTS,
      fetchPolicy: "network-only",
      pollInterval: 2000, // Fetch every 2 seconds
    })
    .result()
    .then(({ data }) => {
      return data.docServiceGetSharedDocs;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCollaborator = (client, id, collabId) => {
  return client
    .mutate({
      mutation: DELETE_COLLABORATOR,
      refetchQueries: [{ query: GET_SHARED_DOCUMENTS }],
      variables: {
        doc_id: id,
        collab_id: collabId,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};
