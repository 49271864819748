import * as React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';

export default function Loading() {
  return (
    <Stack
      direction="column"
      sx={{
        height: '100vh',
        justifyContent: 'center',
        padding: '50px',
        bgcolor: 'background.paper',
        boxSizing: 'border-box',
        color: 'text.primary',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          marginBottom: '10px',
          textTransform: 'capitalize',
        }}
      >
        Loading Note
      </Typography>
      <LinearProgress
        variant="indeterminate"
        sx={{
          position: 'relative',
          height: '25px',
          marginBottom: '10px',
        }}
      />
      {process.env.REACT_APP_ENVIRONMENT === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'staging' ? (
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          You are on Staging, this version is used for testing and quality
          assurance.
        </Typography>
      ) : null}
    </Stack>
  );
}
