// Function to check if analytics should be initialized
export const shouldInitializeAnalytics = () => {
  // Check if preferences have been saved
  const wasPreferenceSaved =
    localStorage.getItem("cookieConsentSaved") === "true";
  const analyticsConsent = localStorage.getItem("analyticsConsent") === "true";

  return wasPreferenceSaved && analyticsConsent;
};

export const shouldInitializeErrorTracking = () => {
  // Check if preferences have been saved
  const wasPreferenceSaved =
    localStorage.getItem("cookieConsentSaved") === "true";
  const errorTrackingConsent =
    localStorage.getItem("errorTrackingConsent") === "true";

  return wasPreferenceSaved && errorTrackingConsent;
};
