// Libraries
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Stack } from '@mui/material';

// Local
import { DocumentContext } from '../../../DocumentContext';
import Summaries from './Summaries';
import Title from './Title';
import Date from './Date';
import DOI from './DOI';
import Venue from './Venue';
import Authors from './Authors';
import AbstractParsed from './AbstractParsed';
import FullbodyParsed from './FullbodyParsed';
import Selection from '../Selection';
import GenToggle from '../../Generator';
import AnnToggle from '../../Annotator';
import References from './References';
import PaperToolbar from '../../../Toolbar/PaperToolbar';
import GoogleScholar from './GoogleScholar';
import AssistToggle from '../../Assist';

const Document = ({ highlighter, client }) => {
  const context = useContext(DocumentContext);
  const [prevTitle, setPrevTitle] = useState('');
  const myRef = useRef(null);

  useEffect(() => {
    if (context.paper && context.paper.Title !== prevTitle) {
      myRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });

      setPrevTitle(context.paper.Title);
    }
  }, [context.paper]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: 'calc(100vh - 51px - 20px)',
          sm: 'calc(100vh - 51px - 20px - 35px)',
        },
      }}
    >
      <GenToggle client={client} />
      <AnnToggle />
      <AssistToggle />
      <PaperToolbar client={client} targetEl={myRef} modular={false} />
      <Selection />
      <Box
        ref={myRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '100%',
          boxShadow: !context.view.explorer && !context.isMobile && 'none',
        }}
      >
        <Stack
          direction="column"
          sx={{
            alignItems: 'flex-start',
            boxSizing: 'border-box',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              padding: '10px',
              border: (theme) =>
                theme.palette.mode === 'dark'
                  ? '1px solid #333333'
                  : '1px solid #d4d4d4',
              marginBottom: '10px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Title paper={context.paper} />
            <Authors paper={context.paper} />
            <Stack direction={'row'}>
              <Date paper={context.paper} />
              <Venue paper={context.paper} />
            </Stack>
            <Stack direction={'row'}>
              <DOI paper={context.paper} />
              <GoogleScholar paper={context.paper} />
            </Stack>
          </Box>
          <Summaries modular={false} />
          <AbstractParsed
            paper={context.paper}
            modular={false}
            highlighter={highlighter}
          />
          <FullbodyParsed
            paper={context.paper}
            modular={false}
            highlighter={highlighter}
          />
          <References paper={context.paper} modular={false} client={client} />
        </Stack>
      </Box>
    </Box>
  );
};

export default Document;
