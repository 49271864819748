import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

/**
 * Get all citations inside the text editor
 *
 * @param {object} editor - The current slate editor instance
 */

export const getCitations = (editor: ReactEditor) => {
  // If editor is empty, return
  if (!editor) {
    throw new Error('Editor is required');
  }

  const results = Array.from(
    Editor.nodes(editor, {
      match: (node) => ['citation'].includes((node as CitationNode).type),
      at: [],
    }),
    (node) => node
  );

  return results;
};
