/* eslint-disable no-case-declarations */
// Libraries
import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useContext,
  useState,
} from 'react';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { createEditor, Transforms, Editor, Range } from 'slate';
import { withHistory } from 'slate-history';
import { Box, Paper } from '@mui/material';
import { debounce } from 'lodash';

// Local
import { DocumentContext } from '../DocumentContext';
import { ModularContext } from '../../Modular/ModularContext';
// import { intelliDelete } from './Utilities/intelliDelete';
import { insertMention } from './Menu/InsertMention';
// import handleMouseDown from './Utilities/handleMouseDown';
import useDecorateSelection from './Decorators/useDecorateSelection';
import Leaf from './Leafs';
import Element from './Elements';
import Menu from './Menu';
import Title from './Title';
import EditorToolbar from '../Toolbar/EditorToolbar';
import HotkeysProviderWrapper from './Types/Hotkeys';
// import withStrictSchema from './Plugins/withStrictSchema';
import withLayout from './Plugins/withLayout';
// import withCharacterFiltering from './Plugins/withCharacterFiltering';
// import withHyperlinks from './Plugins/withHyperlinks';
// import withInlineKatex from './Plugins/withInlineKatex';
// import withKatex from './Plugins/withKatex';
import withCitations from './Plugins/withCitations';
import withReferences from './Plugins/withReferences';
// import withFigures from './Plugins/withFigures';
import MentionDropdown from './Menu/MentionDropdown';
import { getCitations } from './Utilities/getCitations';

const TextEditor = ({ client, modular }) => {
  const refForHighlight = useRef();
  const ref = useRef();

  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [localValue, setLocalValue] = useState(context.value);

  const editor = useMemo(
    () =>
      // withStrictSchema(
      // withCharacterFiltering(
      // withHyperlinks(
      // withInlineKatex(
      // withKatex(
      // withFigures(
      withLayout(
        withCitations(withReferences(withReact(withHistory(createEditor()))))
      ),
    // )
    // )
    // )
    // )
    // )
    []
  );

  const chars = Array.from(
    new Set(
      getCitations(editor)
        .map((c) => c[0]) // Extract the citation
        .filter((c) =>
          c.citation.toLowerCase().startsWith(search.toLowerCase())
        )
        .map((c) => JSON.stringify(c)) // Convert to JSON string to ensure uniqueness
    )
  )
    .map((c) => JSON.parse(c)) // Convert back to objects
    .slice(0, 10);

  const onKeyDown = useCallback(
    (event) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [chars, editor, index, target]
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  // eslint-disable-next-line
  const renderElement = useCallback(
    (props) => <Element {...props} client={client} modular={modular} />,
    [client, modular]
  );

  // eslint-disable-next-line
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  useEffect(() => {
    context.updateContext('editorRef', editor);
    Transforms.select(editor, { offset: 0, path: [0, 0] });
  }, []);

  const debouncedSync = debounce((value) => {
    context.updateContext('value', value);
  }, 2000);

  return (
    <>
      <Slate
        className="editor"
        editor={editor}
        value={localValue}
        onChange={(value) => {
          setLocalValue(value);
          debouncedSync(value);

          const { selection } = editor;

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange);
              setSearch(beforeMatch[1]);
              setIndex(0);
              return;
            }
          }

          setTarget(null);
        }}
      >
        <HotkeysProviderWrapper client={client}>
          {!modular && (
            <Box
              sx={{
                zIndex: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? 'background.paper'
                    : '#171717',
                position: 'absolute',
                width: context.view.edit ? '98%' : '55%',
              }}
            >
              <Menu />
            </Box>
          )}
          <Box
            ref={refForHighlight}
            sx={
              modular
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    height: '100%',
                    width: context.view.edit ? '100%' : '55%',
                    alignItems: context.view.edit ? 'center' : null,
                    overflowY: 'auto',
                    paddingTop: '35px',
                  }
            }
          >
            <Paper
              elevation={0}
              sx={
                modular
                  ? {
                      padding: '10px',
                      background: 'white',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '5px',
                      width: '-webkit-fill-available',
                      margin: '10px',
                      fontFamily: 'Arial',
                    }
                  : {
                      padding:
                        context.view.edit && window.innerWidth > 900
                          ? '0 20vw 0 20vw'
                          : '0 1vw 0 1vw',
                      height: 'calc(100% - 60px)',
                      borderRadius: '5px',
                      width: '100%',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'background.paper'
                          : '#171717',
                      boxSizing: 'border-box',
                      scrollBehavior: 'none',
                      lineHeight: '1.5',
                      fontSize: `${context.fontSize}px`,
                      fontFamily: 'Arial',
                    }
              }
            >
              <Title modular={modular} />
              <EditorToolbar
                client={client}
                modular={modular}
                editorBoxRef={refForHighlight}
              />
              <Editable
                style={{
                  height: '100%',
                }}
                decorate={useDecorateSelection(editor, context, modular)}
                renderElement={renderElement} // Mainly for blocks
                renderLeaf={renderLeaf} // Mainly for inline
                readOnly={modular ? false : context.readOnly}
                id="undraggable"
                // onMouseDown={() => {
                //   handleMouseDown(modular, context, editor);
                // }}
                onKeyDown={(event) => {
                  // intelliDelete(event, editor, context.autoAppearanceOrder);
                  onKeyDown(event);
                }}
              />
            </Paper>
          </Box>
        </HotkeysProviderWrapper>
        {target && chars.length > 0 && (
          <MentionDropdown
            refProp={ref}
            chars={chars}
            index={index}
            setTarget={setTarget}
            target={target}
            editor={editor}
          />
        )}
      </Slate>
    </>
  );
};

export default TextEditor;
