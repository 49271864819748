import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { downloadBibtex, downloadMla } from "../../Utilities/exporting";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  color: "text.primary",
  boxShadow: 24,
  p: 2,
};

const CitationExport = ({
  isOpen,
  setIsOpen,
  bibtex,
  setBibtex,
  mla,
  setMla,
}) => (
  <Modal
    aria-labelledby="Citation exports"
    aria-describedby="BIB or MLA citations"
    open={isOpen}
    onClose={() => setIsOpen(false)}
    closeAfterTransition
  >
    <Fade in={isOpen}>
      <Box sx={style}>
        <Typography
          sx={{
            marginBottom: "5px",
          }}
        >
          MLA:
        </Typography>
        <Typography
          id="mla"
          sx={{
            padding: "10px",
            border: "1px solid lightgrey",
            marginBottom: "5px",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            navigator.clipboard.writeText(mla);
          }}
        >
          {mla}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(mla);
          }}
          sx={{
            marginBottom: "10px",
          }}
        >
          Copy
        </Button>
        <Typography
          sx={{
            marginBottom: "5px",
          }}
        >
          Bibtex:
        </Typography>
        <Typography
          id="bibtex"
          sx={{
            marginTop: "5px",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid lightgrey",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            navigator.clipboard.writeText(bibtex);
          }}
        >
          {bibtex}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(bibtex);
          }}
          sx={{
            marginBottom: "10px",
          }}
        >
          Copy
        </Button>

        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => {
              downloadBibtex(bibtex);
              downloadMla(mla);
            }}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              setBibtex("");
              setMla("");
            }}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Fade>
  </Modal>
);

export default CitationExport;
