// Library
import React, { useContext } from 'react';
import { Button } from '@mui/material';

// Local
import { DocumentContext } from '../../DocumentContext';
import { downloadAnnotation } from "../../Utilities/exporting";

const DownloadAnnotationButton = () => {
  const context = useContext(DocumentContext);

  return (
    <Button
      variant='outlined'
      size='small'
      sx={{
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: 'green',
        '&:hover': { backgroundColor: 'green' },
        height: 'fit-content',
        width: 'fit-content',
      }}
      onClick={async () => {
        const out = {
          manuscript_id: context.documentId,
          manuscript_title: context.title,
          citedpaper_id: context.annotatorPaperId,
          x: [],
          y: [],
          precision: context.annotatorPrecision,
          recall: context.annotatorRecall,
          sufficiency: context.annotatorSufficiency,
          efficiency: context.annotatorEfficiency,
        };

        context.annotatorX.forEach( (item) => {
          out.x.push({
            text: item.text,
            anchor: item.anchor,
            focus: item.focus,
          });
        })
        context.annotatorY.forEach( (item) => {
          out.y.push({
            text: item.text,
            anchor_parent_id: item.anchorParentId,
            anchor_offset: item.anchorOffset,
            focus_parent_id: item.focusParentId,
            focus_offset: item.focusOffset,
          });
        })

        const filename = `annotation#${out.manuscript_id}-${out.citedpaper_id}-${new Date().toISOString()}`;
        downloadAnnotation(JSON.stringify(out, null, 4), filename);
      }}
    >
      Download
    </Button>
  )
}

export default DownloadAnnotationButton;
