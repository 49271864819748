import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const HTMLTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.white,
  },
}));

const RestrictedTooltip = ({
  children,
  tooltipText,
  extendedText = '',
  linkSuffix,
}) => {
  // Check if children is a disabled button
  const isDisabledButton =
    React.isValidElement(children) && children.props.disabled ? true : false;

  const tooltipContent = (
    <Stack
      spacing={0.5}
      alignItems="flex-start"
      sx={{
        maxWidth: '250px',
      }}
    >
      <Typography
        color="inherit"
        fontSize="inherit"
        sx={{
          textAlign: 'left',
        }}
      >
        {tooltipText}
      </Typography>
      <Typography
        color="inherit"
        fontSize="inherit"
        sx={{
          display: extendedText === '' && 'none',
        }}
      >
        {extendedText}
      </Typography>
      <Button
        size="small"
        sx={{
          color: 'white',
          border: 'none',
          margin: 0,
          padding: 0,
        }}
        endIcon={<OpenInNewIcon />}
        onClick={(e) => {
          e.stopPropagation();
          window.open(
            `${process.env.REACT_APP_PLATFORM_DOCUMENTATION}${linkSuffix}`
          );
        }}
      >
        Learn more
      </Button>
    </Stack>
  );

  return (
    <HTMLTooltip enterDelay={500} fontSize="caption" title={tooltipContent}>
      {isDisabledButton ? (
        <span
          style={{
            display: 'inherit',
            width: 'inherit',
            height: 'inherit',
          }}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </HTMLTooltip>
  );
};

export default RestrictedTooltip;
