// Libraries
import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';

// Local
import Footer from '../Common/Footer';

const TermsOfService = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          alignItems: 'flex-start',
          padding: '40px',
          height: 'auto',
          color: 'text.primary',
          textAlign: 'left',
        }}
      >
        <Typography variant="h5">Terms of Service</Typography>
        <Typography variant="caption">Last updated: 2024-08-16</Typography>
        <Typography variant="h6">Overview</Typography>
        <Typography variant="body1">
          We are the Natural Language Processing (NLP) lab at the Institute of
          Neuroinformatics, University of Zurich and ETH Zurich.
          <br /> <br />
          These Terms of Service (“Terms”) govern your access to and use of the
          service we provide through Endoc at endoc.ethz.ch (“Service”). Please
          read them carefully and reach out to us if you have any questions. If
          you don&apos;t agree with these Terms, don&apos;t use our Service.
          <br /> <br />
          By accessing or using any part of our Service, you agree to be bound
          by all of the Terms as well as all other operating rules, policies,
          and procedures that we may publish via the Service from time to time
          (collectively, the “Agreement”). You also agree that we may change,
          update, or add on to our Service as stated in the Terms, and that the
          Agreement will apply to any changes.
        </Typography>
        <Typography variant="h6">Who&apos;s who</Typography>
        <Typography variant="body1">
          “You” refers to any individual or entity using our Service. If you use
          our Service on behalf of another person or entity, you represent and
          warrant that you&apos;re authorized to accept the Agreement on that
          person&apos;s or entity&apos;s behalf, that by using our Service
          you&apos;re accepting the Agreement on behalf of that person or
          entity, and that if you, or that person or entity, violates the
          Agreement, then you and that person or entity agree to be responsible
          to us.
          <br /> <br />
          We refer to ourselves as “Endoc”, “us”, or “we” throughout these
          Terms.
        </Typography>
        <Typography variant="h6">Your account</Typography>
        <Typography variant="body1">
          Our Service is directed only at persons who are enrolled students or
          employees of Swiss institutions as verified by our third party Switch
          edu-ID. By registering for an account with us, you confirm that you
          are an enrolled student of or are employed by at least one of the
          Institutions.
          <br /> <br />
          You agree to provide us with complete and accurate information during
          registration. We may limit your access to our Service until we&apos;re
          able to verify your account information, like your email address. You
          also agree to keep the information current so that we can communicate
          with you about your account and notable updates (like changes to our
          Terms) so you can make informed choices in response. <br />
          <br /> Our Service provides you with tools for navigating collections
          of texts, including, but not limited to, research papers, preprints,
          and review articles (collectively, “Documents”), as well as the
          ability to discover, view, cite, save and import Documents
          (collectively, “Use” or “Usage”). You confirm that you are allowed by
          the Swiss Federal Act on Copyright and Related Rights to Use such
          Documents, for instance, because the Use is for the purposes for
          scientific research (see Art. 24d). You also agree that you are
          extending to us your right to Use Documents on your behalf when
          providing our Service to you. This includes, but is not limited to,
          downloading and preprocessing the files associated with such
          Documents, and using information associated with such Documents during
          literature discovery and text generation. <br />
          <br /> We consider registering for an account to be an inquiry about
          our Service, which means that we may also email you to share more
          details about what we have to offer (i.e., marketing). You can opt out
          of the marketing communications at any time. <br />
          <br /> You&apos;re solely responsible and liable for all activity
          under your account. You&apos;re also fully responsible for maintaining
          the security of your account (which includes keeping your password
          secure). We&apos;re not liable for any acts or omissions by you,
          including any damages of any kind incurred as a result of your acts or
          omissions. Don&apos;t share or misuse your access credentials. Notify
          us immediately of any unauthorized uses of your account, store, or
          website, or of any other breach of security. If we believe your
          account has been compromised, we may suspend or disable it. <br />
          <br /> If you&apos;d like to learn more about how we handle the data
          you provide us, please see our privacy policy.
        </Typography>
        <Typography variant="h6">Your responsibility</Typography>
        <Typography variant="body1">
          We haven&apos;t reviewed, and can&apos;t review, all of the content
          (like text, images, tables, and figures) made available through our
          Service by users or anyone else nor the content on websites that link
          to, or are linked from, our Service (“Content”). We&apos;re not
          responsible for any use of effects of Content or third-party websites.
          So, for example:
        </Typography>
        <ul>
          <li>
            <Typography>
              We don&apos;t have any control over third-party websites.
            </Typography>
          </li>
          <li>
            <Typography>
              A link to or from our Service does not represent or imply that we
              endorse any third-party website.
            </Typography>
          </li>
          <li>
            <Typography>
              We don&apos;t endorse any Content or represent that Content is
              accurate, useful, or not harmful. Content could be offensive,
              indecent, or objectionable; include technical inaccuracies,
              typographical mistakes, or other errors; or violate or infringe
              the privacy, publicity rights, intellectual property rights, or
              other proprietary rights of third parties.
            </Typography>
          </li>
          <li>
            <Typography>
              You&apos;re fully responsible for the Content that you upload and
              create, and any harm resulting from that Content. It&apos;s your
              responsibility to ensure that your Content abides by applicable
              laws and by the Agreement.
            </Typography>
          </li>
          <li>
            <Typography>
              We aren&apos;t responsible for any harm resulting from
              anyone&apos;s access, use, purchase, or downloading of Content, or
              for any harm resulting from third-party websites. We aren&apos;t
              responsible for any harm resulting from anyone&apos;s access, use,
              purchase, or downloading of Content, or for any harm resulting
              from third-party websites. You&apos;re responsible for taking the
              necessary precautions to protect yourself and your computer
              systems from viruses, worms, Trojan horses, and other harmful or
              destructive content.
            </Typography>
          </li>
          <li>
            <Typography>
              We are not a party to, and will have no responsibility or
              liability for, any communications, transactions, interactions, or
              disputes between you and the provider of any Content.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1">
          Additional third-party terms and conditions may apply to Content you
          download, copy, purchase, or use.
        </Typography>
        <Typography variant="h6">Removing Content</Typography>
        <Typography variant="body1">
          If you delete Content, we&apos;ll use reasonable efforts to remove it
          from view by the users who already have access to it, but you
          acknowledge that cached version of the Content or references to the
          Content may not be immediately unavailable.
        </Typography>
        <Typography variant="h6">Feedback</Typography>
        <Typography variant="body1">
          We love hearing from you and are always looking to improve our
          Service. When you share comments, ideas, or feedback with us, you
          agree that we&apos;re free to use them without any restriction or
          compensation to you.
        </Typography>
        <Typography variant="h6">Artificial Intelligence</Typography>
        <Typography variant="body1">
          We may offer features that incorporate artificial intelligence (AI),
          like text suggestions for your notes and recommendations on
          appropriate documents to cite. As between you and Endoc, and to the
          extent permitted by law, you own or have the rights to any content you
          input into AI features (“Input”) and any content generated by the AI
          features (“Output”). <br />
          <br /> AI may occasionally generate Output that&apos;s inaccurate,
          irrelevant, offensive, harmful, or similar to others&apos; content.
          You&apos;re responsible for your Input, the use of any Output, and for
          complying with any applicable laws.
        </Typography>
        <Typography variant="h6">
          General Representation and Warranty
        </Typography>
        <Typography variant="body1">
          Our Service is designed to give you control over your notes and
          AI-powered tools for navigating documents. We encourage you to explore
          our Service, subject to a few requirements. In particular, you
          represent and warrant that your use of our Service:
        </Typography>
        <ul>
          <li>
            <Typography>
              Will be in strict accordance with the Agreement;
            </Typography>
          </li>
          <li>
            <Typography>
              Will comply with all applicable laws and regulations (including,
              without limitation, all applicable laws regarding online conduct
              and acceptable content, privacy, and data protection);
            </Typography>
          </li>
          <li>
            <Typography>
              Will not be for any unlawful purposes, to publish illegal content,
              or in furtherance of illegal activities;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not infringe or misappropriate the intellectual property
              rights of Endoc or any third party;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not overburden or interfere with our systems or impose an
              unreasonable or disproportionately large load on our
              infrastructure, as determined by us in our sole discretion;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not disclose the personal information of others;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not be used to send spam or bulk unsolicited messages;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not interfere with, disrupt, or attack any service or
              network;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not be used to create, distribute, or enable material that
              is, facilitates, or operates in conjunction with, malware,
              spyware, adware, or other malicious programs or code;
            </Typography>
          </li>
          <li>
            <Typography>
              Will not involve reverse engineering, decompiling, disassembling,
              deciphering, or otherwise attempting to derive the source code for
              the Service or any related technology that is not open source; and
            </Typography>
          </li>
          <li>
            <Typography>
              Will not involve renting, leasing, loaning, selling, or reselling
              the Service or related data without our consent.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6">Intellectual property</Typography>
        <Typography variant="body1">
          The Agreement doesn&apos;t transfer any Endoc or third-party
          intellectual property to you, and all right, title, and interest in
          and to such property remains (as between Endoc and you) solely with
          Endoc. Endoc and all other trademarks, service marks, graphics, and
          logos used in connection with our Service are trademarks or registered
          trademarks of Endoc (or Endoc&apos;s licensors). Other trademarks,
          service marks, graphics, and logos used in connection with our Service
          may be the trademarks of other third parties. Using our Service
          doesn&apos;t grant you any right or license to reproduce or otherwise
          use any Endoc or third-party trademarks.
        </Typography>

        <Typography variant="h6">Third-Party Services</Typography>
        <Typography variant="body1">
          While using our Service, you may enable or use tools (like specific AI
          tools) provided or manufactured by a third party or yourself
          (“Third-Party Services”). If you use any Third-Party Services, you
          understand that:
        </Typography>
        <ul>
          <li>
            <Typography>
              Third-Party Services aren&apos;t vetted, endorsed, or controlled
              by Endoc.
            </Typography>
          </li>
          <li>
            <Typography>
              Any use of a Third-Party Service is at your own risk, and we
              won&apos;t be responsible or liable to anyone for Third-Party
              Services.
            </Typography>
          </li>
          <li>
            <Typography>
              Your use is solely between you and the respective third party
              (“Third Party”) and is governed by the Third Party&apos;s terms
              and policies.
            </Typography>
          </li>
          <li>
            <Typography>
              Some Third-Party Services may request or require access to your
              data — or to your visitors&apos; or customers&apos; data — through
              things like pixels or cookies. If you use the Third-Party Service
              or grant them access, the data will be handled in accordance with
              the Third Party&apos;s privacy policy and practices, which you
              should carefully review before you use any Third-Party Services.
              Third-Party Services may not work appropriately with our Service
              and we may not be able to provide support for issues caused by any
              Third-Party Services.
            </Typography>
          </li>
          <li>
            <Typography>
              If you have questions or concerns about how a Third-Party Service
              operates or need support, contact the Third Party directly.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1">
          In rare cases we may at our discretion suspend, disable, or remove
          Third-Party Services from your account.
        </Typography>
        <Typography variant="h6">Changes</Typography>
        <Typography variant="body1">
          We may modify the Terms from time to time, for example, to reflect
          changes to our Service (e.g., adding new features, payment plans or
          benefits or retiring certain features) or for legal, regulatory, or
          security reasons. If we do this, we&apos;ll provide notice of the
          changes, such as by posting the amended Terms and updating the “Last
          Updated” date or, if the changes, in our sole discretion, are
          material, we may notify you through our Service or other
          communications. Any changes will apply on a going-forward basis, and,
          unless we say otherwise, the amended Terms will be effective
          immediately. By continuing to use our Service after we&apos;ve
          notified you, you agree to be bound by the new Terms. You have the
          right to object to any changes at any time by ceasing your use of our
          Service.
        </Typography>
        <Typography variant="h6">Termination</Typography>
        <Typography variant="body1">
          We may terminate your access to all or any part of our Services at any
          time, with or without cause or notice, effective immediately,
          including if we believe, in our sole discretion, that you have
          violated this Agreement, any service guidelines, or other applicable
          terms. We have the right (though not the obligation) to (i) reclaim
          your username due to prolonged inactivity, (ii) refuse or remove any
          content that, in our reasonable opinion, violates any part of this
          Agreement, or is in any way harmful or objectionable, (iii) ask you to
          make adjustments, restrict the resources your account uses, or
          terminate your access to our Service, if we believe your
          account&apos;s storage or bandwidth usage burdens our systems (which
          is rare and typically only occurs when an account is used for file
          sharing or storage), or (iv) terminate or deny access to and use of
          any of our Service to any individual or entity for any reason.
        </Typography>
        <Typography variant="h6">Disclaimers</Typography>
        <Typography variant="body1">
          Our Service is provided “as is”. We hereby disclaim all warranties of
          any kind, express or implied, to the maximum extent allowed by
          applicable law, including, without limitation, the warranties of
          merchantability, fitness for a particular purpose and
          non-infringement. Neither Endoc, nor its suppliers and licensors,
          makes any warranty that our Service will be error free or that access
          thereto will be continuous or uninterrupted. You understand that you
          download from, or otherwise obtain content or services through, our
          Service at your own discretion and risk.
        </Typography>
        <Typography variant="h6">Indemnification</Typography>
        <Typography variant="body1">
          You agree to indemnify and hold harmless Endoc, its contractors, and
          its licensors, and their respective directors, officers, employees,
          and agents from and against any and all losses, liabilities, demands,
          damages, costs, claims, and expenses, including attorneys&apos; fees,
          arising out of or related to your use of our Service, including but
          not limited to your violation of the Agreement or any agreement with a
          provider of third-party services used in connection with the Service
          or Content that you post.
        </Typography>
        <Typography variant="h6">Miscellaneous</Typography>
        <Typography variant="body1">
          The Agreement (together with any other terms we provide that apply to
          any specific Service) constitutes the entire agreement between you and
          us concerning our Service. If any part of the Agreement is unlawful,
          void, or unenforceable, that part is severable from the Agreement, and
          does not affect the validity or enforceability of the rest of the
          Agreement.
          <br />
          <br /> A waiver by either party of any term or condition of the
          Agreement or any breach thereof, in any one instance, will not waive
          such term or condition or any subsequent breach thereof. <br />
          <br />
          We may assign our rights under the Agreement without condition. You
          may only assign your rights under the Agreement with our prior written
          consent.
        </Typography>
      </Stack>
      <Footer />
    </>
  );
};

export default TermsOfService;
