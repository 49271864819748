// Libraries
import React from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Transforms, Location } from 'slate';
import {
  Stack,
  Button,
  Box,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

// Local
import { insertKatex } from './InsertKatex';
import { insertInlineKatex } from './InsertInlineKatex';
import { updateKatex } from './UpdateKatex';
import generateKatex from '../Elements/GenerateKatex';
import { InlineKatexNode, KatexNode } from '../Types'

interface EquationModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  selection: Location,
  equation: string,
  setEquation: React.Dispatch<React.SetStateAction<string>>,
  editable: boolean,
  element: KatexNode | InlineKatexNode | null,
} 

const EquationModal: React.FC<EquationModalProps> = ({
  open,
  setOpen,
  selection,
  equation,
  setEquation,
  editable = false,
  element = null,
}) => {
  const editor: Editor = useSlate();

  // Prevents weird scroll behaviour
  const preventScroll = (event: (React.MouseEvent | React.SyntheticEvent)) => {
    event.preventDefault();

    if (element) {
      const path = ReactEditor.findPath(editor as ReactEditor, element);
      const range = Editor.range(editor, path);
      const selection = { anchor: range.anchor, focus: range.focus };
      Transforms.setSelection(editor, selection);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(e: React.MouseEvent<Element, MouseEvent> | React.SyntheticEvent<Element, Event>) => {
        setOpen(false);
        preventScroll(e);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: 'none',
          outline: 'none',
          color: 'text.primary',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        <Typography id="Equation modal" variant="h6">
          Insert an equation
        </Typography>
        <Typography id="equation-modal-description" gutterBottom>
          Insert an equation as inline between text or block
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            width: '100%',
            borderRadius: '5px',
            boxSizing: 'border-box',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          {equation ? generateKatex(equation)?.html : 'Preview will be shown here'}
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Edit latex"
          value={equation}
          onChange={(event) => setEquation(event.target.value)}
          InputProps={{
            maxRows: 8,
            multiline: true,
          }}
          sx={{
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            display: editable ? 'none' : 'flex',
          }}
        >
          <Button
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              preventScroll(e);
              insertKatex(editor, selection, equation);
              setOpen(false);
            }}
          >
            Insert block
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();

              insertInlineKatex(editor, selection, equation);
              preventScroll(e);
              setOpen(false);
            }}
          >
            Insert inline
          </Button>
        </Stack>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            display: editable ? 'flex' : 'none',
          }}
        >
          <Button
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              if (element) {
                updateKatex(editor, equation, element);
              }
              setOpen(false);
              preventScroll(e);
            }}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EquationModal;
