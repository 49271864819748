// Libraries
import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  Stack,
  Typography,
  darken,
  lighten,
} from '@mui/material';

// Local
import ethzLogo from '../Assets/ethz_logo.png';
import iniLogo from '../Assets/ini_logo.png';
import uzhLogo from '../Assets/uzh_logo.png';
import epflLogo from '../Assets/epfl_logo.png';
import swissuniversitiesLogo from '../Assets/swissuniversities_logo.png';

const Associations = () => (
  <Box
    sx={{
      width: '100%',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? darken(theme.palette.background.paper, 0.025)
          : lighten(theme.palette.background.paper, 0.35),
      paddingTop: '30px',
      height: 'auto',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: '300',
        marginBottom: '10px',
      }}
    >
      Trusted and supported by
    </Typography>
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={8}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <Card
        elevation={0}
        sx={{
          background: 'transparent',
          padding: '20px',
        }}
      >
        <CardMedia
          component="img"
          height="36"
          image={ethzLogo}
          alt="Ethz logo"
          sx={{ objectFit: 'contain' }}
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          background: 'transparent',
          padding: '20px',
        }}
      >
        <CardMedia
          component="img"
          height="36"
          image={iniLogo}
          alt="INI logo"
          sx={{ objectFit: 'contain' }}
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          background: 'transparent',
          padding: '20px',
        }}
      >
        <CardMedia
          component="img"
          height="36"
          image={uzhLogo}
          alt="UZH logo"
          sx={{ objectFit: 'contain' }}
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          background: 'transparent',
          padding: '20px',
        }}
      >
        <CardMedia
          component="img"
          height="36"
          image={epflLogo}
          alt="EPFL logo"
          sx={{ objectFit: 'contain' }}
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          background: 'transparent',
          padding: '20px',
        }}
      >
        <CardMedia
          component="img"
          height="36"
          image={swissuniversitiesLogo}
          alt="Swissuniversities logo"
          sx={{ objectFit: 'contain' }}
        />
      </Card>
    </Stack>
  </Box>
);

export default Associations;
