import React, { useState, useEffect } from 'react';
import { Alert } from '@mui/material';

/**
 * This is a higher order component (HoC)
 * which checks online and offline connectivity
 * if user loses connection then it will display a warning
 * that content is not being saved
 *
 * @param {Component} ComposedComponent - Component to detect connectivity on
 */

export default function (ComposedComponent) {
  const networkDetector = (props) => {
    const [isDisconnected, setIsDisconnected] = useState(false);

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);
      return () => {
        window.removeEventListener('online', handleConnectionChange);
        window.removeEventListener('offline', handleConnectionChange);
      };
    }, []);

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(() => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              setIsDisconnected(false);
              clearInterval(webPing);
            })
            .catch(() => setIsDisconnected(true));
        }, 20000);
        return;
      }

      return setIsDisconnected(true);
    };

    return (
      <>
        {isDisconnected && (
          <Alert
            severity="warning"
            sx={{
              margin: '20px',
              position: 'absolute',
              zIndex: '9999',
              height: 'auto',
              width: 'auto',
            }}
          >
            Internet connection lost!
          </Alert>
        )}
        <ComposedComponent {...props} />
      </>
    );
  };

  return networkDetector;
}
