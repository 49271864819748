// Modules
import React, { useState, useEffect } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';

// Local
import { CONTACT_US } from '../Queries';
import Footer from '../Common/Footer';
import Error from '../Common/Error';

/**
 * Hook component for contact form
 * @function
 * @returns {JSX.Element} - Rendered component
 */

const Contact = ({ title, user }) => {
  const [disabled, setDisabled] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      topic: 'Feature Request',
    },
  });

  // if user exists, set the name and email fields
  if (user) {
    register('name', { value: user.name });
    register('email', { value: user.email });
  }

  useEffect(() => {
    document.title = title;
  }, []);

  const handleChange = (e) => {
    register({ name: e.target.name }, e.target.value);
  };

  const onSubmit = (
    { topic, name, surname, email, subject, message },
    contactUs
  ) => {
    contactUs({
      variables: {
        topic,
        name,
        surname,
        email,
        subject,
        message,
      },
    })
      .then(async ({ data }) => {
        setDisabled(true);
        setEmailMessage(data.contactUs.message);
      })
      .catch(() =>
        setEmailMessage('Something went wrong, please try again later')
      );
  };

  return (
    <>
      <Mutation mutation={CONTACT_US}>
        {(contactUs) => (
          <Box
            component="form"
            sx={{
              display: 'flex',
              width: '100%',
              maxWidth: '800px',
              minHeight: '100vh',
              height: 'auto',
              flexDirection: 'column',
              justifyContent: 'center',
              boxSizing: 'border-box',
            }}
            onSubmit={handleSubmit((data) => onSubmit(data, contactUs))}
          >
            <FormControl
              fullWidth
              sx={{
                marginBottom: '10px',
              }}
            >
              <Select
                id="demo-simple-select"
                defaultValue="Feature Request"
                {...register('topic')}
                disabled={disabled}
              >
                <MenuItem value="Feature Request">Feature Request</MenuItem>
                <MenuItem value="Bug Report">Bug Report</MenuItem>
                <MenuItem value="General Feedback">General Feedback</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              type="text"
              disabled={disabled}
              name="name"
              placeholder="Your name"
              sx={{ marginBottom: '10px' }}
              onChange={handleChange}
              {...register('name', {
                required: 'A name is required',
              })}
            />
            <TextField
              required
              type="text"
              disabled={disabled}
              name="surname"
              placeholder="Your surname"
              sx={{ marginBottom: '10px' }}
              onChange={handleChange}
              {...register('surname', {
                required: 'A surname is required',
              })}
            />
            <TextField
              required
              disabled={disabled}
              type="text"
              name="email"
              placeholder="Your personal email"
              sx={{ marginBottom: '10px' }}
              onChange={handleChange}
              {...register('email', {
                required: 'A valid email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'invalid email address',
                },
              })}
            />
            <TextField
              required
              type="text"
              disabled={disabled}
              name="subject"
              placeholder="Your message subject"
              sx={{ marginBottom: '10px' }}
              onChange={handleChange}
              {...register('subject', {
                required: 'A subject is required',
              })}
            />
            <TextField
              required
              type="text"
              disabled={disabled}
              name="message"
              multiline
              rows={4}
              placeholder="Your message"
              sx={{ marginBottom: '10px' }}
              onChange={handleChange}
              {...register('message', {
                required: 'A message is required',
              })}
            />
            <Button
              variant="outlined"
              disabled={disabled}
              type="submit"
              sx={{
                marginBottom: '10px',
              }}
            >
              Send message
            </Button>
            {errors.name && (
              <Error response={{ error: 'A name is required' }} />
            )}
            {errors.surname && (
              <Error response={{ error: 'A surname is required' }} />
            )}

            {errors.subject && (
              <Error response={{ error: 'A subject is required' }} />
            )}

            {errors.message && (
              <Error response={{ error: 'A message is required' }} />
            )}
            {errors.email && (
              <Error response={{ error: 'A valid email is required' }} />
            )}

            {emailMessage && <Alert severity="info">{emailMessage}</Alert>}
          </Box>
        )}
      </Mutation>
      <Footer />
    </>
  );
};

export default Contact;
