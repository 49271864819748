import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { ReferenceNode, CitationNode, Metadata } from '../Types';

/**
 * Updates a specific element's metadata in the editor, identified by the element.
 *
 * @param {Editor} editor - The Slate editor object.
 * @param {Node} element - The Slate node element that you want to update.
 * @param {Metadata} metadata - The new metadata to apply to the element.
 * @returns {void}
 */

export const updateMetadata = (
  editor: ReactEditor,
  element: ReferenceNode | CitationNode,
  metadata: Metadata
): void => {
  // Find the path to the given element
  const path = ReactEditor.findPath(editor, element);

  // Only accept elements of type 'reference' or 'citation'
  if (element.type !== 'reference' && element.type !== 'citation') {
    return;
  }

  // If any of the values are null or missing in the metadata, reject
  if (
    !metadata.collection ||
    !metadata.id_field ||
    !metadata.id_value ||
    !metadata.id_type
  ) {
    return;
  }

  // Update the node at the found path with the new metadata
  Transforms.setNodes(
    editor,
    {
      ...element,
      metadata: {
        collection: metadata.collection,
        id_value: metadata.id_value,
        id_field: metadata.id_field,
        id_type: metadata.id_type,
      },
    },
    { at: path }
  );
};
