import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Should only be used for alerting the user of an undesirable outcome
 */

export const notify = (message) => {
  toast(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 7000,
    hideProgressBar: true,
    pauseOnHover: false,
    style: { bottom: '15px', border: '1px solid #333333' }, // Add custom inline styling here
  });
};
