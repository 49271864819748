import { notify } from '../../Common/notify';

export const checkCollection = (rankingDetails) => {
  const result =
    rankingDetails.collection !== '' &&
    rankingDetails.collection !== 'arxiv' &&
    rankingDetails.collection !== 'crossref';

  if (!result) notify('Paper must be from pubmed for highlight to work');

  return result;
};

export const checkParsedBody = (paper) => {
  const result =
    paper &&
    paper._id !== null &&
    paper.Content !== null &&
    paper.Content.Fullbody_Parsed !== null;

  if (!result)
    notify(
      'Open a paper in read view first with full parsed body to highlight'
    );

  return result;
};

export const checkHighlightSelection = (selection) => {
  if (selection !== '') {
    return true;
  } else {
    notify('You must select text first to compare against');
    return false;
  }
};

export const highlightSentences = (paper, highlightText) => {
  paper.Content.Fullbody_Parsed.forEach((section) => {
    section.section_text.forEach((paragraph) => {
      paragraph.paragraph_text.forEach((sentence) => {
        if (
          Object.prototype.hasOwnProperty.call(sentence, 'sentence_similarity')
        ) {
          delete sentence['sentence_similarity'];
        }
      });
    });
  });

  const newPaper = paper;

  for (const match of highlightText.response.response) {
    const { parent } = match.location;
    const { section_id: sectionId } = match.location;
    const { paragraph_id: paragraphId } = match.location;
    const { sentence_i: sentenceId } = match.location;

    const adjustedParent =
      parent === 'abstract' ? 'Abstract_Parsed' : 'Fullbody_Parsed';

    newPaper.Content[adjustedParent][sectionId].section_text[
      paragraphId
    ].paragraph_text[sentenceId].sentence_similarity =
      match.sentence_similarity;
  }

  notify('Showing similar sentences to selected text');

  return newPaper;
};

export const removeSentenceHighlights = (paper) => {
  // Deep clone paper object to avoid mutating the original object
  const originalPaper = JSON.parse(JSON.stringify(paper));

  originalPaper.Content.Abstract_Parsed.forEach((section, sectionIndex) => {
    section.section_text.forEach((paragraph, paragraphIndex) => {
      paragraph.paragraph_text.forEach((sentence, sentenceIndex) => {
        if (
          Object.prototype.hasOwnProperty.call(sentence, 'sentence_similarity')
        ) {
          const newSentence = { ...sentence, sentence_similarity: 0 };

          originalPaper.Content.Abstract_Parsed[sectionIndex].section_text[
            paragraphIndex
          ].paragraph_text[sentenceIndex] = newSentence;
        }
      });
    });
  });

  originalPaper.Content.Fullbody_Parsed.forEach((section, sectionIndex) => {
    section.section_text.forEach((paragraph, paragraphIndex) => {
      paragraph.paragraph_text.forEach((sentence, sentenceIndex) => {
        if (
          Object.prototype.hasOwnProperty.call(sentence, 'sentence_similarity')
        ) {
          const newSentence = { ...sentence, sentence_similarity: 0 };

          originalPaper.Content.Fullbody_Parsed[sectionIndex].section_text[
            paragraphIndex
          ].paragraph_text[sentenceIndex] = newSentence;
        }
      });
    });
  });

  return originalPaper;
};
