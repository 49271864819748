export const addScoresToSearchResults = (
  searchResults,
  rerankingScores,
  prefetchingScores,
  currentPage,
  itemsPerPage = 10
) => {
  // Calculate the start index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;

  // Iterate over the searchResults and add the corresponding score and prefetchingScore
  return searchResults.map((result, index) => {
    const globalIndex = startIndex + index;
    if (
      globalIndex < rerankingScores.length &&
      globalIndex < prefetchingScores.length
    ) {
      return {
        ...result,
        rerankingScore: rerankingScores[globalIndex],
        prefetchingScore: prefetchingScores[globalIndex],
      };
    } else if (globalIndex < rerankingScores.length) {
      return {
        ...result,
        rerankingScore: rerankingScores[globalIndex],
      };
    } else if (globalIndex < prefetchingScores.length) {
      return {
        ...result,
        prefetchingScore: prefetchingScores[globalIndex],
      };
    }
    return result;
  });
};
