import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { ReferenceNode } from '../Types';

/**
 * Get all references inside the text editor
 *
 * @param {object} editor - The current slate editor instance
 * @returns {array} - An array of references
 */

export const getReferences = (editor: ReactEditor) => {
  if (!editor) {
    return [];
  }

  const results = Array.from(
    Editor.nodes(editor, {
      match: (node) => ['reference'].includes((node as ReferenceNode).type),
      at: [],
    }),
    (node) => node
  );

  return results;
};
