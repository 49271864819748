import { Editor, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { ReferenceNode } from '../Types';

/**
 * Find the position of the first reference in the editor.
 *
 * @param {ReactEditor} editor The React Slate editor to search for the first reference.
 * @returns {Path | null} The path of the first reference if it exists, otherwise null.
 */

export const findFirstReferencePosition = (
  editor: ReactEditor
): Path | null => {
  const referenceNodes = Array.from(
    Editor.nodes(editor, {
      at: [],
      match: (n) => (n as ReferenceNode).type === 'reference',
      mode: 'all',
    })
  );

  if (referenceNodes.length > 0) {
    // Sort reference elements by their path and select the first one
    const firstReference = referenceNodes.sort((a, b) => {
      return Path.compare(a[1], b[1]); // Directly use the index to access pathA and pathB
    })[0];

    return firstReference[1]; // Return the path of the first reference
  }

  return null;
};
