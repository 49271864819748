import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function Empty() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Card
      sx={{
        boxSizing: 'border-box',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? 'background.paper' : '#171717',
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <Typography gutterBottom variant="body1" component="div">
          Read discovered paper here, it is typically structured as:
        </Typography>
        <List sx={{ width: '100%' }} component="nav">
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Title
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Abstract
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Main Sections
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  References
                </Typography>
              }
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() =>
            openInNewTab(process.env.REACT_APP_PLATFORM_DOCUMENTATION)
          }
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
