// Libraries
import React from "react";
import { Typography } from "@mui/material";

const Venue = ({ paper }) => (
  <Typography
    variant="body1"
    sx={{
      display: paper.Venue.length === 0 && "none",
      marginBottom: "10px",
      color: "green",
      marginRight: "5px",
    }}
  >
    {paper.Venue}
  </Typography>
);

export default Venue;
