import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

const Scores = ({ paper }) => {
  return (
    <Tooltip title={`Reranking/Prefetching scores`} placement="bottom">
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          background: (theme) =>
            theme.palette.mode === 'light' ? 'whitesmoke' : '#353535',
          marginRight: '5px',
          padding: '0px 2px',
          boxSizing: 'border-box',
          color: (theme) =>
            theme.palette.mode === 'light' ? 'black' : 'white',
        }}
      >
        <Typography variant="body2">
          {Math.round(paper.rerankingScore * 100)}
        </Typography>
        <Typography variant="body2">/</Typography>
        <Typography variant="body2">
          {Math.round(paper.prefetchingScore * 100)}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default Scores;
