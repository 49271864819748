import { gql } from "@apollo/client";

export const SAVE_BOOKMARK = gql`
  mutation saveBookmark(
    $id_value: String
    $id_field: String
    $id_type: String
    $id_collection: String
  ) {
    saveBookmark(
      id_value: $id_value
      id_field: $id_field
      id_type: $id_type
      id_collection: $id_collection
    ) {
      status
      message
      response {
        _id
        id_value
        id_field
        id_type
        id_collection
      }
    }
  }
`;

export const IMPORT_BOOKMARKS = gql`
  mutation importBookmarks($file: Upload!) {
    importBookmarks(file: $file) {
      status
      message
      response {
        Title
        collection
        found
        id_field
        id_type
        id_value
        Author {
          AuthorPos
          FamilyName
          GivenName
        }
      }
    }
  }
`;

export const SAVE_IMPORTS = gql`
  mutation saveImports($bookmarks: [bookmarkInput]!) {
    saveImports(bookmarks: $bookmarks) {
      status
      message
    }
  }
`;

export const IMPORT_PDF = gql`
  mutation importPDF($user_id: String!, $base64list: [String]) {
    importPDF(user_id: $user_id, base64list: $base64list) {
      status
      message
      response {
        _id
        id_value
        id_field
        id_type
        id_collection
      }
    }
  }
`;

export const ADD_BOOKMARK_FROM_METADATA = gql`
  mutation addBookmarkFromMetadata(
    $username: String!
    $Title: String!
    $Author: String
    $Abstract: String
    $Venue: String
    $Year: String
    $Month: String
  ) {
    addBookmarkFromMetadata(
      username: $username
      Title: $Title
      Author: $Author
      Abstract: $Abstract
      Venue: $Venue
      Year: $Year
      Month: $Month
    ) {
      status
      message
      response {
        bookmark {
          id_value
          id_field
          id_type
          id_collection
        }
        bookmarks {
          _id
          user
          id_value
          id_field
          id_type
          id_collection
        }
      }
    }
  }
`;
