import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Local
import { notify } from '../../Common/notify';
import { zoteroDisconnect, zoteroRedirect } from '../Requests';

const Zotero = ({ client, user, small = false }) => {
  const [connected, setConnected] = useState(false);

  const requestZoteroAuth = async () => {
    const { status, response, message } = await zoteroRedirect(client);

    if (status === 'success') {
      notify(message);
      localStorage.setItem('zoteroSessionKey', response.sessionKey);

      // Get Zotero authorization
      window.location.replace(response.redirectUrl);
    } else {
      notify(message);
    }
  };

  const disconnect = async () => {
    const { status, message } = await zoteroDisconnect(client);

    if (status === 'success') {
      notify(message);
      setConnected(false);
    } else {
      notify(message);
    }
  };

  useEffect(() => {
    if (user.zoteroUserID !== '') {
      setConnected(true);
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px',
        marginBottom: small ? '0px' : '20px',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          display: small && 'none',
          marginBottom: '10px',
        }}
      >
        Zotero
      </Typography>
      <Stack
        direction="column"
        sx={{
          display: small && 'none',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: '10px',
          }}
        >
          Endoc automatically creates a dedicated folder with your citations and
          references in the Zotero library when exporting
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          marginBottom: '10px',
        }}
      >
        {connected ? (
          <Button
            variant="outlined"
            sx={{
              textTransform: small && 'none',
            }}
            onClick={() => {
              disconnect();
            }}
          >
            Disconnect
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              textTransform: small && 'none',
            }}
            onClick={() => {
              requestZoteroAuth();
            }}
          >
            Connect
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Zotero;
