// Libraries
import React, { useState, useContext } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms, Path } from 'slate';
import { Menu, IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Local
import { DocumentContext } from '../../DocumentContext';
import { FigureNode, BaseNode, KatexNode } from '../Types';

interface OptionsProps {
  element: FigureNode | KatexNode
}

const Options: React.FC<OptionsProps> = ({ element }) => {
  const editor = useSlate();
  const context = useContext(DocumentContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resizeFigure = (size: string) => {
    const path = ReactEditor.findPath(editor as ReactEditor, element);
    Transforms.setNodes(editor, { height: size, width: size } as Partial<FigureNode>, { at: path });
  };

  return (
    <>
      <IconButton
        id="demo-positioned-button"
        aria-label='options-icon-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: context?.readOnly ? 'none' : 'block',
          position: 'absolute',
          padding: '0px !important',
          color: 'grey',
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        aria-label='options-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            if (element.type !== 'reference') {
              Transforms.removeNodes(editor, {
                at: ReactEditor.findPath(editor as ReactEditor, element),
              });
            }
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            Transforms.insertNodes(
              editor,
              {
                type: 'paragraph',
                children: [
                  {
                    text: '',
                  },
                ],
              } as BaseNode,
              {
                at: ReactEditor.findPath(editor as ReactEditor, element),
              }
            );
          }}
        >
          Space Above
        </MenuItem>
        <MenuItem
          onClick={() => {
            Transforms.insertNodes(
              editor,
              {
                type: 'paragraph',
                children: [
                  {
                    text: '',
                  },
                ],
              } as BaseNode,
              {
                at: Path.next(ReactEditor.findPath(editor as ReactEditor, element)),
              }
            );
          }}
        >
          Space Below
        </MenuItem>
        {element.type === 'figure' && [
          <MenuItem aria-label='options-figuresize-small' key="small" onClick={() => resizeFigure('100')}>
            Small (100x100)
          </MenuItem>,
          <MenuItem aria-label='options-figuresize-medium' key="medium" onClick={() => resizeFigure('200')}>
            Medium (200x200)
          </MenuItem>,
          <MenuItem aria-label='options-figuresize-large' key="large" onClick={() => resizeFigure('400')}>
            Large (400x400)
          </MenuItem>,
          <MenuItem aria-label='options-figuresize-full' key="full-width" onClick={() => resizeFigure('full-width')}>
            Full Width
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default Options;
