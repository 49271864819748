// Local
import { GET_BOOKMARKS } from '../../Queries';
import { PAGINATED_SEARCH } from '../Queries/nlp';

/**
 * Step 1: It retrieves a user's library metadata
 * Step 2: It has to reformat metadata for content database query
 * Step 3: It retrieves from content database using formatted metadata
 * Step 4: It merges metadata with content results for future queries to work
 *
 * User's store only fields such as paper ID and origin index
 *
 * @param {client} object - Apollo Client
 * @param {doc_id} number - Document ID
 */

export const getBookmarks = (client) => {
  return client
    .query({
      query: GET_BOOKMARKS,
      fetchPolicy: 'network-only',
    })
    .then(async ({ data }) => {
      if (data.getBookmarks.status === 'success') {
        return data.getBookmarks.response;
      } else {
        return [];
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

// It reformats a metadata array of objects into new format required
export const buildMetadata = (metadata) => {
  return metadata.map((bookmark) => {
    return {
      _id: bookmark._id,
      collection: bookmark.id_collection,
      id_field: bookmark.id_field,
      id_type: bookmark.id_type,
      id_value: bookmark.id_value,
    };
  });
};

// It merges data from the content database
// With our reformatted metadata
// So that additional fields will make future queries work
// It handles incomplete or missing fields from content database
export const mergeWithMetadata = (response, idList) => {
  const mergedData = [];

  response?.forEach((bookmark) => {
    idList?.forEach((metadata) => {
      // Parse id_value based on id_type, whether it is a string or int
      const idValue =
        metadata.id_type === 'int'
          ? parseInt(metadata.id_value)
          : metadata.id_value;

      if (bookmark && bookmark[metadata.id_field] === idValue) {
        const mergedItem = {
          _id: metadata._id,
          abstract:
            bookmark.Content && bookmark.Content.Abstract
              ? bookmark.Content.Abstract
              : null,
          abstract_parsed:
            bookmark.Content && bookmark.Content.Abstract_Parsed
              ? bookmark.Content.Abstract_Parsed
              : null,
          doi: bookmark.DOI ? bookmark.DOI : null,
          author: bookmark.Author ? bookmark.Author : null,
          venue: bookmark.Venue ? bookmark.Venue : null,
          id_int: bookmark.id_int,
          title: bookmark.Title,
          id_collection: metadata.collection,
          id_field: metadata.id_field,
          id_type: metadata.id_type,
          id_value: metadata.id_value,
        };

        mergedData.push(mergedItem);
      }
    });
  });

  return mergedData;
};

// It retrieves content using reformatted metadata
export const retrieveFromMetadata = (
  client,
  newMetadata,
  updateContext = () => {}
) => {
  return client
    .query({
      query: PAGINATED_SEARCH,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        paper_list: newMetadata.map(({ _id, ...rest }) => rest), // Exclude _id
      },
      fetchPolicy: 'network-only',
    })
    .then(async ({ data }) => {
      const { response } = data.paginatedSearch;

      // We need to merge meta data held in our database
      // With the bookmark results so our other queries can work
      if (response.length >= 1) {
        return response;
      }

      return [];
    })
    .catch(() => {
      updateContext('serviceWarnings', {
        content: 'Paper data is currently down, please try again later',
      });
      return [];
    });
};
