import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

/**
 * Get a citation inside the text editor based on metadata id
 *
 * @param {object} editor - The current slate editor instance
 * @param {string} idValue - The id_value in the metadata of the citation
 * @returns {CitationNode} The citation node
 */

export const getCitation = (editor: ReactEditor, idValue: string) => {
  // If editor is empty, return
  if (!editor) {
    throw new Error('Editor is required');
  }

  // If idValue is empty, return
  if (!idValue) {
    throw new Error('idValue is required');
  }

  const result = Array.from(
    Editor.nodes(editor, {
      match: (node) =>
        ['citation'].includes((node as CitationNode).type) &&
        (node as CitationNode).metadata.id_value === idValue,
      at: [],
    }),
    (node) => node
  );

  if (result.length === 0) {
    return [];
  } else {
    return result[0];
  }
};
