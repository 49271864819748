// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ListIcon from '@mui/icons-material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Local
import { TITLE_SEARCH, SINGLE_PAPER } from '../../../../Queries/nlp';
import { DocumentContext } from '../../../../DocumentContext';
import { ModularContext } from '../../../../../Modular/ModularContext';
import { notify } from '../../../../../Common/notify';

const References = ({ paper, modular }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [open, setOpen] = useState(true);

  const [titleSearch] = useLazyQuery(TITLE_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ titleSearch }) => {
      if (titleSearch.status === 'success' && titleSearch.response[0]?.found) {
        const {
          collection,
          id_field: idField,
          id_type: idType,
          id_value: idValue,
        } = titleSearch.response[0];

        singlePaper({
          variables: {
            paper_id: {
              collection,
              id_value: idValue,
              id_field: idField,
              id_type: idType,
            },
          },
          onCompleted: ({ singlePaper }) => {
            if (singlePaper.status === 'success') {
              context.updateContext('paper', singlePaper.response);
              context.updateContext('tab', 'View');
              context.updateContext('sortedView', false);
              context.updateContext('showSelection', false);
              context.updateContext('highlightSelection', '');
              context.updateContext('loadingRequest', false);
              context.updateContext('loadingMessage', 'Retrieved paper');
              context.recordActionHistory({
                collection,
                id_value: idValue,
                id_field: idField,
                id_type: idType,
              });
              context.updateContext('rankingDetails', {
                rankingIdValue: idValue,
                rankingIdField: idField,
                rankingIdType: idType,
                rankingCollection: collection,
              });
            } else {
              notify('Paper unavailable');
              context.updateContext('loadingRequest', false);
              context.updateContext('loadingMessage', '');
            }
          },
        });
      } else {
        notify('Paper unavailable');
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', '');
      }
    },
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER, {
    fetchPolicy: 'network-only',
  });

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const element = document.getElementById(context.scrollToSentenceId);

    if (element && context.scrollToSentenceId !== '') {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      element.classList.add('animate');
      setTimeout(() => {
        element.classList.remove('animate');
      }, 4000);
      context.updateContext('scrollToSentenceId', '');
    }
  }, [modular ? null : context.scrollToSentenceId]);

  const borderCol = (theme) =>
    theme.palette.mode === 'dark' ? '1px solid #333333' : '1px solid #d4d4d4';

  return (
    <Box
      sx={{
        width: '100%',
        display: paper?.Reference.length === 0 && 'none',
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          border: borderCol,
        }}
      >
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="References" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            borderLeft: borderCol,
            borderRight: borderCol,
            borderBottom: borderCol,
          }}
          aria-label="references"
        >
          {paper.Reference.map((reference, index) => (
            <ListItem
              key={index}
              id={'ref-' + index}
              disablePadding
              sx={{
                marginBottom: '10px',
                width: '100%',
                justifyContent: 'space-between',
                '&:last-child': {
                  marginBottom: '0px !important',
                },
              }}
            >
              <ListItemButton
                dense
                sx={{
                  border: borderCol,
                }}
                onClick={(e) => {
                  e.preventDefault();

                  if (modular) return;

                  titleSearch({
                    variables: {
                      titles: [reference.Title],
                    },
                  });
                }}
              >
                <ListItemText primary={reference.ReferenceText} />
                <ListItemIcon
                  sx={{
                    marginLeft: '10px',
                    minWidth: '0px !important',
                  }}
                >
                  <QueryStatsIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default References;
