// Libraries
import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, gql } from '@apollo/client';
import _ from 'lodash';
import {
  Box,
  Stack,
  Button,
  Tooltip,
  Typography,
  AppBar,
  Toolbar,
  TextField,
  Select,
  Backdrop,
  CircularProgress,
  FormControl,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';
import DarkModeToggle from '../../Common/DarkModeToggle';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

// Local
import { notify } from '../../Common/notify';
import { InitialValue } from '../../Common/InitialValue';
import { postDocument } from '../Requests/Documents';
import { importFromJSON } from '../Utilities/importDoc';
import { bytesToMegaBytes } from '../../Document/Utilities/bytesToMegabytes';
import Coach from '../../Coach';
import NavToggle from '../../Common/Navigator';
// import Notifications from './Notifications';
import RestrictedTooltip from './RestrictedTooltip';

export const PDF_TO_MANUSCRIPT = gql`
  query pdfToManuscript($PDF_Base64_String: String!) {
    pdfToManuscript(PDF_Base64_String: $PDF_Base64_String) {
      status
      message
      response {
        title
        content {
          type
          sentences {
            type
            text
            reference_index
          }
        }
        references {
          ReferenceText
          found
          collection
          id_field
          id_type
          id_value
        }
      }
    }
  }
`;

const filter = createFilterOptions();

const Menu = ({
  client,
  user,
  docs,
  value,
  setValue,
  sort,
  setSort,
  // fetchDocs,
}) => {
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [pdfToManuscript] = useLazyQuery(PDF_TO_MANUSCRIPT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ pdfToManuscript }) => {
      if (pdfToManuscript.status === 'success') {
        const value = importFromJSON(pdfToManuscript.response);

        createNewDocument(
          value.title, // Manuscript title
          value.body, // slateJS serialized from raw data
          false, // Initially set to false
          false // Initially set to false
        );
      } else {
        notify(pdfToManuscript.message);
      }

      setLoading(false);
    },
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const createNewDocument = async (
    title = '',
    content = InitialValue,
    numbering = true,
    orderByAppearance = true,
    classic = true
  ) => {
    const data = await postDocument(
      client,
      title,
      JSON.stringify(content),
      user,
      numbering,
      orderByAppearance
    );

    const { response, status, message } = data.postDocument;
    if (status === 'success') {
      if (classic) {
        navigate(`/note/${response._id}`, {
          from: location.pathname,
        });
      } else {
        navigate(`/modular/${response._id}`, {
          from: location.pathname,
        });
      }
    } else {
      notify(message);
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'cornflowerblue',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress
          color="inherit"
          sx={{
            marginBottom: '10px',
          }}
        />
        <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Importing your PDF file, please wait...
        </Typography>
      </Backdrop>
      <Coach target="manuscripts" client={client} />
      <AppBar
        elevation={0}
        sx={{
          position: 'fixed',
          color: 'text.primary',
          minHeight: '64px',
          zIndex: 1,
          padding: '0 !important',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? 'background.paper' : '#171717',
          borderBottom: (theme) =>
            theme.palette.mode === 'dark'
              ? '1px solid #333333'
              : '1px solid #d4d4d4',
        }}
      >
        <Toolbar
          className="toolbar" // For tour purposes
          sx={{
            justifyContent: 'space-between',
            paddingLeft: { xs: '10px', xl: '10%' },
            paddingRight: { xs: '10px', xl: '10%' },
          }}
        >
          <Stack direction="row" spacing={0}>
            <input
              type="file"
              name="upload"
              id="file"
              accept="application/pdf"
              ref={inputFile}
              style={{ display: 'none' }}
              required
              onChange={async ({
                target: {
                  validity,
                  files: [file],
                },
              }) => {
                if (validity.valid && file.name.match(/.(pdf)$/i)) {
                  if (bytesToMegaBytes(file.size) > 50) {
                    notify('Maximum file size of 50 MB exceeded');
                    return;
                  }

                  setLoading(true);

                  const data = await getBase64(file);
                  let base64File = data;
                  base64File = base64File.replace(
                    'data:application/pdf;base64,',
                    ''
                  );

                  pdfToManuscript({
                    variables: {
                      PDF_Base64_String: base64File,
                    },
                  });
                } else {
                  notify('Incorrect file format');
                  setLoading(false);
                }
              }}
            />
            <RestrictedTooltip
              tooltipText={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'New note from PDF file (Max 50MB)'
                  : 'Your account is restricted due to no linked affiliation to Switch edu-ID'
              }
              extendedText={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'Import a PDF file to create a new note'
                  : 'Please add your organizational entity directly in your Switch edu-ID account'
              }
              linkSuffix={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'documentation/help-centre/create-and-manage-notes'
                  : 'documentation/overview/quick-start'
              }
            >
              <Box variant="span">
                <Button
                  disabled={user.hasSwissEduIdLinkedAffiliation === false}
                  variant="outlined"
                  onClick={onButtonClick}
                  sx={{
                    marginRight: '10px',
                  }}
                >
                  <UploadOutlinedIcon />
                  <Typography
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Import
                  </Typography>
                </Button>
              </Box>
            </RestrictedTooltip>
            <RestrictedTooltip
              tooltipText={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'Create a new note'
                  : 'Your account is restricted due to no linked affiliation to Switch edu-ID'
              }
              extendedText={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'Begin writing and research in your new note workspace'
                  : 'Please add your organizational entity directly in your Switch edu-ID account'
              }
              linkSuffix={
                user.hasSwissEduIdLinkedAffiliation
                  ? 'documentation/help-centre/create-and-manage-notes'
                  : 'documentation/overview/quick-start'
              }
            >
              <Box variant="span">
                <Button
                  disabled={user.hasSwissEduIdLinkedAffiliation === false}
                  variant="outlined"
                  onClick={() => createNewDocument()}
                  sx={{
                    marginRight: '10px',
                  }}
                >
                  <PostAddOutlinedIcon />
                  <Typography
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    New
                  </Typography>
                </Button>
              </Box>
            </RestrictedTooltip>
            <Tooltip title={'Experimental modular interface'}>
              <Box variant="span">
                <Button
                  variant="outlined"
                  onClick={() =>
                    createNewDocument(
                      'Untitled',
                      InitialValue,
                      true,
                      true,
                      false
                    )
                  }
                  sx={{
                    display:
                      user.email === 'demo@endoc.ethz.ch' ? 'flex' : 'none',
                  }}
                >
                  <PostAddOutlinedIcon />
                  <Typography
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Modular
                  </Typography>
                </Button>
              </Box>
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <FormControl>
              <Select
                labelId="select-sort"
                id="select-sort"
                value={sort}
                size="small"
                sx={{
                  height: { xs: '30px', md: 'inherit' },
                  width: { xs: 'flex', md: '250px' },
                  flexGrow: 1,
                  marginLeft: '10px',
                }}
                onChange={(event) => setSort(event.target.value)}
              >
                <MenuItem value={'lastSaved'}>Last saved</MenuItem>
                <MenuItem value={'title'}>Title</MenuItem>
                {/* <MenuItem value={'collaborators'}>Collaborators</MenuItem> */}
              </Select>
            </FormControl>
            <Autocomplete
              size="small"
              value={value}
              onChange={(_, newValue) => {
                if (newValue) {
                  setValue(newValue);
                } else {
                  setValue('');
                }
              }}
              filterOptions={(options, params) => {
                return filter(options, params);
              }}
              selectOnFocus
              id="free-solo-with-text"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              options={_.uniq(docs.map((doc) => doc.title))}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{
                height: { xs: '30px', md: 'inherit' },
                width: { xs: 'flex', md: '250px' },
                display: { xs: 'none', md: 'flex' },
                marginRight: { xs: 0, sm: '10px' },
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search notes" />
              )}
            />
            {/* <Notifications user={user} fetchDocs={fetchDocs} /> */}
            <DarkModeToggle
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            ></DarkModeToggle>
            <Box className="navigator">
              <NavToggle user={user} />
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Menu;
