// Libraries
import React from 'react';
import ReactGA from 'react-ga4';
import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px',
        height: 'auto',
        color: 'text.primary',
        backgroundColor: 'background.paper',
        fontFamily: 'Arial',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          display: { xs: 'none', sm: 'inherit' },
        }}
      >
        © 2024 Endoc
      </Typography>
      <Stack direction={'row'}>
        <Stack
          direction={'row'}
          onClick={() => {
            window.open(
              process.env.REACT_APP_TEAMS_URL,
              '_blank',
              'noreferrer'
            );
          }}
          sx={{
            display:
              process.env.REACT_APP_TEAMS_URL && process.env.REACT_APP_ETH
                ? 'flex'
                : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '20px',
            cursor: 'pointer',
          }}
        >
          <Typography
            sx={{
              display: { xs: 'none', sm: 'inherit' },
              marginRight: '5px',
            }}
          >
            Join the ETHZ community on MS Teams
          </Typography>
          <img
            src="/teams.png"
            alt="Endoc MS teams community"
            style={{
              height: '20px',
              width: '20px',
              marginRight: '10px',
            }}
          />
        </Stack>
        <Link
          onClick={() => {
            ReactGA.event({
              category: 'Documentation',
              action: 'Go to documentation',
              label: 'User selected to go documentation',
            });

            window.open(
              process.env.REACT_APP_PLATFORM_DOCUMENTATION,
              '_blank',
              'noreferrer'
            );
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          Documentation
        </Link>
        {process.env.REACT_APP_ETH === 'true' && (
          <Link onClick={() => navigate('/contact')}>
            <Typography
              variant="span"
              sx={{
                marginLeft: '20px',
                cursor: 'pointer',
              }}
            >
              Contact
            </Typography>
          </Link>
        )}
        <Link
          onClick={() => navigate('/privacy-policy')}
          sx={{
            marginLeft: '20px',
            cursor: 'pointer',
          }}
        >
          {isXsOrSm ? 'Policy' : 'Privacy Policy'}
        </Link>
        <Link
          onClick={() => navigate('/terms-of-service')}
          sx={{
            marginLeft: '20px',
            cursor: 'pointer',
          }}
        >
          {isXsOrSm ? 'ToS' : 'Terms of Service'}
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
