// Libraries
import React, { useState } from 'react';
import {
  Modal,
  Paper,
  Box,
  Stack,
  darken,
  lighten,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Options from './Options';
import { RenderElementProps } from 'slate-react';
import { FigureNode } from '../Types';


interface FigureProps extends RenderElementProps {
  element: FigureNode
}

const Figure: React.FC<FigureProps> = ({ attributes, children, element }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper
      {...attributes}
      aria-label="figure-paper"
      contentEditable={false}
      elevation={0}
      sx={{
        padding: '10px',
        boxSizing: 'border-box',
        fontSize: 'inherit',
        cursor: 'pointer',
        color: 'inherit',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? darken(theme.palette.background.paper, 0.05)
              : lighten(theme.palette.background.paper, 0.05),
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Modal
        open={modalIsOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="figure-modal"
        aria-describedby="figure-modal-zoomed"
      >
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '95vh',
            maxHeight: '95vh',
            bgcolor: 'none',
            border: 'none',
            outline: 'none',
            overflow: 'auto',
          }}
        >
          <img
            src={element.url}
            alt=""
            style={{
              width: '100%',
              height: 'auto',
            }}
            onClick={() => setIsOpen(false)}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '5px',
            }}
          >
            <IconButton
              size="small"
              onClick={() => setIsOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      <Stack direction={'row'}>
        {isHovered ? <Options element={element} /> : <Box />}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
          onClick={() => setIsOpen(true)}
        >
          <img
            aria-label="img-figure"
            style={{
              display: 'block',
              width:
                element.width === 'full-width' ? '100%' : element.width + 'px',
              height:
                element.height === 'full-width'
                  ? 'auto'
                  : element.height + 'px',
            }}
            src={element.url}
            alt=""
          />
        </Box>
      </Stack>
      {children}
    </Paper>
  );
};

export default Figure;
