import { Node } from "slate";

/**
 * It calculates the word count.
 *
 * @param {Array} value - The initial array of slate elements
 * @returns {number} - The amount of words from the given slate value
 */

export const wordCount = (value) => {
  const text = value
    .map((n) => Node.string(n))
    .join("\n")
    .trim();

  return text.length === 0 ? 0 : text.split(/\s+/).length;
};
