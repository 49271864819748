// Libraries
import React from "react";
import { LeafNode } from "../Types"
import { RenderLeafProps } from 'slate-react';

interface LeafProps extends RenderLeafProps {
  leaf: LeafNode
}

// Not a switch statement because the same text can have multiple types of formatting
const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.highlighted) {
    return (
      <span
        {...attributes}
        style={{
          backgroundColor: "#fbc02d",
        }}
      >
        {children}
      </span>
    );
  }

  if (leaf.subscript) {
    children = <sub {...attributes}>{children}</sub>;
  }

  if (leaf.superscript) {
    children = <sup {...attributes}>{children}</sup>;
  }

  if (leaf.strikethrough) {
    children = <s {...attributes}>{children}</s>;
  }

  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>;
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>;
  }

  return (
    <span
      {...attributes}
      style={{
        position: "relative",
      }}
    >
      {children}
    </span>
  );
};

export default Leaf;
