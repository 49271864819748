import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { ReferenceNode } from '../Types';

/**
 * Gets the total number of references in the editor
 *
 * @param {object} editor - The current slate editor instance
 * @returns {number} The total count of references
 */

export const totalReferences = (editor: ReactEditor): number => {
  const results = Array.from(
    Editor.nodes(editor, {
      match: (node) => ['reference'].includes((node as ReferenceNode).type),
      at: [],
    }),
    (node) => node
  );

  return results.length;
};
