// Libraries
import React, { useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Box, Modal, Button, Typography, Stack } from '@mui/material';

// Local
import { DELETE_BOOKMARK, GET_BOOKMARKS } from '../../../../Queries';
import { PAGINATED_SEARCH } from '../../../Queries/nlp';
import { DocumentContext } from '../../../DocumentContext';
import { notify } from '../../../../Common/notify';
import { buildMetadata, mergeWithMetadata } from '../../../Requests/Library';

const RemovePrompt = ({ paper, modal, setModal }) => {
  const context = useContext(DocumentContext);

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const [getBookmarks] = useLazyQuery(GET_BOOKMARKS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getBookmarks }) => {
      if (getBookmarks.status === 'success') {
        const paperList = buildMetadata(getBookmarks.response);

        paginatedSearch({
          variables: {
            paper_list: paperList.map((paper) => {
              return {
                collection: paper.collection,
                id_field: paper.id_field,
                id_type: paper.id_type,
                id_value: paper.id_value,
              };
            }),
          },
          onCompleted: ({ paginatedSearch }) => {
            const bookmarks = mergeWithMetadata(
              paginatedSearch.response,
              paperList
            );

            console.log('bookmarks', bookmarks);

            context.updateContext('bookmarks', bookmarks);
            context.updateContext('loadingRequest', false);
            context.updateContext('loadingMessage', 'Removed library item');
          },
        });
      }
    },
  });

  const [deleteBookmark] = useMutation(DELETE_BOOKMARK, {
    fetchPolicy: 'network-only',
    variables: {
      id_value: paper.id_value,
    },
    onCompleted: ({ deleteBookmark }) => {
      if (deleteBookmark.status === 'success') {
        getBookmarks();
      } else {
        notify(deleteBookmark.message);
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', '');
      }
    },
  });

  return (
    <Modal
      aria-labelledby="Remove"
      aria-describedby="Remove library item"
      open={modal}
      onClose={() => setModal(false)}
      closeAfterTransition
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: 'auto',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          color: 'text.primary',
          boxShadow: 24,
          p: 2,
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            marginBottom: '10px',
          }}
        >
          {paper.title}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="outlined"
            onClick={async () => {
              context.updateContext('loadingRequest', true);
              context.updateContext('loadingMessage', 'Removing library item');

              setModal(false);
              deleteBookmark();
            }}
          >
            Remove
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RemovePrompt;
