// Libraries
import React, { useState } from 'react';
import { useSlate, RenderElementProps } from 'slate-react';
import { Location } from 'slate';
import { Typography, Paper, darken, lighten } from '@mui/material';

// Local
import generateKatex from './GenerateKatex';
import EquationModal from '../Menu/EquationModal';
import { InlineKatexNode } from '../Types'

interface InlineKatexProps extends RenderElementProps {
  element: InlineKatexNode
}

const InlineKatex: React.FC<InlineKatexProps> = ({ attributes, element, children }) => {
  const editor = useSlate();
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<Location>({
    anchor: {
      path: [0, 0],
      offset: 0,
    },
    focus: {
      path: [0, 0],
      offset: 0,
    }
  });
  const [equation, setEquation] = useState('');

  return (
    <>
      <EquationModal
        aria-label='equation-modal'
        open={open}
        setOpen={setOpen}
        selection={selection}
        equation={equation}
        setEquation={setEquation}
        editable={true}
        element={element}
      />
      <Paper
        component={'span'}
        elevation={0}
        aria-label='inlinekatex-paper'
        sx={{
          display: 'inline',
          cursor: 'pointer',
          fontSize: 'inherit',
          color: 'grey',
          borderRadius: '0px',
          marginLeft: '1px', // Needed to allow cursor placement
          marginRight: '1px', // Needed to allow cursor placement
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? darken(theme.palette.background.paper, 0.05)
                : lighten(theme.palette.background.paper, 0.05),
          },
        }}
        {...attributes}
        contentEditable={false}
        onClick={() => {
          // Get the selection before we lose it on modal open
          setEquation(element.equation);
          setSelection(editor.selection as Location);

          setOpen(true);
        }}
      >
        <Typography component={'span'} variant={'body2'} aria-label='inlinekatex-typography'>
          {generateKatex(element.equation)?.html}
        </Typography>
        {children}
      </Paper>
    </>
  );
};

export default InlineKatex;
