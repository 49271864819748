// Libraries
import React, { useContext } from 'react';
import { Node } from 'slate';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  ToggleButton,
  useTheme,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

// Local
import {
  DOCUMENT_SEARCH,
  PAGINATED_SEARCH,
  KEYWORDS_BASED_ON_SEARCH,
} from '../../../Queries/nlp.js';
import { DocumentContext } from '../../../DocumentContext';
import { generateSearchStats } from '../../../Utilities/generateSearchStats';
import DocumentationTooltip from '../../../../Common/DocumentationTooltip.js';
import { addScoresToSearchResults } from '../../../Utilities/addScoresToSearchResults.js';

const SearchMenu = () => {
  const context = useContext(DocumentContext);
  const theme = useTheme();

  const [keywordsBasedOnSearch] = useLazyQuery(KEYWORDS_BASED_ON_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const [documentSearch] = useLazyQuery(DOCUMENT_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ documentSearch }) => {
      if (documentSearch.status === 'success') {
        context.updateContext('paperIds', documentSearch.response.paper_list);
        context.updateContext(
          'rerankingScores',
          documentSearch.response.reranking_scores
        );
        context.updateContext(
          'prefetchingScores',
          documentSearch.response.prefetching_scores
        );
        context.updateContext(
          'pageCount',
          documentSearch.response.paper_list.length / 10
        );
        context.updateContext('loadingRequest', false);
        context.updateContext(
          'loadingMessage',
          generateSearchStats(documentSearch.response.search_stats)
        );

        paginatedSearch({
          variables: {
            keywords: context.keywords,
            paper_list: documentSearch.response.paper_list.slice(0, 10),
          },
          onCompleted: ({ paginatedSearch }) => {
            const updatedSearchResults = addScoresToSearchResults(
              paginatedSearch.response, // in batches of 10 papers
              documentSearch.response.reranking_scores, // scores for all papers
              documentSearch.response.prefetching_scores, // prefetching scores for all papers
              1, // current page
              10 // items per page
            );

            context.updateContext('searchResults', updatedSearchResults);
            context.updateContext('currentPage', 1);

            if (context.searchLibrary) {
              context.updateContext('searchLibrary', false);
            }
          },
        });

        keywordsBasedOnSearch({
          fetchPolicy: 'network-only',
          variables: {
            ranking_variable: context.searchTerm,
            keywords: context.keywords,
            paper_list: documentSearch.response.paper_list,
          },
          onCompleted: ({ keywordsBasedOnSearch }) => {
            context.updateContext(
              'keywordsBasedOnSearch',
              keywordsBasedOnSearch.response
            );
          },
        });
      } else {
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Paper retrieval failed');
      }
    },
  });

  const extractMetadata = (bookmarks) =>
    bookmarks.map((bookmark) => {
      return {
        collection: bookmark.id_collection,
        id_field: bookmark.id_field,
        id_type: bookmark.id_type,
        id_value: bookmark.id_value,
      };
    });

  const toggleButtonStyles = {
    ...(theme.palette.mode === 'dark' && {
      borderWidth: 1,
      backgroundColor: 'transparent',
      '&.Mui-selected': {
        backgroundColor: 'grey',
      },
    }),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    height: '25px',
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        padding: '5px 10px',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        height: '35px',
        maxHeight: '35px',
        boxSizing: 'border-box',
      }}
    >
      <DocumentationTooltip
        tooltipText={'Discover papers relevant to your note.'}
        linkSuffix={
          'help-centre/discover-the-literature#discover-papers-that-are-similar-to-a-particular-paper'
        }
      >
        <Button
          variant="outlined"
          startIcon={
            <DocumentScannerOutlinedIcon
              sx={{
                fontSize: 'medium !important',
                display: 'inline-flex',
              }}
            />
          }
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onClick={() => {
            const rankingVariable = context.value
              .map((n) => Node.string(n))
              .join('\n');
            let paperList = undefined;

            if (context.searchLibrary) {
              paperList = extractMetadata(context.bookmarks);
            }

            context.updateContext('searchTerm', '[Contents of manuscript]');
            context.updateContext('loadingMessage', 'Retrieving papers');
            context.updateContext('loadingRequest', true);

            documentSearch({
              variables: {
                ranking_variable: rankingVariable,
                keywords: context.keywords,
                paper_list: paperList,
              },
            });
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              fontSize: '10px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            Quick search
          </Typography>
        </Button>
      </DocumentationTooltip>
      <Divider
        orientation="vertical"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '1px',
          background: theme.palette.mode === 'light' ? 'whitesmoke' : '#333333',
        }}
      />
      <Stack
        direction="row"
        className="library"
        sx={{
          alignItems: 'center',
        }}
      >
        <DocumentationTooltip
          tooltipText={'Show only note library items'}
          linkSuffix={'help-centre/manage-your-library'}
        >
          <ToggleButton
            size="small"
            variant="outlined"
            value={context.onlyManuscriptLibrary}
            selected={context.onlyManuscriptLibrary}
            sx={{
              ...toggleButtonStyles,
              marginRight: '5px',
            }}
            onChange={() => {
              const toggleState = !context.onlyManuscriptLibrary;
              context.updateContext('onlyManuscriptLibrary', toggleState);

              if (toggleState === true) {
                context.updateContext('searchLibrary', false);
              }
            }}
          >
            <ArticleOutlinedIcon
              sx={{
                fontSize: '10px',
                marginRight: '8px',
              }}
            />
            Note library
          </ToggleButton>
        </DocumentationTooltip>
        <DocumentationTooltip
          tooltipText={'View and discover within all note libraries.'}
          linkSuffix={'help-centre/manage-your-library'}
        >
          <ToggleButton
            size="small"
            variant="outlined"
            value={context.searchLibrary}
            selected={context.searchLibrary}
            sx={toggleButtonStyles}
            onChange={() => {
              const toggleState = !context.searchLibrary;
              context.updateContext('searchLibrary', toggleState);

              if (toggleState === true) {
                context.updateContext('onlyManuscriptLibrary', false);
              }
            }}
          >
            <LibraryBooksOutlinedIcon
              sx={{
                fontSize: '10px',
                marginRight: '8px',
              }}
            />
            All Note Libraries
          </ToggleButton>
        </DocumentationTooltip>
      </Stack>
    </Stack>
  );
};

export default SearchMenu;
