import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';

const CookieConsentModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [displayToggles, setDisplayToggles] = useState<boolean>(false);
  const [analyticsConsent, setAnalyticsConsent] = useState<boolean>(true);
  const [errorTrackingConsent, setErrorTrackingConsent] =
    useState<boolean>(true);

  useEffect(() => {
    const savedAnalyticsConsent =
      localStorage.getItem('analyticsConsent') === 'true';
    const savedErrorTrackingConsent =
      localStorage.getItem('errorTrackingConsent') === 'true';
    const wasPreferenceSaved =
      localStorage.getItem('cookieConsentSaved') === 'true';

    if (wasPreferenceSaved) {
      setAnalyticsConsent(savedAnalyticsConsent);
      setErrorTrackingConsent(savedErrorTrackingConsent);
    } else {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    if (displayToggles) {
      localStorage.setItem('analyticsConsent', analyticsConsent.toString());
      localStorage.setItem(
        'errorTrackingConsent',
        errorTrackingConsent.toString()
      );
    } else {
      localStorage.setItem('analyticsConsent', 'true');
      localStorage.setItem('errorTrackingConsent', 'true');
    }
    localStorage.setItem('cookieConsentSaved', 'true');
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="cookie-consent-title"
      aria-describedby="cookie-consent-description"
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          right: { xs: 0, sm: 16 },
          maxWidth: '500px',
          width: 'auto',
          background: '#111111',
          color: 'text.primary',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          outline: 'none',
        }}
      >
        <Typography id="cookie-consent-title" variant="h6" component="h2">
          Cookie settings
        </Typography>
        <Typography
          id="cookie-consent-description"
          sx={{
            marginBottom: displayToggles ? '10px' : '0px',
          }}
        >
          We use cookies for our third party analytics and error tracking tools
          to provide you with an improved user-friendly digital experience.
        </Typography>
        <FormGroup
          sx={{
            display: displayToggles ? 'inherit' : 'none',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={analyticsConsent}
                onChange={(e) => setAnalyticsConsent(e.target.checked)}
              />
            }
            label="Google analytics"
          />
          <FormControlLabel
            control={
              <Switch
                checked={errorTrackingConsent}
                onChange={(e) => setErrorTrackingConsent(e.target.checked)}
              />
            }
            label="Sentry error tracking"
          />
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 2,
          }}
        >
          <Button
            data-testid="save-settings-button"
            size="small"
            onClick={() => {
              if (displayToggles) {
                handleAccept();
              } else {
                setDisplayToggles(true);
              }
            }}
            variant="outlined"
          >
            {displayToggles ? 'Save settings' : 'Change Settings'}
          </Button>
          <Button
            size="small"
            onClick={handleAccept}
            sx={{
              marginLeft: '20px',
            }}
          >
            Accept all cookies
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CookieConsentModal;
