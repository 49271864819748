// Libraries
import React, { useState, useEffect } from 'react';
import {
  Box,
  Alert,
  Grid,
  Skeleton,
  darken,
  lighten,
  useTheme,
  useMediaQuery,
} from '@mui/material';

// Local
import { notify } from '../Common/notify';
import { sortByFunction } from './Utilities/SortBy';
import { getDocuments } from './Requests/Documents';
import { getSharedDocuments } from './Requests/SharedDocuments';
import networkDetector from '../Common/NetworkDetector';
import Coach from '../Coach';
import Menu from './Components/Menu';
import Manuscript from './Components/Manuscript';

const Documents = ({ title, client, user }) => {
  const [docs, setDocs] = useState([]);
  const [value, setValue] = useState('');
  const [sort, setSort] = useState('lastSaved');
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const getColumns = () => {
    if (isXs) return 1; // Full width
    if (isSm) return 2; // Half width
    if (isMd) return 3; // One-third width
    if (isLg) return 4; // One-quarter width
    if (isXl) return 4; // One-quarter width
    return 1; // Default to full width
  };

  const columns = getColumns();

  const fetchDocs = async () => {
    const data = await getDocuments(client);
    const shared = await getSharedDocuments(client);
    const allDocs = [...data.response, ...shared.response];
    setDocs(allDocs);

    if (data.status === 'failure') {
      notify(data.message);
    }

    if (shared.status === 'failure') {
      notify(data.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchDocs();
    document.title = title;
  }, []);

  return (
    <Box
      sx={{
        overflow: 'hidden !important',
        height: '100vh',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? darken(theme.palette.background.paper, 0.05)
            : lighten(theme.palette.background.paper, 0.035),
      }}
    >
      <Coach target="manuscripts" client={client} />
      <Menu
        client={client}
        user={user}
        docs={docs}
        value={value}
        setValue={setValue}
        sort={sort}
        setSort={setSort}
        // fetchDocs={fetchDocs}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{
          width: '100%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 65px)',
          marginTop: '65px !important',
          marginLeft: '0px !important',
          paddingTop: '0px',
          paddingBottom: '10px',
          paddingLeft: { xs: '10px', xl: '10%' },
          paddingRight: { xs: '10px', xl: '10%' },
        }}
      >
        {loading ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              sx={{
                width: '100%',
                height: '350px',
              }}
              animation="wave"
              variant="rectangular"
            />
          </Grid>
        ) : docs.length > 0 ? (
          docs
            .filter((doc) => doc.title.includes(value))
            .sort(sortByFunction[sort])
            .map((doc, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={doc._id}
                sx={{
                  paddingLeft:
                    index % columns === 0 ? '0px !important' : 'inherit',
                }}
              >
                <Manuscript
                  client={client}
                  user={user}
                  doc={doc}
                  docs={docs}
                  fetchDocs={fetchDocs}
                />
              </Grid>
            ))
        ) : (
          <Alert
            severity="info"
            sx={{
              height: 'min-content',
              marginTop: '20px',
            }}
          >
            You have no notes, either create a new note or import a note file
            (pdf)
          </Alert>
        )}
      </Grid>
    </Box>
  );
};

export default networkDetector(Documents);
