// Libraries
import React, { useState, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import {
  Card,
  Box,
  ButtonGroup,
  CardContent,
  IconButton,
  Collapse,
  Typography,
  Tooltip,
  Stack,
  darken,
  lighten,
  SvgIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

// Local
import { SUMMARIZE, SINGLE_PAPER, CITE_PAPER } from '../../../Queries/nlp';
import { SAVE_BOOKMARK } from '../../../Queries/document';
import { DocumentContext } from '../../../DocumentContext';
import { notify } from '../../../../Common/notify';
import { cite } from '../../../Editor/Utilities/cite';
import { ModularContext } from '../../../../Modular/ModularContext';
import AbstractParsed from '../../View/Document/AbstractParsed';
import CitationExport from '../../Common/CitationExport';
import { addPaperToZotero } from '../../../Requests/Document'
import Scores from './Scores';

const SearchResult = ({ client, paper, modular }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);

  const [collapsed, collapsedToggle] = useState(false);
  const [bibtex, setBibtex] = useState('');
  const [mla, setMla] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [refresher, setRefresher] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const setLoadingRequest = (loading) => {
    context.updateContext('loadingRequest', loading);
  }

  const setLoadingMessage = (message) => {
    context.updateContext('loadingMessage', message);
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [saveBookmark] = useMutation(SAVE_BOOKMARK, {
    fetchPolicy: 'network-only',
  });

  const [summarizePaper] = useLazyQuery(SUMMARIZE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ summarizePaper }) => {
      context.updateContext('summaries', summarizePaper.response);
    },
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER, {
    fetchPolicy: 'network-only',
  });

  const [citePaper] = useLazyQuery(CITE_PAPER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ citePaper }) => {
      if (citePaper.status === 'success') {
        setBibtex(citePaper.response.bibtex);
        setMla(citePaper.response.mla);
        setIsOpen(true);
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Retrieved citations');
      } else {
        notify('Could not generate citations, try again later');
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', '');
      }
    },
  });

  return (
    <Card
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? 'background.paper' : '#171717',
        width: '100%',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? darken(theme.palette.background.paper, 0.05)
              : lighten('#171717', 0.05),
        },
      }}
      elevation={0}
    >
      <CitationExport
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        bibtex={bibtex}
        setBibtex={setBibtex}
        mla={mla}
        setMla={setMla}
      />
      <CardContent
        sx={{
          padding: '10px',
          ':last-child': {
            paddingBottom: '5px',
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
          }}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginBottom: '10px',
              boxSizing: 'border-box',
              maxWidth: '100%',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: 'cornflowerblue',
                cursor: 'pointer',
                width: '100%',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onMouseDown={() => {
                if (modular) {
                  const idValue = paper.id_int.toString();

                  const metadata = _.find(context.metadataResults, {
                    id_value: idValue,
                  });

                  singlePaper({
                    variables: {
                      paper_id: {
                        collection: metadata.collection,
                        id_value: metadata.id_value,
                        id_field: metadata.id_field,
                        id_type: metadata.id_type,
                      },
                    },
                    onCompleted: ({ singlePaper }) => {
                      if (singlePaper.status === 'success') {
                        const windowId = context.onAddWindow('Read');

                        const newPaperList = context.papers;
                        newPaperList[windowId] = {
                          window_id: windowId,
                          ...singlePaper.response,
                        };

                        context.updateContext('papers', newPaperList);

                        // make paperTitles in Generation module a context variable, update it from here

                        const newPaperTitles = Object.values(newPaperList).map(
                          (p) => p.Title
                        );

                        context.updateContext('paperTitles', newPaperTitles);

                        const newPaperMetadata = context.papersMetadata;
                        newPaperMetadata[windowId] = {
                          window_id: windowId,
                          ...metadata,
                        };

                        context.updateContext(
                          'papersMetadata',
                          newPaperMetadata
                        );

                        let highlightScope = context.highlightPapers;
                        highlightScope = {
                          rankingIdValue: metadata.id_value,
                          rankingIdField: metadata.id_field,
                          rankingIdType: metadata.id_type,
                          rankingCollection: metadata.collection,
                        };

                        context.updateContext(
                          'highlightPapers',
                          highlightScope
                        );

                        // add window to textAlign object
                        const newTextAlign = context.textAlign;
                        newTextAlign[windowId] = {
                          source: '',
                          isTextAlign: false,
                          sentences: [],
                          title: paper.Title,
                        };
                        context.updateContext('textAlign', newTextAlign);
                      } else {
                        notify('cannot retrieve the paper, try later!');
                      }
                    },
                  });
                } else {
                  context.updateContext('loadingRequest', true);
                  context.updateContext('loadingMessage', 'Retrieving paper');
                  context.updateContext('sortedView', false);

                  const {
                    collection,
                    id_value: idValue,
                    id_field: idField,
                    id_type: idType,
                  } = context.paperIds.find(
                    (meta) => meta.id_value === paper.id_int.toString()
                  );

                  context.updateContext('rankingDetails', {
                    rankingIdValue: idValue,
                    rankingIdField: idField,
                    rankingIdType: idType,
                    rankingCollection: collection,
                  });

                  summarizePaper({
                    variables: {
                      paper_id: {
                        collection,
                        id_value: idValue,
                        id_field: idField,
                        id_type: idType,
                      },
                    },
                  });

                  singlePaper({
                    variables: {
                      paper_id: {
                        collection,
                        id_value: idValue,
                        id_field: idField,
                        id_type: idType,
                      },
                    },
                    onCompleted: ({ singlePaper }) => {
                      if (singlePaper.status === 'success') {
                        context.updateContext('paper', singlePaper.response);
                        context.updateContext('tab', 'View');
                        context.updateContext('showSelection', false);
                        context.updateContext('highlightSelection', '');
                        context.updateContext('loadingRequest', false);
                        context.updateContext(
                          'loadingMessage',
                          'Retrieved paper'
                        );
                        context.recordActionHistory({
                          collection,
                          id_value: idValue,
                          id_field: idField,
                          id_type: idType,
                        });
                      } else {
                        notify('Could not retrieve paper, try again later');
                        context.updateContext('loadingRequest', false);
                        context.updateContext('loadingMessage', '');
                      }
                    },
                  });
                }
              }}
            >
              <Scores paper={paper} />
              {paper.Title}
            </Typography>
            {paper.Author.slice(0, 3).map((author, index) => (
              <Typography
                variant="caption"
                sx={{
                  color: 'green',
                  marginLeft: '0px !important',
                  marginRight: index < 2 ? '5px !important' : '0px',
                }}
                key={index}
              >
                {`${author.FamilyName}, ${
                  author.GivenName.endsWith('.')
                    ? author.GivenName
                    : author.GivenName + '.'
                }`}
              </Typography>
            ))}
            {paper.Author.length > 3 && (
              <Typography
                variant="caption"
                sx={{
                  color: 'green',
                  marginLeft: '0px !important',
                  marginRight: '5px !important',
                }}
              >
                ...
              </Typography>
            )}
            {paper.Venue && (
              <Typography
                variant="caption"
                sx={{
                  color: 'green',
                  marginLeft: '0px !important',
                  marginRight: '5px !important',
                }}
              >
                - {paper.Venue}
              </Typography>
            )}
            {paper.PublicationDate.Year && (
              <Typography
                variant="caption"
                sx={{
                  color: 'green',
                  marginLeft: '0px !important',
                  marginRight: '5px !important',
                }}
              >
                - {paper.PublicationDate.Year}
              </Typography>
            )}
          </Stack>

          <ButtonGroup
            fullWidth={false}
            aria-label="small button group"
            sx={{
              marginLeft: { xs: '0px', md: '20px' },
              maxHeight: '40px',
              marginBottom: '10px',
              width: 'auto',
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls={open ? 'paper-menu' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
              size="small"
            >
              <AddIcon fontSize='small' color='primary' />
            </IconButton>
            <Menu
              id="paper-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'more-button',
              }}
            >
              <MenuItem
                disabled={context.readOnly}
                onClick={async () => {
                  handleMenuClose();
                  if (context.readOnly) return;

                  if (!modular) {
                    context.updateContext('loadingRequest', true);
                    context.updateContext('loadingMessage', 'Citing paper');
                  }

                  const {
                    collection,
                    id_value: idValue,
                    id_field: idField,
                    id_type: idType,
                  } = context[modular ? 'metadataResults' : 'paperIds'].find(
                    (meta) => meta.id_value === paper.id_int.toString()
                  );

                  singlePaper({
                    variables: {
                      paper_id: {
                        collection,
                        id_value: idValue,
                        id_field: idField,
                        id_type: idType,
                      },
                    },
                    onCompleted: ({ singlePaper }) => {
                      if (singlePaper.status === 'success') {
                        cite(
                          context.editorRef,
                          singlePaper.response,
                          {
                            collection,
                            id_value: idValue,
                            id_field: idField,
                            id_type: idType,
                          },
                          context.autoAppearanceOrder,
                          context.showNumbering
                        );

                        if (!modular) {
                          context.updateContext('loadingRequest', false);
                          context.updateContext(
                            'loadingMessage',
                            'Cited paper'
                          );
                        }
                      } else {
                        notify('Could not retrieve paper, try again later');
                        if (!modular) {
                          context.updateContext('loadingRequest', false);
                          context.updateContext('loadingMessage', '');
                        }
                      }
                    },
                  });
                }}
              >
                <FormatQuoteOutlinedIcon sx={{ width: 16, height: 16, marginRight: '10px' }} />
                Cite paper in note
              </MenuItem>

              <MenuItem
                onClick={async () => {
                  handleMenuClose();

                  const {
                    collection,
                    id_value: idValue,
                    id_field: idField,
                    id_type: idType,
                  } = context.paperIds.find(
                    (meta) => meta.id_value === paper.id_int.toString()
                  );

                  const paperMetadataToAddToZotero = {
                    collection: collection,
                    id_field: idField,
                    id_value: idValue,
                    id_type: idType,
                  };

                  context.updateContext('loadingRequest', true);
                  context.updateContext('loadingMessage', 'Adding paper to Zotero');
                  addPaperToZotero(
                    client,
                    {
                      _id: context.documentId,
                      title: context.title,
                      papers: [paperMetadataToAddToZotero],
                    },
                    setLoadingRequest,
                    setLoadingMessage
                  );
                }}
              >
                <SvgIcon
                  viewBox="0 0 14px 14px"
                  sx={{  transform: 'translate(0px, 3px)', }}
                >
                  <polygon
                    stroke="rgb(188, 57, 60)"
                    strokeWidth="1"
                    fill="rgb(188, 57, 60)"
                    points="13.863 2.73 13.027 1 2.137 1 2.137 3.8 2.137 3.921 8.822 3.921 1.289 13.233 2.137 15 13.863 15 13.863 12.142 13.863 12.021 6.448 12.021 13.863 2.73"
                  />
                </SvgIcon>
                Add paper to Zotero
              </MenuItem>

              <MenuItem
                onClick={async (e) => {
                  handleMenuClose();
                  e.preventDefault();

                  if (!modular) {
                    context.updateContext('loadingRequest', true);
                    context.updateContext('loadingMessage', 'Adding to library');
                  }

                  const {
                    collection,
                    id_value: idValue,
                    id_field: idField,
                    id_type: idType,
                  } = context[modular ? 'metadataResults' : 'paperIds'].find(
                    (meta) => meta.id_value === paper.id_int.toString()
                  );

                  saveBookmark({
                    variables: {
                      id_collection: collection,
                      id_value: idValue,
                      id_field: idField,
                      id_type: idType,
                    },
                    onCompleted: ({ saveBookmark }) => {
                      if (saveBookmark.status === 'success') {
                        const updatedBookmarks = context.bookmarks;

                        updatedBookmarks.push({
                          _id: paper._id,
                          abstract: paper.Content.Abstract,
                          abstract_parsed: paper.Content.Abstract_Parsed,
                          doi: paper.DOI,
                          id_int: paper.id_int,
                          title: paper.Title,
                          id_collection: collection,
                          id_field: idField,
                          id_type: idType,
                          id_value: idValue,
                        });

                        context.updateContext('bookmarks', updatedBookmarks);

                        if (!modular) {
                          context.updateContext('loadingRequest', false);
                          context.updateContext('loadingMessage', 'Added to library');
                        }

                        if (modular) {
                          setRefresher(refresher + 1);
                          context.updateContext('bookmarksRefresher', refresher);
                        }
                      } else {
                        notify(saveBookmark.message);
                        context.updateContext('loadingRequest', false);
                        context.updateContext('loadingMessage', '');
                      }
                    },
                  });
                }}
              >
                <AddBoxOutlinedIcon sx={{ width: 16, height: 16, marginRight: '10px' }} />
                Add paper to library
              </MenuItem>

              <MenuItem
                onClick={async () => {
                  handleMenuClose();

                  if (!modular) {
                    context.updateContext('loadingRequest', true);
                    context.updateContext('loadingMessage', 'Retrieving citations');
                  }

                  const {
                    collection,
                    id_value: idValue,
                    id_field: idField,
                    id_type: idType,
                  } = context[modular ? 'metadataResults' : 'paperIds'].find(
                    (meta) => meta.id_value === paper.id_int.toString()
                  );

                  citePaper({
                    variables: {
                      paper_id: {
                        collection,
                        id_value: idValue,
                        id_field: idField,
                        id_type: idType,
                      },
                    },
                  });
                }}
              >
                <FileDownloadIcon sx={{ width: 16, height: 16, marginRight: '10px' }} />
                Generate paper citations
              </MenuItem>
            </Menu>
            <Tooltip title="Show abstract" placement="top-start">
              <IconButton
                color="primary"
                aria-label="show or hide abstract"
                component="label"
                onMouseDown={() => collapsedToggle(!collapsed)}
              >
                {collapsed ? (
                  <ExpandLess sx={{ width: 16, height: 16 }} />
                ) : (
                  <ExpandMore sx={{ width: 16, height: 16 }} />
                )}
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Stack>

        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <AbstractParsed paper={paper} modular={modular} highlighter={''} />
        </Collapse>
        {paper.relevant_sentence !== undefined &&
          paper.relevant_sentences.map((sentence, index) => (
            <Box
              key={index}
              sx={{
                border: '1px solid lightgray',
                marginBottom: '5px',
                fontSize: '14px',
                padding: '5px',
                fontFamily: 'Arial',
              }}
            >
              <Highlighter
                key={index}
                autoEscape={false}
                highlightClassName={{
                  fontWeight: 'bold',
                }}
                highlightStyle={{
                  color: 'indianred',
                  backgroundColor: 'transparent',
                }}
                searchWords={
                  !modular && context.keywords
                    ? keywordRegex(context.keywords)
                    : context.keywords // ELSE POINT TO MODULAR KEYWORDS
                }
                textToHighlight={sentence}
              />
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

const keywordRegex = (keywords) => {
  const keywordList = keywords.join('|');
  const re = new RegExp(`\\b(${keywordList})\\b`, 'gm');
  return [re];
};

export default SearchResult;
