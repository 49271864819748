// Libraries
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Local

import { updateUser } from '../Requests';
import { notify } from '../../Common/notify';
import Error from '../../Common/Error';

const PasswordForm = ({ client }) => {
  const [currentFormPassword, setCurrentFormPassword] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealPassword2, setRevealPassword2] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  // watch current password
  const currentPassword = useRef({});
  currentPassword.current = watch('currentPassword', '');

  // watch new password
  const password = useRef({});
  password.current = watch('password', '');

  const registerForm = register;

  const onSubmit = ({ currentPassword, password }) => {
    updateUser(client, '', password, currentPassword, '')
      .then((data) => {
        notify(data.updateUser.message);
      })
      .catch(() => notify(`Could not update account at this time`));
  };

  const handleChange = (e) => {
    register({ name: e.target.name }, e.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        maxWidth: '800px',
        width: '100%',
        flexDirection: 'column',
        boxSizing: 'border-box',
        marginBottom: '20px',
      }}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
        }}
      >
        Change password
      </Typography>
      <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
        <OutlinedInput
          required
          name="currentPassword"
          type={currentFormPassword ? 'text' : 'password'}
          placeholder="Your current password"
          onChange={handleChange}
          {...registerForm('currentPassword', {
            required: 'A password must be at least 6 characters long',
            min: 6,
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFormPassword(!currentFormPassword);
                }}
                edge="end"
              >
                {currentFormPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
        <OutlinedInput
          required
          name="password"
          type={revealPassword ? 'text' : 'password'}
          placeholder="Password with 6 characters minimum"
          onChange={handleChange}
          {...registerForm('password', {
            validate: (value) =>
              value !== currentPassword.current ||
              'The passwords have not changed',
            required: 'A password must be at least 6 characters long',
            min: 6,
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  e.preventDefault();
                  setRevealPassword(!revealPassword);
                }}
                edge="end"
              >
                {revealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
        <OutlinedInput
          required
          name="password_repeat"
          type={revealPassword2 ? 'text' : 'password'}
          placeholder="Repeat password with 6 characters minimum"
          onChange={handleChange}
          {...registerForm('password_repeat', {
            validate: (value) =>
              value === password.current || 'The passwords do not match',
            min: 6,
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  e.preventDefault();
                  setRevealPassword2(!revealPassword2);
                }}
                edge="end"
              >
                {revealPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {errors.password && (
        <Error response={{ error: errors.password.message }} />
      )}
      {errors.password_repeat && (
        <Error response={{ error: errors.password_repeat.message }} />
      )}
      <Button variant="outlined" type="submit" sx={{ marginBottom: '10px' }}>
        Update
      </Button>
    </Box>
  );
};

export default PasswordForm;
