import * as React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

export default function Empty() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Card
      elevation={0}
      sx={{
        boxSizing: 'border-box',
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? 'background.paper' : '#171717',
      }}
    >
      <CardContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <Typography gutterBottom variant="body1" component="div">
          The papers in your library will appear below. You will be able to:
        </Typography>
        <List
          sx={{
            width: '100%',
          }}
          component="nav"
        >
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Save or Remove papers
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Search your library
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Cite papers
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.secondary">
                  Upload papers
                </Typography>
              }
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() =>
            openInNewTab(process.env.REACT_APP_PLATFORM_DOCUMENTATION)
          }
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
