import { Editor } from 'slate';
import { ReferenceNode } from '../Types';

// This plugin allows reference nodes to be void elements.
const withReferences = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return (element as ReferenceNode).type === 'reference'
      ? true
      : isVoid(element);
  };

  return editor;
};

export default withReferences;
