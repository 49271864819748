// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import { useLazyQuery } from '@apollo/client';
import ReactGA from 'react-ga4';
import Typewriter from 'typewriter-effect';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

// Local
import { DocumentContext } from '../../DocumentContext';
import { notify } from '../../../Common/notify';
import { retrieveFromMetadata } from '../../Requests/Library';
import { citationFormat } from '../../Editor/Utilities/citationFormat';
import { totalReferences } from '../../Editor/Utilities/totalReferences';
import { CITATION_GENERATION } from '../../../Queries';

const rankingDetailsExist = (rankingDetails) => {
  return (
    rankingDetails.rankingIdValue &&
    rankingDetails.rankingIdField &&
    rankingDetails.rankingIdType &&
    rankingDetails.rankingCollection
  );
};

const GenToggle = ({ client }) => {
  const theme = useTheme();
  const context = useContext(DocumentContext);

  return (
    <Accordion
      elevation={0}
      expanded={context.generatorView === true}
      onChange={() => {
        if (context.generatorView)
          context.updateContext('generatorView', undefined);
        else if (context.generatorView === undefined)
          context.updateContext('generatorView', true);
        else context.updateContext('generatorView', false);
      }}
      sx={{
        position: 'sticky',
        zIndex: '1',
        top: '0px',
        flexDirection: 'column',
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06)',
        },
        display: !context.paper
          ? 'none'
          : !context.generatorView
            ? context.generatorView === undefined
              ? 'block'
              : 'none'
            : 'block',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: (theme) => theme.palette.action.hover },
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'Gainsboro',
          borderBottom: '1px solid lightgrey',
          '.MuiAccordionSummary-content': {
            margin: '0px !important', // Prevent margin changes
            '&.Mui-expanded': {
              margin: '0px !important', // Explicitly prevent margin changes upon expansion
            },
          },
          '&.Mui-expanded': {
            minHeight: '0px !important', // Adjust this as per your default minHeight
          },
          minHeight: '0px', // Adjust this as per your desired minHeight
          padding: '6px 5px', // Adjust padding to make it smaller
        }}
      >
        <AutoFixHighOutlinedIcon
          sx={{
            marginRight: '10px',
          }}
        />
        <Typography>Citation generation</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Generator client={client} />
      </AccordionDetails>
    </Accordion>
  );
};

const options = [
  {
    model: 'ETHZ',
    modelName: 'ETHZ',
  },
  {
    model: 'GPT',
    modelName: 'GPT 4',
  },
];

const intentOptions = [
  {
    label: 'background',
    id: 1,
  },
  {
    label: 'method',
    id: 2,
  },
  {
    label: 'result',
    id: 3,
  },
  {
    label: 'N/A',
    id: 4,
  },
];

const Generator = ({ client }) => {
  const context = useContext(DocumentContext);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [intent, setIntent] = useState({ label: 'background', id: 1 });
  const [content, setContent] = useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [collapsed, collapsedToggle] = useState(false);
  const [showGeneratedText, setShowGeneratedText] = useState(false);

  const [citationGeneration] = useLazyQuery(CITATION_GENERATION, {
    fetchPolicy: 'network-only',
  });

  const generate = () => {
    setLoading(true);
    ReactGA.event({
      category: 'AI',
      action: 'Generate citation text for editor',
      label: 'Citation generation',
    });
    citationGeneration({
      variables: {
        model: options[selectedIndex].model,
        text_before_citation: context.generatorContextText,
        cited_paper_id: context.generatorPaperId,
        keywords: context.generatorKeywords,
        sentences: context.generatorSentences,
        intent: intent.label,
      },
      onCompleted: ({ citationGeneration }) => {
        if (citationGeneration.status === 'success') {
          setContent(citationGeneration.response);
          setKey(key + 1);
          setLoading(false);
          setShowGeneratedText(true);
        } else {
          notify(citationGeneration.message);
          setLoading(false);
        }
      },
      onError: (error) => {
        setLoading(false);
        notify(error.message);
      },
    });
  };

  const getContext = () => {
    if (context.editorRef.selection) {
      // The start of the range, i.e. referred to as mouse up on selection
      const focus = Editor.before(
        context.editorRef,
        context.editorRef.selection.anchor,
        { distance: 30, unit: 'word' }
      );

      // The end of the range, i.e. referred to as mouse down on selection
      const anchor = context.editorRef.selection.anchor;

      // If we are able to get a starting position, and end, then get words
      if (focus) {
        // Get the text string content of a location.
        const text = Editor.string(context.editorRef, {
          anchor,
          focus,
        });

        context.updateContext('generatorContextText', text);
      }
    }
  };

  useEffect(() => {
    // If toggled on, grab context automatically
    if (context.generatorView) {
      getContext();
    }
  }, [context.generatorView]);

  useEffect(() => {
    collapsedToggle(true);
  }, [context.generatorSentences]);

  useEffect(() => {
    if (context.paper) {
      context.updateContext('generatorPaperId', context.paper._id);
      context.updateContext('generatorKeywords', context.keywords);
    }
  }, [context.paper]);

  return (
    <Box
      component="form"
      sx={{
        padding: '10px',
        width: '100%',
        overflowY: 'auto',
        boxSizing: 'border-box',
        maxHeight: '600px',
        borderBottom: '1px solid lightgrey',
      }}
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        alignItems={'center'}
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Stack
          direction="column"
          sx={{ width: '100%', maxWidth: 'calc(100% - 48px)' }}
        >
          <TextField
            fullWidth
            label={
              context.readOnly
                ? 'Context text (Read only)'
                : 'Note Context (200 characters preceding the cursor position)'
            }
            id="fullWidth"
            InputProps={{
              maxRows: 4,
              multiline: true,
            }}
            size="small"
            value={context.generatorContextText}
            onChange={(e) => {
              context.updateContext('generatorContextText', e.target.value);
            }}
            sx={{
              fontSize: 12,
              marginBottom: '5px',
            }}
          />
          <Tooltip title="Get the 200 characters preceding the cursor position">
            <Button
              size="small"
              variant="outlined"
              disabled={context.readOnly}
              sx={{
                marginTop: '10px',
                marginBottom: '5px',
                width: '300px',
              }}
              onClick={() => {
                getContext();
              }}
            >
              Get note context
            </Button>
          </Tooltip>
          <Box
            sx={{
              padding: '10px',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                width: '100%',
                marginLeft: '5px',
              }}
            >
              {'Paper Context: ' + context.paper.Title}
            </Typography>
          </Box>
        </Stack>
        <IconButton
          color="primary"
          variant="contained"
          aria-label="show or hide generator options"
          component="label"
          onClick={() => {
            collapsedToggle(!collapsed);
          }}
        >
          {collapsed ? (
            <FilterListOutlinedIcon />
          ) : (
            <FilterListOutlinedIcon sx={{ color: 'grey' }} />
          )}
        </IconButton>
      </Stack>

      <Collapse
        in={collapsed}
        timeout="auto"
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          size="small"
          freeSolo
          sx={{
            marginBottom: '15px',
          }}
          value={context.generatorSentences}
          onChange={(_, newValue) => {
            context.updateContext('generatorSentences', newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={option}
                size="small"
                {...getTagProps({ index })}
                style={{ marginTop: '5px', marginBottom: '5px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label="Paper Context(s) (select from the paper below)"
              size="small"
              placeholder="Enter similar sentences"
            />
          )}
        />

        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          size="small"
          freeSolo
          sx={{
            marginBottom: '15px',
          }}
          value={context.generatorKeywords}
          onChange={(_, newValue) => {
            context.updateContext('generatorKeywords', newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                variant="outlined"
                size="small"
                label={option}
                {...getTagProps({ index })}
                style={{ marginTop: '5px', marginBottom: '5px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Keywords (the generated sentence will try to include these keywords)"
              size="small"
              placeholder="Enter a keyword i.e. songbird, then press enter"
            />
          )}
        />

        <Stack direction="row" spacing={2} marginBottom={'15px'}>
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="select-model-label">{'Generate with'}</InputLabel>
            <Select
              labelId="select-model-label"
              id="select-model-label"
              value={options[selectedIndex].modelName}
              label="Generate with"
              onChange={(event) => {
                const index = options.findIndex(
                  (obj) => obj.modelName === event.target.value
                );
                setSelectedIndex(index);
              }}
            >
              {options.map((item) => (
                <MenuItem key={item.model} value={item.modelName}>
                  {item.modelName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="select-intent-label">
              {'Intent of citation sentence'}
            </InputLabel>
            <Select
              labelId="select-intent-label"
              id="select-intent-label"
              value={intent.label}
              label="Intent of citation sentence"
              onChange={(event) => {
                const index = intentOptions.findIndex(
                  (obj) => obj.label === event.target.value
                );
                if (event.target.value.label === 'N/A') {
                  setIntent('');
                } else {
                  setIntent(intentOptions[index]);
                }
              }}
            >
              {intentOptions.map((item) => (
                <MenuItem key={item.id} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Collapse>

      <React.Fragment>
        <LoadingButton
          variant="outlined"
          size="small"
          loading={loading}
          onClick={generate}
          sx={{
            width: '120px',
            marginBottom:
              content.length > 0 && showGeneratedText ? '15px' : '0px',
          }}
        >
          {'Generate'}
        </LoadingButton>
      </React.Fragment>

      {content.length > 0 && showGeneratedText && (
        <Card>
          <CardContent
            sx={{
              paddingLeft: 1,
              paddingRight: 0,
              paddingBottom: 0,
              paddingTop: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography component={'div'} variant="body2">
              <Typewriter
                key={key}
                onInit={(typewriter) => {
                  typewriter.changeDelay(25).typeString(content).start();
                }}
              />
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowGeneratedText(false);
              }}
              size="small"
              sx={{
                marginTop: -1,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </CardContent>
          <CardActions>
            <Tooltip title="Copy as plain text">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(content);
                }}
              >
                Copy
              </Button>
            </Tooltip>
            <Tooltip title="Insert at last known cursor position">
              <>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={context.readOnly}
                  onClick={async () => {
                    if (
                      !context.readOnly &&
                      rankingDetailsExist(context.rankingDetails)
                    ) {
                      context.updateContext('loadingRequest', true);
                      context.updateContext(
                        'loadingMessage',
                        'Adding generated citation'
                      );

                      const splitSentence = content.split('#REFR');

                      const metadata = {
                        collection: context.rankingDetails.rankingCollection,
                        id_field: context.rankingDetails.rankingIdField,
                        id_type: context.rankingDetails.rankingIdType,
                        id_value: context.rankingDetails.rankingIdValue,
                      };

                      const [item] = await retrieveFromMetadata(client, [
                        metadata,
                      ]);

                      Transforms.insertNodes(
                        context.editorRef,
                        {
                          type: 'paragraph',
                          children: [
                            { text: splitSentence[0] },
                            {
                              type: 'citation',
                              number: totalReferences(context.editorRef),
                              citation: citationFormat(
                                item.Author,
                                item.PublicationDate
                              ),
                              metadata,
                              children: [
                                {
                                  text: '',
                                },
                              ],
                            },
                            {
                              text:
                                splitSentence.length > 1
                                  ? splitSentence[1]
                                  : '',
                            },
                          ],
                        },
                        { at: context.editorRef.selection.anchor }
                      );

                      context.updateContext('loadingRequest', false);
                    } else {
                      notify("You don't have write access to this note");
                    }
                  }}
                >
                  Insert
                </Button>
              </>
            </Tooltip>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};

export default GenToggle;
