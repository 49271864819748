import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import { Stack, Typography, Button } from '@mui/material';
import { shouldInitializeErrorTracking } from '../Common/CookieInitializer';

try {
  if (process.env.REACT_APP_SENTRY && shouldInitializeErrorTracking()) {
    Sentry.init({
      dsn: `${process.env.REACT_APP_SENTRY}`,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // This enables screen capturing on all errors and browser tracing
      integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
    });
  } else {
    console.warn('Error tracking is disabled.');
  }
} catch {
  console.log('sentry disabled');
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      eventId: null,
      message: 'Help us zap this bug',
      clicked: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      // Render fallback UI
      return (
        <Stack
          direction="column"
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'background.paper',
            color: 'text.primary',
          }}
        >
          <SentimentVeryDissatisfiedOutlinedIcon
            sx={{
              width: 36,
              height: 36,
            }}
          />
          <Typography variant="h5">Something went wrong</Typography>
          <Typography variant="h6" gutterBottom>
            You&apos;ve stumbled upon uncharted waters, please try again
            later...
          </Typography>
          <Button
            variant="outlined"
            disabled={this.state.clicked}
            onClick={() => {
              Sentry.showReportDialog({ eventId: this.state.eventId });
              this.setState({
                message: 'Thanks!',
                clicked: true,
              });
            }}
          >
            <BoltOutlinedIcon />
            {this.state.message}
          </Button>
        </Stack>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
