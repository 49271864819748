import {
  BaseNode,
  CitationNode,
  ReferenceNode,
} from '../../Document/Editor/Types';

interface Sentence {
  type: 'sentence' | 'citation';
  text: string;
  reference_index: number | null;
}

interface Paragraph {
  type: 'paragraph';
  sentences: Sentence[];
}

interface Reference {
  ReferenceText: string;
  found: boolean;
  collection: string | null;
  id_field: string | null;
  id_type: string | null;
  id_value: string | null;
}

export interface ImportedFileResponse {
  title: string;
  content: Paragraph[];
  references: Reference[];
}

export const importFromJSON = (response: ImportedFileResponse) => {
  const title = response.title;
  const content = response.content;
  const references = response.references;
  const body: BaseNode[] = [];

  content.forEach((item) => {
    // Main body, with inline citations
    body.push({
      type: 'paragraph',
      children: item.sentences.flatMap((sentence) => {
        // Citation
        if (sentence.type === 'citation' && sentence.reference_index !== null) {
          const reference = references[sentence.reference_index];

          return [
            { text: '' }, // Empty text node before citation
            {
              type: 'citation',
              citation: sentence.text,
              number: sentence.reference_index + 1,
              metadata: reference['found']
                ? {
                    id_value: reference.id_value?.toString() || null,
                    id_field: reference.id_field || null,
                    id_type: reference.id_type || null,
                    collection: reference.collection || null,
                  }
                : {
                    id_value: null,
                    id_field: null,
                    id_type: null,
                    collection: null,
                  },
              children: [
                {
                  text: '',
                },
              ],
            } as CitationNode,
            { text: '' }, // Empty text node after citation
          ];
        }

        // Normal sentence text
        return { text: sentence.text };
      }),
    });

    // Empty space between paragraphs
    body.push({
      type: 'paragraph',
      children: [{ text: '' }],
    });
  });

  // Add references at end of document
  references.forEach((reference, index) => {
    body.push({
      type: 'reference',
      number: index + 1,
      metadata: reference['found']
        ? {
            id_value: reference.id_value?.toString() || null,
            id_field: reference.id_field || null,
            id_type: reference.id_type || null,
            collection: reference.collection || null,
          }
        : {
            id_value: null,
            id_field: null,
            id_type: null,
            collection: null,
          },
      reference: reference['ReferenceText'],
      children: [
        {
          text: '',
        },
      ],
    } as ReferenceNode);
  });

  return {
    title,
    body,
  };
};
