// Libraries
import React from 'react';
import { Stack, Typography } from '@mui/material';

const MessageBanner = () => (
  <Stack
    direction="column"
    data-test="component-beta-stage"
    onClick={() => {
      window.open(
        `${process.env.REACT_APP_PLATFORM_DOCUMENTATION}/latest-releases`,
        '_blank',
        'noreferrer'
      );
    }}
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      height: 'auto',
      padding: '20px',
      boxSizing: 'border-box',
      background: '#265ed5',
      width: '100%',
      cursor: 'pointer',
    }}
  >
    <Typography
      variant="body1"
      sx={{
        textAlign: 'center',
        color: 'white',
      }}
    >
      Endoc is currently in its beta stage and is receiving regular
      improvements.
    </Typography>
  </Stack>
);

export default MessageBanner;
