import {
  UPDATE_USER,
  DELETE_USER,
  PROFILE_PIC,
  GET_CURRENT_USER,
  GENERATE_KEY,
  REVOKE_KEY,
  GET_KEY,
  ZOTERO_REDIRECT,
  ZOTERO_DISCONNECT,
} from "../../Queries";

/**
 * It retrieves all personal document details based on authToken
 * @param {client} object - Apollo Client
 */

export const deleteUser = (client) => {
  return client
    .mutate({
      mutation: DELETE_USER,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const profilePic = (client, file) => {
  return client
    .mutate({
      mutation: PROFILE_PIC,
      refetchQueries: [{ query: GET_CURRENT_USER }],
      variables: {
        file,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateUser = (client, email, password, currentPassword, name) => {
  return client
    .mutate({
      mutation: UPDATE_USER,
      variables: {
        email,
        password,
        currentPassword,
        name,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const generateKey = (client) => {
  return client
    .mutate({
      mutation: GENERATE_KEY,
    })
    .then(({ data }) => {
      return data.generateKey;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const revokeKey = (client) => {
  return client
    .mutate({
      mutation: REVOKE_KEY,
    })
    .then(({ data }) => {
      return data.revokeKey;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getKey = (client) => {
  return client
    .query({
      query: GET_KEY,
    })
    .then(({ data }) => {
      return data.getKey;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * It starts the auth process with zotero
 * @param {client} object - Apollo Client
 */

export const zoteroRedirect = (client) => {
  return client
    .query({
      query: ZOTERO_REDIRECT,
    })
    .then(({ data }) => {
      return data.zoteroRedirect;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * It starts the auth process with zotero
 * @param {client} object - Apollo Client
 */

export const zoteroDisconnect = (client) => {
  return client
    .mutate({
      mutation: ZOTERO_DISCONNECT,
    })
    .then(({ data }) => {
      return data.zoteroDisconnect;
    })
    .catch((err) => {
      throw new Error(err);
    });
};
