// Libraries
import React, { useEffect, useContext, useState } from 'react';
import Highlighter from 'react-highlight-words';
import {
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';

// Local
import { insertCitationLinks } from '../../../../Utilities/paper';
import { DocumentContext } from '../../../../DocumentContext';
import { ModularContext } from '../../../../../Modular/ModularContext';
import Fullbody from '../Fullbody';

const FullbodyParsed = ({
  useTextAlign,
  paper,
  modular,
  highlighter,
  granularity,
}) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [showParsedFullbody, setShowParsedFullbody] = useState(false);

  const getSectionThreshold = () => {
    if (
      paper.Content &&
      paper.Content.Fullbody_Parsed &&
      paper.Content.Fullbody_Parsed.length > 0
    ) {
      const sectSimilarities = [];
      paper.Content.Fullbody_Parsed.map((section) => {
        sectSimilarities.push(section.section_similarity);
      });
      sectSimilarities.sort((a, b) => b - a);
      return sectSimilarities[0];
    }
  };

  const getParagraphThreshold = () => {
    if (
      paper.Content &&
      paper.Content.Fullbody_Parsed &&
      paper.Content.Fullbody_Parsed.length > 0
    ) {
      const paraSimilarities = [];

      paper.Content.Fullbody_Parsed.map((section) =>
        section.section_text.map((para) => {
          paraSimilarities.push(para.paragraph_similarity);
        })
      );
      paraSimilarities.sort((a, b) => b - a);

      return paraSimilarities[4]; // use the fifth largest paragraph similarity as threshold
    }
  };

  useEffect(() => {
    if (
      Array.isArray(paper.Content?.Fullbody_Parsed) &&
      paper.Content.Fullbody_Parsed.length > 0
    ) {
      setShowParsedFullbody(true);
    } else {
      setShowParsedFullbody(false);
    }
  }, [paper.Content.Fullbody_Parsed]); // Dependency array includes the specific field

  useEffect(() => {
    const element = document.getElementById(context.scrollToSentenceId);

    if (element && context.scrollToSentenceId !== '') {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      context.updateContext('scrollToSentenceId', '');
    }
  });

  return showParsedFullbody ? (
    <Box
      sx={{
        width: '100%',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        padding: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
      }}
    >
      {useTextAlign !== 'Highlights'
        ? paper.Content.Fullbody_Parsed.map((fullbody, index) => {
            return (
              <Stack
                direction="column"
                key={index}
                sx={{
                  display: 'inline-block',
                }}
              >
                {fullbody.section_similarity === getSectionThreshold() &&
                granularity === 'Section' ? (
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                      marginTop: '10px',
                      marginBottom: '10px',
                      width: 'auto',
                      background: '#fbc02d', // only highlight the section title when granularity is Section
                      display: 'block',
                    }}
                  >
                    {fullbody.section_title}
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                      marginTop: '10px',
                      marginBottom: '10px',
                      width: 'auto',
                      background: 'none',
                      display: 'block',
                    }}
                  >
                    {fullbody.section_title}
                  </Typography>
                )}

                {!modular
                  ? fullbody.section_text.map((paragraph, sectIndex) => {
                      return (
                        <React.Fragment key={sectIndex}>
                          <Box>
                            {paragraph.paragraph_text.map((sentence, index) => {
                              return (
                                <Typography
                                  key={index}
                                  variant="body2"
                                  id={`${'Fullbody_Parsed'}#${
                                    fullbody.section_id
                                  }-${paragraph.paragraph_id}-${
                                    sentence.sentence_id
                                  }`}
                                  sx={{
                                    display: 'inline',
                                    background:
                                      sentence.sentence_similarity >= 0.66
                                        ? '#fbc02d'
                                        : sentence.sentence_similarity >=
                                              0.33 &&
                                            sentence.sentence_similarity >= 0.65
                                          ? '#fdd835'
                                          : sentence.sentence_similarity >=
                                                0.1 &&
                                              sentence.sentence_similarity >=
                                                0.32
                                            ? '#ffeb3b'
                                            : 'none',
                                    color:
                                      sentence.sentence_similarity >= 0.1 &&
                                      'black',
                                  }}
                                >
                                  {insertCitationLinks(
                                    sentence.sentence_text,
                                    sentence.cite_spans,
                                    context
                                  )}
                                  {paragraph.paragraph_text.length === index
                                    ? null
                                    : ' '}
                                </Typography>
                              );
                            })}
                          </Box>
                          <br />
                        </React.Fragment>
                      );
                    })
                  : // MODULAR HERE

                    useTextAlign === 'none' // render user selected text
                    ? fullbody.section_text.map((paragraph, index) => (
                        <Highlighter
                          style={{
                            display: 'inline-block',
                            marginBottom: '10px',
                          }}
                          key={index + paragraph}
                          searchWords={[highlighter]}
                          highlightStyle={{
                            backgroundColor: '#fbc02d', // gold color
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontSize: '14px',
                            lineHeight: 1.5,
                            letterSpacing: '0.00938em',
                            fontWeight: 400,
                          }}
                          unhighlightStyle={{
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontSize: '14px',
                            lineHeight: 1.5,
                            letterSpacing: '0.00938em',
                            fontWeight: 400,
                          }}
                          textToHighlight={paragraph.paragraph_text
                            .map((sentence) => sentence.sentence_text)
                            .join(' ')}
                          autoEscape={true}
                          caseSensitive={false}
                        />
                      ))
                    : // render fullbody with clickable citations

                      granularity === 'Sentence'
                      ? fullbody.section_text.map((paragraph, paragraphIdx) => {
                          return (
                            <Stack
                              key={paragraphIdx}
                              direction="column"
                              sx={{
                                display: 'inline-block',
                                marginBottom: '10px',
                              }}
                            >
                              {paragraph.paragraph_text.map(
                                (sentence, index) => {
                                  return (
                                    <Typography
                                      key={index}
                                      variant="body2"
                                      id={`${fullbody.section_id}${paragraph.paragraph_id}${sentence.sentence_id}`}
                                      sx={{
                                        marginLeft: '2px',
                                        display: 'inline',
                                        background:
                                          sentence.sentence_similarity >= 0.66
                                            ? '#fbc02d'
                                            : sentence.sentence_similarity >=
                                                  0.33 &&
                                                sentence.sentence_similarity >=
                                                  0.65
                                              ? '#fdd835'
                                              : sentence.sentence_similarity >=
                                                    0.1 &&
                                                  sentence.sentence_similarity >=
                                                    0.32
                                                ? '#ffeb3b'
                                                : 'none',
                                      }}
                                    >
                                      {insertCitationLinks(
                                        sentence.sentence_text,
                                        sentence.cite_spans,
                                        context
                                      )}
                                    </Typography>
                                  );
                                }
                              )}
                            </Stack>
                          );
                        })
                      : granularity === 'Paragraph'
                        ? fullbody.section_text.map(
                            (paragraph, paragraphIdx) => {
                              return (
                                <Stack
                                  key={paragraphIdx}
                                  direction="column"
                                  sx={{
                                    display: 'inline-block',
                                    marginBottom: '10px',
                                  }}
                                >
                                  {paragraph.paragraph_text.map(
                                    (sentence, index) => {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          id={`${fullbody.section_id}${paragraph.paragraph_id}`}
                                          sx={{
                                            marginLeft: '2px',
                                            display: 'inline',
                                            background:
                                              paragraph.paragraph_similarity >=
                                              getParagraphThreshold() // highlight the top five paragraphs
                                                ? '#fbc02d'
                                                : 'none',
                                          }}
                                        >
                                          {insertCitationLinks(
                                            sentence.sentence_text,
                                            sentence.cite_spans,
                                            context
                                          )}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Stack>
                              );
                            }
                          )
                        : fullbody.section_text.map(
                            (paragraph, paragraphIdx) => {
                              return (
                                <Stack
                                  key={paragraphIdx}
                                  direction="column"
                                  sx={{
                                    display: 'inline-block',
                                    marginBottom: '10px',
                                  }}
                                >
                                  {paragraph.paragraph_text.map(
                                    (sentence, index) => {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          id={`${fullbody.section_id}${paragraph.paragraph_id}${sentence.sentence_id}`} // no need to scroll for section granularity
                                          sx={{
                                            marginLeft: '2px',
                                            display: 'inline',
                                          }}
                                        >
                                          {insertCitationLinks(
                                            sentence.sentence_text,
                                            sentence.cite_spans,
                                            context
                                          )}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Stack>
                              );
                            }
                          )}
              </Stack>
            );
          })
        : context.memsumHighlights !== undefined &&
          context.memsumHighlights.length > 0 && (
            // render memsum summary
            <Box width="100%">
              <ListItemButton
                sx={{
                  border: '1px solid lightgrey',
                  marginBottom: '10px',
                }}
              >
                <ListItemIcon>
                  <ListOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Highlights" />
              </ListItemButton>
              <List>
                {context.memsumHighlights.map((sent, sentIdx) => (
                  <ListItem
                    key={sentIdx}
                    id={sentIdx === 0 ? paper.Title + ' memsum' : null}
                    sx={{
                      border: '1px solid lightgrey',
                      marginBottom: '10px',
                    }}
                  >
                    <ListItemText primary={sent} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
    </Box>
  ) : (
    <Fullbody paper={paper} highlighter={highlighter} />
  );
};

export default FullbodyParsed;
