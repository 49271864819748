// Libraries
import openSocket from 'socket.io-client';

// Local
import Constants from '../constants';

export const useSocket = (user, docId) =>
  openSocket(process.env.REACT_APP_COLLAB_SERVICE, {
    transports: ['websocket'],
    query: {
      doc_id: docId,
      user: user._id,
      token: Constants.TOKEN,
    },
    reconnection: false,
  });
