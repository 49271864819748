// Libraries
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Typography, Stack } from '@mui/material';

const DateComponent = ({ paper }) => {
  const [dateString, setDateString] = useState('');

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    if (!_.isEmpty(paper.PublicationDate)) {
      const parts = [];
      const { Day, Month, Year } = paper.PublicationDate;

      if (!_.isEmpty(Day)) {
        parts.push(Day);
      }

      if (!_.isEmpty(Month)) {
        // Assuming Month is a number from 1 to 12
        const formattedMonth = monthNames[parseInt(Month) - 1];
        parts.push(formattedMonth);
      }

      if (!_.isEmpty(Year)) {
        parts.push(Year);
      }

      setDateString(parts.join('-'));
    }
  }, [paper.PublicationDate]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: !dateString && 'none',
        marginBottom: '10px',
        color: 'green',
        marginRight: '5px',
      }}
    >
      <Typography>{dateString}</Typography>
    </Stack>
  );
};

export default DateComponent;
