// Libraries
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Joyride, { Step } from 'react-joyride';

// Local
import { GET_COACH, SET_COACH } from '../Queries';
import { manuscripts, manuscript } from './steps';
import { notify } from '../Common/notify';
import Window from './Window';

interface CoachProps {
  target: string;
}

// API Response types
interface CoachResponse {
  getCoach: {
    status: string;
    message: string;
    response: Record<string, boolean>;
  };
  setCoach: {
    status: string;
    message: string;
    response: Record<string, boolean>;
  };
}

// To use on any component, simply add in steps with target and content.
// Then on that component add className="Your Target Name"
const Coach: React.FC<CoachProps> = ({ target }) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [run, setRun] = useState<boolean>(false);

  const { data } = useQuery<CoachResponse>(GET_COACH, {
    fetchPolicy: 'network-only',
  });

  const [setCoach] = useMutation<CoachResponse>(SET_COACH, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const {
        getCoach: { response, status },
      } = data;

      if (status === 'success') {
        if (target === 'manuscripts' && !response['manuscripts']) {
          setRun(true);
          setSteps(manuscripts as Step[]);
        }

        if (target === 'manuscript' && !response['manuscript']) {
          setRun(true);
          setSteps(manuscript as Step[]);
        }
      }
    }
  }, [data]);

  return (
    <Joyride
      tooltipComponent={Window as React.FC}
      callback={(props) => {
        if (props.status === 'finished' && props.type === 'tour:end') {
          setCoach({
            variables: {
              [target]: true,
            },
            onCompleted: ({ setCoach }) => {
              if (setCoach.status === 'success') {
                setRun(false);
                notify('Completed walkthrough');
              } else {
                notify(setCoach.message);
              }
            },
          });
        }
      }}
      steps={steps}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showSkipButton
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default Coach;
