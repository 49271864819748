import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { ReferenceNode } from '../Types';

/**
 * Updates a specific reference node in the editor, identified by the element,
 * with the new reference text.
 *
 * @param {ReactEditor} editor - The Slate editor object.
 * @param {Node} element - The Slate node element that corresponds to the reference you want to update.
 * @param {string} newReferenceText - The new reference text to apply to the reference node.
 * @return {void}
 */

export const updateReference = (
  editor: ReactEditor,
  element: ReferenceNode,
  newReferenceText: string
): void => {
  // Find the path to the given element
  const path = ReactEditor.findPath(editor, element);

  // Only accept elements of type 'reference'
  if (element.type !== 'reference') {
    return;
  }

  // If its an empty string, do not update
  if (newReferenceText === '') {
    return;
  }

  // Update the node at the found path with the new reference text
  Transforms.setNodes(
    editor,
    { reference: newReferenceText } as ReferenceNode, // Adjust the property name as needed to match your node structure
    { at: path }
  );
};
