// Libraries
import { Editor, Location, Transforms } from 'slate';

// Local
import generateKatex from '../Elements/GenerateKatex';

export const insertKatex = (editor: Editor, selection: Location, equation: string) => {
  const voidNode = { type: 'katex', equation, children: [{ text: '' }] };
  
    // Checks for insertion
    if (!equation) return;
    if (!generateKatex(equation)?.isValid) return;

  // Set the selection
  Transforms.select(editor, selection);
  Transforms.insertNodes(editor, voidNode);
};
