// Libraries
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

// Local
import Warning from '../Warning';

const Fullbody = ({ paper, highlighter }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const isFullbodyEmpty = (text) => {
      return !text || text.trim().length === 0;
    };

    if (!isFullbodyEmpty(paper.Content?.Fullbody)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [paper.Content?.Fullbody]);

  return show ? (
    <Box
      sx={{
        width: '100%',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        padding: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
          fontWeight: 'bold',
          marginBottom: '10px',
          width: '100%',
        }}
      >
        Contents
      </Typography>
      <Alert
        severity="info"
        sx={{
          display: 'none',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        This paper&apos;s full body is unstructured, therefore sentence
        similarity is unavailable for this portion of the text.
      </Alert>
      <Highlighter
        searchWords={highlighter.split(' ')}
        textToHighlight={paper.Content.Fullbody}
        autoEscape={true}
      />
    </Box>
  ) : (
    <Warning section={'Full body unavailable'} />
  );
};

export default Fullbody;
