import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

export const insertMention = (editor: ReactEditor, citation: CitationNode) => {
  // Insert the citation node at the current selection
  Transforms.insertNodes(editor, citation);

  if (editor.selection) {
    const pointAfterCitation = Editor.after(editor, editor.selection);
    if (pointAfterCitation) {
      // Insert a space text node after the citation
      const spaceText = { text: ' ' };
      Transforms.insertNodes(editor, spaceText, {
        at: pointAfterCitation,
      });

      // Move the selection to the end of the inserted space node
      const pointAfterSpace = Editor.end(editor, pointAfterCitation);
      if (pointAfterSpace) {
        Transforms.select(editor, pointAfterSpace);
      }
    }
  }

  // Set the focus back to the editor
  ReactEditor.focus(editor);
};
