// Local
import { notify } from '../../../Common/notify.js';

/**
 * Tries to add a text span to Y, the existing annotations of influencing content
 * Insertion occurs only if the text span meets multiple criteria (listed in function)
 * Returns either Y or Y with the text span
 *
 * @param {object} newSpan - the text span to be inserted
 * @param {array} existingSpans - the existing annotations of influencing content (aka Y)
 * @param {array} annotatorX - the existing annotations of influenced content (aka X)
 * @returns {array} - the (potentially updated) annotations of influencing content
 */

const updateAnnotatorY = (newSpan, existingSpans, annotatorX) => {
  // check: note has been annotated
  if (annotatorX.length === 0) {
    notify('Please annotate a note paragraph first.');
    return [];
  }

  // check: newSpan is from a single section
  const anchorLocation = [
    ...newSpan.anchorParentId.split(/[#-]/),
    newSpan.anchorOffset,
  ];
  const focusLocation = [
    ...newSpan.focusParentId.split(/[#-]/),
    newSpan.focusOffset,
  ];
  if (
    anchorLocation[0] !== focusLocation[0] ||
    anchorLocation[1] !== focusLocation[1]
  ) {
    notify('Paper annotations must be from a single section');
    return [];
  }

  // if newSpan was selected in reverse, swap anchor and focus
  if (
    anchorLocation[2] > focusLocation[2] ||
    (anchorLocation[2] === focusLocation[2] &&
      anchorLocation[3] > focusLocation[3])
  ) {
    [newSpan.anchorParentId, newSpan.focusParentId] = [
      newSpan.focusParentId,
      newSpan.anchorParentId,
    ];
    [newSpan.anchorOffset, newSpan.focusOffset] = [
      newSpan.focusOffset,
      newSpan.anchorOffset,
    ];
  }

  // consider appending newSpan to existingSpans
  const spans = [...existingSpans, newSpan];

  // sort spans by appearance
  spans.sort((a, b) => {
    if (a.anchorParentId !== b.anchorParentId) {
      return a.anchorParentId.localeCompare(b.anchorParentId);
    }
    return a.anchorOffset - b.anchorOffset;
  });

  return spans;
};

export default updateAnnotatorY;
