import { formatAuthors } from './formatAuthors';
import { PublicationDate, Author } from '../Types';

/**
 * Formats a citation's text based on content availability in IEEE style
 *
 * @param {Author[]} authors - The list of authors
 * @param {PublicationDate} dates - The dates object
 * @returns {string} The formatted citation text
 */

export const citationFormat = (authors: Author[], dates: PublicationDate) => {
  let authorStr;
  if (authors.length > 3) {
    authorStr = formatAuthors([authors[0]]) + ' et al.'; // First author + et al. for more than 3 authors
  } else {
    authorStr = formatAuthors(authors).join(', '); // All authors listed if 3 or fewer
  }

  if (dates && dates.Year) {
    return `${authorStr}, ${dates.Year}`;
  } else {
    return authorStr;
  }
};
