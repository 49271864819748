import { Transforms, Node, Editor } from 'slate';
import { getReferences } from './getReferences';
import { getCitations } from './getCitations';
import { findAndDelete } from './findAndDelete';

/**
 * Synchronizes citations and references numbering based on current editor state
 *
 * @param {object} editor - The current slate editor instance
 */

export const synchronizeAll = (editor) => {
  Editor.withoutNormalizing(editor, () => {
    // Get list of references
    let references = getReferences(editor);
    // Get list of citations
    const citations = getCitations(editor);
    // Create citation map
    const citationMap = {};
    for (let i = 0; i < citations.length; i++) {
      const citation = citations[i][0]['metadata'];
      citationMap[citation.id_value] = { ...citation, exists: false };
    }
    // Delete any uncited references
    references.forEach((reference) => {
      const metadata = reference[0].metadata;
      // If there is a match
      if (citationMap[metadata.id_value]) {
        // delete duplicate reference
        if (citationMap[metadata.id_value].exists) {
          findAndDelete(editor, 'reference', metadata.id_value);
        } else {
          // Mark as a reference existing
          citationMap[metadata.id_value].exists = true;
        }
      } else {
        // delete reference
        findAndDelete(editor, 'reference', metadata.id_value);
      }
    });

    // Get list of references again
    references = getReferences(editor);

    // For each reference, sync its index position to number
    // For each citation related to the reference, sync citation number
    // to the new reference number
    references.forEach((reference, index) => {
      // Verify that the path exists
      const node = Node.get(editor, reference[1]);
      if (node) {
        try {
          Transforms.setNodes(
            editor,
            {
              number: index + 1,
            },
            {
              at: reference[1],
            }
          );
        } catch (error) {
          console.error(`Error setting node at path ${reference[1]}: `, error);
        }
      } else {
        console.warn(`Node at path ${reference[1]} does not exist`);
      }

      citations.forEach((citation) => {
        if (
          citation[0].metadata &&
          reference[0].metadata &&
          citation[0].metadata.id_value === reference[0].metadata.id_value
        ) {
          // Verify that the path exists
          const citationNode = Node.get(editor, citation[1]);
          if (citationNode) {
            try {
              Transforms.setNodes(
                editor,
                {
                  number: index + 1,
                },
                {
                  at: citation[1],
                }
              );
            } catch (error) {
              console.error(
                `Error setting node at path ${citation[1]}: `,
                error
              );
            }
          } else {
            console.warn(`Node at path ${citation[1]} does not exist`);
          }
        }
      });
    });
  });
};
