// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlate, RenderElementProps } from 'slate-react';
import { useLazyQuery } from '@apollo/client';
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Stack,
  Button,
  darken,
  lighten,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Local
import { SINGLE_PAPER, SUMMARIZE } from '../../Queries/nlp';
import { DocumentContext, DocumentContextType } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';
import { notify } from '../../../Common/notify';
import { updateReference } from '../Utilities/updateReference';
import GlobalModal from '../../../Common/GlobalModal';
import MetadataForm from './MetadataForm';
import { ReferenceNode, Metadata } from '../Types';

interface ReferenceProps extends RenderElementProps {
  element: ReferenceNode;
  modular: boolean;
}

// Define a React component to render elements as a Reference.
const Reference: React.FC<ReferenceProps> = ({
  attributes,
  children,
  element,
  modular,
}) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(
    contextSource as React.Context<DocumentContextType>
  );
  const editor = useSlate();

  const [modal, setModal] = useState(false);
  const [reference, setReference] = useState('');

  const [summarizePaper] = useLazyQuery(SUMMARIZE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ summarizePaper }) => {
      context.updateContext('summaries', summarizePaper.response);
    },
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER);

  const fetchPaper = async (metadata: Metadata) => {
    context.updateContext('loadingRequest', true);
    context.updateContext('loadingMessage', 'Retrieving paper');
    context.updateContext('sortedView', false);

    singlePaper({
      fetchPolicy: 'network-only',
      variables: {
        paper_id: {
          collection: metadata.collection,
          id_type: metadata.id_type,
          id_field: metadata.id_field,
          id_value: metadata.id_value,
        },
      },
      onCompleted: ({ singlePaper }) => {
        if (singlePaper.status === 'success') {
          context.updateContext('rankingDetails', {
            rankingIdValue: metadata.id_value,
            rankingIdField: metadata.id_field,
            rankingIdType: metadata.id_type,
            rankingCollection: metadata.collection,
          });

          context.updateContext('tab', 'View');
          context.updateContext('paper', singlePaper.response);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', 'Retrieved paper');
          context.updateContext('showSelection', false);

          if (context.recordActionHistory) {
            context.recordActionHistory({
              id_value: metadata.id_value,
              id_field: metadata.id_field,
              id_type: metadata.id_type,
              collection: metadata.collection,
            });
          }
        } else {
          notify(singlePaper.message);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', '');
        }
      },
    });

    summarizePaper({
      variables: {
        paper_id: {
          collection: metadata.collection,
          id_type: metadata.id_type,
          id_field: metadata.id_field,
          id_value: metadata.id_value,
        },
      },
    });
  };

  // Prevents weird scroll behaviour
  const preventScroll = (event: React.MouseEvent) => {
    event.preventDefault();

    const path = ReactEditor.findPath(
      context.editorRef as ReactEditor,
      element
    );
    const range = Editor.range(context.editorRef as ReactEditor, path);
    const selection = { anchor: range.anchor, focus: range.focus };
    Transforms.setSelection(context.editorRef as ReactEditor, selection);
  };

  useEffect(() => {
    setReference(element.reference);
  }, [modal]);

  return (
    <>
      <GlobalModal
        isOpen={modal}
        setOpen={setModal}
        heading={'Edit reference'}
        subheading={'Update a single reference'}
        aria-label="global-modal"
      >
        <TextField
          id="outlined-basic"
          placeholder="Edit reference"
          variant="outlined"
          value={reference}
          onChange={(event) => setReference(event.target.value)}
          InputProps={{
            maxRows: 8,
            multiline: true,
          }}
          sx={{
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
        <MetadataForm element={element as ReferenceNode} modular={modular} />
        <Stack direction={'row'} spacing={1} sx={{ marginTop: '10px' }}>
          <Button
            variant="outlined"
            aria-label='update-reference-button'
            onClick={() => {
              updateReference(editor as ReactEditor, element, reference);
              setModal(false);
            }}
          >
            Update reference
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              Transforms.removeNodes(editor as ReactEditor, {
                at: ReactEditor.findPath(editor as ReactEditor, element),
              });
              setModal(false);
            }}
          >
            Delete reference
          </Button>
        </Stack>
      </GlobalModal>
      <Paper
        elevation={0}
        aria-label="reference-paper"
        sx={{
          position: 'relative',
          display: 'inline-block',
          boxSizing: 'border-box',
          fontSize: 'inherit',
          cursor: 'pointer',
          width: '100%',
          color: 'grey',
          backgroundColor: 'transparent',
          borderRadius: '0px',
          '&:not(:last-child)': {
            marginBottom: '10px',
          },
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? darken(theme.palette.background.paper, 0.05)
                : lighten(theme.palette.background.paper, 0.05),
          },
          '&:hover .editIconButton': {
            // Target the IconButton with a specific class on hover
            display: context.readOnly ? 'none' : 'block',
          },
        }}
        {...attributes}
        contentEditable={false}
        onClick={async (e) => {
          e.preventDefault();
          preventScroll(e);

          // if any metadata properties are null
          if (
            element.metadata.collection === null ||
            element.metadata.id_type === null ||
            element.metadata.id_field === null ||
            element.metadata.id_value === null
          ) {
            setModal(true);
            notify('No paper linked, add your own metadata');
            return;
          }

          fetchPaper(element.metadata);
        }}
      >
        <Typography
          component={'span'}
          aria-label="reference-typography"
          sx={{
            fontSize: 'inherit',
            wordWrap: 'break-word',
            width: '100%',
          }}
        >
          {/* {context.showNumbering ? <>[{element.number}] </> : null}
           */}
          {element.reference}
        </Typography>
        <Typography
          component="span"
          sx={{
            display: 'none',
          }}
        >
          {children}
        </Typography>
        <IconButton
          className="editIconButton"
          size="small"
          onClick={(e) => {
            e.stopPropagation();

            setModal(true);
          }}
          sx={{
            display: 'none',
            position: 'absolute',
            top: -15,
            left: 10,
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? darken(theme.palette.background.paper, 0.05)
                  : lighten(theme.palette.background.paper, 0.05),
            },
          }}
        >
          <EditOutlinedIcon
            sx={{
              fontSize: '12px',
              height: '18px',
              width: '22px',
              margin: 0,
              padding: 0,
            }}
          />
        </IconButton>
      </Paper>
    </>
  );
};

export default Reference;
