// Libraries
import { Editor, Location, Transforms } from 'slate';

// Local
import { InlineKatexNode } from '../Types';
import generateKatex from '../Elements/GenerateKatex';

export const insertInlineKatex = (
  editor: Editor,
  selection: Location,
  equation: string
) => {
  const inline = {
    type: 'inline-katex',
    equation,
    children: [{ text: '' }],
  };

  // Checks for insertion
  if (!equation) return;
  if (!generateKatex(equation)?.isValid) return;

  // Check if the current selection is within an inline-katex node
  const [inlineKatexNodeEntry] = Editor.nodes(editor, {
    match: (node) => (node as InlineKatexNode).type === 'inline-katex',
    at: selection,
  });

  // If the selection is within an inline-katex node, do not insert a new one
  if (inlineKatexNodeEntry) {
    return;
  }

  // Set the selection
  Transforms.select(editor, selection);
  Transforms.insertNodes(editor, inline);
};
