// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  IconButton,
  Stack,
  Button,
  TextField,
  lighten,
  darken,
  Paper,
} from '@mui/material';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlate, useSelected, useFocused } from 'slate-react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Local
import { SINGLE_PAPER, SUMMARIZE } from '../../Queries/nlp';
import { DocumentContext } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';
import { updateCitation } from '../Utilities/updateCitation';
import { updateCitations } from '../Utilities/updateCitations';
import { notify } from '../../../Common/notify';
import GlobalModal from '../../../Common/GlobalModal';
import MetadataForm from './MetadataForm';
// import InlineChromiumBugfix from './ChromiumBugFix';

// Define a React component to render elements as a Reference.
const Citation = ({ attributes, children, element, modular }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();

  const [modal, setModal] = useState(false);
  const [citation, setCitation] = useState('');

  const [summarizePaper] = useLazyQuery(SUMMARIZE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ summarizePaper }) => {
      context.updateContext('summaries', summarizePaper.response);
    },
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER);

  const fetchPaper = async (metadata) => {
    context.updateContext('loadingRequest', true);
    context.updateContext('loadingMessage', 'Retrieving paper');
    context.updateContext('sortedView', false);

    singlePaper({
      fetchPolicy: 'network-only',
      variables: {
        paper_id: {
          collection: metadata.collection,
          id_type: metadata.id_type,
          id_field: metadata.id_field,
          id_value: metadata.id_value,
        },
      },
      onCompleted: ({ singlePaper }) => {
        if (singlePaper.status === 'success') {
          context.updateContext('rankingDetails', {
            rankingIdValue: metadata.id_value,
            rankingIdField: metadata.id_field,
            rankingIdType: metadata.id_type,
            rankingCollection: metadata.collection,
          });

          context.updateContext('tab', 'View');
          context.updateContext('paper', singlePaper.response);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', 'Retrieved paper');
          context.updateContext('showSelection', false);

          context.recordActionHistory({
            id_value: metadata.id_value,
            id_field: metadata.id_field,
            id_type: metadata.id_type,
            collection: metadata.collection,
          });
        } else {
          notify(singlePaper.message);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', '');
        }
      },
    });

    summarizePaper({
      variables: {
        paper_id: {
          collection: metadata.collection,
          id_type: metadata.id_type,
          id_field: metadata.id_field,
          id_value: metadata.id_value,
        },
      },
    });
  };

  // Prevents weird scroll behaviour (ChatGPT-4 generated)
  const preventScroll = (event) => {
    event.preventDefault();
    const path = ReactEditor.findPath(context.editorRef, element);
    const range = Editor.range(context.editorRef, path);
    const selection = { anchor: range.anchor, focus: range.focus };
    Transforms.setSelection(context.editorRef, selection);
  };

  useEffect(() => {
    setCitation(element.citation);
  }, [modal]);

  return (
    <>
      <GlobalModal
        isOpen={modal}
        setOpen={setModal}
        heading={'Edit citation'}
        subheading={' Update a single citation or all'}
      >
        <TextField
          id="outlined-basic"
          placeholder="Edit citation"
          variant="outlined"
          value={citation}
          onChange={(event) => setCitation(event.target.value)}
          InputProps={{
            maxRows: 8,
            multiline: true,
          }}
          sx={{
            width: '100%',
            marginTop: '10px',
            marginBottom: '5px',
          }}
        />
        <MetadataForm element={element} modular={modular} />
        <Stack direction={'row'} spacing={1} sx={{ marginTop: '10px' }}>
          <Button
            variant="outlined"
            onClick={() => {
              updateCitation(editor, element, citation);
              setModal(false);
            }}
          >
            Update citation
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              updateCitations(editor, element, citation);
              setModal(false);
            }}
          >
            Update all citations
          </Button>
        </Stack>
      </GlobalModal>
      <Paper
        component={'span'}
        {...attributes}
        contentEditable={false}
        onClick={async (e) => {
          e.preventDefault();
          preventScroll(e);

          // if any metadata properties are null
          if (
            element.metadata.collection === null ||
            element.metadata.id_type === null ||
            element.metadata.id_field === null ||
            element.metadata.id_value === null
          ) {
            setModal(true);
            notify('No paper linked, add your own metadata');
            return;
          }

          fetchPaper(element.metadata);
        }}
        sx={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer',
          fontSize: 'inherit',
          color: 'grey',
          borderRadius: '0px',
          marginLeft: '1px', // Needed to allow cursor placement
          marginRight: '1px', // Needed to allow cursor placement
          boxShadow: selected && focused ? '0 0 0 1px grey' : 'none',
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? darken(theme.palette.background.paper, 0.05)
                : lighten(theme.palette.background.paper, 0.05),
          },
          '&:hover .editIconButton': {
            // Target the IconButton with a specific class on hover
            display: context.readOnly ? 'none' : 'block',
          },
        }}
      >
        {/* {context.showNumbering ? element.number : element.citation}
         */}
        {element.citation}
        {children}
        <IconButton
          className="editIconButton"
          size="small"
          onClick={(e) => {
            e.stopPropagation();

            setModal(true);
          }}
          sx={{
            display: 'none',
            position: 'absolute',
            top: -12,
            left: 5,
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? darken(theme.palette.background.paper, 0.05)
                  : lighten(theme.palette.background.paper, 0.05),
            },
          }}
        >
          <EditOutlinedIcon
            sx={{
              fontSize: '12px',
              height: '18px',
              width: '22px',
              margin: 0,
              padding: 0,
            }}
          />
        </IconButton>
      </Paper>
    </>
  );
};

export default Citation;
