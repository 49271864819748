// Library
import React, { useState, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { 
  Button,
  Typography
 } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

// Local
import { PAGINATED_SEARCH } from '../../Queries/nlp'
import { ADD_BOOKMARK_FROM_METADATA } from '../../Queries/document';
import { DocumentContext } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';
import GlobalModal from '../../../Common/GlobalModal';
import { notify } from '../../../Common/notify';
import { PaperForm } from '../../../Common/PaperForm'


// Local

const ImportPaperManually = ({ setBackdrop, setBackdropText, modular = false }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const [modal, setModal] = useState(false)  

  const context = useContext(contextSource);

  const [addBookmarkFromMetadata] = useMutation(ADD_BOOKMARK_FROM_METADATA, {
    fetchPolicy: 'network-only',
  });

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const onSubmit = (data) => {
    setBackdrop(true)
    setModal(false)
    setBackdropText('Uploading paper')

    addBookmarkFromMetadata({
      variables: {
        username: context.user._id,
        ...data,
      },
      onCompleted: ({ addBookmarkFromMetadata }) => {

        if (addBookmarkFromMetadata.status === 'success') {
          const paperList = addBookmarkFromMetadata.response.bookmarks.map(
            (bookmark) => {
              return {
                collection: bookmark.id_collection,
                id_field: bookmark.id_field,
                id_type: bookmark.id_type,
                id_value: bookmark.id_value,
              };
            }
          );

          paginatedSearch({
            variables: {
              paper_list: paperList,
            },
            onCompleted: ({ paginatedSearch }) => {
              if (paginatedSearch.status === 'success') {
                const updatedBookmarks = [];

                paginatedSearch.response.forEach((paper) => {
                  paperList.forEach((meta) => {
                    if (paper.id_int != null && paper.id_int.toString() === meta.id_value) {
                      updatedBookmarks.push({
                        _id: paper._id,
                        abstract: paper.Content.Abstract,
                        abstract_parsed: paper.Content.Abstract_Parsed,
                        doi: paper.DOI,
                        id_int: paper.id_int,
                        title: paper.Title,
                        id_collection: meta.collection,
                        id_field: meta.id_field,
                        id_type: meta.id_type,
                        id_value: meta.id_value,
                      });
                    }
                  });
                });
                
                context.updateContext('bookmarks', updatedBookmarks);
                setBackdropText('Uploaded paper')
                setBackdrop(false)
                context.updateContext('togglePanel', false)
                context.updateContext('searchLibrary', true)
              } else {
                notify(
                  'Could not add metadata item to library at this moment, try again later'
                );
              }
            },
          });
        } else {
          notify(addBookmarkFromMetadata.message);
          setBackdrop(false)
          setBackdropText('')
        }
      },
    });
  };


  return (
    <>
      <GlobalModal
        isOpen={modal}
        setOpen={setModal}
        heading={'Enter paper metadata manually'}
        subheading={''}
      >
        <PaperForm onSubmit={onSubmit}/>
      </GlobalModal>
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={() => {
          setModal(true)
        }}
      >
        <FileUploadOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography variant="body1">Add paper to library</Typography>
      </Button>
    </>
  );
};

export default ImportPaperManually;
