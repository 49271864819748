// Libraries
import React, {
  useState,
  useEffect,
  // useRef
} from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router-dom';
// import { Mutation } from '@apollo/client/react/components';
import {
  Box,
  // Avatar,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Modal,
  Fade,
  Alert,
} from '@mui/material';

// Local
import { deleteUser } from './Requests';
import {
  // PROFILE_PIC, GET_CURRENT_USER,
  VALIDATE_UPDATE,
} from '../Queries';
import { notify } from '../Common/notify';
import DarkModeToggle from '../Common/DarkModeToggle';
import NavToggle from '../Common/Navigator';
import Constants from '../constants';
import PasswordForm from './PasswordForm';
import ApiKey from './ApiKey';
import EmailForm from './EmailForm';
import NameForm from './NameForm';
import Zotero from './Zotero';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  color: 'text.primary',
  boxShadow: 24,
  p: 2,
};

/**
 * Account Component
 *
 * This component renders the user's account page in the application. It allows users to view and modify their account information, including their profile picture, name, email, and password. Additionally, it provides options to delete the account or upgrade the subscription plan, if applicable.
 *
 * The component uses various Material-UI components for layout and styling and Apollo Client for GraphQL queries and mutations. It also includes custom hooks and components for specific functionalities like dark mode toggle, navigation toggle, and various form elements.
 *
 * @component
 * @example
 * return (
 *   <Account client={apolloClient} user={currentUser} refetch={refetchUserData} />
 * )
 *
 * @param {Object} client - The Apollo Client instance for making GraphQL queries and mutations.
 * @param {Object} user - The current user's data.
 * @param {Function} refetch - A function to refetch user data from the server.
 */

const Account = ({
  client,
  user,
  // refetch
}) => {
  // const [profilePicUrl, setProfilePicUrl] = useState(user.profilePicUrl);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const uploadRef = useRef(null);
  const navigate = useNavigate();
  const { token } = useParams();

  const deleteAcc = async () => {
    ReactGA.event({
      category: 'Account',
      action: 'Deleted account',
      label: 'Deleted account',
    });

    await deleteUser(client);
    localStorage.clear();
    document.cookie =
      'token=; refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    Constants.TOKEN = '';
    setModalOpen(false);
    // navigate('/register');
    navigate('/login');
  };

  useEffect(() => {
    if (!token) return;

    client
      .query({
        query: VALIDATE_UPDATE,
        fetchPolicy: 'network-only',
        variables: {
          token,
        },
      })
      .then(async ({ data }) => {
        notify(data.validateUpdate.message);
        navigate('/account');
      })
      .catch((err) => {
        notify(err.message);
      });
  }, []);

  useEffect(() => {
    if (user.email === process.env.REACT_APP_DEMO_ACCOUNT) {
      navigate('/notes');
      notify('You are using the demo account, you cannot modify the account');
    }
  }, []);

  return (
    <Box
      sx={{
        overflow: 'hidden !important',
      }}
    >
      <AppBar
        elevation={0}
        sx={{
          position: 'fixed',
          color: 'text.primary',
          minHeight: '64px',
          zIndex: 1,
          padding: '0 !important',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? 'background.paper' : '#171717',
          borderBottom: (theme) =>
            theme.palette.mode === 'dark'
              ? '1px solid #333333'
              : '1px solid #d4d4d4',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            paddingLeft: { xs: '10px', xl: '10%' },
            paddingRight: { xs: '10px', xl: '10%' },
          }}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" component="div">
              Account
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DarkModeToggle />
            <NavToggle user={user} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Backdrop
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'cornflowerblue',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress
          color="inherit"
          sx={{
            marginBottom: '10px',
          }}
        />
        <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Updating your account picture, please wait...
        </Typography>
      </Backdrop>
      <Modal
        aria-labelledby="Citation exports"
        aria-describedby="BIB or MLA citations"
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
      >
        <Fade in={isModalOpen}>
          <Box sx={style}>
            <Typography
              sx={{
                marginBottom: '5px',
              }}
            >
              You are about to delete your account and all related data,
              proceed?
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={() => deleteAcc()}>
                I understand, proceed
              </Button>
              <Button variant="outlined" onClick={() => setModalOpen(false)}>
                Close
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: `calc(100vh - 64px)`,
          width: '100%',
          marginTop: '65px',
          bgcolor: 'background.paper',
          color: 'text.primary',
          padding: '20px',
          boxSizing: 'border-box',
          paddingLeft: { xs: '10px', xl: '10%' },
          paddingRight: { xs: '10px', xl: '10%' },
        }}
      >
        {/* <Mutation
          mutation={PROFILE_PIC}
          refetchQueries={() => [{ query: GET_CURRENT_USER }]}
        >
          {(profilePic) => (
            <input
              id="myInput"
              type="file"
              required
              ref={uploadRef}
              style={{ display: 'none', height: '0', width: '0' }}
              onChange={({
                target: {
                  validity,
                  files: [file],
                },
              }) => {
                setLoading(true);
                validity.valid &&
                  profilePic({ variables: { file } }).then(({ data }) => {
                    if (data.profilePic.status === 'success') {
                      setProfilePicUrl(data.profilePic.response);
                      refetch();
                    } else {
                      notify('Something went wrong, please try again later');
                    }

                    setLoading(false);
                  });
              }}
            />
          )}
        </Mutation> */}
        {/* <Avatar
          alt="Profile pic"
          variant="rounded"
          src={profilePicUrl}
          sx={{ width: 200, height: 200, marginBottom: '20px' }}
        />
        <Button
          variant="outlined"
          sx={{ width: 200, marginBottom: '40px' }}
          onClick={() => {
            uploadRef.current.click();
          }}
        >
          Change picture
        </Button> */}
        <Stack
          direction="column"
          spacing={2}
          sx={{
            maxWidth: '400px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {user.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: 'auto',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {user.email} {user.switchRegistered ? '(Switch edu-ID)' : ''}
          </Typography>

          {process.env.REACT_APP_ETH === 'true' &&
            user.switchRegistered &&
            user.hasSwissEduIdLinkedAffiliation && (
              <Alert severity="info">
                Switch edu-ID institutional verified member
              </Alert>
            )}
          {process.env.REACT_APP_ETH === 'true' ? null : (
            <>
              <Button variant="outlined" onClick={() => {}}>
                Upgrade plan
              </Button>
              <Button variant="outlined" onClick={() => {}}>
                Manage subscription
              </Button>
            </>
          )}
          {user.switchRegistered ? (
            <Alert
              severity="success"
              sx={{
                marginBottom: '20px',
              }}
            >
              Your name is managed via Switch edu-ID.
            </Alert>
          ) : (
            <NameForm user={user} client={client} />
          )}
          {user.switchRegistered ? (
            <Alert
              severity="success"
              sx={{
                marginBottom: '20px',
              }}
            >
              Your email is managed via Switch edu-ID.
            </Alert>
          ) : (
            <EmailForm user={user} client={client} />
          )}
          {user.switchRegistered ? (
            <Alert
              severity="success"
              sx={{
                marginBottom: '20px',
              }}
            >
              Your credentials are managed via Switch edu-ID.
            </Alert>
          ) : (
            <PasswordForm user={user} client={client} />
          )}
          <Zotero client={client} user={user} />
          {user.switchRegistered && user.hasSwissEduIdLinkedAffiliation ? (
            <ApiKey client={client} />
          ) : (
            <Alert
              severity="success"
              sx={{
                marginBottom: '20px',
              }}
            >
              Only institutional users can access the API
            </Alert>
          )}
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: '100%',
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => setModalOpen(true)}
            sx={{
              maxWidth: '200px',
            }}
          >
            Delete Account*
          </Button>
          <Alert
            severity="warning"
            sx={{
              width: 'fit-content',
            }}
          >
            *Your content will be deleted along with your account, there are
            currently no recovery options.
          </Alert>
        </Stack>
      </Box>
    </Box>
  );
};

export default Account;
