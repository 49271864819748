import React, { useContext } from 'react';
import { useHotkeys, HotkeysProvider } from 'react-hotkeys-hook';
import { toggleMark, toggleBlock } from '../Menu/index';
import { useSlate } from 'slate-react';
import { DocumentContext } from '../../DocumentContext';
import { notify } from '../../../Common/notify';
import { saveDocument } from '../../Requests/Document';

const hotkeysConfig = [
  {
    key: 'ctrl + b',
    format: 'bold',
    description: 'Bold',
    tooltip: 'Ctrl+B',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + i',
    format: 'italic',
    description: 'Italic',
    tooltip: 'Ctrl+I',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + u',
    format: 'underline',
    description: 'Underline',
    tooltip: 'Ctrl+U',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + s',
    format: 'save',
    description: 'Save',
    tooltip: 'Ctrl+S',
    action: (hotkeyOptions) => hotkeyOptions.save(),
  },
  {
    key: 'ctrl + comma',
    format: 'subscript',
    description: 'Subscript',
    tooltip: 'Ctrl+,',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + period',
    format: 'superscript',
    description: 'Superscript',
    tooltip: 'Ctrl+.',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + shift + s',
    format: 'strikethrough',
    description: 'Strike through',
    tooltip: 'Ctrl+Shift+S',
    action: (hotkeyOptions) =>
      toggleMark(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + alt + 1',
    format: 'heading-one',
    description: 'Heading 1',
    tooltip: 'Ctrl+Alt+1',
    action: (hotkeyOptions) =>
      toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  },
  {
    key: 'ctrl + alt + 2',
    format: 'heading-two',
    description: 'Heading 2',
    tooltip: 'Ctrl+Alt+2',
    action: (hotkeyOptions) =>
      toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  },
  // {
  //   key: 'ctrl + alt + 3',
  //   format: 'heading-three',
  //   description: 'Heading 3',
  //   tooltip: 'Ctrl+Alt+3',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + alt + 4',
  //   format: 'heading-four',
  //   description: 'Heading 4',
  //   tooltip: 'Ctrl+Alt+4',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + b',
  //   format: 'block-quote',
  //   description: 'Block Quote',
  //   tooltip: 'Ctrl+Shift+B',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + 8',
  //   format: 'bulleted-list',
  //   description: 'Bulleted List',
  //   tooltip: 'Ctrl+Shift+8',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + 7',
  //   format: 'numbered-list',
  //   description: 'Numbered List',
  //   tooltip: 'Ctrl+Shift+7',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + l',
  //   format: 'left',
  //   description: 'Left Alignment',
  //   tooltip: 'Ctrl+Shift+L',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + e',
  //   format: 'center',
  //   description: 'Center Alignment',
  //   tooltip: 'Ctrl+Shift+E',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + r',
  //   format: 'right',
  //   description: 'Right Alignment',
  //   tooltip: 'Ctrl+Shift+R',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
  // {
  //   key: 'ctrl + shift + j',
  //   format: 'justify',
  //   description: 'Justify Center',
  //   tooltip: 'Ctrl+Shift+J',
  //   action: (hotkeyOptions) =>
  //     toggleBlock(hotkeyOptions.editor, hotkeyOptions.format),
  // },
];

const getShortcut = (format) => {
  const shortcut = hotkeysConfig.find((s) => s.format === format);
  return shortcut ? shortcut.tooltip : null;
};

const HotkeysProviderWrapper = ({ children, client }) => {
  const context = useContext(DocumentContext);
  const editor = useSlate();

  const save = () => {
    if (!context.readOnly) {
      saveDocument(
        client,
        context.documentId,
        context.title,
        context.value,
        context.user,
        context.showNumbering,
        context.autoAppearanceOrder,
        'Note saved'
      );
    } else {
      notify('You cannot save as you are read only');
    }
  };

  hotkeysConfig.forEach((hotkey) => {
    useHotkeys(
      hotkey.key,
      (event) => {
        event.preventDefault();
        hotkey.action({ editor: editor, format: hotkey.format, save: save });
      },
      {
        preventDefault: true,
        enableOnContentEditable: true,
      }
    );
  });

  return <HotkeysProvider>{children}</HotkeysProvider>;
};

export { getShortcut, hotkeysConfig };
export default HotkeysProviderWrapper;
