export const permissions = (loggedInUser, author, collaborators) => {
  // If user is the author, read only access is false, they have write access
  if (loggedInUser._id === author) return false;

  let permission = false;

  collaborators.forEach((member) => {
    // Get a member's access from collaborator list, if condition matches set permission
    if (member.user._id === loggedInUser._id && member.access === "read") {
      permission = true;
    }
  });

  // The value is either updated or defaulted to read only false
  return permission;
};
