import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function Offline() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="body1" component="div">
          The contents database is currently offline, the below is unavailable:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Searching for content
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Library content
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Citation links
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Citing library content
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Exporting & Importing library items
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Exporting & Importing references
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() =>
            openInNewTab(process.env.REACT_APP_PLATFORM_DOCUMENTATION)
          }
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
