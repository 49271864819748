import { useCallback } from 'react';
import { Text, Range, Editor } from 'slate';

// Custom hook to decorate the selection
const useDecorateSelection = (editor, context, modular) => {
  return useCallback(
    ([node, path]) => {
      if (Text.isText(node) && editor.selection !== null) {
        const intersection = Range.intersection(
          editor.selection,
          Editor.range(editor, path)
        );

        if (intersection === null) {
          return [];
        }

        const range = {
          highlighted: true,
          ...intersection,
        };

        if (
          context.writeAnchorOffset !== undefined &&
          context.writeFocusOffset !== undefined &&
          modular
        ) {
          const newRange = {
            anchor: {
              offset: context.writeAnchorOffset,
              path: range.anchor.path,
            },
            focus: { offset: context.writeFocusOffset, path: range.focus.path },
            highlighted: true,
          };

          return [newRange];
        }
        return [];
      }
      return [];
    },
    [context.writeAnchorOffset, context.writeFocusOffset]
  );
};

export default useDecorateSelection;
