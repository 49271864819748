// Libraries
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

// Local
import generateKatex from '../Elements/GenerateKatex';
import { BaseNode } from '../Types/index';

export const updateKatex = (editor: Editor, equation: string, element: BaseNode) => {  
    // Checks for insertion
    if (!equation) return;
    if (!generateKatex(equation)?.isValid) return;

    Transforms.setNodes(
      editor,
      {
        equation: equation,
      } as Partial<BaseNode>,
      {
        at: ReactEditor.findPath(editor as ReactEditor, element),
      }
    );
};
