/* eslint-disable */
// TODO: Highlighter here is not set and then passed as a dead prop to children

// Libraries
import React, { useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Local
import { DocumentContext } from '../../DocumentContext';
import Menu from './Menu';
import Empty from './Empty';
import Document from './Document';
import SortedView from './SortedView';

const Viewer = ({ client }) => {
  const theme = useTheme();
  const context = useContext(DocumentContext);

  // eslint-disable-next-line no-unused-vars
  const [highlighter, setHighlighter] = useState('');

  return (
    <Box
      sx={{
        display: context.tab !== 'View' && 'none',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Menu client={client} />
      {context.paper === null && <Empty />}
      {context.paper && !context.sortedView && (
        <Document highlighter={highlighter} client={client} />
      )}
      {context.paper && context.sortedView && (
        <SortedView showSelection={context.showSelection} />
      )}
    </Box>
  );
};

export default Viewer;
