import React from 'react';
import { Card, CardMedia } from '@mui/material';

const Feature = ({ gif, isLeft }) => (
  <Card
    elevation={0}
    sx={{
      display: 'flex',
      justifyContent: isLeft ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      flex: '1 1 auto',
      height: '100%',
      mr: isLeft ? '10px' : '0px',
      ml: isLeft ? '0px' : '10px',
    }}
  >
    <CardMedia
      component="img"
      src={gif}
      sx={{
        maxWidth: '700px',
        borderRadius: '5px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    />
  </Card>
);

export default Feature;
