// Libraries
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { throttle } from 'lodash';
import { Subscription } from '@apollo/client/react/components';
import moment from 'moment';
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

// Local
import CountSelection from './CountSelection';
import { DocumentContext } from '../DocumentContext';
import { DOCUMENT_SAVED } from '../../Queries';
import { getReferences } from '../Editor/Utilities/getReferences';
import { wordCount } from '../Utilities/wordCount';

/**
 * It renders our footer
 * Display word count, last saved and who is writing
 */

const Footer = () => {
  const context = useContext(DocumentContext);
  const [referenceCount, setReferenceCount] = useState(0);
  const [throttledWordCount, setThrottledWordCount] = useState(0);

  // Define a memoized version of the function that updates reference count
  const throttledGetReferences = useCallback(
    throttle(
      () => {
        const newReferenceCount = getReferences(context.editorRef).length;
        setReferenceCount(newReferenceCount);
      },
      1000,
      { trailing: true }
    ),
    [context.editorRef]
  );

  // Define a memoized version of the function that updates word count
  const updateWordCount = useCallback(
    throttle(
      () => {
        const newWordCount = wordCount(context.value);
        setThrottledWordCount(newWordCount);
      },
      1000,
      { trailing: true }
    ),
    [context.value]
  );

  useEffect(() => {
    if (context.editorRef) {
      throttledGetReferences();
    }
    // Update word count on context.value change
    updateWordCount();

    return () => {
      throttledGetReferences.cancel();
      updateWordCount.cancel();
    };
  }, [
    context.editorRef,
    context.value,
    throttledGetReferences,
    updateWordCount,
  ]);

  const calcUser = (data) => {
    // return data !== undefined
    //   ? `${data.documentSaved.response.lastSavedBy} ${moment(
    //       parseInt(data.documentSaved.response.time)
    //     ).fromNow()}`
    //   : `${context.lastSavedBy} ${moment(context.time).fromNow()}`;

    return data !== undefined
      ? moment(parseInt(data.documentSaved.response.time)).fromNow()
      : moment(context.time).fromNow();
  };

  return (
    <Subscription
      subscription={DOCUMENT_SAVED}
      variables={{ doc_id: context.documentId }}
    >
      {({ data }) => (
        <>
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: { xs: '0px', sm: '35px' },
              display: !context.loadingRequest && 'none',
            }}
          >
            <LinearProgress />
          </Box>
          <Stack
            className="footer"
            sx={{
              paddingLeft: '10px',
              boxSizing: 'border-box',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTop: (theme) =>
                theme.palette.mode === 'dark'
                  ? '1px solid #333333'
                  : '1px solid #d4d4d4',
              display: { xs: 'none', sm: 'flex' },
              height: '35px',
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
            }}
            direction="row"
            spacing={2}
          >
            <Stack direction="row" spacing={2}>
              <Tooltip
                title={
                  context.readOnly
                    ? 'You can only read'
                    : 'You can edit this note'
                }
              >
                <Typography
                  noWrap
                  sx={{
                    display: context.readOnly ? 'flex' : 'none',
                    fontSize: '12px',
                    cursor: 'default',
                    alignItems: 'center',
                  }}
                >
                  <AdminPanelSettingsOutlinedIcon
                    fontSize="small"
                    sx={{
                      marginRight: '5px',
                    }}
                  />
                  Read-only
                </Typography>
              </Tooltip>
              <Tooltip title="Based on all individual words">
                <Typography
                  noWrap
                  sx={{
                    fontSize: '12px',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ManageSearchOutlinedIcon
                    fontSize="small"
                    sx={{
                      marginRight: '5px',
                    }}
                  />
                  {throttledWordCount}{' '}
                  {throttledWordCount === 1 ? 'word' : 'words'}
                </Typography>
              </Tooltip>
              <Tooltip title="Total references in note">
                <Typography
                  noWrap
                  sx={{
                    fontSize: '12px',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StyleOutlinedIcon
                    fontSize="small"
                    sx={{
                      marginRight: '5px',
                    }}
                  />
                  {referenceCount}{' '}
                  {referenceCount === 1 ? 'reference' : 'references'}
                </Typography>
              </Tooltip>
              <CountSelection />
              <Tooltip title="Synced">
                <Typography
                  noWrap
                  sx={{
                    fontSize: '12px',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}
                >
                  <SyncOutlinedIcon
                    fontSize="small"
                    sx={{
                      marginRight: '5px',
                    }}
                  />
                  {calcUser(data)}
                </Typography>
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Tooltip
                title={
                  context.loadingRequest
                    ? 'Processing request'
                    : 'Waiting for actions'
                }
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: '12px',
                    cursor: 'default',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: '10px',
                    '&::first-letter': { textTransform: 'capitalize' },
                  }}
                >
                  {context.loadingRequest ? (
                    <CircularProgress
                      sx={{
                        fontSize: '15px',
                        marginRight: '5px',
                      }}
                      size={15}
                    />
                  ) : (
                    <CheckOutlinedIcon
                      sx={{
                        fontSize: '15px',
                        marginRight: '5px',
                        display:
                          context.loadingRequest ||
                          context.loadingMessage === ''
                            ? 'none'
                            : 'block',
                      }}
                    />
                  )}
                  {context.loadingMessage}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        </>
      )}
    </Subscription>
  );
};

export default Footer;
