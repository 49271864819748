import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  Box,
  Stack,
  Alert,
  Typography,
  Button,
  TextField,
  Link,
} from '@mui/material';

// Local
import { notify } from '../../Common/notify';
import { generateKey, revokeKey, getKey } from '../Requests';

const ApiKey = ({ client }) => {
  const [apiKey, setApiKey] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      const { status, response, message } = await getKey(client);

      if (status === 'success') {
        setApiKey(response);
        setMessage(message);
      } else {
        setMessage(message);
      }
    }

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px',
        marginBottom: '50px',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
        }}
      >
        Developer API
      </Typography>
      <Stack
        direction="column"
        sx={{
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: '10px',
          }}
        >
          Your API Key:
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: '10px',
          }}
        >
          You will be able to access select NLP AI endpoints directly,
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Documentation',
                action: 'Go to documentation',
                label: 'User selected to go documentation',
              });

              window.open(
                `${process.env.REACT_APP_PLATFORM_DOCUMENTATION}/documentation/community/developers`,
                '_blank',
                'noreferrer'
              );
            }}
            sx={{
              marginLeft: '5px',
              cursor: 'pointer',
              textTransform: 'none',
            }}
          >
            see documentation.
          </Link>
        </Typography>
        <TextField disabled={true} value={apiKey ? apiKey : 'None'} />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          marginBottom: '10px',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(apiKey);
            notify('Copied API Key');
          }}
        >
          Copy
        </Button>
        <Button
          variant="outlined"
          onClick={async () => {
            const { status, response, message } = await generateKey(client);

            if (status === 'success') {
              setApiKey(response);
              setMessage(message);
            } else {
              setMessage(message);
            }
          }}
        >
          Generate
        </Button>
        <Button
          variant="outlined"
          onClick={async () => {
            const { status, message } = await revokeKey(client);

            if (status === 'success') {
              setApiKey('');
              setMessage(message);
            } else {
              setMessage(message);
            }
          }}
        >
          Revoke
        </Button>
      </Stack>
      <Alert severity="info">{message}</Alert>
    </Box>
  );
};

export default ApiKey;
