import { InitialValue } from '../Common/InitialValue';

export const initialState = {
  assist: false, // Whether to show the AI assist
  summaries: [], // Used for summarizing papers
  historyIndex: 0, // The index position of actionHistory
  actionHistory: [], // recorded steps and metadata, default on discover
  socket: null,
  loading: true, // Shows the loading screen instead of editor
  loadingRequest: false, // Used to signify loading colors in footer
  loadingMessage: '', // The message to pair up with the loading
  manuscriptLibrary: [], // The note's direct library IDs
  scroll: 0, // Used to close hover & dropdown menus on scroll
  editorRef: null, // Used to access editor commands outside of slate context
  documentId: null, // The document ID derived from URL
  title: 'Example Paper', // The document title, also used for tab title
  fontSize: 16, // Document font size
  authorId: '', // The author's user ID
  authorName: '', // The author's name
  readOnly: false, // For restricting write access and save features
  togglePanel: false, // For mobile view removal of the panel
  value: InitialValue, // The slate editor state value
  collaborators: [], // The other users working on the document
  lastSavedBy: '', // The user who saved the document last
  time: '', // The time the document was last saved
  referenceCount: 0, // Reference count of the text editor
  onlyCited: false, // Whether to show bookmarks that are cited
  onlyManuscriptLibrary: false, // Whether to show bookmarks that are in note library
  searchLibrary: false, // Whether to use discovery on library IDs
  bookmarks: [], // The user's library content
  user: null, // The user passed in from withAuth
  tab: 'Discover', // Either, Discover, Library, PDF, View
  searchTerm: '', // The search terms in the search field
  rankingDetails: {
    rankingIdValue: '',
    rankingIdField: '',
    rankingIdType: '',
    rankingCollection: '',
  }, // The latest accessed paper from content database
  keywords: [], // The current keywords tag list
  keywordsBasedOnSearch: [], // The keywords suggested on search
  pageCount: 1, // The pagination page count used to divide results
  currentPage: 0, // The page to remember which we are on
  searchResults: [], // The search results from our custom search engine
  highlightSelection: '', // The currently selected text from the editor
  showSelection: false, // Whether to show the selected text in read view
  sortedView: false, // Whether to show the highlight sentences in order
  annotatorView: false, // Whether to show the citation annotation accordion
  generatorView: false, // Whether to show the citation generation accordion
  paperIds: [], // The collection of paper IDs retrieved during search
  rerankingScores: [], // The reranking scores of paper IDs ordered by index
  prefetchingScores: [], // The prefetching scores of paper IDs ordered by index
  toggleTabMenu: true, // Menu toggles within the explorer for more space
  paper: null, // The current paper being viewed
  isMobile: false, // Whether user is on mobile phone
  isIdle: false,
  scrollToSentenceId: '',
  view: {
    // For managing the view
    edit: false,
    explorer: false,
    dual: true,
  },
  showNumbering: false, // For editor citations display numbers
  autoAppearanceOrder: false, // For editor citations and reference syncing
  serviceWarnings: {
    content: '',
  },
  generatorContextText: '',
  generatorPaperId: { label: '', id: 0 },
  generatorKeywords: [],
  generatorSentences: [],
  annotatorPaperId: null,
  annotatorX: [],
  annotatorY: [],
  annotatorPrecision: 0,
  annotatorRecall: 0,
  annotatorSufficiency: 0,
  annotatorEfficiency: 0,
};

// Reducer function to handle state updates
export function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_PROPERTY':
      return { ...state, [action.property]: action.value };
    // Handle other actions here
    default:
      return state;
  }
}
