// Libraries
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'; // Google analytics
import { createRoot } from 'react-dom/client';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  Observable,
  ApolloLink,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

// Local
import { shouldInitializeAnalytics } from './Common/CookieInitializer';
import { ColorModeContext } from './DarkMode';
import withAuthentication from './withAuthentication';
import Layout from './Common/Layout';
import ErrorBoundary from './ErrorBoundary';
import Homepage from './Homepage';
import Login from './Login';
import SwitchAuthenticate from './Switch';
import Zotero from './Zotero';
// import Register from './Register';
import Contact from './Contact';
import Team from './Team';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Documents from './Documents';
// import Modular from './Modular';
import Document from './Document';
import Account from './Account';
// import Recover from './Recover';
// import Reset from './Reset';
// import ValidateEmail from './ValidateEmail';
// import ResendConfirmation from './ResendConfirmation';
import HttpsRedirect from 'react-https-redirect';
import Constants from './constants';
// import RegistrationConfirmation from './RegistrationConfirmation';
import CookieConsentModal from './CookiesModal';

// Configs
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';

const cache = new InMemoryCache({
  possibleTypes: {
    LoginResult: ['LoginResult'],
    RegisterResult: ['RegisterResult'],
  },
  addTypename: false, // stop adding it in queries or mutations
});

const request = (operation) => {
  operation.setContext({
    headers: {
      authorization: Constants.TOKEN,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_API_GATEWAY_SOCKET}`,
  options: {
    reconnect: true,
  },
});

// Custom progress of uploads
const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri, options) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

// Create an http link:
const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_GATEWAY}`,
  credentials: 'include',
  fetch: customFetch,
  maxBodyLength: 80 * 1024 * 1024, // 80mb limit
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log('GraphQL Error', graphQLErrors);
      }
      if (networkError) {
        console.log('Network Error', networkError);
      }
    }),
    requestLink,
    splitLink,
  ]),
  cache,
});

// All protected routes
const AuthenticatedHomepage = withAuthentication(Homepage);
const AuthenticatedTeam = withAuthentication(Team);
const AuthenticatedLogin = withAuthentication(Login);
const AuthenticatedSwitch = withAuthentication(SwitchAuthenticate);
// const AuthenticatedRecover = withAuthentication(Recover);
// const AuthenticatedReset = withAuthentication(Reset);
// const AuthenticatedRegister = withAuthentication(Register);
// const AuthenticatedRegistrationConfirmation = withAuthentication(
//   RegistrationConfirmation
// );
// const AuthenticatedValidation = withAuthentication(ValidateEmail);
// const AuthenticatedResend = withAuthentication(ResendConfirmation);
const AuthenticatedContact = withAuthentication(Contact);
const AuthenticatedTerms = withAuthentication(TermsOfService);
const AuthenticatedPolicy = withAuthentication(PrivacyPolicy);
const AuthenticatedAccount = withAuthentication(Account);
const AuthenticatedManuscripts = withAuthentication(Documents);
const AuthenticatedZotero = withAuthentication(Zotero);
const AuthenticatedManuscript = withAuthentication(Document);
// const AuthenticatedModular = withAuthentication(Modular);

const Root = ({ client }) => (
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <AuthenticatedHomepage
              client={client}
              title="Endoc | Writing and Research Assisted"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/team"
        element={
          <Layout>
            <AuthenticatedTeam
              client={client}
              title="Endoc | Team"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <Layout>
            <AuthenticatedLogin title="Endoc | Login" isPublic={true} />
          </Layout>
        }
      />
      <Route
        path="/authenticate"
        element={
          <AuthenticatedSwitch
            title="Endoc | Switch Authentication"
            isPublic={true}
          />
        }
      />
      {/* <Route
        path="/recover"
        element={
          <Layout>
            <AuthenticatedRecover
              client={client}
              title="Endoc | Recover"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/reset/:token"
        element={
          <Layout>
            <AuthenticatedReset
              client={client}
              title="Endoc | Reset"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/register"
        element={
          <Layout>
            <AuthenticatedRegister
              client={client}
              title="Endoc | Register"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/registerconfirmation"
        element={
          <Layout>
            <AuthenticatedRegistrationConfirmation
              client={client}
              title="Endoc | Register Confirmation"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/validate/:token"
        element={
          <Layout>
            <AuthenticatedValidation
              client={client}
              title="Endoc | Confirm Email"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/resend"
        element={
          <Layout>
            <AuthenticatedResend
              client={client}
              title="Endoc | Resend Confirmation Email"
              isPublic={true}
            />
          </Layout>
        }
      /> */}
      {process.env.REACT_APP_ETH === 'true' && (
        <Route
          path="/contact"
          element={
            <Layout>
              <AuthenticatedContact
                client={client}
                title="Endoc | Contact"
                isPublic={true}
              />
            </Layout>
          }
        />
      )}
      <Route
        path="/terms-of-service"
        element={
          <Layout>
            <AuthenticatedTerms
              client={client}
              title="Endoc | Terms of Service"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Layout>
            <AuthenticatedPolicy
              client={client}
              title="Endoc | Privacy Policy"
              isPublic={true}
            />
          </Layout>
        }
      />
      <Route
        path="/account/:token?"
        element={
          <AuthenticatedAccount
            client={client}
            title="Endoc | Account"
            isPublic={false}
          />
        }
      />
      <Route
        path="/zotero"
        element={
          <AuthenticatedZotero
            client={client}
            title="Endoc | Zotero Authentication"
            isPublic={false}
          />
        }
      />
      <Route
        path="/notes"
        element={
          <AuthenticatedManuscripts
            client={client}
            title="Endoc | Notes"
            isPublic={false}
          />
        }
      />
      {/* <Route
        path="/modular/:docId"
        element={<AuthenticatedModular client={client} />}
      /> */}
      <Route
        path="/note/:doc_id"
        element={<AuthenticatedManuscript client={client} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
);

// Root App
const App = () => {
  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const currMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('mode', currMode);
          return currMode;
        });
      },
    }),
    []
  );

  const borderColor = mode === 'light' ? '#c4c4c5' : '#484c50';

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'capitalize',
                color: mode === 'light' ? 'black' : 'white',
                borderColor: mode === 'light' ? 'black' : 'white',
                border: `1px solid ${borderColor}`,
                // Ensure the border does not change on hover
                ':hover': {
                  borderColor: mode === 'light' ? 'black' : 'white',
                },
              },
            },
          },
          MuiToggleButton: {
            styleOverrides: {
              root: {
                textTransform: 'capitalize',
                color: mode === 'light' ? 'black' : 'white',
                borderColor: mode === 'light' ? 'black' : 'white',
                border: `1px solid ${borderColor}`,
                '&.Mui-selected': {
                  // backgroundColor: mode === "light" ? "white" : "black",
                  color: mode === 'light' ? 'black' : 'white',
                },
                ':hover': {
                  borderColor: mode === 'light' ? 'black' : 'white',
                  backgroundColor:
                    mode === 'light'
                      ? 'rgba(0, 0, 0, 0.04)'
                      : 'rgba(255, 255, 255, 0.08)',
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                filter: 'grayscale(100%)', // Apply grayscale filter
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textTransform: 'capitalize',
                color: mode === 'light' ? 'black' : 'white', // Sets the link color based on the theme mode
                textDecorationColor: mode === 'light' ? 'black' : 'white', // Underline color
              },
            },
          },
        },
      }),
    [mode]
  );

  useEffect(() => {
    if (localStorage.getItem('mode')) {
      setMode(localStorage.getItem('mode'));
    }
  }, []);

  useEffect(() => {
    document.body.style.background = mode === 'light' ? 'white' : 'black';
  });

  useEffect(() => {
    // Google Analytics 4
    if (
      process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID &&
      shouldInitializeAnalytics()
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    } else {
      console.warn('Analytics is disabled.');
    }
  }, []);

  return (
    <HttpsRedirect>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <Root client={client} />
              <CookieConsentModal />
              <ToastContainer autoClose={2000} draggable={false} theme={mode} />
            </ApolloProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HttpsRedirect>
  );
};

createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
