import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

const withCitations = (editor: ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;

  // This is a custom editor that allows for citation nodes to be inline
  editor.isInline = (element) => {
    return (element as CitationNode).type === 'citation'
      ? true
      : isInline(element);
  };

  // This is a custom editor that allows for citation nodes to be void
  editor.isVoid = (element) => {
    return (element as CitationNode).type === 'citation'
      ? true
      : isVoid(element);
  };

  // This is a custom editor that allows for citation nodes to be markable void
  // markable void elements are void elements that can be marked with a selection
  editor.markableVoid = (element) => {
    return (element as CitationNode).type === 'citation'
      ? true
      : markableVoid(element);
  };

  return editor;
};

export default withCitations;
