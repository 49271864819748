// Libraries
import React from 'react';

// Local
import Reference from './Reference';
import Citation from './Citation';
import Figure from './Figure';
import Katex from './Katex';
import Hyperlink from './Hyperlink';
import InlineKatex from './InlineKatex';

const Element = ({ attributes, children, element, client, modular }) => {
  const style = { textAlign: element.align, margin: 0 };

  switch (element.type) {
    case 'hyperlink':
      return (
        <Hyperlink attributes={attributes} element={element}>
          {children}
        </Hyperlink>
      );
    case 'bulleted-list':
      return (
        <ul style={{ ...style, listStylePosition: 'inside' }} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'heading-three':
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case 'heading-four':
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={{ ...style, listStylePosition: 'inside' }} {...attributes}>
          {children}
        </ol>
      );
    case 'reference':
      return (
        <Reference
          attributes={attributes}
          element={element}
          client={client}
          modular={modular}
        >
          {children}
        </Reference>
      );
    case 'citation':
      return (
        <Citation
          attributes={attributes}
          element={element}
          client={client}
          modular={modular}
        >
          {children}
        </Citation>
      );
    case 'figure':
      return (
        <Figure
          attributes={attributes}
          element={element}
          client={client}
          modular={modular}
        >
          {children}
        </Figure>
      );
    case 'katex':
      return (
        <Katex attributes={attributes} element={element}>
          {children}
        </Katex>
      );
    case 'inline-katex':
      return (
        <InlineKatex attributes={attributes} element={element}>
          {children}
        </InlineKatex>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export default Element;
