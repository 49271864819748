// Libraries
import React from 'react';
import Alert from '@mui/material/Alert';

const Warning = ({ section }) => (
  <Alert
    severity="info"
    sx={{
      marginBottom: '10px',
      width: '100%',
      boxSizing: 'border-box',
    }}
  >
    {section}
  </Alert>
);

export default Warning;
