// Libraries
import React, { useContext, useState } from 'react';
import {
  Box,
  List,
  Alert,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Local
import { DocumentContext } from '../../../../DocumentContext';
import { ModularContext } from '../../../../../Modular/ModularContext';
import { notify } from '../../../../../Common/notify';
import './Summaries.css';

const Summaries = ({ modular }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [open, setOpen] = useState(false);
  const [noSummary, setNoSummary] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const borderCol = (theme) =>
    theme.palette.mode === 'dark' ? '1px solid #333333' : '1px solid #d4d4d4';

  return (
    <Box
      sx={{
        width: '100%',
        display: !modular
          ? context.summaries.length === 0 && 'none'
          : context.memsumSummaries.length === 0 && 'none',
        marginBottom: '10px',
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          border: borderCol,
        }}
      >
        <ListItemIcon>
          <ListOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Summaries" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {!modular ? (
          <List
            sx={{
              width: '100%',
              padding: '10px',
              boxSizing: 'border-box',
              borderBottom: borderCol,
              borderLeft: borderCol,
              borderRight: borderCol,
            }}
            aria-label="contacts"
          >
            {context.summaries.map((summary, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ marginBottom: '10px' }}
              >
                <ListItemButton
                  dense
                  sx={{
                    border: borderCol,
                  }}
                  onClick={() => {
                    // Elements must have unique IDs, not assigned elsewhere, otherwise scrollIntoView won't work
                    const id = `${summary.tag}#${summary.section_id}-${
                      summary.paragraph_id
                    }-${summary.sentence_id}`;

                    const element = document.getElementById(id);

                    if (element) {
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                      });

                      element.classList.add('animate');

                      setTimeout(() => {
                        element.classList.remove('animate');
                      }, 4000);
                    } else {
                      notify('Could not find matching sentence!');
                    }
                  }}
                >
                  <ListItemText primary={summary.sentence_text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          // MODULAR HERE
          <List
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
            aria-label="contacts"
          >
            {context.memsumSummaries.length > 0 ? (
              context.memsumSummaries.map((summary, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ width: '100%', marginBottom: '10px' }}
                >
                  <ListItemButton
                    dense
                    sx={{
                      border: '1px solid lightgrey',
                    }}
                    onClick={() => {
                      // highlight the summary sentence in read window

                      const element = document.getElementById(
                        `${summary.section_id}${summary.paragraph_id}${summary.sentence_id}`
                      );

                      if (element) {
                        element.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'center',
                        });
                        element.style.cssText =
                          'background-color: lightcoral; border: 1px solid lightgrey; font-size: 14px;';
                      } else {
                        notify('Could not find matching sentence!');
                      }
                    }}
                  >
                    <ListItemIcon>
                      <StarIcon sx={{ color: 'lightcoral' }} />
                    </ListItemIcon>
                    <ListItemText primary={summary.sentence_text} />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Collapse
                timeout="auto"
                unmountOnExit
                in={noSummary}
                sx={{
                  width: '90%',
                  marginTop: '10px',
                }}
              >
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setNoSummary(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  This paper has no body text, no summary generated.
                </Alert>
                <Typography>why not rendering?????</Typography>
              </Collapse>
            )}
          </List>
        )}
      </Collapse>
    </Box>
  );
};

export default Summaries;
