/* eslint-disable react/display-name */

// Library
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';

// Local
import { GET_CURRENT_USER, NEW_JWT_FROM_REFRESHTOKEN } from './Queries';
import Constants from './constants';

/**
 * If a valid JWT is present then details are passed
 * If an invalid or missing JWT, then we get details from RefreshToken
 * If refreshToken is missing or invalid, then the user most likely logged out
 * Or is returning after a long time
 */

const withAuthentication = (Component) => {
  return (props) => {
    const { isPublic = false } = props;
    const { data, loading, refetch } = useQuery(GET_CURRENT_USER);
    const {
      data: refreshData,
      loading: refreshLoading,
      refetch: refreshRefetch,
    } = useQuery(NEW_JWT_FROM_REFRESHTOKEN, {
      skip: !loading && data?.authenticateUser?.status === 'success',
      fetchPolicy: 'network-only',
    });

    if (loading || refreshLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            overflow: 'hidden',
            background: 'background.paper',
          }}
        />
      );
    }

    if (data?.authenticateUser?.status === 'success') {
      return (
        <Component
          {...props}
          refetch={refetch}
          user={data.authenticateUser.response}
          session={data.authenticateUser}
        />
      );
    }

    if (refreshData?.newJWTFromRefreshToken?.status === 'success') {
      Constants.TOKEN = refreshData.newJWTFromRefreshToken.response.token;
      Constants.EXPIRY = refreshData.newJWTFromRefreshToken.response.jwtExpiry;

      return (
        <Component
          {...props}
          refetch={refreshRefetch}
          user={refreshData.newJWTFromRefreshToken.response.user}
          session={refreshData.newJWTFromRefreshToken}
        />
      );
    }

    return isPublic ? (
      <Component {...props} refetch={refetch} />
    ) : (
      <Navigate to="/login" replace />
    );
  };
};

export default withAuthentication;
