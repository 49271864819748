// Libraries
import React, { useContext } from 'react';
import { Divider, Tooltip, IconButton, Stack, useTheme } from '@mui/material';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

// Local
import { DocumentContext } from '../DocumentContext';

const Middle = () => {
  const context = useContext(DocumentContext);
  const theme = useTheme();

  return (
    <Divider
      orientation="vertical"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: context.view.dual ? '1px' : '0px',
        background: theme.palette.mode === 'light' ? 'whitesmoke' : '#171717',
      }}
    >
      <Stack
        direction="column"
        spacing={0}
        sx={{
          position: 'relative',
          left: context.view.edit
            ? '-10px'
            : context.view.explorer
              ? '10px'
              : '0px',
        }}
      >
        <Tooltip title="Expand left" placement="top">
          <IconButton
            aria-label="expand left"
            onClick={() =>
              context.updateContext('view', {
                edit: false,
                explorer: true,
                dual: false,
              })
            }
            sx={{
              display: context.view.explorer && 'none',
              zIndex: 1200,
            }}
          >
            <KeyboardDoubleArrowLeftOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Expand right"
          placement={context.view.explorer ? 'top' : 'bottom'}
        >
          <IconButton
            aria-label="expand right"
            onClick={() =>
              context.updateContext('view', {
                edit: true,
                explorer: false,
                dual: false,
              })
            }
            sx={{
              display: context.view.edit && 'none',
              zIndex: 1200,
            }}
          >
            <KeyboardDoubleArrowRightOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Expand both ways">
          <IconButton
            aria-label="expand equally both ways"
            onClick={() =>
              context.updateContext('view', {
                edit: false,
                explorer: false,
                dual: true,
              })
            }
            sx={{
              display:
                (context.view.dual && 'none') || (context.isMobile && 'none'),
              zIndex: 1200,
            }}
          >
            <SwapHorizOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Divider>
  );
};

export default Middle;
