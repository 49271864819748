// Local
import { notify } from '../../../Common/notify.js';

/**
 * Tries to add a text span to X, the existing annotations of influenced content
 * Insertion occurs only if the text span meets multiple criteria (listed in function)
 * Returns either X or X with the text span
 *
 * @param {object} newSpan - the text span to be inserted
 * @param {array} existingSpans - the existing annotations of influenced content (aka X)
 * @param {array} paragraphCitedIds - ids of all papers cited in the paragraph from which newSpan was selected. paper ids are in format f"{collection}_{id_value}", e.g. "PMCOA_1243521"
 * @returns {array} - the (potentially updated) annotations of influenced content
 */

const updateAnnotatorX = (newSpan, existingSpans, paragraphCitedIds) => {
  // check: newSpan has nonempty text
  if (!newSpan.text.trim()) {
    notify('Annotations must be nonempty texts.');
    return [];
  }

  // check: newSpan comprises text from a single paragraph node
  if (newSpan.type.length !== 1 || newSpan.type[0] !== 'paragraph') {
    notify('Each note annotation must be contained within a single paragraph.');
    return [];
  }

  // check: newSpan is from a paragraph that cites current paper
  if (paragraphCitedIds.indexOf(newSpan.paperId) === -1) {
    notify('The note paragraph being annotated must cite the paper above.');
    return [];
  }

  // if part was selected in reverse, swap anchor and focus
  const sentenceDiff = newSpan.focus.path[1] - newSpan.anchor.path[1];
  const characterDiff = newSpan.focus.offset - newSpan.anchor.offset;
  if (sentenceDiff < 0 || (sentenceDiff === 0 && characterDiff < 0)) {
    [newSpan.anchor, newSpan.focus] = [newSpan.focus, newSpan.anchor];
  }

  // consider appending part to existing parts
  const spans = [newSpan, ...existingSpans];

  // check: all parts are from the same note paragraph
  const anchorParagraphLocations = new Set(spans.map((_) => _.anchor.path[0]));
  const focusParagraphLocations = new Set(spans.map((_) => _.focus.path[0]));
  if (
    anchorParagraphLocations.size !== 1 ||
    focusParagraphLocations.size !== 1
  ) {
    notify('Annotate one note paragraph at a time.');
    return [];
  }

  // sort parts by appearance
  spans.sort((a, b) => {
    if (a.anchor.path[1] !== b.anchor.path[1]) {
      return a.anchor.path[1] - b.anchor.path[1];
    }
    return a.anchor.offset - b.anchor.offset;
  });

  return spans;
};

export default updateAnnotatorX;
