// Libraries
import React, { useReducer, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import { Box, Stack } from '@mui/material';

// Local
import {
  GET_DOCUMENT,
  SAVE_DOCUMENT,
  GET_BOOKMARKS,
  GET_LOCKED,
} from '../Queries';
import { PAGINATED_SEARCH } from './Queries/nlp';
import { DocumentProvider } from './DocumentContext';
import { useSocket } from './useWithSocket';
import { reducer, initialState } from './reducer';
import { permissions } from './Utilities/permissions';
import { buildMetadata, mergeWithMetadata } from './Requests/Library';
import { notify } from '../Common/notify';
import networkDetector from '../Common/NetworkDetector';
import Loading from './Loading';
import TextEditor from './Editor';
import Middle from './Middle';
import Explorer from './Explorer';
import Idle from './Idle';
import Panel from './Panel';
import Footer from './Footer';
import Coach from '../Coach';

const debouncedSave = debounce((saveFn) => saveFn(), 1000);

const Document = ({ client, user }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { doc_id: docId } = useParams();

  const navigate = useNavigate();

  const [getLocked] = useLazyQuery(GET_LOCKED, {
    fetchPolicy: 'network-only',
  });

  const [docServiceGetDoc] = useLazyQuery(GET_DOCUMENT, {
    fetchPolicy: 'network-only',
  });

  const [saveDocument] = useMutation(SAVE_DOCUMENT, {
    fetchPolicy: 'network-only',
  });

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const [getBookmarks] = useLazyQuery(GET_BOOKMARKS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getBookmarks }) => {
      if (getBookmarks.status === 'success') {
        const paperList = buildMetadata(getBookmarks.response);

        paginatedSearch({
          variables: {
            paper_list: paperList.map((paper) => {
              return {
                collection: paper.collection,
                id_field: paper.id_field,
                id_type: paper.id_type,
                id_value: paper.id_value,
              };
            }),
          },
          onCompleted: ({ paginatedSearch }) => {
            const bookmarks = mergeWithMetadata(
              paginatedSearch.response,
              paperList
            );

            updateContext('bookmarks', bookmarks);
            updateContext('loading', false);
          },
        });
      }
    },
  });

  const updateContext = (property, value) => {
    dispatch({ type: 'UPDATE_PROPERTY', property, value });

    // Save document when user types
    if (property === 'value') {
      // 1000 milliseconds = 1 second
      debouncedSave(save);
    }
  };

  const save = useCallback(() => {
    if (!state.loading) {
      saveDocument({
        variables: {
          id: state.documentId,
          title: state.title,
          content: JSON.stringify(state.value),
          name: user.name,
          numbering: state.showNumbering,
          orderByAppearance: state.autoAppearanceOrder,
        },
      });
    }
  }, [
    state.documentId,
    state.title,
    state.value,
    state.showNumbering,
    state.autoAppearanceOrder,
  ]);

  useEffect(() => {
    let saveInterval = null;

    // 60,000 milliseconds = 1 minute
    if (state.loading === false) {
      saveInterval = setInterval(save, 60000);
    }

    // if idle stop saving
    if (state.isIdle) {
      clearInterval(saveInterval);
    }

    return () => clearInterval(saveInterval);
  }, [state.loading, state.isIdle]);

  const recordActionHistory = (metadata) => {
    const history = state.actionHistory;

    // Don't let it grow beyond 10 items
    if (history.length === 10) {
      history.shift();
    }

    // Add item to end of array
    const newArrayLength = history.push(metadata);

    // Insert into the current place in array
    updateContext('historyIndex', newArrayLength - 1);
    updateContext('actionHistory', history);
  };

  const handleResize = () => {
    const { dual } = state.view;

    if (window.innerWidth <= 900) {
      updateContext('isMobile', true);
      updateContext('togglePanel', false);

      if (dual) {
        updateContext('view', {
          edit: true,
          explorer: false,
          dual: false,
        });
      }
    } else {
      updateContext('isMobile', false);
      updateContext('togglePanel', false);
    }
  };

  useEffect(() => {
    // Create a debounced version of handleResize
    const debouncedHandleResize = debounce(handleResize, 10); // 10ms

    window.addEventListener('resize', debouncedHandleResize);

    // Call it once initially in case the initial state needs adjustment
    debouncedHandleResize();

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  useEffect(() => {
    let socket = null;

    // Get non-sensitive data first about the document
    getLocked({
      variables: {
        doc_id: docId,
      },
      onCompleted: (data) => {
        const lockResponse = data.docServiceGetDoc.response;

        if (lockResponse.locked && lockResponse.lockedBy._id !== user._id) {
          navigate('/notes');
        }

        docServiceGetDoc({
          variables: {
            doc_id: docId,
          },
          onCompleted: ({ docServiceGetDoc }) => {
            if (docServiceGetDoc.status === 'success') {
              const {
                _id,
                user: author,
                collaborators,
                content,
                title,
                library,
                authorName,
                lastSavedBy,
                numbering,
                orderByAppearance,
                time,
              } = docServiceGetDoc.response;

              socket = useSocket(user, docId);

              socket.on('connect', () => {
                updateContext('user', user);
                updateContext('documentId', _id);
                updateContext('title', title);
                updateContext('value', JSON.parse(content));
                updateContext('authorId', author);
                updateContext('authorName', authorName);
                updateContext('showNumbering', numbering);
                updateContext('autoAppearanceOrder', orderByAppearance);
                updateContext('time', time);
                updateContext('manuscriptLibrary', library);
                updateContext('collaborators', collaborators);
                updateContext('lastSavedBy', lastSavedBy);
                updateContext(
                  'readOnly',
                  permissions(user, author, collaborators)
                );

                getBookmarks();
              });

              socket.on('connect_error', () => {
                notify('Note synchronization down, please try again later');
                navigate('/notes');
              });

              socket.on('error', () => {
                notify('Sorry, there seems to be an issue locking the note');
                navigate('/notes');
              });

              socket.on('backup', () => {
                save();
              });

              socket.on('hello', () => {
                notify('Disconnected');
                navigate('/notes');
              });
            } else {
              notify('Could not access note, try again later');
              navigate('/notes');
            }
          },
        });
      },
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const renderLayout = () => {
    const { explorer, edit } = state.view;
    const style = {
      height: { xs: '100vh', sm: 'calc(100vh - 35px)' },
    };

    if (edit) {
      return (
        <Stack direction="row" sx={style}>
          <TextEditor client={client} modular={false} />
          <Middle />
        </Stack>
      );
    } else if (explorer) {
      return (
        <Stack direction="row" sx={style}>
          <Middle />
          <Explorer client={client} />
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" sx={style}>
          <TextEditor client={client} modular={false} />
          <Middle />
          <Explorer client={client} />
        </Stack>
      );
    }
  };

  const layout = useMemo(() => renderLayout(), [state.view]);

  return (
    <DocumentProvider value={{ ...state, updateContext, recordActionHistory }}>
      {state.loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minHeight: '100%',
            width: '100vw',
            overflow: 'hidden',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? 'background.paper' : '#171717',
            color: 'text.primary',
            position: 'absolute',
            padding: 0,
            margin: 0,
          }}
        >
          {layout}
          <Idle />
          <Coach target={'manuscript'} />
          <Panel client={client} user={user} />
          <Footer />
        </Box>
      )}
    </DocumentProvider>
  );
};

export default networkDetector(Document);
