import semanticDiscoveryGif from '../Assets/semantic_discovery.gif';
import keywordFilterGif from '../Assets/keyword_filter.gif';
import textAlign from '../Assets/text_align.gif';
// import generateCitationGif from '../Assets/generate_citation.gif';
import exportManuscriptGif from '../Assets/export_manuscript.gif';

export const features = [
  {
    gif: semanticDiscoveryGif,
    isGifLeft: true,
    title: 'Discover the most relevant papers',
    listItems: [
      'Enjoy our database of 140 million papers (updated regularly)',
      'Get our suggestions on appropriate papers to cite [1]',
      'Like a specific paper? Find others similar to it',
    ],
    readMore: {
      body: "We've trained our AI to recognize the papers that are most suitable as citations in a given text. When you select some text in your note and trigger discovery, our AI computes a score between your text and each paper in our database, and we identify the papers with the highest scores for you. This process is called citation recommendation, and our AI has been proven to achieve excellent performance [1].",
      refLink: 'https://link.springer.com/chapter/10.1007/978-3-030-99736-6_19',
      ref: '[1] N. Gu, Y. Gao, R.H.R. Hahnloser. Local Citation Recommendation with Hierarchical-Attention Text Encoder and SciBERT-based Reranking. ECIR 2022.',
    },
  },
  {
    gif: keywordFilterGif,
    isGifLeft: false,
    title: 'Finetune discovery with keywords',
    listItems: [
      'Save time by restricting discovery to papers with your keywords',
      'Need more flexibility? Our keyword filter understands basic boolean logic',
      'Try our automatically-detected keywords',
    ],
    readMore: {
      body: 'This is most useful when you are looking for something specific within our paper database. You can also use keywords to specify publication year, publication venue, author names, and more. Try combining keywords with basic boolean logic (AND, OR, NOT) for a more sophisticated discovery experience. Unsure of what keywords to use? Whenever you trigger discovery, we automatically identify the words that best represent the discovered papers, and will suggest them to you as keywords.',
      refLink: null,
      ref: null,
    },
  },
  {
    gif: textAlign,
    isGifLeft: true,
    title: 'Locate the content that matters within each paper',
    listItems: [
      "Get the paper's most informative sentences with our automatic highlights [3]",
      'Find the sentences that are most similar to your own notes',
    ],
    readMore: {
      body: "Get instant highlights of the papers you read. We have trained our AI to extract the sentences of each paper that are most representative of the overall paper, namely the sentences that contain high informational overlap with the paper's abstract. This AI is the best one around and its underlying system won the [2023 DocIU Cup Award](https://arxiv.org/abs/2310.06436), a competition on document understanding.",
      refLink: 'https://aclanthology.org/2022.acl-long.450/',
      ref: '[3] N. Gu, E. Ash, and R.H.R. Hahnloser. MemSum: Extractive Summarization of Long Documents using Multi-step Episodic Markov Decision Processes. ACL 2022.',
    },
  },
  // {
  //   gif: generateCitationGif,
  //   isGifLeft: false,
  //   title: 'Integrate papers into your notes with automatic citations',
  //   listItems: [
  //     'Using our AI, generate sentences that cite papers and fit citations in your notes',
  //     'Control the AI by telling it the reason for citing, the keywords to consider, the content to cite',
  //   ],
  //   readMore: {
  //     body: 'Citing your sources is an important part of scientific writing, but writing good citation sentences can be challenging. To assist you, we have trained our AI to generate a suitable citation sentence based on your note and the paper to be cited [4], and we encourage using the generated sentence as an initial draft. You can also control and help the AI perform better by telling it about why you are citing this paper at all (e.g. for backgrounding purposes) and any information you might want to emphasise, such as keywords and excerpts from the paper to be cited.',
  //     refLink: 'https://arxiv.org/abs/2211.07066',
  //     ref: "[4] Gu, Nianlong, and Richard HR Hahnloser. 'Controllable Citation Text Generation.' arXiv preprint arXiv:2211.07066 (2022).",
  //   },
  // },
  {
    gif: exportManuscriptGif,
    isGifLeft: false,
    title: 'Import and export your notes and papers with ease',
    listItems: [
      'Continue working with your notes and papers by importing them to Endoc',
      'Imported citations are automatically matched with the papers in our database',
      'Export your Endoc notes together with citations and references (.tex, .bib, .docx)',
      'Exported note citations in .docx are compatible with conntected Zotero accounts',
    ],
    readMore: null,
  },
];
