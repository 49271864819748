import { Editor, Point } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

/**
 * Find the point after the selected citation
 *
 * @param {object} editor - The current slate editor instance
 * @returns {Point} The point after the selected citation
 */

export const findPointAfterSelectedCitation = (
  editor: ReactEditor
): Point | null => {
  const { selection } = editor;

  if (selection) {
    const [selectedCitationNode] = Array.from(
      Editor.nodes(editor, {
        match: (n) => (n as CitationNode).type === 'citation',
        at: selection,
      })
    );

    if (selectedCitationNode) {
      const [, citationPath] = selectedCitationNode;
      const pointAfterCitation = Editor.after(editor, citationPath);
      return pointAfterCitation as Point;
    }
  }

  return null;
};
