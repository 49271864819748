// Libraries
import React, { useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Node } from 'slate';
import _ from 'lodash';
import {
  Box,
  Stack,
  TextField,
  Collapse,
  Autocomplete,
  Button,
  useTheme,
  IconButton,
} from '@mui/material';
import SearchRounded from '@mui/icons-material/SearchRounded';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DocumentationTooltip from '../../../../Common/DocumentationTooltip.js';

// Local
import {
  KEYWORD_SUGGESTIONS,
  KEYWORDS_BASED_ON_SEARCH,
  DOCUMENT_SEARCH,
  PAGINATED_SEARCH,
} from '../../../Queries/nlp.js';
import { DocumentContext } from '../../../DocumentContext';
import { generateSearchStats } from '../../../Utilities/generateSearchStats';
import { addScoresToSearchResults } from '../../../Utilities/addScoresToSearchResults.js';
import SearchKeywords from '../SearchKeywords';
import EditableChip from './EditableChip.js';

/**
 * Controls our search field
 *
 * @param {object} client: The apollo client method
 */

const SearchBox = ({ heightRef }) => {
  const theme = useTheme();
  const context = useContext(DocumentContext);

  const [collapsed, collapsedToggle] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');

  const [keywordSuggestions] = useLazyQuery(KEYWORD_SUGGESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ keywordSuggestions }) => {
      const options = _.uniq(keywordSuggestions.response);

      setSuggestions(options);
    },
  });

  const [keywordsBasedOnSearch] = useLazyQuery(KEYWORDS_BASED_ON_SEARCH);

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH);

  const [documentSearch] = useLazyQuery(DOCUMENT_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ documentSearch }) => {
      if (documentSearch.status === 'success') {
        context.updateContext('paperIds', documentSearch.response.paper_list);
        context.updateContext(
          'rerankingScores',
          documentSearch.response.reranking_scores
        );
        context.updateContext(
          'prefetchingScores',
          documentSearch.response.prefetching_scores
        );
        context.updateContext(
          'pageCount',
          documentSearch.response.paper_list.length / 10
        );

        context.updateContext('loadingRequest', false);
        context.updateContext(
          'loadingMessage',
          generateSearchStats(documentSearch.response.search_stats)
        );

        paginatedSearch({
          fetchPolicy: 'network-only',
          variables: {
            keywords: context.keywords,
            paper_list: documentSearch.response.paper_list.slice(0, 10),
          },
          onCompleted: ({ paginatedSearch }) => {
            const updatedSearchResults = addScoresToSearchResults(
              paginatedSearch.response, // in batches of 10 papers
              documentSearch.response.reranking_scores, // scores for all papers
              documentSearch.response.prefetching_scores, // prefetching scores for all papers
              1, // current page
              10 // items per page
            );

            context.updateContext('searchResults', updatedSearchResults);
            context.updateContext('currentPage', 1);

            // Toggle away from personal libraries
            context.updateContext('searchLibrary', false);
            context.updateContext('onlyManuscriptLibrary', false);
          },
        });

        keywordsBasedOnSearch({
          fetchPolicy: 'network-only',
          variables: {
            ranking_variable: context.searchTerm,
            keywords: context.keywords,
            paper_list: documentSearch.response.paper_list,
          },
          onCompleted: ({ keywordsBasedOnSearch }) => {
            context.updateContext(
              'keywordsBasedOnSearch',
              keywordsBasedOnSearch.response
            );
          },
        });
      } else {
        context.updateContext('loadingRequest', false);
        context.updateContext('loadingMessage', 'Paper retrieval failed');
      }
    },
  });

  const handleInputChange = async (query) => {
    setAutoCompleteInputValue(query);

    if (!query) {
      setSuggestions([]);
      return;
    }

    keywordSuggestions({
      variables: {
        keyword: query,
      },
    });
  };

  const extractMetadata = (bookmarks) =>
    bookmarks.map((bookmark) => {
      return {
        collection: bookmark.id_collection,
        id_field: bookmark.id_field,
        id_type: bookmark.id_type,
        id_value: bookmark.id_value,
      };
    });

  const onSubmit = (e) => {
    e.preventDefault();

    let rankingVariable = '';
    let paperList = undefined;

    // If quicksearch was clicked, then the field contains this text
    // we search using the manuscript
    if (context.searchTerm === '[Contents of manuscript]') {
      rankingVariable = context.value.map((n) => Node.string(n)).join('\n');
    } else {
      rankingVariable = context.searchTerm;
    }

    // If all note libraries is toggled, then only use the paper ids in bookmarks context
    if (context.searchLibrary) {
      paperList = extractMetadata(context.bookmarks);
    }

    // If note library is toggled, then only use the paper ids tied to the note
    if (context.onlyManuscriptLibrary) {
      paperList = extractMetadata(
        context.bookmarks.filter((bookmark) => {
          return context.manuscriptLibrary.some(
            (libraryItem) => libraryItem._id === bookmark._id
          );
        })
      );
    }

    context.updateContext('loadingRequest', true);
    context.updateContext('loadingMessage', 'Retrieving papers');

    documentSearch({
      variables: {
        ranking_variable: rankingVariable,
        keywords: context.keywords,
        paper_list: paperList,
      },
    });
  };

  const borderColor = theme.palette.mode === 'light' ? '#c4c4c5' : '#484c50';

  return (
    <Box
      ref={heightRef}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '100%',
        padding: '10px 10px 0px 10px',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
      }}
    >
      <Stack
        direction="row"
        sx={{
          marginBottom: '10px',
          fontSize: '12px',
          width: '100%',
        }}
      >
        <DocumentationTooltip
          tooltipText={
            context.searchLibrary || context.onlyManuscriptLibrary
              ? 'Discover library papers relevant to your query.'
              : 'Discover papers relevant to your query.'
          }
          linkSuffix="help-centre/discover-the-literature#conduct-discovery-directly"
        >
          <Button
            type="submit"
            variant="outlined"
            sx={{
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              border: `1px solid ${borderColor}`,
              borderRight: 'none',
            }}
          >
            {context.searchLibrary || context.onlyManuscriptLibrary ? (
              <ManageSearchOutlinedIcon />
            ) : (
              <SearchRounded />
            )}
          </Button>
        </DocumentationTooltip>
        <TextField
          fullWidth
          name="search"
          label="Semantic search"
          placeholder="Semantic discovery"
          InputLabelProps={{ shrink: true }}
          id="fullWidth"
          data-tip="Text for retrieving and ranking papers"
          data-delay-show="1000"
          size="small"
          value={context.searchTerm}
          onChange={(event) => {
            context.updateContext('searchTerm', event.target.value);
          }}
          sx={{
            fontSize: '12px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderTopLeftRadius: '0px !important',
              borderBottomLeftRadius: '0px !important',
            },
          }}
        />
        <DocumentationTooltip
          tooltipText="Constrain discovery with lexical filters"
          extendedText="Boolean Query: ' ' = and, '|' = or, '!' = not, example: banana apple|orange !cherry"
          linkSuffix="help-centre/discover-the-literature#apply-lexical-filters-to-constrain-discovery"
        >
          <IconButton
            color="primary"
            aria-label="show or hide abstract"
            component="label"
            onClick={() => {
              collapsedToggle(!collapsed);
            }}
          >
            {collapsed ? (
              <TuneOutlinedIcon sx={{ color: 'grey' }} />
            ) : (
              <TuneOutlinedIcon sx={{ color: 'grey' }} />
            )}
          </IconButton>
        </DocumentationTooltip>
      </Stack>
      <Collapse
        in={collapsed}
        timeout="auto"
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <Autocomplete
          multiple
          id="tags-filled"
          options={suggestions}
          inputValue={autoCompleteInputValue}
          onInputChange={(_, newInputValue) => {
            handleInputChange(newInputValue);
          }}
          freeSolo
          sx={{
            marginBottom: '10px',
            width: '100%',
          }}
          value={context.keywords}
          onChange={(_, newValue) => {
            context.updateContext('keywords', newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <EditableChip
                key={option}
                option={option}
                index={index}
                getTagProps={getTagProps}
                setAutoCompleteInputValue={setAutoCompleteInputValue}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Keyword based filter"
              InputLabelProps={{ shrink: true }}
              placeholder="Add lexical filters to constrain your search (Press Enter after each word or ngram)"
              variant="outlined"
            />
          )}
        />
        <SearchKeywords />
      </Collapse>
    </Box>
  );
};

export default SearchBox;
