import { formatAuthors } from './formatAuthors';
import { Author, PublicationDate } from '../Types';

/**
 * Formats a reference's text based on content availability in IEEE style
 *
 * @param {array} authors - The list of authors
 * @param {object} dates - The dates object
 * @param {string} title - The title string
 * @param {string} venue - The venue string (optional)
 * @returns {string} The formatted reference
 */

export const referenceFormat = (
  authors: Author[],
  dates: PublicationDate,
  title: string,
  venue: string = ''
): string => {
  const names = formatAuthors(authors); // Use the IEEE format for authors
  const cleanedTitle = title.replace(/\n|\r/g, ''); // Remove newlines from title

  // Collect the parts of the reference
  const components = [
    names.join(', ') || '',
    cleanedTitle ? `"${cleanedTitle}"` : '',
    venue || '',
    dates?.Year ? `(${dates.Year})` : '',
  ];

  // Filter out empty components and join with comma and space
  const formattedReference = components
    .filter((component) => component)
    .join(', ');

  // Ensure there is a period at the end if the reference is not empty
  return formattedReference ? formattedReference + '.' : formattedReference;
};
