// Libraries
import React from 'react';
import katex from 'katex';
import parse from 'html-react-parser';
import Alert from '@mui/material/Alert';

// Local
import 'katex/dist/katex.min.css';

const generateKatex = (text: string) => {
  try {
    const html = katex.renderToString(text, {
      throwOnError: true,
    });

    return { isValid: true, html: parse(html) };
  } catch (e) {
    if (e instanceof katex.ParseError) {
      const error = (
        <Alert severity="error">Error in LaTeX, {e.message.substring(6)}</Alert>
      );

      return { isValid: false, html: error };
    }
  }
};

export default generateKatex;