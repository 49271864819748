// Libraries
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

// Local
import { features } from './Components/FeatureList';
import ValueProposition from './Components/ValueProposition';
import Hero from './Components/Hero';
import MessageBanner from './Components/MessageBanner';
import Associations from './Components/Associations';
import Problem from './Components/Problem';
import Footer from '../Common/Footer';

const Homepage = ({ title, session }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;

    if (session && session.status === 'success') {
      navigate('/notes');
    }
  }, []);

  return (
    <>
      <MessageBanner />
      <Hero />
      <Button
        size="large"
        sx={{
          color: 'white',
          display: 'flex',
          background: '#265ed5',
          textTransform: 'none',
          marginTop: '30px',
          marginBottom: '6vw',
          border: 'none',
          '&:hover': {
            background: '#265ed5',
          },
        }}
        onClick={() => navigate('/login')}
      >
        <Typography variant="h6">Get started</Typography>
      </Button>
      <Associations />
      <Problem />
      {features.map((props, index) => (
        <ValueProposition key={index} {...props} />
      ))}
      <Footer />
    </>
  );
};

export default Homepage;
