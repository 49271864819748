import { createContext } from 'react';
import { ReactEditor } from 'slate-react';

interface ActionHistory {
  id_value: string;
  id_field: string;
  id_type: string;
  collection: string;
}
export interface DocumentContextType {
  title: string;
  readOnly: boolean;
  showNumbering?: boolean;
  editorRef?: ReactEditor;
  updateContext: (key: string, value: unknown) => void;
  recordActionHistory?: (action: ActionHistory) => void;
}

export const DocumentContext = createContext<DocumentContextType | null>(null);

export const DocumentProvider = DocumentContext.Provider;
export const DocumentConsumer = DocumentContext.Consumer;
