// Libraries
import React, { useContext, useState } from 'react';
import { useSlate } from 'slate-react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Typography, Collapse } from '@mui/material';

// Local
import { PAGINATED_SEARCH } from '../../Queries/nlp';
import { ADD_BOOKMARK_FROM_METADATA } from '../../Queries/document';
import { DocumentContext } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';
import { updateMetadata } from '../Utilities/updateMetadata';

import { notify } from '../../../Common/notify';
import { PaperForm } from '../../../Common/PaperForm';

const isMetadataMissing = (metadata) => {
  // If any field is null then return true
  return Object.values(metadata).some((value) => value === null);
};

const MetadataForm = ({ modular, element }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const [expanded, setExpanded] = useState(false);

  const editor = useSlate();

  const [addBookmarkFromMetadata] = useMutation(ADD_BOOKMARK_FROM_METADATA, {
    fetchPolicy: 'network-only',
  });

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const onSubmit = (data) => {
    context.updateContext('loadingRequest', true);
    context.updateContext('loadingMessage', 'Linking paper');

    addBookmarkFromMetadata({
      variables: {
        username: context.user._id,
        ...data,
      },
      onCompleted: ({ addBookmarkFromMetadata }) => {
        const metadata = addBookmarkFromMetadata.response.bookmark;

        updateMetadata(editor, element, {
          collection: metadata.id_collection,
          id_field: metadata.id_field,
          id_value: metadata.id_value,
          id_type: metadata.id_type,
        });

        // Hide the form after metadata updated
        setExpanded(false);

        if (addBookmarkFromMetadata.status === 'success') {
          const paperList = addBookmarkFromMetadata.response.bookmarks.map(
            (bookmark) => {
              return {
                collection: bookmark.id_collection,
                id_field: bookmark.id_field,
                id_type: bookmark.id_type,
                id_value: bookmark.id_value,
              };
            }
          );

          paginatedSearch({
            variables: {
              paper_list: paperList,
            },
            onCompleted: ({ paginatedSearch }) => {
              if (paginatedSearch.status === 'success') {
                const updatedBookmarks = [];

                paginatedSearch.response.forEach((paper) => {
                  paperList.forEach((meta) => {
                    if (
                      paper.id_int != null &&
                      paper.id_int.toString() === meta.id_value
                    ) {
                      updatedBookmarks.push({
                        _id: paper._id,
                        abstract: paper.Content.Abstract,
                        abstract_parsed: paper.Content.Abstract_Parsed,
                        doi: paper.DOI,
                        id_int: paper.id_int,
                        title: paper.Title,
                        id_collection: meta.collection,
                        id_field: meta.id_field,
                        id_type: meta.id_type,
                        id_value: meta.id_value,
                      });
                    }
                  });
                });

                context.updateContext('loadingRequest', false);
                context.updateContext('loadingMessage', 'Linked paper');
                context.updateContext('bookmarks', updatedBookmarks);
              } else {
                notify(
                  'Could not add metadata item to library at this moment, try again later'
                );
              }
            },
          });
        } else {
          notify(addBookmarkFromMetadata.message);
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', '');
        }
      },
    });
  };

  return (
    <>
      <Typography
        variant="body1"
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: !isMetadataMissing(element.metadata) && 'none',
          color: 'grey',
          cursor: 'pointer',
        }}
      >
        Add missing metadata
      </Typography>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography
          variant="caption"
          gutterBottom
          sx={{
            color: 'grey',
          }}
        >
          Please note: By adding custom paper metadata, you will create a new
          paper in your library, which will then be permanently associated with
          this reference or citation. There is currently no way to edit
          metadata, it can only be added once when it is missing initially.
        </Typography>
        <PaperForm onSubmit={onSubmit} />
      </Collapse>
    </>
  );
};

export default MetadataForm;
