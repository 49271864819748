import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CitationNode } from '../Types';

/**
 * Updates all citation nodes in the editor where the node's metadata.id_value matches
 * the id_value of the given element with the new citation text.
 *
 * @param {Editor} editor - The Slate editor object.
 * @param {object} element - The element containing the metadata to identify the citation.
 * @param {string} newCitationText - The new text for the citation.
 * @return {void}
 */
export const updateCitations = (
  editor: ReactEditor,
  element: CitationNode,
  newCitationText: string
): void => {
  const {
    metadata: { id_value: idValue },
  } = element;

  // If the element does not have an id_value, return early
  if (!idValue) {
    return;
  }

  // If the new citation text is empty, return early
  if (!newCitationText) {
    return;
  }

  // Find all citation nodes that match the id_value
  const results = Array.from(
    Editor.nodes(editor, {
      match: (node) =>
        (node as CitationNode).type === 'citation' &&
        (node as CitationNode).metadata?.id_value === idValue,
      at: [],
    })
  );

  // Update all matching nodes with the new citation text
  results.forEach(([node, path]) => {
    Transforms.setNodes(
      editor,
      { ...node, citation: newCitationText },
      { at: path }
    );
  });
};
