// Libraries
import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

// Local
import { DocumentContext } from '../../DocumentContext';
import DownloadAnnotationButton from './DownloadAnnotationButton';

const AnnotationToggle = ({ client }) => {
  const context = useContext(DocumentContext);

  return (
    <Accordion
      elevation={0}
      expanded={context.annotatorView}
      sx={{
        position: 'sticky',
        zIndex: '1',
        top: '0px',
        flexDirection: 'column',
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06)',
        },
        display: context.annotatorView ? 'block' : 'none',
      }}
    >
      <AccordionDetails sx={{ padding: 0 }}>
        <Annotator client={client} />
      </AccordionDetails>
    </Accordion>
  );
};

const Annotator = () => {
  const context = useContext(DocumentContext);

  useEffect(() => {
    if (context.annotatorPaperId !== context.paper._id) {
      context.updateContext('annotatorPaperId', context.paper._id);
      context.updateContext('annotatorX', []);
      context.updateContext('annotatorY', []);
      context.updateContext('annotatorPrecision', 0);
      context.updateContext('annotatorRecall', 0);
      context.updateContext('annotatorSufficiency', 0);
      context.updateContext('annotatorEfficiency', 0);
    }
  }, [context.paper]);

  return (
    <Box
      component="form"
      sx={{
        padding: '10px',
        width: '100%',
        justifyContent: 'flex-end',
        overflowY: 'auto',
        boxSizing: 'border-box',
        maxHeight: '600px',
        borderBottom: '1px solid lightgrey',
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={1} direction="column">
        <Autocomplete
          multiple
          id="tags-filled"
          fullWidth
          options={[]}
          size="small"
          freeSolo
          sx={{ marginBottom: '15px', minWidth: 500 }}
          value={context.annotatorX}
          onChange={(event, value) => {
            if (event.type === 'keydown') return;

            context.updateContext('annotatorX', value);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip title={option.text} key={index} placement="top">
                <Chip
                  key={index}
                  variant="outlined"
                  label={option.text}
                  size="small"
                  {...getTagProps({ index })}
                  style={{ marginTop: '5px', marginBottom: '5px' }}
                />
              </Tooltip>
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label="x = Content of note paragraph (left) influenced by paper (below)"
              size="small"
            />
          )}
        />
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          size="small"
          freeSolo
          sx={{ marginBottom: '15px' }}
          value={context.annotatorY}
          onChange={(event, value) => {
            if (event.type === 'keydown') return;

            context.updateContext('annotatorY', value);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip title={option.text} key={index} placement="top">
                <Chip
                  key={index}
                  variant="outlined"
                  label={option.text}
                  size="small"
                  {...getTagProps({ index })}
                  style={{ marginTop: '5px', marginBottom: '5px' }}
                />
              </Tooltip>
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label="y = Content of paper (below) influencing note paragraph (left)"
              size="small"
            />
          )}
        />
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
        >
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 85 }}
            size="small"
          >
            <InputLabel id="annotatorPrecision">Precision of x</InputLabel>
            <Select
              autoWidth
              labelId="annotatorPrecision"
              value={context.annotatorPrecision}
              onChange={(event, newValue) => {
                context.updateContext(
                  'annotatorPrecision',
                  newValue.props.value
                );
              }}
              label="Precision"
            >
              <MenuItem value={0}>
                <em>N.A.</em>
              </MenuItem>
              <MenuItem value={1}>Low</MenuItem>
              <MenuItem value={2}>Partial</MenuItem>
              <MenuItem value={3}>High</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 85 }}
            size="small"
          >
            <InputLabel id="annotatorRecall">Recall of x</InputLabel>
            <Select
              autoWidth
              labelId="annotatorRecall"
              value={context.annotatorRecall}
              onChange={(event, newValue) => {
                context.updateContext('annotatorRecall', newValue.props.value);
              }}
              label="Recall"
            >
              <MenuItem value={0}>
                <em>N.A.</em>
              </MenuItem>
              <MenuItem value={1}>Low</MenuItem>
              <MenuItem value={2}>Partial</MenuItem>
              <MenuItem value={3}>High</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 85 }}
            size="small"
          >
            <InputLabel id="Sufficiency">Sufficiency of y</InputLabel>
            <Select
              autoWidth
              labelId="Sufficiency"
              value={context.annotatorSufficiency}
              onChange={(event, newValue) => {
                context.updateContext(
                  'annotatorSufficiency',
                  newValue.props.value
                );
              }}
              label="Sufficiency"
            >
              <MenuItem value={0}>
                <em>N.A.</em>
              </MenuItem>
              <MenuItem value={1}>Low</MenuItem>
              <MenuItem value={2}>Partial</MenuItem>
              <MenuItem value={3}>High</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 85 }}
            size="small"
          >
            <InputLabel id="annotatorEfficiency">Efficiency of y</InputLabel>
            <Select
              autoWidth
              labelId="annotatorEfficiency"
              value={context.annotatorEfficiency}
              onChange={(event, newValue) => {
                context.updateContext(
                  'annotatorEfficiency',
                  newValue.props.value
                );
              }}
              label="Efficiency"
            >
              <MenuItem value={0}>
                <em>N.A.</em>
              </MenuItem>
              <MenuItem value={1}>Low</MenuItem>
              <MenuItem value={2}>Partial</MenuItem>
              <MenuItem value={3}>High</MenuItem>
            </Select>
          </FormControl>
          <DownloadAnnotationButton />
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'red',
              '&:hover': { backgroundColor: 'red  ' },
              height: 'fit-content',
              width: 'fit-content',
            }}
            onClick={() => {
              context.updateContext('annotatorX', []);
              context.updateContext('annotatorY', []);
              context.updateContext('annotatorPrecision', 0);
              context.updateContext('annotatorRecall', 0);
              context.updateContext('annotatorSufficiency', 0);
              context.updateContext('annotatorEfficiency', 0);
            }}
          >
            Clear all
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AnnotationToggle;
