// Libraries
import React, { useContext } from 'react';
import { Tabs, Tab, Stack, darken, lighten, useTheme } from '@mui/material';

// Local
import { DocumentContext } from '../../DocumentContext';
// import Historic from './Historic';

const Menu = () => {
  const context = useContext(DocumentContext);
  const theme = useTheme();

  const tabStyle = {
    fontSize: '12px',
    // Styles for the selected state
    '&.Mui-selected': {
      color: 'inherit',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? darken(theme.palette.background.paper, 0.05)
          : lighten(theme.palette.background.paper, 0.05),
    },
    // Additional styles for dark mode
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: 'transparent',
    }),
  };

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        height: '35px',
        maxHeight: '35px',
      }}
    >
      {/* <Historic /> */}
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Tabs
          value={context.tab}
          onChange={(_, newValue) => {
            context.updateContext('tab', newValue);
          }}
          textColor="primary"
          aria-label="Explorer tabs"
          indicatorColor={'transparent'}
          sx={{
            display: 'flex',
            '&.MuiTabs-root': {
              minHeight: '0px',
              height: '35px',
            },
          }}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab
            className="discover"
            value="Discover"
            label={
              <span
                style={{
                  position: 'relative',
                  top: '-6px',
                }}
              >
                Discover
              </span>
            }
            iconPosition="start"
            sx={tabStyle}
          />
          <Tab
            className="view"
            value="View"
            label={
              <span
                style={{
                  position: 'relative',
                  top: '-6px',
                }}
              >
                Read
              </span>
            }
            iconPosition="start"
            sx={tabStyle}
          />
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default Menu;
