// Libraries
import React, { useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  ToggleButton,
  Stack,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

// Local
import {
  KEYWORDS_BASED_ON_SEARCH,
  DOCUMENT_SEARCH,
  PAGINATED_SEARCH,
  SINGLE_PAPER,
} from '../../../Queries/nlp.js';
import { SAVE_BOOKMARK } from '../../../Queries/document.js';
import { DocumentContext } from '../../../DocumentContext';
import { generateSearchStats } from '../../../Utilities/generateSearchStats';
import { notify } from '../../../../Common/notify';
import { cite } from '../../../Editor/Utilities/cite';
import { addScoresToSearchResults } from '../../../Utilities/addScoresToSearchResults.js';
import DocumentationTooltip from '../../../../Common/DocumentationTooltip.js';

const ViewMenu = () => {
  const context = useContext(DocumentContext);
  const theme = useTheme();

  const [saveBookmark] = useMutation(SAVE_BOOKMARK, {
    fetchPolicy: 'network-only',
  });

  const [singlePaper] = useLazyQuery(SINGLE_PAPER, {
    fetchPolicy: 'network-only',
  });

  const [keywordsBasedOnSearch] = useLazyQuery(KEYWORDS_BASED_ON_SEARCH);

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH);

  const [documentSearch] = useLazyQuery(DOCUMENT_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ documentSearch }) => {
      context.updateContext('paperIds', documentSearch.response.paper_list);
      context.updateContext(
        'rerankingScores',
        documentSearch.response.reranking_scores
      );
      context.updateContext(
        'prefetchingScores',
        documentSearch.response.prefetching_scores
      );
      context.updateContext(
        'pageCount',
        documentSearch.response.paper_list.length / 10
      );
      context.updateContext('loadingRequest', false);
      context.updateContext(
        'loadingMessage',
        generateSearchStats(documentSearch.response.search_stats)
      );

      paginatedSearch({
        fetchPolicy: 'network-only',
        variables: {
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list.slice(0, 10),
        },
        onCompleted: ({ paginatedSearch }) => {
          const updatedSearchResults = addScoresToSearchResults(
            paginatedSearch.response, // in batches of 10 papers
            documentSearch.response.reranking_scores, // scores for all papers
            documentSearch.response.prefetching_scores, // prefetching scores for all papers
            1, // current page
            10 // items per page
          );

          context.updateContext('searchResults', updatedSearchResults);
          context.updateContext('currentPage', 1);
          context.updateContext('tab', 'Discover');

          if (context.searchLibrary) {
            context.updateContext('searchLibrary', false);
          }
        },
      });

      keywordsBasedOnSearch({
        fetchPolicy: 'network-only',
        variables: {
          ranking_variable: context.searchTerm,
          keywords: context.keywords,
          paper_list: documentSearch.response.paper_list,
        },
        onCompleted: ({ keywordsBasedOnSearch }) => {
          context.updateContext(
            'keywordsBasedOnSearch',
            keywordsBasedOnSearch.response
          );
        },
      });
    },
  });

  const sizeCases = {
    dualBreakPoints: {
      small: useMediaQuery('(min-width:0px) and (max-width:1100px)'),
      medium: useMediaQuery('(min-width:1100px) and (max-width:1670px)'),
      large: useMediaQuery('(min-width:1670px)'),
    },
    explorerBreakPoints: {
      small: useMediaQuery('(min-width:0px) and (max-width:600px)'),
      medium: useMediaQuery('(min-width:600px) and (max-width:830px)'),
      large: useMediaQuery('(min-width:830px)'),
    },
  };

  const getCase = () => {
    const c = context.view.dual
      ? sizeCases.dualBreakPoints
      : sizeCases.explorerBreakPoints;
    return {
      small: c.small,
      medium: c.medium,
      large: c.large,
    };
  };

  const boringButtonstyle = {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    fontSize: '10px',
  };

  const toggleButtonStyles = {
    // Additional styles for dark mode
    ...(theme.palette.mode === 'dark' && {
      borderWidth: 1,
      backgroundColor: 'transparent',
      '&.Mui-selected': {
        backgroundColor: 'grey',
      },
    }),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        justifyContent: 'space-between',
        padding: '5px 10px',
        borderBottom: (theme) =>
          theme.palette.mode === 'dark'
            ? '1px solid #333333'
            : '1px solid #d4d4d4',
        height: '35px',
        maxHeight: '35px',
        boxSizing: 'border-box',
      }}
    >
      <Stack direction="row" spacing={1}>
        <DocumentationTooltip
          tooltipText="Discover papers similar to this one."
          linkSuffix="help-centre/discover-the-literature#discover-papers-that-are-similar-to-a-particular-paper"
        >
          <Button
            size="small"
            variant="outlined"
            disabled={context.paper ? false : true}
            sx={{
              fontSize: '10px',
            }}
            onClick={() => {
              const {
                rankingCollection,
                rankingIdValue,
                rankingIdField,
                rankingIdType,
              } = context.rankingDetails;

              documentSearch({
                variables: {
                  ranking_collection: rankingCollection,
                  ranking_id_field: rankingIdField,
                  ranking_id_type: rankingIdType,
                  ranking_id_value: rankingIdValue,
                  keywords: context.keywords,
                },
              });

              context.updateContext('searchTerm', context.paper.Title);
              context.updateContext('loadingRequest', true);
              context.updateContext(
                'loadingMessage',
                'Retrieving similar papers'
              );
            }}
          >
            <TravelExploreOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: getCase().small ? '0px' : '8px',
              }}
            />
            Similar papers
          </Button>
        </DocumentationTooltip>
        <Divider
          orientation="vertical"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '1px',
            background:
              theme.palette.mode === 'light' ? 'whitesmoke' : '#333333',
          }}
        />
        <DocumentationTooltip
          tooltipText="Generate a citation sentence for this paper."
          linkSuffix="help-centre/view-selected-papers#generate-citation-text"
        >
          <ToggleButton
            size="small"
            variant="outlined"
            value="generator"
            selected={context.generatorView}
            disabled={!context.paper}
            sx={{
              ...toggleButtonStyles,
              display:
                process.env.REACT_APP_SHOW_AI_ASSIST === 'true'
                  ? 'inherit'
                  : 'none',
            }}
            onChange={() => {
              context.updateContext('generatorView', !context.generatorView);
              context.updateContext('annotatorView', false);
              context.updateContext('assist', false);
            }}
          >
            <AutoFixHighOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: getCase().small ? '0px' : '8px',
              }}
            />
            {getCase().small
              ? null
              : getCase().medium
                ? 'Generate'
                : getCase().large
                  ? 'Generate Citation'
                  : null}
          </ToggleButton>
        </DocumentationTooltip>
        <ToggleButton
          size="small"
          variant="outlined"
          value="assist"
          selected={context.assist}
          disabled={!context.paper}
          sx={{
            ...toggleButtonStyles,
            display:
              process.env.REACT_APP_SHOW_AI_ASSIST === 'true'
                ? 'inherit'
                : 'none',
          }}
          onChange={() => {
            context.updateContext('assist', !context.assist);
            context.updateContext('generatorView', false);
            context.updateContext('annotatorView', false);
          }}
        >
          <BoltOutlinedIcon
            sx={{
              fontSize: 'medium',
              marginRight: getCase().small ? '0px' : '8px',
            }}
          />
          {getCase().small
            ? null
            : getCase().medium
              ? 'AI'
              : getCase().large
                ? 'AI Assistant'
                : null}
        </ToggleButton>
        <DocumentationTooltip
          tooltipText="Annotate citing and cited text spans."
          linkSuffix="help-centre/view-selected-papers#annotate-citation-spans"
        >
          <ToggleButton
            size="small"
            variant="outlined"
            value="annotator"
            selected={context.annotatorView}
            disabled={!context.paper}
            sx={{
              ...toggleButtonStyles,
              display: context.user?.isDataLabeler ? 'inherit' : 'none',
            }}
            onChange={() => {
              context.updateContext('annotatorView', !context.annotatorView);
              context.updateContext('generatorView', false);
              context.updateContext('assist', false);
            }}
          >
            <BorderColorOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: getCase().small ? '0px' : '8px',
              }}
            />
            {getCase().small
              ? null
              : getCase().medium
                ? 'Annotate'
                : getCase().large
                  ? 'Annotate Citation'
                  : null}
          </ToggleButton>
        </DocumentationTooltip>
      </Stack>
      <Stack direction="row" spacing={1}>
        <DocumentationTooltip
          tooltipText="Add this paper to your library."
          linkSuffix="help-centre/manage-your-library#add-a-paper-to-your-library"
        >
          <Button
            variant="outlined"
            size="small"
            disabled={context.paper ? false : true}
            sx={boringButtonstyle}
            onClick={async () => {
              context.updateContext('loadingRequest', true);
              context.updateContext('loadingMessage', 'Adding to library');

              const {
                rankingCollection,
                rankingIdValue,
                rankingIdField,
                rankingIdType,
              } = context.rankingDetails;

              saveBookmark({
                variables: {
                  id_collection: rankingCollection,
                  id_value: rankingIdValue,
                  id_field: rankingIdField,
                  id_type: rankingIdType,
                },
                onCompleted: ({ saveBookmark }) => {
                  if (saveBookmark.status === 'success') {
                    const updatedBookmarks = context.bookmarks;

                    updatedBookmarks.push({
                      _id: context.paper._id,
                      abstract: context.paper.Content.Abstract,
                      abstract_parsed: context.paper.Content.Abstract_Parsed,
                      doi: context.paper.DOI,
                      id_int: context.paper.id_int,
                      title: context.paper.Title,
                      id_collection: rankingCollection,
                      id_field: rankingIdField,
                      id_type: rankingIdType,
                      id_value: rankingIdValue,
                    });

                    context.updateContext('bookmarks', updatedBookmarks);
                    context.updateContext('loadingRequest', false);
                    context.updateContext('loadingMessage', 'Added to library');
                  } else {
                    notify(saveBookmark.message);
                    context.updateContext('loadingRequest', false);
                    context.updateContext('loadingMessage', '');
                  }
                },
              });
            }}
          >
            <AddBoxOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: getCase().small ? '0px' : '8px',
              }}
            />
            {getCase().small
              ? null
              : getCase().medium
                ? 'Add'
                : getCase().large
                  ? 'Add to library'
                  : null}
          </Button>
        </DocumentationTooltip>
        <DocumentationTooltip
          tooltipText="Cite this paper in your note."
          linkSuffix="help-centre/insert-and-export-citations#insert-or-delete-a-citation-marker"
        >
          <Button
            size="small"
            variant="outlined"
            disabled={context.readOnly ? true : context.paper ? false : true}
            sx={boringButtonstyle}
            onClick={async (e) => {
              e.preventDefault();

              if (context.readOnly) return;

              context.updateContext('loadingRequest', true);
              context.updateContext('loadingMessage', 'Citing paper');

              const {
                rankingCollection,
                rankingIdValue,
                rankingIdField,
                rankingIdType,
              } = context.rankingDetails;

              singlePaper({
                variables: {
                  paper_id: {
                    collection: rankingCollection,
                    id_field: rankingIdField,
                    id_type: rankingIdType,
                    id_value: rankingIdValue,
                  },
                },
                onCompleted: ({ singlePaper }) => {
                  if (singlePaper.status === 'success') {
                    cite(
                      context.editorRef,
                      singlePaper.response,
                      {
                        collection: rankingCollection,
                        id_field: rankingIdField,
                        id_type: rankingIdType,
                        id_value: rankingIdValue,
                      },
                      context.autoAppearanceOrder,
                      context.showNumbering
                    );

                    context.updateContext('loadingRequest', false);
                    context.updateContext('loadingMessage', 'Cited paper');
                  } else {
                    notify(singlePaper.message);
                    context.updateContext('loadingRequest', false);
                    context.updateContext('loadingMessage', '');
                  }
                },
              });
            }}
          >
            <FormatQuoteOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: getCase().small ? '0px' : '8px',
              }}
            />
            {getCase().small
              ? null
              : getCase().medium
                ? 'Cite'
                : getCase().large
                  ? 'Cite paper'
                  : null}
          </Button>
        </DocumentationTooltip>
      </Stack>
    </Stack>
  );
};

export default ViewMenu;
