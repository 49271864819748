// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Local
import { AppBar, Toolbar, Typography, Link, Stack } from '@mui/material';
import DarkModeToggle from './DarkModeToggle';

const Menu = () => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      sx={{
        width: '100%',
        color: 'text.primary',
        bgcolor: 'background.paper',
        paddingLeft: { xs: 0, xl: '10%' },
        paddingRight: { xs: 0, xl: '10%' },
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            display: 'flex',
          }}
          spacing={0}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <img
            src="/logo.png"
            alt="Endoc logo"
            style={{
              height: '40px',
              width: '35px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              cursor: 'pointer',
              display: { xs: 'none', md: 'block' },
            }}
            onClick={() => navigate('/')}
          >
            Endoc
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link onClick={() => navigate('/login')}>
            <Typography
              variant="body1"
              sx={{
                marginRight: '20px',
                cursor: 'pointer',
              }}
            >
              Log in
            </Typography>
          </Link>
          {/* <Link onClick={() => navigate('/register')}>
            <Typography
              variant="body1"
              sx={{
                marginRight: '20px',
                cursor: 'pointer',
              }}
            >
              Register
            </Typography>
          </Link> */}
          <Link onClick={() => navigate('/team')}>
            <Typography
              variant="body1"
              sx={{
                marginRight: '20px',
                cursor: 'pointer',
              }}
            >
              Team
            </Typography>
          </Link>
          <DarkModeToggle />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Menu;
