// Libraries
import React, { useState, useContext } from 'react';
import { useSlate ,RenderElementProps } from 'slate-react';
import { Location } from 'slate';
import { Paper, Box, Stack, Typography, darken, lighten } from '@mui/material';

// Local
import { DocumentContext } from '../../DocumentContext';
import Options from './Options';
import generateKatex from './GenerateKatex';
import EquationModal from '../Menu/EquationModal';
import { KatexNode } from '../Types'

/**
 * It renders our katex element
 * It renders the equation, number and caption
 * It provides a dropdown that closes on scroll
 */



interface KatexProps extends RenderElementProps {
  element: KatexNode
}


const Katex: React.FC<KatexProps> = ({ attributes, element, children }) => {
  const editor = useSlate();
  const context = useContext(DocumentContext);

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<Location>({
    anchor: {
      path: [0, 0],
      offset: 0,
    },
    focus: {
      path: [0, 0],
      offset: 0,
    }
  });
  const [equation, setEquation] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <EquationModal
        aria-label='equation-modal'
        open={open}
        setOpen={setOpen}
        selection={selection}
        equation={equation}
        setEquation={setEquation}
        editable={true}
        element={element}
      />
      <Paper
        {...attributes}
        contentEditable={false}
        elevation={0}
        aria-label='katex-paper'
        sx={{
          padding: '10px',
          boxSizing: 'border-box',
          fontSize: 'inherit',
          cursor: context?.readOnly ? 'default' : 'pointer',
          color: 'grey',
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' && !context?.readOnly
                ? darken(theme.palette.background.paper, 0.05)
                : lighten(theme.palette.background.paper, 0.05),
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Stack direction={'row'}>
          {isHovered ? <Options element={element} /> : <Box />}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
            onClick={() => {
              if (!context?.readOnly) {
                // Get the selection before we lose it on modal open
                setEquation(element.equation);
                setSelection(editor.selection as Location);

                setOpen(true);
              }
            }}
          >
            <Typography component="span" variant="body1" aria-label='katex-typography'>
              {generateKatex(element.equation)?.html}
            </Typography>
          </Box>
        </Stack>
        {children}
      </Paper>
    </>
  );
};

export default Katex;
