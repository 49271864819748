import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

const Feedback = () => {
  const navigate = useNavigate();

  return (
    <Button
      size="small"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: 'none',
        width: '100%',
        textTransform: 'none',
      }}
      onClick={() => {
        navigate('/contact');
      }}
    >
      <RateReviewOutlinedIcon
        fontSize="small"
        sx={{
          marginRight: '8px',
        }}
      />
      <Typography variant="body1">Submit feedback</Typography>
    </Button>
  );
};

export default Feedback;
