// Libraries
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import FormatClearOutlinedIcon from '@mui/icons-material/FormatClearOutlined';

// Local
import { DocumentContext } from '../../../DocumentContext';
import { removeSentenceHighlights } from '../../../Utilities/highlight';
import DocumentationTooltip from '../../../../Common/DocumentationTooltip.js';

const Selection = () => {
  const context = useContext(DocumentContext);
  const theme = useTheme();

  const [collapsed, setCollapsed] = useState(false);
  const [targetText, setTargetText] = useState('');

  useEffect(() => {
    // Only update when highlight has been fired again and it has changed the paper
    setTargetText(context.highlightSelection);
  }, [context.paper]);

  const sizeCases = {
    dualBreakPoints: {
      small: useMediaQuery('(min-width:0px) and (max-width:1100px)'),
      medium: useMediaQuery('(min-width:1100px) and (max-width:1670px)'),
      large: useMediaQuery('(min-width:1670px)'),
    },
    explorerBreakPoints: {
      small: useMediaQuery('(min-width:0px) and (max-width:600px)'),
      medium: useMediaQuery('(min-width:600px) and (max-width:830px)'),
      large: useMediaQuery('(min-width:830px)'),
    },
  };

  const getCase = () => {
    const c = context.view.dual
      ? sizeCases.dualBreakPoints
      : sizeCases.explorerBreakPoints;
    return {
      small: c.small,
      medium: c.medium,
      large: c.large,
    };
  };

  return (
    <Accordion
      expanded={collapsed}
      onChange={() => setCollapsed(!collapsed)}
      sx={{
        position: 'sticky',
        zIndex: '1',
        top: '0px',
        display: context.showSelection ? 'flex' : 'none',
        flexDirection: 'column',
        '&.Mui-expanded': {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: '0px 10px',
          '&.Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <Typography
          sx={{
            marginRight: '10px',
            padding: '1px',
            background: '#fbc02d',
            fontStyle: 'italic',
            color: 'black',
            lineHeight: 2,
            boxSizing: 'border-box',
            whiteSpace: 'nowrap', // Prevents the text from wrapping
            overflow: 'hidden', // Keeps the text within its bounding box
            textOverflow: 'ellipsis', // Adds ellipsis to truncated text
            maxWidth: '300px', // Ensures the text container fits its parent
          }}
        >
          {targetText}
        </Typography>
        <DocumentationTooltip
          tooltipText="Show aligned sentences only."
          linkSuffix="help-centre/view-selected-papers#highlight-similar-sentences"
        >
          <ToggleButton
            size="small"
            variant="outlined"
            value="sorted"
            selected={context.sortedView}
            disabled={context.paper && context.showSelection ? false : true}
            sx={{
              ...(theme.palette.mode === 'dark' && {
                borderWidth: 1,
                backgroundColor: 'transparent',
                '&.Mui-selected': {
                  backgroundColor: 'grey',
                },
              }),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '10px',
            }}
            onChange={(e) => {
              e.stopPropagation();
              context.updateContext('sortedView', !context.sortedView);
            }}
          >
            <SortOutlinedIcon
              sx={{
                fontSize: 'medium',
                marginRight: '8px',
              }}
            />
            {getCase().small
              ? null
              : getCase().medium
                ? 'Sort'
                : getCase().large
                  ? 'Sort aligned'
                  : null}
          </ToggleButton>
        </DocumentationTooltip>
        <Button
          size="small"
          variant="outlined"
          value="highlight"
          disabled={context.paper ? false : true}
          sx={{
            marginLeft: '10px',
            '&.Mui-selected': {
              backgroundColor: 'grey',
            },

            ...(theme.palette.mode === 'dark' && {
              borderWidth: 1,
              backgroundColor: 'transparent',
              '&.Mui-selected': {
                backgroundColor: 'grey',
              },
            }),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px',
          }}
          onClick={(e) => {
            e.stopPropagation();

            const originalPaper = removeSentenceHighlights(context.paper);
            context.updateContext('paper', originalPaper);
            context.updateContext('showSelection', false);
            context.updateContext('sortedView', false);
            context.updateContext(
              'loadingMessage',
              'Removed similar sentences'
            );
          }}
        >
          <FormatClearOutlinedIcon
            sx={{
              fontSize: 'medium',
              marginRight: getCase().small ? '0px' : '8px',
            }}
          />
          {getCase().small
            ? null
            : getCase().medium
              ? 'Clear'
              : getCase().large
                ? 'Clear similarities'
                : null}
        </Button>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          borderLeft: 'none',
        }}
      >
        <Box
          sx={{
            padding: '10px',
          }}
        >
          <Typography
            sx={{
              background: '#fbc02d',
              color: 'black',
              fontStyle: 'italic',
              padding: '10px',
            }}
          >
            {targetText}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Selection;
