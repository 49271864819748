// Library
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import Lodash from 'lodash';
import { Mutation } from '@apollo/client/react/components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  TextField,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Typography,
  Divider,
  Link,
  Stack,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Local
import { LOGIN_USER } from '../Queries';
import Constants from '../constants';
import Error from '../Common/Error';
/**
 * Hook component for login form
 * @function
 * @returns {JSX.Element} - Rendered component
 */

const Form = (props) => {
  const [revealPassword, setRevealPassword] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ email, password }, login) => {
    login({
      variables: {
        email,
        password,
      },
    }).then(async ({ data }) => {
      if (data.login.status === 'success') {
        Constants.TOKEN = data.login.response.token;
        Constants.EXPIRY = data.login.response.jwtExpiry;
        await props.refetch();

        navigate('/notes');
      }
    });

    ReactGA.event({
      category: 'User',
      action: 'Clicked the login button',
      label: 'Login form',
    });
  };

  const handleChange = (e) => {
    register({ name: e.target.name }, e.target.value);
  };

  const initiateLogin = () => {
    const CLIENT_ID = process.env.REACT_APP_SWITCH_CLIENT_ID;
    const REDIRECT_URI = process.env.REACT_APP_SWITCH_REDIRECT_URI;
    const ENDPOINT = process.env.REACT_APP_SWITCH_AUTHORIZE_URL;
    const SCOPE = process.env.REACT_APP_SWITCH_SCOPE;

    const authUrl = `${ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}`;
    window.location.href = authUrl;
  };

  return (
    <Mutation mutation={LOGIN_USER}>
      {(login, { data }) => {
        return (
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              width: '100%',
            }}
            onSubmit={handleSubmit((data) => onSubmit(data, login))}
          >
            {process.env.REACT_APP_ETH === 'true' ? (
              <Stack
                direction="column"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    marginBottom: '15px',
                    width: '100%',
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: 'Clicked the login button',
                      label: 'Login form',
                    });

                    initiateLogin();
                  }}
                >
                  Switch edu-ID
                </Button>
                <Divider
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  <Typography>OR</Typography>
                </Divider>
                <Stack
                  direction="column"
                  sx={{
                    display: showForm ? 'flex' : 'none',
                    flexDirection: 'column',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <TextField
                    required
                    type="text"
                    name="email"
                    placeholder="Your personal email"
                    sx={{ marginBottom: '10px' }}
                    onChange={handleChange}
                    {...register('email', {
                      required: 'A valid email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                  <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
                    <OutlinedInput
                      required
                      name="password"
                      type={revealPassword ? 'text' : 'password'}
                      placeholder="Password with 6 characters minimum"
                      onChange={handleChange}
                      {...register('password', {
                        required:
                          'A password must be at least 6 characters long',
                        min: 6,
                      })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                              e.preventDefault();
                              setRevealPassword(!revealPassword);
                            }}
                            edge="end"
                          >
                            {revealPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      marginBottom: '10px',
                    }}
                  >
                    Log in
                  </Button>
                </Stack>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '12px',
                    color: 'grey',
                    marginBottom: '30px',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => setShowForm(!showForm)}
                >
                  Log in via email address (Legacy)
                </Typography>
                <Typography variant="caption" gutterBottom>
                  With switch edu-ID you get unrestricted access to the platform
                  if affiliated to a Swiss institute supporting switch.{' '}
                  <Link
                    onClick={() => {
                      ReactGA.event({
                        category: 'Documentation',
                        action: 'Go to documentation',
                        label: 'User selected to go documentation',
                      });

                      window.open(
                        `${process.env.REACT_APP_PLATFORM_DOCUMENTATION}/documentation/overview/quick-start`,
                        '_blank',
                        'noreferrer'
                      );
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    See documentation.
                  </Link>
                </Typography>
                <Typography variant="caption" gutterBottom>
                  By clicking &quot;Log in via email address&quot; or
                  &quot;Switch edu-ID&quot;, you agree to our{' '}
                  <Link
                    onClick={() => navigate('/terms-of-service')}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link
                    onClick={() => navigate('/privacy-policy')}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Stack>
            ) : (
              <Typography variant="caption">
                By clicking &quot;Log in&quot;, you agree to our{' '}
                <Link
                  onClick={() => navigate('/terms-of-service')}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  onClick={() => navigate('/privacy-policy')}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            )}
            {errors.email && (
              <Error response={{ error: errors.email.message }} />
            )}
            {errors.password && (
              <Error response={{ error: 'A password is required' }} />
            )}

            {Lodash.get(data, 'login.status') === 'failure' && (
              <Error response={data.login.response} />
            )}
          </Box>
        );
      }}
    </Mutation>
  );
};

export default Form;
