// Libraries
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
} from 'react';
import { Editor, Range } from 'slate';
import { Typography, Tooltip } from '@mui/material';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';

// Local
import { DocumentContext } from '../DocumentContext';

const CountSelection = () => {
  const { editorRef } = useContext(DocumentContext);
  const [selectedWords, setSelectedWords] = useState(0);
  const [selectedCharacters, setSelectedCharacters] = useState(0);

  const updateCount = useCallback(
    (selection) => {
      const selectedText = Editor.string(editorRef, selection);
      if (selectedText.length > 0) {
        // Adjust word count calculation for partial word selections
        const wordCount = selectedText.includes(' ')
          ? selectedText.trim().split(/\s+/).length
          : 0;
        setSelectedWords(wordCount);
        setSelectedCharacters(selectedText.length);
      } else {
        setSelectedWords(0);
        setSelectedCharacters(0);
      }
    },
    [editorRef]
  );

  useEffect(() => {
    if (editorRef) {
      const { selection } = editorRef;

      if (selection && !Range.isCollapsed(selection)) {
        updateCount(selection);
      } else {
        setSelectedWords(0);
        setSelectedCharacters(0);
      }
    }
  }, [editorRef?.selection, updateCount]);

  return (
    <Tooltip title="Count selected words and characters">
      <Typography
        noWrap
        sx={{
          fontSize: '12px',
          cursor: 'default',
          display: selectedCharacters > 0 ? 'flex' : 'none',
          alignItems: 'center',
        }}
      >
        <HighlightAltOutlinedIcon
          fontSize="small"
          sx={{
            marginRight: '5px',
          }}
        />
        Selected words: {selectedWords} / characters: {selectedCharacters}
      </Typography>
    </Tooltip>
  );
};

export default memo(CountSelection);
