// Libraries
import React, { useState } from 'react';
import { Node } from 'slate';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Stack,
  Card,
  // CardActions,
  CardContent,
  IconButton,
  Button,
  // Tooltip,
  Typography,
  // Chip,
  Modal,
  darken,
  lighten,
} from '@mui/material';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

// Local
import { notify } from '../../Common/notify';
import { deleteDocument, getDocumentLock } from '../Requests/Documents';
import { deleteCollaborator } from '../Requests/SharedDocuments';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  boxShadow: 24,
  padding: '20px',
  cursor: 'pointer',
};

const serializeNode = (node) => {
  if (node.type === 'citation') {
    return `(${node.citation})`;
  } else if (node.type === 'reference') {
    return `${node.reference}\n`;
  } else if (node.children) {
    return node.children.map(serializeNode).join('');
  } else {
    return Node.string(node);
  }
};

const serialize = (nodes) => {
  if (!nodes || !Array.isArray(nodes)) return '';

  return nodes.map(serializeNode).join('\n');
};

const Manuscript = ({ client, user, doc, docs, fetchDocs }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [collabModal, setCollabModal] = useState(false);
  const [targetId, setTargetId] = useState(''); // document
  const [collabId, setCollabId] = useState(''); // collaborator

  const navigate = useNavigate();
  const location = useLocation();

  const deleteCollab = async () => {
    const data = await deleteCollaborator(client, targetId, collabId);
    if (data.deleteCollaborator.status === 'success') {
      notify(data.deleteCollaborator.message);
    } else {
      notify(data.deleteCollaborator.message);
    }

    setTargetId('');
    setCollabId('');
    setCollabModal(false);
    fetchDocs();
  };

  const checkLock = async (doc) => {
    getDocumentLock(client, doc._id).then((data) => {
      if (data.status === 'success' && !data.response.locked) {
        // If demo account navigate to modular
        if (user.email === process.env.REACT_APP_DEMO_ACCOUNT) {
          navigate(`/modular/${doc._id}`, {
            from: location.pathname,
          });
          return;
        }

        navigate(`/note/${doc._id}`, {
          from: location.pathname,
        });
      } else if (
        data.status === 'success' &&
        data.response.locked &&
        data.response.lockedBy &&
        data.response.lockedBy._id === user._id
      ) {
        // If demo account navigate to modular
        if (user.email === process.env.REACT_APP_DEMO_ACCOUNT) {
          navigate(`/modular/${doc._id}`, {
            from: location.pathname,
          });
          return;
        }

        navigate(`/note/${doc._id}`, {
          from: location.pathname,
        });
      } else if (
        data.status === 'success' &&
        data.response.locked &&
        data.response.lockedBy &&
        data.response.lockedBy._id !== user._id
      ) {
        notify(`Note is locked by ${data.response.lockedBy.name}`);
      } else {
        notify(data.message);
      }
    });
  };

  return (
    <>
      <Modal
        open={deleteModal}
        onClose={() => {
          setTargetId('');
          setDeleteModal(false);
        }}
        aria-labelledby="modal-modal-references"
        aria-describedby="modal-modal-paper-authors"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: '250px',
              md: '400px',
            },
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            color="text.primary"
            sx={{
              marginBottom: '10px',
            }}
          >
            Are you sure you want to delete this note?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={async () => {
                const data = await deleteDocument(client, targetId);
                fetchDocs();

                notify(data.deleteDocument.message);
                setDeleteModal(false);
                setTargetId('');
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setDeleteModal(false);
                setTargetId('');
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={collabModal}
        onClose={() => {
          setTargetId('');
          setCollabId('');
          setCollabModal(false);
        }}
        aria-labelledby="modal-modal-collab"
        aria-describedby="modal-collaborator-remove"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: '250px',
              md: '400px',
            },
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            color="text.primary"
            sx={{
              marginBottom: '10px',
            }}
          >
            Are you sure you want to leave as a collaborator from this note?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={async () => {
                deleteCollab();
              }}
            >
              Leave
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCollabModal(false);
                setCollabId('');
                setTargetId('');
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Card
        elevation={0}
        className="manuscript" // Tour purposes
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? 'background.paper' : '#171717',
          width: '100%',
          height: '350px',
          boxSizing: 'border-box',
          cursor: 'pointer',
          border: (theme) =>
            theme.palette.mode === 'dark'
              ? '1px solid #333333'
              : '1px solid #d4d4d4',
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? darken(theme.palette.background.paper, 0.05)
                : lighten(theme.palette.background.paper, 0.05),
          },
        }}
        onClick={() => {
          checkLock(doc);
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0px',
            paddingBottom: '0px',
          }}
        >
          <Stack
            direction={'row'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                cursor: 'pointer',
                ':hover': {
                  color: 'cornflowerblue',
                },
              }}
              onClick={() => {
                checkLock(doc);
              }}
            >
              {doc.title === ''
                ? 'Untitled'
                : doc.title.length <= 50
                  ? doc.title
                  : `${doc.title.slice(0, 50)}...`}
            </Typography>
            <IconButton
              className="delete"
              size="small"
              aria-label="delete note"
              onClick={(e) => {
                e.stopPropagation();

                if (doc.user === user._id) {
                  setDeleteModal(true);
                  setTargetId(doc._id);
                } else {
                  let collabId = '';
                  docs.forEach((document) =>
                    document.collaborators.map((collaborator) =>
                      collaborator.user === user._id
                        ? (collabId = collaborator._id)
                        : null
                    )
                  );

                  setCollabId(collabId);
                  setTargetId(doc._id);
                  setCollabModal(true);
                }
              }}
              sx={{
                border: 'none',
                color: (theme) =>
                  theme.palette.mode === 'light'
                    ? darken(theme.palette.background.paper, 0.65)
                    : lighten(theme.palette.background.paper, 0.65),
                opacity: 0.65,
                ':hover': {
                  color: 'inherit',
                  opacity: 1,
                },
              }}
            >
              <DeleteForeverOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Typography
            variant="caption"
            sx={{
              marginBottom: '10px',
            }}
          >
            Last saved: {moment(doc.time).format('DD MMMM, hh:mm')}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              marginBottom: '10px',
              borderRadius: '5px',
            }}
          >
            {(() => {
              const content = serialize(JSON.parse(doc.content)).trim();
              return content.length > 150
                ? `${content.slice(0, 150)}...`
                : content;
            })()}
          </Typography>
          {/* <Stack
            direction="row"
            spacing={0}
            sx={{
              marginBottom: '0px',
              boxSizing: 'border-box',
              maxWidth: '100%',
              flexWrap: 'wrap',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {doc.collaborators.map((collaborator, index) => (
              <Tooltip title="Collaborator" key={index}>
                <Chip
                  size="small"
                  label={collaborator.name}
                  sx={{
                    fontSize: '10px',
                    borderRadius: '5px !important',
                    marginBottom: '10px !important',
                    marginRight: '10px !important',
                  }}
                />
              </Tooltip>
            ))}
          </Stack> */}
        </CardContent>
        {/* <CardActions
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          {doc.locked ? (
            <Tooltip title={'Note locked'}>
              <LockOutlinedIcon sx={{ width: 20, height: 20 }} />
            </Tooltip>
          ) : null}
          {user._id === doc.user ? null : (
            <Tooltip title="Note shared with you">
              <FolderSharedOutlinedIcon sx={{ width: 22, height: 22 }} />
            </Tooltip>
          )}
        </CardActions> */}
      </Card>
    </>
  );
};

export default Manuscript;
