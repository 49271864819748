// Libraries
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Local

import { updateUser } from '../Requests';
import { notify } from '../../Common/notify';
import Error from '../../Common/Error';

const EmailForm = ({ client }) => {
  const [currentPassword, setCurrentPassword] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  const registerForm = register;

  const onSubmit = ({ currentPassword, newEmail }) => {
    updateUser(client, newEmail, '', currentPassword, '')
      .then((data) => {
        notify(data.updateUser.message);
      })
      .catch(() => notify(`Could not update account at this time`));
  };

  const handleChange = (e) => {
    register({ name: e.target.name }, e.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        maxWidth: '800px',
        width: '100%',
        flexDirection: 'column',
        boxSizing: 'border-box',
        marginBottom: '20px',
      }}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
        }}
      >
        Change email
      </Typography>
      <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
        <OutlinedInput
          required
          name="currentPassword"
          type={currentPassword ? 'text' : 'password'}
          placeholder="Your current password"
          onChange={handleChange}
          {...registerForm('currentPassword', {
            required: 'A password must be at least 6 characters long',
            min: 6,
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentPassword(!currentPassword);
                }}
                edge="end"
              >
                {currentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="outlined" sx={{ marginBottom: '10px' }}>
        <OutlinedInput
          required
          name="email"
          type={'email'}
          placeholder="Your new email"
          onChange={handleChange}
          {...registerForm('newEmail', {
            required: 'A valid email is required',
            min: 6,
          })}
        />
      </FormControl>
      {errors.newEmail && (
        <Error response={{ error: errors.newEmail.message }} />
      )}
      <Button variant="outlined" type="submit" sx={{ marginBottom: '10px' }}>
        Update
      </Button>
    </Box>
  );
};

export default EmailForm;
