// Libraries
import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Stack,
  Drawer,
  Tooltip,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

// Local
import { useSignOut } from './HandleSignout';
import { ColorModeContext } from '../DarkMode';

const NavToggle = ({ user }) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <Tooltip title={`Account, documentation and more...`}>
        <Button
          onClick={() => setNavOpen(true)}
          sx={{
            color: 'text.primary',
            border: 'none',
          }}
        >
          <MenuOutlinedIcon />
        </Button>
      </Tooltip>
      <Navigator navOpen={navOpen} setNavOpen={setNavOpen} user={user} />
    </>
  );
};

const Navigator = ({ navOpen, setNavOpen, user }) => {
  const location = useLocation();
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const signOut = useSignOut();

  return (
    <Drawer anchor={'right'} open={navOpen} onClose={() => setNavOpen(false)}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: '250px',
          boxSizing: 'border-box',
        }}
      >
        <List>
          <ListItem disablePadding onClick={() => navigate('/notes')}>
            <ListItemButton selected={location.pathname === '/notes'}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Notes'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => navigate('/account')}
            sx={{
              display:
                user.email === process.env.REACT_APP_DEMO_ACCOUNT
                  ? 'none'
                  : 'flex',
            }}
          >
            <ListItemButton selected={location.pathname === '/account'}>
              <ListItemIcon>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Account'} />
            </ListItemButton>
          </ListItem>
          {process.env.REACT_APP_ETH === 'true' && (
            <ListItem disablePadding onClick={() => navigate('/contact')}>
              <ListItemButton selected={location.pathname === '/contact'}>
                <ListItemIcon>
                  <AlternateEmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Feedback'} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            disablePadding
            onClick={() => {
              window.open(
                process.env.REACT_APP_PLATFORM_DOCUMENTATION,
                '_blank',
                'noopener noreferrer'
              );
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <MenuBookOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Documentation'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={colorMode.toggleColorMode}
            sx={{ display: { xs: 'flex', lg: 'none' } }}
          >
            {theme.palette.mode === 'light' ? (
              <ListItemButton>
                <ListItemIcon>
                  <Brightness4Icon />
                </ListItemIcon>
                <ListItemText primary="Dark Mode" />
              </ListItemButton>
            ) : (
              <ListItemButton>
                <ListItemIcon>
                  <Brightness7Icon />
                </ListItemIcon>
                <ListItemText primary="Light Mode" />
              </ListItemButton>
            )}
          </ListItem>
          <ListItem disablePadding onClick={signOut}>
            <ListItemButton>
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Log out'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </Drawer>
  );
};

export default NavToggle;
