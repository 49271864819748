import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { CitationNode } from '../Types';

/**
 * Updates a specific citation node in the editor, identified by the element,
 * with the new citation text.
 *
 * @param {ReactEditor} editor - The Slate editor object.
 * @param {Node} element - The Slate node element that corresponds to the citation you want to update.
 * @param {string} newCitationText - The new citation text to apply to the citation node.
 * @return {void}
 */

export const updateCitation = (
  editor: ReactEditor,
  element: CitationNode,
  newCitationText: string
): void => {
  // Find the path to the given element
  const path = ReactEditor.findPath(editor, element);

  // Only accept elements of type 'citation'
  if (element.type !== 'citation') {
    return;
  }

  // If its an empty string, do not update
  if (newCitationText === '') {
    return;
  }

  // Update the node at the found path with the new citation text
  Transforms.setNodes(
    editor,
    { citation: newCitationText } as CitationNode, // Make sure this matches your node structure; adjust property names as needed
    { at: path }
  );
};
