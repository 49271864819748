// Library
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Box, Modal, Fade, Button, Typography, Stack } from '@mui/material';

// Local
import { DocumentContext } from '../DocumentContext';

// Asks the user after 10 minutes if they want to continue.
// Waits for a response for 5 minutes before navigating them back to notes.
const Idle = () => {
  const context = useContext(DocumentContext);
  const [idleTimeout, setIdleTimeout] = useState(null);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds

  const navigate = useNavigate();

  useIdleTimer({
    timeout: 1000 * 60 * 10, // Wait for initial idleness of 10 minutes
    onIdle: () => context.updateContext('isIdle', true),
    debounce: 500,
  });

  useEffect(() => {
    let timer;
    if (context.isIdle) {
      // User is idle, start a 5-minute timer to navigate away
      setCountdown(300); // Reset countdown to 5 minutes
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            navigate('/notes');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      // User is not idle or clicked 'Continue', clear the timer
      if (idleTimeout) {
        clearTimeout(idleTimeout);
        setIdleTimeout(null);
      }
      if (timer) {
        clearInterval(timer);
      }
    }

    // Cleanup function
    return () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [context.isIdle, navigate]);

  const handleContinue = () => {
    // Reload the page to refetch workspace
    window.location.reload();
  };

  return (
    <Modal
      aria-labelledby="Idle warning"
      aria-describedby="Click continue to avoid returning to dashboard"
      open={context.isIdle}
      onClose={handleContinue}
      closeAfterTransition
      onKeyDown={(e) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
          handleContinue();
        }
      }}
    >
      <Fade in={context.isIdle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
            bgcolor: 'background.paper',
            color: 'text.primary',
            outline: 'none',
            boxShadow: 24,
            border: 'none',
            p: 2,
          }}
        >
          <Typography
            sx={{
              padding: '10px',
              marginBottom: '10px',
            }}
          >
            You have been inactive for a while. Your work will be saved and you
            will be redirected to all notes in {Math.floor(countdown / 60)}:
            {(countdown % 60).toString().padStart(2, '0')} unless you choose to
            continue.
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              onClick={handleContinue}
              sx={{
                textTransform: 'none',
              }}
            >
              Continue or press ↵ Enter
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Idle;
