import { Transforms, Editor } from 'slate';
import { getReferences } from './getReferences';
import { getCitations } from './getCitations';

/* Get the order of appearance for citations
 * Then update ref number based on their order of appearance
 * Then update references and their number based on citation order of appearance
 */

export const orderOfAppearance = (editor) => {
  Editor.withoutNormalizing(editor, () => {
    // Get list of references
    // Get list of citations
    const references = getReferences(editor);
    const citations = getCitations(editor);
    const citationMap = [];

    // Build map
    citations.forEach((citation) => {
      if (!citationMap.includes(citation[0].metadata.id_value)) {
        citationMap.push(citation[0].metadata.id_value);
      }
    });

    // Update citations
    citations.forEach((citation) => {
      Transforms.setNodes(
        editor,
        {
          number: citationMap.indexOf(citation[0].metadata.id_value) + 1,
        },
        {
          at: citation[1],
        }
      );
    });

    const referenceMap = {};
    const referenceOrder = [];

    // Get the current references locations (Order of appearance)
    references.forEach((reference, index) => {
      referenceOrder.push(reference[1]);
      referenceMap[index + 1] = {
        location: reference[1],
        metadata: reference[0].metadata,
        number: reference[0].number,
        reference: reference[0].reference,
      };
    });

    // For each citationMap id, find its reference in the reference map
    citationMap.forEach((citation, index) => {
      for (const property in referenceMap) {
        // Then if reference id value matches
        if (referenceMap[property].metadata.id_value === citation) {
          // Update the node which appears in order with new content / metadata
          Transforms.setNodes(
            editor,
            {
              number: index + 1,
              metadata: referenceMap[property].metadata,
              reference: referenceMap[property].reference,
            },
            {
              at: referenceOrder[index],
            }
          );
        }
      }
    });
  });
};
