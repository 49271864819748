import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { Metadata, ReferenceNode } from '../Types';

/**
 * Checks if a reference exists
 *
 * @param {object} editor - The current slate editor instance
 * @param {object} metadata - The metadata used to find the reference
 * @returns {boolean} True if the reference exists, false otherwise
 */

export const referenceExists = (
  editor: ReactEditor,
  metadata: Metadata
): boolean => {
  const results = Array.from(
    Editor.nodes(editor, {
      match: (node) => {
        return (
          (node as ReferenceNode).type === 'reference' &&
          (node as ReferenceNode).metadata.id_value === metadata.id_value
        );
      },
      at: [],
    }),
    (node) => node
  );

  return results.length >= 1;
};
