import {
  DELETE_DOCUMENT,
  GET_DOCUMENTS,
  GET_LOCKED,
  POST_DOCUMENT,
} from '../../Queries';

/**
 * It retrieves all personal document details based on authToken
 * @param {client} object - Apollo Client
 */

export const getDocuments = (client) => {
  return client
    .watchQuery({
      query: GET_DOCUMENTS,
      fetchPolicy: 'network-only',
      pollInterval: 2000, // Fetch every 2 seconds
    })
    .result()
    .then(({ data }) => {
      return data.docServiceGetDocs;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const postDocument = (
  client,
  title,
  content,
  user,
  numbering = true,
  orderByAppearance = true
) => {
  return client
    .mutate({
      mutation: POST_DOCUMENT,
      refetchQueries: [
        {
          query: GET_DOCUMENTS,
        },
      ],
      variables: {
        title,
        content,
        lastSavedBy: user.name,
        numbering,
        orderByAppearance,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteDocument = (client, id) => {
  return client
    .mutate({
      mutation: DELETE_DOCUMENT,
      refetchQueries: [
        {
          query: GET_DOCUMENTS,
        },
      ],
      variables: {
        id,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getDocumentLock = (client, docId) => {
  return client
    .query({
      query: GET_LOCKED,
      variables: {
        doc_id: docId,
      },
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      return data.docServiceGetDoc;
    })
    .catch((err) => {
      throw new Error(err);
    });
};
