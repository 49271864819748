// Libraries
import React, {
  // useState,
  useContext,
  useRef,
  //  useEffect
} from 'react';
import { Editor, Transforms, Element as SlateElement } from 'slate';
// import { debounce } from 'lodash';
import { useSlate } from 'slate-react';
// import { useMutation } from '@apollo/client';
import {
  Stack,
  Tooltip,
  Button,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  // Menu as MuiMenu,
  MenuItem,
  // IconButton,
  Typography,
} from '@mui/material';

// Icons
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
// import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
// import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
// import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
// import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
// import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
// import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
// import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
// import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
// import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
// import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
// import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import SuperscriptOutlinedIcon from '@mui/icons-material/SuperscriptOutlined';
import SubscriptOutlinedIcon from '@mui/icons-material/SubscriptOutlined';
import StrikethroughSOutlinedIcon from '@mui/icons-material/StrikethroughSOutlined';
// import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';

// Local
import { DocumentContext } from '../../DocumentContext';
// import { DOCUMENT_FIGURE } from '../../../Queries';
// import { notify } from '../../../Common/notify';
// import { insertFigure } from './InsertFigure';
// import EquationModal from './EquationModal';
// import HyperlinkModal from './HyperlinkModal';
import { getShortcut } from '../Types/Hotkeys';

// const ITEM_HEIGHT = 48;

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const Menu = () => {
  const editor = useSlate();
  const context = useContext(DocumentContext);
  // const inputRef = useRef(); // Ref for uploading images
  const menuRef = useRef(null); // Ref for the menu container
  // const [open, setOpen] = useState(false); // For equation modal
  // const [openHyperlink, setOpenHyperlink] = useState(false); // For hyperlink modal
  // const [equation, setEquation] = useState(''); // For equation modal
  // const [menuWidth, setMenuWidth] = useState(0); // State for the menu width
  // const [selection, setSelection] = useState(''); // State for editor selection
  // const [anchorEl, setAnchorEl] = useState(null); //  For dropdown options when overflowing
  // const anchorOpen = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // Update menu width on resize
  // useEffect(() => {
  //   const updateWidth = debounce(() => {
  //     if (menuRef.current) {
  //       setMenuWidth(menuRef.current.offsetWidth);
  //     }
  //   }, 10); // Debounce period of 10 milliseconds

  //   // Call once to set initial width
  //   updateWidth();

  //   // Add event listener for window resize
  //   window.addEventListener('resize', updateWidth);

  //   // Remove event listener on cleanup
  //   return () => {
  //     window.removeEventListener('resize', updateWidth);
  //     updateWidth.cancel(); // Cancel any pending debounced calls
  //   };
  // }, []);

  // const [documentFigure] = useMutation(DOCUMENT_FIGURE, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: ({ documentFigure }) => {
  //     if (documentFigure.status === 'success') {
  //       insertFigure(editor, documentFigure.response);
  //     } else {
  //       notify('Something went wrong, please try again later');
  //     }
  //   },
  // });

  // // const handleOpen = () => setOpen(true);

  const BlockButton = ({ format, children, menuItem = false }) => {
    const editor = useSlate();

    if (!menuItem) {
      return (
        <Tooltip
          title={
            getShortcut(format) ? `${format} (${getShortcut(format)})` : format
          }
          placement="bottom"
        >
          <Typography variant="span">
            <ToggleButton
              disabled={context.readOnly}
              value={format}
              selected={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
              )}
              onChange={(e) => {
                e.preventDefault();
                toggleBlock(editor, format);
              }}
              sx={{
                borderRadius: '0px',
                height: '100%',
                border: 'none !important',
              }}
            >
              {children}
            </ToggleButton>
          </Typography>
        </Tooltip>
      );
    } else {
      return (
        <MenuItem
          key={format}
          selected={isBlockActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
          )}
          onClick={() => toggleBlock(editor, format)}
        >
          {children}
        </MenuItem>
      );
    }
  };

  const MarkButton = ({ format, children, menuItem = false }) => {
    const editor = useSlate();

    if (!menuItem) {
      return (
        <Tooltip
          title={
            getShortcut(format) ? `${format} (${getShortcut(format)})` : format
          }
          placement="bottom"
        >
          <Typography variant="span">
            <ToggleButton
              disabled={context.readOnly}
              value={format}
              selected={isMarkActive(editor, format)}
              onChange={(e) => {
                e.preventDefault();
                toggleMark(editor, format);
              }}
              sx={{
                borderRadius: '0px',
                height: '100%',
                border: 'none !important',
              }}
            >
              {children}
            </ToggleButton>
          </Typography>
        </Tooltip>
      );
    } else {
      return (
        <MenuItem
          key={format}
          selected={isMarkActive(editor, format)}
          onClick={() => toggleMark(editor, format)}
        >
          {children}
        </MenuItem>
      );
    }
  };

  // const renderMenu = () => {
  //   if (menuWidth > 1200) {
  //     return (
  //       <>
  //         <ToggleButtonGroup
  //           sx={{
  //             display: context.readOnly && 'none',
  //             height: '100%',
  //           }}
  //         >
  //           <BlockButton format={'heading-two'}>
  //             <LooksTwoOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'heading-three'}>
  //             <Looks3OutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'heading-four'}>
  //             <Looks4OutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'bulleted-list'}>
  //             <FormatListBulletedOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'numbered-list'}>
  //             <FormatListNumberedOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'left'}>
  //             <FormatAlignLeftOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'center'}>
  //             <FormatAlignCenterOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'right'}>
  //             <FormatAlignRightOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //           <BlockButton format={'justify'}>
  //             <FormatAlignJustifyOutlinedIcon fontSize="small" />
  //           </BlockButton>
  //         </ToggleButtonGroup>
  //         <ButtonGroup
  //           variant="text"
  //           aria-label="editor button group"
  //           sx={{
  //             display: context.readOnly && 'none',
  //             color: 'grey !important',
  //             height: '100%',
  //             border: 'none !important', // explicitly setting border to none
  //             '& .MuiButton-root': {
  //               border: 'none !important', // also applying border none to individual buttons if needed
  //             },
  //             '& .MuiButtonGroup-grouped': {
  //               border: 'none !important',
  //               borderRight: 'none !important', // Removes the border to the right of each button except the last one
  //             },
  //             '& .MuiButtonGroup-grouped:last-of-type': {
  //               border: 'none !important',
  //               borderLeft: 'none !important', // Optionally, removes the left border of the last button
  //             },
  //           }}
  //         >
  //           <Tooltip title="Insert hyperlink" placement="bottom">
  //             <Button
  //               color="inherit"
  //               disabled={context.readOnly}
  //               aria-label="insert hyperlink"
  //               edge="start"
  //               onMouseDown={(e) => {
  //                 e.preventDefault();

  //                 // Get the selection before we lose it on modal open
  //                 setSelection(editor.selection);
  //                 setOpenHyperlink(true);
  //               }}
  //             >
  //               <LinkOutlinedIcon fontSize="small" />
  //             </Button>
  //           </Tooltip>
  //           <Tooltip title="Insert equation" placement="bottom">
  //             <Button
  //               color="inherit"
  //               disabled={context.readOnly}
  //               aria-label="insert equation"
  //               edge="start"
  //               onMouseDown={(e) => {
  //                 e.preventDefault();

  //                 // Get the selection before we lose it on modal open
  //                 setSelection(editor.selection);
  //                 handleOpen(true);
  //               }}
  //             >
  //               <FunctionsOutlinedIcon fontSize="small" />
  //             </Button>
  //           </Tooltip>
  //           <Tooltip title="Insert figure" placement="bottom">
  //             <Button
  //               color="inherit"
  //               disabled={context.readOnly}
  //               aria-label="insert figure into editor content"
  //               edge="start"
  //               onMouseDown={() => {
  //                 inputRef.current.click();
  //               }}
  //             >
  //               <AddPhotoAlternateOutlinedIcon fontSize="small" />
  //             </Button>
  //           </Tooltip>
  //         </ButtonGroup>
  //         <ToggleButtonGroup
  //           sx={{
  //             display: context.readOnly && 'none',
  //             height: '100%',
  //           }}
  //         >
  //           <MarkButton format={'code'}>
  //             <CodeOutlinedIcon fontSize="small" />
  //           </MarkButton>
  //         </ToggleButtonGroup>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <Tooltip title="More options">
  //           <IconButton
  //             aria-label="more"
  //             id="long-button"
  //             aria-controls={anchorOpen ? 'long-menu' : undefined}
  //             aria-expanded={anchorOpen ? 'true' : undefined}
  //             aria-haspopup="true"
  //             color="inherit"
  //             disabled={context.readOnly}
  //             onClick={handleClick}
  //             sx={{
  //               display: context.readOnly && 'none',
  //               borderRadius: '0%', // Makes the button circular
  //             }}
  //           >
  //             <MoreVertIcon fontSize="small" />
  //           </IconButton>
  //         </Tooltip>
  //         <MuiMenu
  //           id="long-menu"
  //           MenuListProps={{
  //             'aria-labelledby': 'long-button',
  //           }}
  //           anchorEl={anchorEl}
  //           open={anchorOpen}
  //           onClose={handleClose}
  //           slotProps={{
  //             paper: {
  //               style: {
  //                 maxHeight: ITEM_HEIGHT * 4.5,
  //                 width: 'auto',
  //               },
  //             },
  //           }}
  //         >
  //           <BlockButton format={'heading-two'} menuItem={true}>
  //             <LooksTwoOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>heading two</Typography>
  //           </BlockButton>
  //           <BlockButton format={'heading-three'} menuItem={true}>
  //             <Looks3OutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>heading three</Typography>
  //           </BlockButton>
  //           <BlockButton format={'heading-four'} menuItem={true}>
  //             <Looks4OutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>heading four</Typography>
  //           </BlockButton>
  //           <BlockButton format={'bulleted-list'} menuItem={true}>
  //             <FormatListBulletedOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>bulleted-list</Typography>
  //           </BlockButton>
  //           <BlockButton format={'numbered-list'} menuItem={true}>
  //             <FormatListNumberedOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>numbered-list</Typography>
  //           </BlockButton>
  //           <BlockButton format={'left'} menuItem={true}>
  //             <FormatAlignLeftOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>left align</Typography>
  //           </BlockButton>
  //           <BlockButton format={'center'} menuItem={true}>
  //             <FormatAlignCenterOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>center align</Typography>
  //           </BlockButton>
  //           <BlockButton format={'right'} menuItem={true}>
  //             <FormatAlignRightOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>right align</Typography>
  //           </BlockButton>
  //           <BlockButton format={'justify'} menuItem={true}>
  //             <FormatAlignJustifyOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>justify align</Typography>
  //           </BlockButton>
  //           <MenuItem
  //             disabled={context.readOnly}
  //             onClick={(e) => {
  //               e.preventDefault();

  //               setSelection(editor.selection);
  //               setOpenHyperlink(true);
  //             }}
  //           >
  //             <LinkOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>insert hyperlink</Typography>
  //           </MenuItem>
  //           <MenuItem
  //             disabled={context.readOnly}
  //             onClick={(e) => {
  //               e.preventDefault();

  //               inputRef.current.click();
  //             }}
  //           >
  //             <AddPhotoAlternateOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>insert figure</Typography>
  //           </MenuItem>
  //           <MenuItem
  //             disabled={context.readOnly}
  //             onClick={(e) => {
  //               e.preventDefault();

  //               setSelection(editor.selection);
  //               handleOpen(true);
  //             }}
  //           >
  //             <FunctionsOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>insert equation</Typography>
  //           </MenuItem>
  //           <MarkButton format={'code'} menuItem={true}>
  //             <CodeOutlinedIcon
  //               fontSize="small"
  //               sx={{
  //                 marginRight: '10px',
  //               }}
  //             />
  //             <Typography>code</Typography>
  //           </MarkButton>
  //         </MuiMenu>
  //       </>
  //     );
  //   }
  // };

  const innerWidth = window.innerWidth;

  return (
    <Stack
      ref={menuRef}
      direction="row"
      spacing={0}
      sx={{
        zIndex: 1,
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
        height: '35px',
        padding:
          context.view.edit && innerWidth > 900 ? '0 20vw 0 20vw' : '0px',
      }}
    >
      {/* <EquationModal
        open={open}
        setOpen={setOpen}
        selection={selection}
        equation={equation}
        setEquation={setEquation}
      />
      <HyperlinkModal
        openHyperlink={openHyperlink}
        setOpenHyperlink={setOpenHyperlink}
        selection={selection}
      /> */}
      <Tooltip title="Open panel">
        <Button
          className="panel"
          color="inherit"
          aria-label="open panel"
          onClick={() => {
            context.updateContext('togglePanel', true);
          }}
          sx={{
            border: 'none',
            borderRadius: '0px',
            position:
              context.view.edit && innerWidth > 900 ? 'absolute' : 'relative',
            top: '0px',
            left: '0px',
          }}
          edge="start"
        >
          <MenuOpenOutlinedIcon />
        </Button>
      </Tooltip>
      <Typography
        sx={{
          display: !context.readOnly && 'none',
        }}
        variant="caption"
      >
        {context.title.slice(0, 60)}...
      </Typography>
      <ButtonGroup
        variant="text"
        aria-label="editor button group"
        sx={{
          height: '100%',
          display: context.readOnly && 'none',
          color: 'grey !important',
          border: 'none !important', // explicitly setting border to none
          '& .MuiButton-root': {
            border: 'none !important', // also applying border none to individual buttons if needed
          },
          '& .MuiButtonGroup-grouped': {
            border: 'none !important',
            borderRight: 'none !important', // Removes the border to the right of each button except the last one
          },
          '& .MuiButtonGroup-grouped:last-of-type': {
            border: 'none !important',
            borderLeft: 'none !important', // Optionally, removes the left border of the last button
          },
        }}
      >
        <Tooltip title="Undo (ctrl+z)">
          <Button
            color="inherit"
            aria-label="undo editor content"
            disabled={context.readOnly}
            onClick={() => {
              editor.undo();
            }}
            edge="start"
          >
            <UndoOutlinedIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Redo (ctrl+y)">
          <Button
            color="inherit"
            disabled={context.readOnly}
            aria-label="redo editor content"
            onClick={() => {
              editor.redo();
            }}
            edge="start"
          >
            <RedoOutlinedIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <ToggleButtonGroup
        sx={{
          display: context.readOnly && 'none',
          height: '100%',
        }}
      >
        <MarkButton format={'bold'}>
          <FormatBoldOutlinedIcon fontSize="small" />
        </MarkButton>
        <MarkButton format={'italic'}>
          <FormatItalicOutlinedIcon fontSize="small" />
        </MarkButton>
        <MarkButton format={'underline'}>
          <FormatUnderlinedOutlinedIcon fontSize="small" />
        </MarkButton>
        <MarkButton format={'strikethrough'}>
          <StrikethroughSOutlinedIcon fontSize="small" />
        </MarkButton>
        <MarkButton format={'subscript'}>
          <SubscriptOutlinedIcon fontSize="small" />
        </MarkButton>
        <MarkButton format={'superscript'}>
          <SuperscriptOutlinedIcon fontSize="small" />
        </MarkButton>
        <BlockButton format={'heading-one'}>
          <LooksOneOutlinedIcon fontSize="small" />
        </BlockButton>
        <BlockButton format={'heading-two'}>
          <LooksTwoOutlinedIcon fontSize="small" />
        </BlockButton>
      </ToggleButtonGroup>
      {/* <input
        id="myInput"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        required
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={({
          target: {
            validity,
            files: [file],
          },
        }) => {
          const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
          if (validity.valid && supportedFormats.includes(file.type)) {
            documentFigure({
              variables: { file, doc_id: context.documentId },
            });
          } else {
            notify('Invalid image format');
          }
        }}
      /> */}
      {/* {renderMenu()} */}
    </Stack>
  );
};

export { toggleMark, toggleBlock };
export default Menu;
