import React from "react";
import Alert from "@mui/material/Alert";

const ErrorMessage = ({ response }) => {
  if (response && response.errors) {
    return (
      <Alert
        severity="error"
        data-test="component-error"
        sx={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {response.errors[0].msg}
      </Alert>
    );
  }

  if (response.error) {
    return (
      <Alert
        severity="error"
        data-test="component-error"
        sx={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {response.error}
      </Alert>
    );
  }

  return (
    <Alert
      severity="error"
      data-test="component-error"
      sx={{ marginTop: "10px", marginBottom: "10px" }}
    >
      {null}
    </Alert>
  );
};

export default ErrorMessage;
