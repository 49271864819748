interface SearchStats {
  nMatchingDocuments: string;
  DurationTotalSearch: number;
}

export const generateSearchStats = (searchStats: SearchStats): string => {
  const totalPapers = parseInt(searchStats.nMatchingDocuments);
  const paperWord = totalPapers === 1 ? 'paper' : 'papers';
  const papersRetrieved = totalPapers > 100 ? 100 : totalPapers;

  return `Retrieved top ${papersRetrieved} of ${totalPapers} ${paperWord} in ${(
    searchStats.DurationTotalSearch / 1000
  ).toFixed(2)} seconds`;
};
