// Modules
import React, { useEffect } from 'react';
import {
  Typography,
  Fade,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  darken,
  lighten,
} from '@mui/material';
import Footer from '../Common/Footer';

const team = [
  {
    first_name: 'Richard',
    surname: 'Hahnloser',
    image_url: 'https://placehold.co/400',
    description: 'Full Professor',
    profile:
      'https://ee.ethz.ch/the-department/faculty/professors/person-detail.NTI3MDk=.TGlzdC80MTEsMTA1ODA0MjU5.html',
  },
  {
    first_name: 'Jhony',
    surname: 'Prada',
    image_url: 'https://placehold.co/400',
    description: 'Software Engineer',
    profile:
      'https://ee.ethz.ch/the-department/people-a-z/person-detail.MjM5Mzgx.TGlzdC8zMjc5LC0xNjUwNTg5ODIw.html',
  },
  {
    first_name: 'Nianlong',
    surname: 'Gu',
    image_url: 'https://placehold.co/400',
    description: 'Machine Learning Specialist',
    profile: 'https://www.liri.uzh.ch/en/aboutus/Nianlong-Gu.html',
  },
  {
    first_name: 'Jessica',
    surname: 'Lam',
    image_url: 'https://placehold.co/400',
    description: 'PhD Student',
    profile:
      'https://ee.ethz.ch/the-department/people-a-z/person-detail.MjcyNTM2.TGlzdC8zMjc5LC0xNjUwNTg5ODIw.html',
  },
  {
    first_name: 'Yingqiang',
    surname: 'Gao',
    image_url: 'https://placehold.co/400',
    description: 'Research Specialist',
    profile:
      'https://ee.ethz.ch/the-department/people-a-z/person-detail.MjU1ODMw.TGlzdC8zMjc5LC0xNjUwNTg5ODIw.html',
  },
  {
    first_name: 'Anna',
    surname: 'Kiepura',
    image_url: 'https://placehold.co/400',
    description: 'PhD Student',
    profile:
      'https://services.ini.uzh.ch/admin/modules/uzh/person.php?id=91916&back=../uzh/people',
  },
  {
    first_name: 'Grigor',
    surname: 'Dochev',
    image_url: 'https://placehold.co/400',
    description: 'Master Student AI - Programmer',
    profile: 'https://www.ini.uzh.ch/en/institute/people.html',
  },
  {
    first_name: 'Andreas',
    surname: 'Giannoutsos',
    image_url: 'https://placehold.co/400',
    description: 'Master Student CS - Programmer',
    profile:
      'https://services.ini.uzh.ch/admin/modules/uzh/person.php?id=107455&back=../uzh/people',
  },
];

/**
 * @function
 * @returns {JSX.Element} - Rendered component
 */

const Team = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Typography
        data-testid="team-title"
        gutterBottom
        variant="h5"
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '800px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        We help researchers find semantic content and write scientific content..
      </Typography>
      <Typography
        data-testid="team-description"
        variant="body1"
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '500px',
          marginBottom: '50px',
        }}
      >
        Our mission is to help researchers focus their attention on ideas,
        results, and the implications of their work, whilst minimizing the time
        they spend on cognitively demanding but routine tasks such as literature
        searching, narrowing of search results, and skimming through selected
        papers for information.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '50px',
        }}
      >
        Team
      </Typography>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 4, sm: 6, md: 12 }}
        sx={{
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {team.map((member, index) => (
          <Fade in={true} timeout={2000} key={index}>
            <Card
              onClick={() => {
                if (member.profile) {
                  window.open(member.profile, '_blank');
                }
              }}
              sx={{
                width: { xs: '100%', md: 345 },

                margin: '20px',
                textAlign: 'center',
                boxSizing: 'border-box',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? darken(theme.palette.background.paper, 0.025)
                    : lighten(theme.palette.background.paper, 0.025),
                borderRadius: '0px',
                cursor: member.profile !== '' ? 'pointer' : 'default',
              }}
              elevation={0}
            >
              <CardActionArea
                disableRipple
                sx={{
                  textAlign: 'center',
                }}
              >
                <CardContent
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h5" component="div" gutterBottom>
                    {member.first_name} {member.surname}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#265ed5',
                    }}
                  >
                    {member.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Fade>
        ))}
      </Grid>
      <Footer />
    </>
  );
};

export default Team;
