// Library
import React, { useContext } from "react";
import { Button, Tooltip, Typography, useTheme } from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

// Local
import { ColorModeContext } from "../DarkMode";

const DarkModeToggle = ({ small = true, sx }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  // Determine the current mode and the icon/label for the toggle.
  const isDarkMode = theme.palette.mode === "dark";
  const Icon = isDarkMode ? LightModeOutlinedIcon : DarkModeOutlinedIcon;
  const modeLabel = isDarkMode ? "Switch to Light mode" : "Switch to Dark mode";

  return (
    <Tooltip title={modeLabel}>
      <Button
        variant="outlined"
        onClick={colorMode.toggleColorMode}
        sx={{
          border: small && "none !important",
          textTransform: "none",
          width: !small && "100%",
          ...sx,
        }}
      >
        {small && <Icon />}
        {!small && (
          <Typography sx={{ fontSize: "14px" }}>{modeLabel}</Typography>
        )}
      </Button>
    </Tooltip>
  );
};

export default DarkModeToggle;
