// Libraries
import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  ButtonProps,
  BoxProps,
} from '@mui/material';

interface Step {
  title: string;
  content: string;
}

interface WindowProps {
  continuous: boolean;
  index: number;
  step: Step;
  backProps: ButtonProps;
  closeProps: ButtonProps;
  primaryProps: ButtonProps;
  tooltipProps: BoxProps;
}

const Window: React.FC<WindowProps> = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => (
  <Box
    {...tooltipProps}
    sx={{
      position: 'inherit',
      padding: '20px',
      width: '275px',
      bgcolor: 'background.paper',
      color: 'text.primary',
      height: 'auto',
      border: '1px solid lightgrey',
      borderRadius: '5px',
    }}
  >
    <Typography variant="h6" gutterBottom>
      {step.title}
    </Typography>
    <Typography
      sx={{
        marginBottom: '20px',
      }}
    >
      {step.content}
    </Typography>
    <Stack direction="row" spacing={2}>
      {index > 0 && (
        <Button variant="outlined" {...backProps}>
          <Typography id="back">Back</Typography>
        </Button>
      )}
      {continuous && (
        <Button variant="outlined" {...primaryProps}>
          <Typography id="next">Next</Typography>
        </Button>
      )}
      {!continuous && (
        <Button variant="outlined" {...closeProps}>
          <Typography id="close">Skip</Typography>
        </Button>
      )}
    </Stack>
  </Box>
);

export default Window;
