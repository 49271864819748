import { Editor, Path, Range, Transforms } from 'slate';
import { citationFormat } from './citationFormat';
import { referenceFormat } from './referenceFormat';
import { findFirstReferencePosition } from './findFirstReferencePosition';
import { findPointAfterSelectedCitation } from './findPointAfterSelectedCitation';
import { referenceExists } from './referenceExists';
import { getCitation } from './getCitation';
import { totalReferences } from './totalReferences';
import { synchronizeAll } from './synchronizeAll';
import { CitationNode, ReferenceNode, Metadata, CitationData } from '../Types';
import { ReactEditor } from 'slate-react';
import { orderOfAppearance } from './orderOfAppearance';
// import { intelliCite } from './intelliCite';

/**
 * Insert citation inside the text editor
 * if no reference exists for citation, insert the reference
 *
 * @param {object} editor - The current slate editor instance
 * @param {object} citationData - The text data to show in the inline citation
 * @param {object} metadata - The citation metadata
 * @param {boolean} autoAppearanceOrder - Whether to automatically synchronize all citations
 * @param {boolean} showNumbering -
 */

export const cite = (
  editor: ReactEditor,
  citationData: CitationData,
  metadata: Metadata,
  autoAppearanceOrder: boolean
  // showNumbering: boolean
) => {
  // Avoid insert inside of another citation by moving selection afterwards
  const pointAfterSelectedCitation = findPointAfterSelectedCitation(editor);

  if (pointAfterSelectedCitation) {
    // Move the selection to the point after the selected citation
    Transforms.setSelection(editor, {
      anchor: pointAfterSelectedCitation,
      focus: pointAfterSelectedCitation,
    });
  }

  // Avoid insert inside or after the first reference
  const firstReferencePosition = findFirstReferencePosition(editor);
  const { selection } = editor;

  if (firstReferencePosition && selection) {
    const [start] = Range.edges(selection);
    const pointBeforeFirstReference = Editor.before(
      editor,
      firstReferencePosition
    );

    // Check if the selection point is after the first reference
    if (
      pointBeforeFirstReference &&
      Path.isAfter(start.path, pointBeforeFirstReference.path)
    ) {
      // Move the selection point to just before the first reference
      Transforms.setSelection(editor, {
        anchor: pointBeforeFirstReference,
        focus: pointBeforeFirstReference,
      });
    }
  }

  // If highlighting text, set selection at end
  // Check if there's a selection
  if (selection && Range.isExpanded(selection)) {
    // Get the end point of the current selection
    const [, endPoint] = Range.edges(selection);

    // Move the selection to the end of the highlighted text
    Transforms.setSelection(editor, {
      anchor: endPoint,
      focus: endPoint,
    });
  }

  // If a reference exists, so does its citation
  if (referenceExists(editor, metadata)) {
    // Always use the previous citation number and text
    // To keep subsequent insertions synced irrespective of settings
    const prevCitation = getCitation(editor, metadata.id_value);
    const prevNumber = (prevCitation[0] as CitationNode).number;
    const prevText = prevCitation
      ? (prevCitation[0] as CitationNode).citation
      : citationFormat(citationData.Author, citationData.PublicationDate);

    const citationNode = {
      type: 'citation',
      number: prevNumber,
      citation: prevText,
      metadata,
      children: [{ text: '' }],
    };

    // Insert the node
    Transforms.insertNodes(editor, citationNode, { select: true });

    // // Get the current selection after insertion
    // const { selection } = editor;

    // if (selection) {
    //   // Get the path of the start of the selection
    //   const { anchor } = selection;
    //   let citationPath = anchor.path;
    //   citationPath = [citationPath[0], citationPath[1]];

    //   intelliCite(editor, citationPath, showNumbering);
    // } else {
    //   console.warn('No selection found after insertion.');
    // }
  } else {
    const citationNode = {
      type: 'citation',
      number: totalReferences(editor),
      citation: citationFormat(
        citationData.Author,
        citationData.PublicationDate
      ),
      metadata,
      children: [{ text: '' }],
    };

    // Insert the node
    Transforms.insertNodes(editor, citationNode, { select: true });

    // Get the current selection after insertion
    // const { selection } = editor;

    // if (selection) {
    //   // Get the path of the start of the selection
    //   const { anchor } = selection;
    //   let citationPath = anchor.path;
    //   citationPath = [citationPath[0], citationPath[1]];

    //   intelliCite(editor, citationPath, showNumbering);
    // } else {
    //   console.warn('No selection found after insertion.');
    // }

    // Insert the reference at bottom of document
    Transforms.insertNodes(
      editor,
      {
        type: 'reference',
        number: totalReferences(editor),
        metadata,
        reference: referenceFormat(
          citationData.Author,
          citationData.PublicationDate,
          citationData.Title,
          citationData.Venue
        ),
        children: [
          {
            text: '',
          },
        ],
      } as ReferenceNode,
      {
        at: [editor.children.length],
      }
    );

    // Always synchronize on insertion of new citations
    synchronizeAll(editor);

    // Check if reference and citation numbering
    // should be updated based where citations are inserted
    if (autoAppearanceOrder) {
      orderOfAppearance(editor);
    }
  }
};
