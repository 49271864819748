import React from 'react';

/**
 * Inserts hyperlink elements into a text string at positions specified by the `citeSpans` array.
 * Each citation link is created based on the provided citation data, including its start and end
 * positions within the text, the text of the citation, and a reference ID. The function returns
 * an array of strings and JSX elements representing the original text with citation links embedded.
 *
 * @param {string} text - The original text into which citation links will be inserted.
 * @param {Object[]} citeSpans - An array of objects, each representing a citation. Each object should have `start`, `end`, `text`, and `ref_id` properties.
 * @param {Object} context - The context object used for handling actions, like updating the application state or handling navigation when a citation link is clicked.
 * @returns {Array} An array of strings and JSX elements with citation links inserted at the appropriate positions.
 */

export const insertCitationLinks = (text, citeSpans, context) => {
  if (!citeSpans || citeSpans.length === 0) return text;

  const elements = [];
  let lastIndex = 0;

  citeSpans.forEach((citation, index) => {
    // Add text before the citation
    elements.push(text.substring(lastIndex, citation.start));

    // Add the citation link
    elements.push(
      <a
        onClick={() =>
          context.updateContext('scrollToSentenceId', 'ref-' + citation.ref_id)
        }
        key={index}
        style={{
          color: 'cornflowerblue',
          padding: '0px 2px',
          cursor: 'pointer',
        }}
      >
        {citation.text}
      </a>
    );

    // Update the lastIndex
    lastIndex = citation.end;

    // If the next characters are a space and then a comma, skip over the space
    if (text.substring(lastIndex, lastIndex + 2) === ' ,') {
      lastIndex++;
    }

    // If this is the last citation, and the next character is a space, adjust lastIndex to remove it
    if (index === citeSpans.length - 1 && text[lastIndex] === ' ') {
      lastIndex++;
    }
  });

  // Add any remaining text after the last citation
  elements.push(text.substring(lastIndex));

  return elements;
};
