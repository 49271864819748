// Library
import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Typography,
  // Avatar,
  Menu,
  MenuList,
  Paper,
  MenuItem,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Local
import { DocumentContext } from '../../DocumentContext';
import { useSignOut } from '../../../Common/HandleSignout';

const Header = ({ user }) => {
  const context = useContext(DocumentContext);

  const { doc_id: docId } = useParams();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const [accAnchor, setAccAnchor] = useState(null);
  const accOpen = Boolean(accAnchor);

  return (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        padding: '10px',
        marginBottom: '2px',
      }}
    >
      <Button
        id="basic-button"
        size="small"
        aria-controls={accOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={accOpen ? 'true' : undefined}
        onClick={(e) => setAccAnchor(e.currentTarget)}
        sx={{
          border: 'none',
        }}
      >
        {/* <Avatar
          alt="Profile picture"
          variant="square"
          src={context.user.profilePicUrl}
          sx={{ width: 24, height: 24 }}
        /> */}
        <Typography
          sx={{
            // marginLeft: '10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'none',
          }}
        >
          {context.user.name}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={accAnchor}
        open={accOpen}
        onClose={() => setAccAnchor(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: 320,
            maxWidth: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
        >
          <MenuList dense>
            <MenuItem
              onClick={() => {
                setAccAnchor(null);
                navigate('/account');
              }}
            >
              <ListItemIcon>
                <AccountCircleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Account</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAccAnchor(null);
                navigate(`/modular/${docId}`);
              }}
              sx={{
                display:
                  user.email === process.env.REACT_APP_DEMO_ACCOUNT
                    ? 'flex'
                    : 'none',
              }}
            >
              <ListItemIcon>
                <ViewQuiltOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Modular mode</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setAccAnchor(null);
                signOut();
              }}
            >
              <ListItemIcon>
                <LogoutOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
      <Tooltip title="Close panel">
        <IconButton
          className="panel"
          size="small"
          aria-label="close panel"
          onClick={() => {
            context.updateContext('togglePanel', false);
          }}
          sx={{
            border: 'none',
          }}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default Header;
