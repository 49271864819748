// Libraries
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Editor } from 'slate';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Stack,
  darken,
  lighten,
  Tooltip,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Local
import { MODEL_PROMPTING } from '../../Queries/nlp';
import { DocumentContext } from '../../DocumentContext';

const AssistToggle = () => {
  const theme = useTheme();
  const context = useContext(DocumentContext);
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        position: 'sticky',
        zIndex: '1',
        top: '0px',
        flexDirection: 'column',
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06)',
        },
        display: context.assist ? 'block' : 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: (theme) => theme.palette.action.hover },
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'Gainsboro',
          borderBottom: '1px solid lightgrey',
          '.MuiAccordionSummary-content': {
            margin: '0px !important', // Prevent margin changes
            '&.Mui-expanded': {
              margin: '0px !important', // Explicitly prevent margin changes upon expansion
            },
          },
          '&.Mui-expanded': {
            minHeight: '0px !important', // Adjust this as per your default minHeight
          },
          minHeight: '0px', // Adjust this as per your desired minHeight
          padding: '6px 5px', // Adjust padding to make it smaller
        }}
      >
        <BoltOutlinedIcon
          sx={{
            marginRight: '10px',
          }}
        />
        <Typography>AI Assistant</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Assist />
      </AccordionDetails>
    </Accordion>
  );
};

function Assist() {
  const context = useContext(DocumentContext);
  // const editor = useSlate();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const chatRef = useRef(null);

  // Scroll to bottom every time messages change
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  // Load messages from local storage
  useEffect(() => {
    const localMessages = localStorage.getItem('messages');

    if (localMessages) {
      setMessages(JSON.parse(localMessages));
    }
  }, []);

  const [modelPrompting, { loading, error }] = useLazyQuery(MODEL_PROMPTING, {
    fetchPolicy: 'network-only',
    onCompleted: ({ modelPrompting }) => {
      let conversation;

      if (modelPrompting.status === 'success' && !error) {
        conversation = [
          ...messages,
          { sender: 'Assist', text: modelPrompting.response },
        ];
      } else {
        conversation = [
          ...messages,
          {
            sender: 'Assist',
            text: "I'm sorry, I didn't understand that. Please try again.",
          },
        ];
      }

      setMessages(conversation);
      localStorage.setItem('messages', JSON.stringify(conversation));

      // make box scroll to bottom
      const element = document.getElementById('chat');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    },
  });

  const replaceTokens = (text) => {
    const lastSelectedFromPaper = context.highlightSelection;
    const lastSelectedFromEditor = Editor.string(
      context.editorRef,
      context.editorRef.selection
    );

    return text
    .replace(/\$W/g, `"${lastSelectedFromEditor}"`)
    .replace(/\$R/g, `"${lastSelectedFromPaper}"`)
    .trim();
  };

  return (
    <Box
      sx={{
        padding: '0px',
        width: '100%',
        justifyContent: 'flex-end',
        overflowY: 'auto',
        boxSizing: 'border-box',
        maxHeight: '400px',
        borderBottom: '1px solid lightgrey',
      }}
      role="presentation"
    >
      <Stack
        direction="row"
        sx={{
          width: '100%',
          display: 'flex',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '5px',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        <Typography variant="body1">Chat</Typography>
        <Stack direction={'row'}>
          <Tooltip title="Clear chat">
            <IconButton
              size="small"
              onClick={() => {
                setMessages([]);
                localStorage.setItem('messages', JSON.stringify([]));
              }}
            >
              <RestartAltOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack
        ref={chatRef}
        direction={'column'}
        sx={{
          height: '100%',
          overflowY: 'auto',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '10px',
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: '5px',
              width: '100%',
              marginBottom: '10px',
              backgroundColor: (theme) =>
                message.sender !== 'user'
                  ? theme.palette.mode === 'light'
                    ? darken(theme.palette.background.paper, 0.05)
                    : lighten(theme.palette.background.paper, 0.05)
                  : 'none',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: 'bold',
                padding: message.sender === 'user' ? 'none' : '5px',
              }}
            >
              <QuickreplyOutlinedIcon
                fontSize="smaller"
                sx={{
                  display: message.sender === 'user' ? 'none' : 'block',
                  marginRight: '5px',
                }}
              />
              {message.sender === 'user' ? 'You' : 'Assistant'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'pre-wrap',
                padding: message.sender === 'user' ? 'none' : '5px',
              }}
            >
              {message.text.split('\n').map((line, idx) => (
                <React.Fragment key={idx}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        ))}
        {loading && (
          <CircularProgress
            sx={{
              marginTop: '10px',
              height: '20px !important',
              width: '20px !important',
            }}
          />
        )}
      </Stack>
      <TextField
        disabled={loading}
        id="outlined-basic"
        placeholder="Ask a question here. Type $W to replace it with the text selected in your Write window, type $R to replace it with the text selected in your Read window. To select a text span, highlight it and press the arrows button."
        variant="outlined"
        fullWidth
        multiline
        maxRows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            // Check if Enter is pressed without Shift
            e.preventDefault(); // Prevent default to stop the enter from creating a new line
            const userMessage = e.target.value.trim();
            const modifiedMessage = replaceTokens(userMessage);

            console.log(modifiedMessage);

            setMessages([...messages, { sender: 'user', text: userMessage }]);
            setMessage('');

            modelPrompting({ variables: { text: modifiedMessage } });
          }
        }}
        sx={{
          position: 'relative',
          bottom: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 0,
            border: 'none',
            outline: 'none',
          },
          '& .MuiInputBase-input::placeholder': {
            fontFamily: 'Arial', // Change to your desired font family
            fontSize: '14px', // Change to your desired font size
            fontStyle: 'normal', // Additional styling (optional)
          },
        }}
      />
    </Box>
  );
}

export default AssistToggle;
